import { Component, OnDestroy, OnInit } from '@angular/core';
import { IimUser } from 'ui-sdk/models/iim-user.model';
import { fadeInAnimation } from '../../animations';
import { Subscription } from 'rxjs';
import {UserMethods} from 'ui-sdk';

@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.scss'],
  animations: [fadeInAnimation]
})
export class AdminPageComponent implements OnInit, OnDestroy {
  currentUser: IimUser;
  subscriptions: Subscription = new Subscription();
  selectedItem: string | null = null;
  constructor() {}

  ngOnInit() {
    const userSubscription = UserMethods.currentUser$.subscribe(
      (user: IimUser) => {
        this.currentUser = user;
      }
    );
    this.subscriptions.add(userSubscription);
  }

  select(item: string){
    this.selectedItem = item;
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
