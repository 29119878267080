import { Component, OnInit, ViewChild, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { fadeInDownAnimation, fadeInOutAnimation, fadeInAnimation, fadeInLeftAnimation } from 'src/app/animations';
import { ClrForm } from '@clr/angular';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { ClientService } from 'src/app/services/client.service';
import { IimUser } from 'ui-sdk/models/iim-user.model';
import { Subscription } from 'rxjs';
import {UserMethods} from 'ui-sdk';
import {LegalHoldMethods} from 'ui-sdk';

@Component({
  selector: 'app-legal-hold-modal',
  templateUrl: './legal-hold-modal.component.html',
  styleUrls: ['./legal-hold-modal.component.scss'],
  animations: [
    fadeInDownAnimation,
    fadeInOutAnimation,
    fadeInAnimation,
    fadeInLeftAnimation
  ]
})

export class LegalHoldModalComponent implements OnInit, OnDestroy {
  currentUser: IimUser;
  subscriptions: Subscription = new Subscription();

  legalHoldForm: FormGroup;
  processingModalRequest: boolean = false;

  displayCurrentDocuments: Array<any> = [];
  allLegalHolds: Array<string> = [];
  selectedLegalHold: string = '';

  activeTab = '';

  @ViewChild(ClrForm) clrForm;

  constructor(private alertService: AlertService,
              private clientService: ClientService) {
  }

  @Input() selectedDocuments: Array<any> = [];
  @Input() activeLegalHoldsInfo: Array<any> = [];
  @Input() currentLegalHold: any = null;   // this is the existing Legal Hold to edit

  @Output() created: EventEmitter<any> = new EventEmitter();
  @Output() updated: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    const userSubscription = UserMethods.currentUser$.subscribe(
      (user: IimUser) => this.currentUser = user
    );

    if (this.isNewLegalHold()) {
      this.legalHoldForm = this.createLegalHoldFormGroup(null);
    } else {
      this.legalHoldForm = this.createLegalHoldFormGroup(this.currentLegalHold);
    }

    this.subscriptions.add(userSubscription);

    this.onChanges();

    this.selectedLegalHold = '';

    this.activeTab = 'addLegalHold';
   }

   ngOnDestroy() {
    this.subscriptions.unsubscribe();
   }

  isNewLegalHold() {
    return (!!!this.currentLegalHold ? true : false);
  }

  private createLegalHoldFormGroup(legalHold: any) {
    return new FormGroup({
      legalHoldAccountNumber: new FormControl({value: !!legalHold && !!legalHold.accountNumber ?
        legalHold.accountNumber : null, disabled: false}),
      legalHoldName: new FormControl({value: !!legalHold && !!legalHold.name ?
        legalHold.name : null, disabled: false}, [Validators.required]),
      legalHoldDescription: new FormControl({value: !!legalHold && !!legalHold.comment ?
        legalHold.comment : null, disabled: false}, [Validators.required]),
      legalHoldGroupId: new FormControl({ value: null, disabled: false })
    });
  }

  onChanges() {
    this.subscriptions.add(this.legalHoldForm.get('legalHoldGroupId').valueChanges.subscribe((legalHoldGroupId: any) => {
      this.selectedLegalHold = legalHoldGroupId;

      this.legalHoldSelect(legalHoldGroupId);
    }));
  }

  legalHoldSelect(legalHoldId) {
    if (legalHoldId) {
      this.selectedLegalHold = legalHoldId;

      this.displayCurrentDocuments = [];
      let currentDocIds = [];

      this.activeLegalHoldsInfo.forEach(selectedLegalHoldObject => {
        if (selectedLegalHoldObject.legalHoldId === legalHoldId) {
          currentDocIds = selectedLegalHoldObject.documentIds;
        }
      });

      this.selectedDocuments.forEach(documentObject => {
        if (currentDocIds.includes(documentObject.id)) {
          this.displayCurrentDocuments.push(documentObject);
        }
      });

    } else {
      this.selectedLegalHold = '';
      this.legalHoldForm.get('legalHoldName').setValue(!!legalHoldId && !!legalHoldId.legalHoldName ?
        legalHoldId.legalHoldName : null);
      this.legalHoldForm.get('legalHoldDescription').setValue(!!legalHoldId && !!legalHoldId.legalHoldDescription ?
        legalHoldId.legalHoldDescription : null);
      this.legalHoldForm.get('legalHoldAccountNumber').setValue(!!legalHoldId && !!legalHoldId.accountNumber ?
        legalHoldId.accountNumber : null);
    }
  }

  onSubmit() {
    if (!this.isNewLegalHold()) {
      this.updateLegalHold();
    } else {
      if (this.activeTab === 'addLegalHold' && this.legalHoldForm.valid) {
        this.saveLegalHold();
      } else if (this.activeTab === 'removeLegalHold') {
        this.removeLegalHold();
      } else {
        this.clrForm.markAsDirty();
      }
    }
  }

  updateLegalHold() {
    const legalHold = { name: this.legalHoldForm.get('legalHoldName').value,
                        comment: this.legalHoldForm.get('legalHoldDescription').value,
                        legalHoldId: this.currentLegalHold.legalHoldId
                      };

    if (this.legalHoldForm.get('legalHoldAccountNumber').value) {
      legalHold['accountNumber'] = this.legalHoldForm.get('legalHoldAccountNumber').value;
    }

    this.processingModalRequest = true;

    this.clientService.updateLegalHold(legalHold).subscribe(response  => {
        this.processingModalRequest = false;

        this.updated.emit(legalHold);
        this.close.emit();
        this.alertService.showSuccessToast(`Legal Hold '
                                           ${legalHold.name}
                                           ' updated'`, undefined);
    }, error => { // Error for createLegalHold
          this.processingModalRequest = false;
      if (error.status === 403) {
        this.alertService.showError('Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [UPDATE DocumentLegalHold].`);
      } else {
        this.alertService.showError('Error', `Unable to update Legal Hold`);
      }

      this.close.emit();
    });
  }

  saveLegalHold() {
    const legalHold = { name: this.legalHoldForm.get('legalHoldName').value,
                        comment: this.legalHoldForm.get('legalHoldDescription').value,
                        documentIds: this.selectedDocuments.map(doc => doc.id)};

    if (this.legalHoldForm.get('legalHoldAccountNumber').value) {
      legalHold['accountNumber'] = this.legalHoldForm.get('legalHoldAccountNumber').value;
    }

    this.processingModalRequest = true;

    LegalHoldMethods.createLegalHold(legalHold).subscribe(()  => {
    this.processingModalRequest = false;

    // this.created.emit();
    this.legalHoldForm.reset();
    // this.close.emit();
    this.alertService.showSuccessToast(`Legal Hold '
                                       ${legalHold.name}
                                       ' Created for '
                                       ${legalHold.documentIds.length}
                                       '  document(s)`, undefined);
    },
    error => { // Error for createLegalHold
      this.processingModalRequest = false;
      if (error.status === 403) {
        this.alertService.showError('Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [CREATE DocumentLegalHold].`);
      } else {
        this.alertService.showError('Error', `Unable to create Legal Hold for ${legalHold.documentIds.length}' document(s)`);
      }
      this.close.emit();
    });
  }

  removeLegalHold() {
    this.alertService.confirmDangerousAction('Remove Legal Hold?', `Are you sure you want to remove Legal Hold '${this.legalHoldForm.get('legalHoldGroupId').value}' for selected '${this.displayCurrentDocuments.length}' document(s)?`).then(confirmed => {
      if (confirmed) {
        this.processingModalRequest = true;

        const legalHold = {
          documentIds: this.displayCurrentDocuments.map(doc => doc.id)
          };

        const legalHoldPayload = JSON.stringify(legalHold);

        this.clientService.removeDocumentsFromLegalHold(legalHoldPayload, this.selectedLegalHold).subscribe(() => {
          this.processingModalRequest = false;

          for (let i = 0; i < this.activeLegalHoldsInfo.length; i++) {
            const legalHold = this.activeLegalHoldsInfo[i];

            if (legalHold.legalHoldId === this.selectedLegalHold) {
              this.activeLegalHoldsInfo.splice(i, 1);
            }
          }

          this.legalHoldSelect(this.activeLegalHoldsInfo.length > 0 ? this.activeLegalHoldsInfo[0]['legalHoldId'] : '');
          this.alertService.showSuccessToast(`Legal Hold '${this.selectedLegalHold}' succesfully removed for selected '${this.displayCurrentDocuments.length}' document(s).`, undefined);

        }, error => {
          if (error.status === 403) {
            this.alertService.showError('Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [UPDATE DocumentLegalHold].`);
          } else {
            this.alertService.showError('Error', `Failed to delete ${this.selectedLegalHold} Legal Hold. Please try again.`);
          }
        });
      }
    }, onrejected => {
    });
  }

  closeModal() {
    if (document.getElementById('new-legalHold-submit-trigger')) {
      document.getElementById('new-legalHold-submit-trigger')['disabled'] = true;
    }

    // propagate event to Search Page component to Refetch documents
    if (this.isNewLegalHold()) {
      this.alertService.emitChildEvent('Refetch Documents');
    }

    this.selectedLegalHold = '';

    this.legalHoldForm.reset();
    this.close.emit();
  }
}
