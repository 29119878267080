import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { IimUser } from 'ui-sdk/models/iim-user.model';
import {ClrForm} from '@clr/angular';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AlertService} from '../../../services/alert.service';
import {DocumentMethods} from 'ui-sdk';

@Component({
  selector: 'app-create-legal-hold-modal',
  templateUrl: './create-legal-hold-modal.component.html',
  styleUrls: ['./create-legal-hold-modal.component.scss']
})
export class CreateLegalHoldModalComponent implements OnInit {

  currentLoginUser: IimUser;
  createLegalHoldsForm;

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Input() documentData: any;

  @ViewChild(ClrForm) clrForm;
  submitInProgress: boolean;

  constructor(private alertService: AlertService) {

  }
  ngOnInit() {
    this.createLegalHoldsForm = this.createLegalHoldsFormGroup();
  }

  private createLegalHoldsFormGroup() {
    return new FormGroup({
      holdName: new FormControl({
        value: null,
        disabled: false
      }, [Validators.required]),
      reasonForHold: new FormControl({
        value: null,
        disabled: false
      }, [Validators.required]),
      notify: new FormControl({
        value: false,
        disabled: false
      }, null),
      impliedHold: new FormControl({
        value: false,
        disabled: false
      }, null),
      consent: new FormControl({
        value: false,
        disabled: false
      }, null)
    });
  }

  closeModal() {
    this.close.emit();
  }

  clickHiddenSubmitButton() {
    document.getElementById('create-legal-hold-submit').click();
  }

  onSubmitLegalHolds() {
    const docIds = this.documentData.selected.map((doc)=> doc.id);
    this.submitInProgress = true;
    DocumentMethods.createDocumentLegalHold(
      this.documentData.selected[0].documentTypeId,
      docIds,
      this.createLegalHoldsForm.value.holdName,
      this.createLegalHoldsForm.value.reasonForHold,
      this.createLegalHoldsForm.value.notify,
      this.createLegalHoldsForm.value.impliedHold,
      this.createLegalHoldsForm.value.consent
    ).then((res) => {
      this.submitInProgress = false;
      this.close.emit();
    }, (err) => {
      this.submitInProgress = false;
      const [title, msg] = [err.statusText, err.error?.messagee];
      this.alertService.showError(title, msg);
    });
  }
}
