import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {AlertService} from 'src/app/services/alert.service';
import {IimUser} from 'ui-sdk/models/iim-user.model';
import {IimClient} from 'ui-sdk/models/iim-client.model';
import {Subscription} from 'rxjs';
import {ClrForm} from '@clr/angular';
import {filter} from 'rxjs/operators';
import {UserMethods} from 'ui-sdk';
import {ClientMethods} from 'ui-sdk';
import {HttpHeaders} from '@angular/common/http';
import {fadeInAnimation} from '../../../animations';
import {IdmRole, IdmRoleMethods} from 'ui-sdk';
import {FileSizeCalc} from '../../../utility/fie-size-calc';
import {faEnvelopeOpenText, faEnvelope, faComment, faPrint, faFax} from '@fortawesome/free-solid-svg-icons';
import {FaIconLibrary} from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-client-onboard-modal',
  templateUrl: './client-onboard-modal.component.html',
  styleUrls: ['./client-onboard-modal.component.scss'],
  animations: [fadeInAnimation]
})

export class ClientOnboardModalComponent implements OnInit, OnDestroy {
  copyIdToClipboard: string;
  timer;

  fileUploadPreprocessorOptions = [
    'Base64Decode',
    'None'
  ];

  currentLoginUser: IimUser;
  @Input() modalEnabled: boolean;

  @Input('permissions')
  set permissions(value: any) {
    if (value) {
      this._permissions = value;
    }
  }

  @Input('existingClientIdmRoles')
  set existingClientIdmRoles(value: any) {
    if (value != null && value.length > 0) {
      this.companyIds = [];

      value.forEach(v => {
        const roles = v.roles;
        this.companyIds.push(v.companyId);

        if (roles) {
          Object.keys(roles).forEach(key => {
            this.clientRoles.set(key, {
              roleValue: roles[key],
              companyId: v.companyId
            });
          });
        }
      });

      if (this._selectedRoleName) {
        const roleToEditFromRedirect = {
          key: this._selectedRoleName,
          value: this.clientRoles.get(this._selectedRoleName)
        };

        this.editRole(roleToEditFromRedirect);
      }

      this.rolesExistButEmpty = true;
    }
  }

  _selectedClient: IimClient;
  @Input('selectedClient')
  set selectedClient(value: IimClient) {
    if (value) {
      this._selectedClient = value;
      this.clientOnBoardForm = this.createClientOnBoardFormGroup();
    }
  }

  _selectedRoleName: string;
  @Input('selectedRoleName')
  set selectedRoleName(value: string) {
    if (value) {
      this._selectedRoleName = value;
    }
  }

  constructor(private alertService: AlertService, library: FaIconLibrary) {
    library.addIcons(faEnvelopeOpenText);
    library.addIcons(faEnvelope);
    library.addIcons(faComment);
    library.addIcons(faPrint);
    library.addIcons(faFax);
  }
  subscriptions: Subscription = new Subscription();

  allClients: Array<IimClient> = [];

  @Output() changed: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Input() archiveProviders: Array<string>;
  @Input() loading: boolean;

  SUPER_USER_PERM_CODE = 'A';
  _permissions: any;
  clientRoles: Map<string, object> = new Map<string, object>();
  companyIds: Array<string> = [];
  contentTypeFormArray: FormArray;
  deliveryMethodsFormArray: FormArray;
  userAttributesFormArray: FormArray;
  otherAttributesFormArray: FormArray;
  entitlementFormArray: FormArray;

  rolesExistButEmpty: boolean = false;
  currentRoleEditName: string;
  selectedRoleCompanyId: string;
  permsChanged: boolean = false;
  rolesChanged: boolean = false;
  isRemoveAllClientRoles: boolean = false;

  rolesToDelete: Set<string> = new Set<string>();

  clientOnboardPhase: string = '';
  onboardedClientId: string = '';
  client: String;
  clientOnboardStatusCheckCounter = 0;
  clientOnBoardForm: FormGroup;
  processingModalRequest: boolean = false;
  logoFile: any;

  @ViewChild(ClrForm) clrForm;
  @ViewChild('fileInput') fileInput: ElementRef;

  uploadInProgress: boolean;
  fileDropRef: any;

  perms: { selected: Array<any>; current: Array<any> } = {
    selected: [],
    current: []
  };

  isSuperUserSelected = false;

  showCompanyIdModal: boolean = false;
  isValidCompanyId: boolean = false;
  companyIdToAdd: string = '';
  createCompanyIdLoading: boolean = false;

  ngOnInit() {
    const userSubscription = UserMethods.currentUser$.subscribe(
      (loginUser: IimUser) => this.currentLoginUser = loginUser
    );
    this.subscriptions.add(userSubscription);

    this.clientOnBoardForm = this.createClientOnBoardFormGroup();
  }

  private createClientOnBoardFormGroup() {
    const form = new FormGroup({
      roleName: new FormControl({
        value: null,
        disabled: false
      }),
      clientName: new FormControl({
        value: this._selectedClient && this._selectedClient.name,
        disabled: false
      }, [Validators.required]),
      archiveProvider: new FormControl({
        value: null,
        disabled: false
      },
        [Validators.required]),
      fileUploadPreprocessor: new FormControl({
        value: this._selectedClient?.fileUploadPreprocessor ? this._selectedClient?.fileUploadPreprocessor : null,
        disabled: false
        }),
      username: new FormControl({
        value: null,
        disabled: false
      }, null),
      password: new FormControl({
        value: null,
        disabled: false
      }, null),
      url: new FormControl({
        value: null,
        disabled: false
      }, null),
      externalClientId: new FormControl({
        value: this._selectedClient && this._selectedClient.externalClientId,
        disabled: false
      }, null),
      allowPreferenceUpdate: new FormControl({
        value: !!(this._selectedClient && this._selectedClient.deliveryPreferences?.allowPreferenceUpdate),
        disabled: false
      }, null),
      allowEmptyPreferences: new FormControl({
        value: !!(this._selectedClient && this._selectedClient.deliveryPreferences?.allowEmptyPreferences),
        disabled: false
      }, null),
      allowSearchBySubClientId: new FormControl({
        value: !!(this._selectedClient && this._selectedClient.deliveryPreferences?.allowSearchBySubClientId),
        disabled: false
      }, null),
      isPrintDefaultIfNoPreferences: new FormControl({
        value: !!(this._selectedClient && this._selectedClient.deliveryPreferences?.isPrintDefaultIfNoPreferences),
        disabled: this._selectedClient && this._selectedClient.deliveryPreferences?.isCustomErrorMsgIfNoPreferences !== null
          && this._selectedClient.deliveryPreferences?.isCustomErrorMsgIfNoPreferences === true
      }, null),
      isCustomErrorMsgIfNoPreferences: new FormControl({
        value: !!(this._selectedClient && this._selectedClient.deliveryPreferences?.isCustomErrorMsgIfNoPreferences),
        disabled: this._selectedClient && this._selectedClient.deliveryPreferences?.isPrintDefaultIfNoPreferences !== null
          && this._selectedClient.deliveryPreferences?.isPrintDefaultIfNoPreferences === true
      }, null),
      customErrorMsgNoPreferences: new FormControl({
        value: this._selectedClient && this._selectedClient.deliveryPreferences?.customErrorMsgNoPreferences,
        disabled: false
      }, null),
      enablePreference: new FormControl({
        value: !!(this._selectedClient && this._selectedClient.deliveryPreferences?.termsAndConditions?.enabled),
        disabled: false
      }, null),
      contentTypeOption: new FormControl({
        value: this._selectedClient?.deliveryPreferences?.contentTypeOption || 'MULTIPLE',
        disabled: false
      }, null),
      termsHtml: new FormControl({
        value: this._selectedClient && this._selectedClient.deliveryPreferences?.termsAndConditions?.termsHtml,
        disabled: false
      }, null),
      otp: new FormControl({
        value: this._selectedClient?.deliveryPreferences?.otpAuthenticate?.otp,
        disabled: false
      }, null),
      otpOptions: new FormControl({
        value: this._selectedClient?.deliveryPreferences?.otpAuthenticate?.otpOptions,
        disabled: false
      }, null),
      searchResultsActionButtons: new FormControl({
        value: null,
        disabled: false
      }, null),
      goPaperless: new FormControl({
        value: !!(this._selectedClient && this._selectedClient?.deliveryPreferences?.goPaperless),
        disabled: false
      }, null),
      setFooter: new FormControl({
        value: !!(this._selectedClient && this._selectedClient?.deliveryPreferences?.setFooter),
        disabled: false
      }, null),
      toolTipContent: new FormControl({
        value: this._selectedClient && this._selectedClient.deliveryPreferences?.toolTipContent,
        disabled: false
      }, null),
      maxEmail: new FormControl({
        value: this._selectedClient && this._selectedClient.deliveryPreferences?.maxEmail,
        disabled: false
      }, null),
      maxMobileNumber: new FormControl({
        value: this._selectedClient && this._selectedClient.deliveryPreferences?.maxMobileNumber,
        disabled: false
      }, null),
      contentTypeFormArray: new FormArray([]),
      deliveryMethodsFormArray: new FormArray([]),
      userAttributesFormArray: new FormArray([]),
      otherAttributesFormArray: new FormArray([]),
      entitlementFormArray: new FormArray([]),
    });

    if (this._selectedClient && (this._selectedClient.searchResultsConfig == null || !Object.keys(this._selectedClient.searchResultsConfig))) {
      form.get('searchResultsActionButtons').setValue('viewAndDownloadDocument');

    } else if (this._selectedClient) {
      const actionButtons = this._selectedClient.searchResultsConfig['actionButtons'];
      if (!actionButtons || actionButtons !== 'viewBatchAndGenerateReport') {
        form.get('searchResultsActionButtons').setValue('viewAndDownloadDocument');

      } else if (actionButtons === 'viewBatchAndGenerateReport') {
        form.get('searchResultsActionButtons').setValue('viewBatchAndGenerateReport');
      }
    }

    if (this._selectedClient?.deliveryPreferences?.contentTypes?.length) {
      form.controls.contentTypeFormArray = new FormArray(this._selectedClient.deliveryPreferences.contentTypes.map(content => new FormGroup({
        key: new FormControl({value: content?.key, disabled: false}, [Validators.required]),
        label: new FormControl({value: content?.label, disabled: false}, [Validators.required]),
      })));
    }

    if (this._selectedClient?.deliveryPreferences?.deliveryMethods?.length) {
      form.controls.deliveryMethodsFormArray = new FormArray(this._selectedClient.deliveryPreferences.deliveryMethods.map(deliveryMethod => new FormGroup({
        name: new FormControl({value: deliveryMethod?.name, disabled: false}, [Validators.required]),
        icon: new FormControl({value: deliveryMethod?.icon, disabled: false}, [Validators.required]),
        label: new FormControl({value: deliveryMethod?.deliveryChannel?.label, disabled: false}, [Validators.required]),
        displayType: new FormControl({value: deliveryMethod?.deliveryChannel?.displayType || 'inputbox', disabled: false}, [Validators.required]),
        enableTandC: new FormControl({value: deliveryMethod?.enableTandC, disabled: false}, [])
      })));
    }
    if (this._selectedClient?.deliveryPreferences?.userFields?.length) {
      form.controls.userAttributesFormArray = new FormArray(this._selectedClient.deliveryPreferences.userFields.map(userField => new FormGroup({
        ipmAttribute: new FormControl({value: userField?.ipmAttribute, disabled: false}, [Validators.required, Validators.pattern('[a-zA-Z0-9.]*')]),
        dpLabel: new FormControl({value: userField?.dpLabel, disabled: false}, [Validators.required]),
      })));
    }

    if (this._selectedClient?.deliveryPreferences?.otherAttributes?.length) {
      form.controls.otherAttributesFormArray = new FormArray(this._selectedClient.deliveryPreferences.otherAttributes.map(userField => new FormGroup({
        attributeValue: new FormControl({value: userField?.attributeValue, disabled: false}, [Validators.required, Validators.pattern('[a-zA-Z0-9.]*')]),
        label: new FormControl({value: userField?.label, disabled: false}, [Validators.required]),
      })));
    }

    if (this._selectedClient?.clientEntitlements) {
      form.controls.entitlementFormArray = new FormArray(Object.entries(this._selectedClient.clientEntitlements).map(([clientEntitlementName, clientEntitlementType]) => new FormGroup({
        clientEntitlementName: new FormControl({value: clientEntitlementName, disabled: false}, [Validators.required, Validators.pattern('[a-zA-Z0-9.]*')]),
        clientEntitlementType: new FormControl({value: clientEntitlementType, disabled: false}, [Validators.required]),
      })));
    }

    if (this._selectedClient) {
      form.get('archiveProvider').setValidators(null);
    }

    this.contentTypeFormArray = <FormArray> form.get('contentTypeFormArray');
    this.deliveryMethodsFormArray = <FormArray> form.get('deliveryMethodsFormArray');
    this.userAttributesFormArray = <FormArray> form.get('userAttributesFormArray');
    this.otherAttributesFormArray = <FormArray> form.get('otherAttributesFormArray');
    this.entitlementFormArray = <FormArray> form.get('entitlementFormArray');

    form.get('otp').valueChanges.subscribe((val) => {
        val ? form.get('otpOptions').setValidators([Validators.required]) : form.get('otpOptions').clearValidators();
        form.get('otpOptions').updateValueAndValidity();
    });
    return form;
  }

  onArchiveProviderChange(value) {
    if (value && value !== 'IIM_Native') {
      this.clientOnBoardForm.controls.username.setValidators([Validators.required]);
      this.clientOnBoardForm.controls.password.setValidators([Validators.required]);
    } else {
      this.clientOnBoardForm.controls.username.setValidators(null);
      this.clientOnBoardForm.controls.password.setValidators(null);

      this.clientOnBoardForm.controls.username.setValue(null);
      this.clientOnBoardForm.controls.password.setValue(null);
    }
    this.clientOnBoardForm.controls.username.updateValueAndValidity();
    this.clientOnBoardForm.controls.password.updateValueAndValidity();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
   }

   closeModal() {
    this.clientRoles.clear();
    this._selectedClient = null;
    this.clientOnBoardForm.reset();
    this.isRemoveAllClientRoles = false;
    this.permsChanged = false;
    this.rolesChanged = false;
    this._selectedRoleName = null;
    this.clientOnboardPhase = '';
    this.companyIds = [];
    this.logoFile = null;
    this.resetRoleInputs();
    this.close.emit();
  }

  createPayload() {
    const payload = {
      name: this.clientOnBoardForm.value.clientName,
      apiCredentials: [
        {
          providerType: this.clientOnBoardForm.value.archiveProvider,
          username: this.clientOnBoardForm.value.username,
          password: this.clientOnBoardForm.value.password,
          url: this.clientOnBoardForm.value.url ? this.clientOnBoardForm.value.url : null
        }
      ],
      fileUploadPreprocessor: this.clientOnBoardForm.value.fileUploadPreprocessor
    };
    return [payload];
  }

  onSubmitClientOnBoard() {
    this.uploadInProgress = true;
    const blob = new Blob([JSON.stringify(this.createPayload())], { type: 'application/json' });

    if (this._selectedClient) {
      const deliveryMethods = this.clientOnBoardForm.controls.deliveryMethodsFormArray.value;
      let sameData = deliveryMethods.filter((deliveryMethod) => {
        return (deliveryMethods.filter((dm) => dm.name === deliveryMethod.name).length >= 2);
      });
      if (sameData.length >= 1) {
        this.alertService.showError('Validation Error', `Delivery Method name should be unique`);
        this.uploadInProgress = false;
        return;
      }
      sameData = deliveryMethods.filter((deliveryMethod) => {
        return (deliveryMethods.filter((dm) => dm.icon === deliveryMethod.icon).length >= 2);
      });
      if (sameData.length >= 1) {
        this.alertService.showError('Validation Error', `Delivery Method icon should be unique`);
        this.uploadInProgress = false;
        return;
      }
      const successMessage = 'Client updated successfully';
      const payload: IimClient = {
        name: this.clientOnBoardForm.value.clientName,
        externalClientId: this.clientOnBoardForm.value.externalClientId,
        deliveryPreferences: {
          termsAndConditions: {
            enabled:  this.clientOnBoardForm.value.enablePreference,
            termsHtml:  this.clientOnBoardForm.value.termsHtml
          },
          otpAuthenticate: {
            otp: this.clientOnBoardForm.value.otp,
          },
          goPaperless: this.clientOnBoardForm.value.goPaperless,
          setFooter: this.clientOnBoardForm.value.setFooter,
          toolTipContent: this.clientOnBoardForm.value.toolTipContent,
          maxEmail: this.clientOnBoardForm.value.maxEmail,
          maxMobileNumber: this.clientOnBoardForm.value.maxMobileNumber,
          allowPreferenceUpdate: this.clientOnBoardForm.value.allowPreferenceUpdate,
          allowEmptyPreferences: this.clientOnBoardForm.value.allowEmptyPreferences,
          allowSearchBySubClientId: this.clientOnBoardForm.value.allowSearchBySubClientId,
          isPrintDefaultIfNoPreferences: this.clientOnBoardForm.value.isPrintDefaultIfNoPreferences,
          isCustomErrorMsgIfNoPreferences: this.clientOnBoardForm.value.isCustomErrorMsgIfNoPreferences,
          customErrorMsgNoPreferences: this.clientOnBoardForm.value.customErrorMsgNoPreferences,
          contentTypeOption: this.clientOnBoardForm.value.contentTypeOption,
          contentTypes: this.clientOnBoardForm.controls.contentTypeFormArray.value,
          userFields: this.clientOnBoardForm.controls.userAttributesFormArray.value,
          otherAttributes: this.clientOnBoardForm.controls.otherAttributesFormArray.value,
          deliveryMethods: this.clientOnBoardForm.controls.deliveryMethodsFormArray.value.map((method) => {
            const newMethod = { ...method };
            delete newMethod.label;
            delete newMethod.displayType;
            return {
              ...newMethod,
              deliveryChannel: {
                label: method.label,
                displayType: method.displayType
              }
            };
          })
        },
        fileUploadPreprocessor: this.clientOnBoardForm.value.fileUploadPreprocessor,
        clientEntitlements: this.createClientEntitlements(this.clientOnBoardForm.controls.entitlementFormArray.value)
      };
      if (this.clientOnBoardForm.value.otp) {
        payload.deliveryPreferences.otpAuthenticate.otpOptions = this.clientOnBoardForm.value.otpOptions;
      }

      if (this.clientOnBoardForm.value.searchResultsActionButtons) {
        payload.searchResultsConfig = {
          actionButtons: this.clientOnBoardForm.value.searchResultsActionButtons
        };
      }

      ClientMethods.updateClient(this._selectedClient.id, payload).subscribe((response: any) => {
        this.manageClientRoles();
        this.alertService.showSuccessToast('Success', successMessage);
        this.uploadInProgress = false;
        this.processingModalRequest = false;
        this.closeModal();
      }, error => { // Error for update client
        if (error.status === 403) {
          this.alertService.showError('Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [CREATE Client].`);
        }
        this.uploadInProgress = false;
        this.processingModalRequest = false;
        this.closeModal();
      });

    } else {
      this.clientOnboardPhase = 'Creating client';

      ClientMethods.onboardClients(blob).subscribe((response: any) => {
        this.onboardedClientId = response.clientIds[this.clientOnBoardForm.value.clientName];
        this.checkEventStatus(response.eventId, response.clientIds[this.clientOnBoardForm.value.clientName]);
      }, error => { // Error for create user
        if (error.status === 403) {
          this.alertService.showError('Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [CREATE Client].`);
        } else {
          this.alertService.showError('Error', error.error.message);
        }
        this.uploadInProgress = false;
        this.processingModalRequest = false;
        this.closeModal();
      });
    }
  }

  createClientEntitlements(entitlementFormArrayValue: Array<any>) {
    const clientEntitlements = {};
    entitlementFormArrayValue.forEach(({ clientEntitlementName, clientEntitlementType }) => {
      clientEntitlements[clientEntitlementName] = clientEntitlementType;
    });
    return clientEntitlements;
  }

  uploadLogo(clientId) {
    if (this.logoFile) {
      const metaData = {
        'fileName': this.logoFile.name,
        'fileType': this.logoFile.type
      };

      this.clientOnboardPhase = 'Saving client logo';

      ClientMethods.clientLogoMetaData(clientId, metaData).subscribe((uploadMetadataResponse: any) => {
        const fileNames = Object.keys(uploadMetadataResponse);
        const uploadUrls = Object.entries(uploadMetadataResponse);
        let successfullUploadsCounter = 0;
        for (const [fileName, uploadUrl] of uploadUrls) {
          const blob = new Blob([this.logoFile], {type: 'application/octet-stream'});

          const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  this.logoFile.type
                })
            };

          ClientMethods.uploadClientLogo(uploadUrl, blob, httpOptions).then(() => {
            successfullUploadsCounter++;
            if (successfullUploadsCounter === fileNames.length) {
              this.manageClientRoles(clientId);
            }

          }, error => {
            this.manageClientRoles(clientId, true);
            this.alertService.showWarning('Oops, looks like something went wrong', error['error']['message']);
            this.uploadInProgress = false;
            this.processingModalRequest = false;
            this.closeModal();
          });
        }
      },
      error => { // Error for create user
        this.manageClientRoles(clientId, true);
        this.alertService.showWarning('Oops, looks like something went wrong', error['error']['message']);
        this.uploadInProgress = false;
        this.processingModalRequest = false;
        this.closeModal();
      });
    } else {
      this.manageClientRoles(clientId);
    }
  }

  addClientToUser(clientName, clientId) {
    this.clientOnboardPhase = 'Adding the client to user';

    const userSubscription = UserMethods.getUsers().pipe(
      filter(users => true)
    ).subscribe(users => {
      const currentUserData = users.filter((user) => user.userName === this.currentLoginUser.firstName);

      currentUserData[0].clients[clientId] = clientName;
      UserMethods.updateUser(currentUserData[0]).subscribe(response  => {
        this.uploadLogo(clientId);
      },
        error => {
          this.alertService.showWarning('Oops, looks like something went wrong', error['error']['message']);
          this.uploadInProgress = false;
          this.processingModalRequest = false;
          this.closeModal();
        });

    }, error => {
      this.alertService.showWarning('Oops, looks like something went wrong', error['error']['message']);
      this.uploadInProgress = false;
      this.processingModalRequest = false;
      this.closeModal();
    });

    this.subscriptions.add(userSubscription);
  }

  checkEventStatus(eventId, clientId) {
    this.clientOnboardStatusCheckCounter++;

    ClientMethods.checkOnboardStatus(eventId).subscribe(status => {
      const eventStatus = status['description'].toLowerCase();
      if ((eventStatus.includes('started') || eventStatus.includes('in_progress')) && this.clientOnboardStatusCheckCounter < 20) {
        const timeoutLength = 500 + (this.clientOnboardStatusCheckCounter * 200);
        setTimeout(() => {
          this.checkEventStatus(eventId, clientId);
        }, timeoutLength);
      } else if (eventStatus.includes('failed')) {
        this.alertService.showError('Error', status['description']);

        this.uploadInProgress = false;
        this.processingModalRequest = false;
        this.closeModal();

        this.clientOnboardStatusCheckCounter = 0;
      } else if (eventStatus.includes('completed')) {
        this.addClientToUser(this.clientOnBoardForm.value.clientName, clientId);
        this.clientOnboardStatusCheckCounter = 0;
      } else {
        this.alertService.showWarning('Oops, looks like something went wrong', status['description']);

        this.uploadInProgress = false;
        this.processingModalRequest = false;
        this.closeModal();
      }
    });
  }

  saveButtonEnabled() {
    // Something has to have changed in the form
    return (this.clientOnBoardForm.dirty && this.clientOnBoardForm.valid && this.clientOnBoardForm.controls.userAttributesFormArray.valid && this.clientOnBoardForm.controls.otherAttributesFormArray.valid && this.clientOnBoardForm.controls.contentTypeFormArray.valid)
      || (this._selectedClient && ((this.clientRoles.size > 0 && this.rolesChanged) || this.updateClientValueChange()))
      || this.isRemoveAllClientRoles
      || (this.clientOnBoardForm.controls.entitlementFormArray.dirty && this.clientOnBoardForm.controls.entitlementFormArray.valid);
  }

  updateClientValueChange() {
    return (this._selectedClient && this.clientOnBoardForm.value.clientName && (this.clientOnBoardForm.value.clientName !== this._selectedClient.name || this.clientOnBoardForm.value.externalClientId !== this._selectedClient.externalClientId));
  }

  fileBrowserHandler(file: File) {
    this.onFileChange(file);
   }

   onFileChange(file: File) {
     if (file) {
       if (file.size > 200000) {
        this.alertService.showWarning('', 'Please select logo less then 200kb.');
      } else if (file.type.split('/')[0] !== 'image') {
         this.alertService.showWarning('', 'Please select image file.');
       } else if (file.type === 'image/gif') {
         this.alertService.showWarning('', 'Please select valid image file.');
       } else {
        this.logoFile = file;
      }
    }
  }

  deleteSelectedFile() {
    this.logoFile = null;
    this.fileDropRef.nativeElement.value = null;
  }

  closeModalSuccess(isUpdate?: boolean) {
    const documentTypeUrl = `admin/document-type?clientId=${this.onboardedClientId}`;
    let successMessage = `Client(s) successfully onboarded. Please add <a href="${documentTypeUrl}">document types</a> to complete onboarding for this client`;

    if (isUpdate) {
      successMessage = 'Client updated successfully';
      UserMethods.getUserProfile(false).then(() => {
        this.uploadInProgress = false;
        this.processingModalRequest = false;
        this.closeModal();
        this.alertService.showSuccess('Success', successMessage);
      }, error => {
        this.processingModalRequest = false;
        this.closeModal();
      });
    } else {
      this.uploadInProgress = false;
      this.processingModalRequest = false;
      this.changed.emit();
      this.alertService.showSuccess('Success', successMessage);
      this.closeModal();
    }
  }

  mapToObj(rolesMap: any): Object {
    const jsonObject = {};
    rolesMap.forEach((value, key) => {
      jsonObject[key] = value;
    });
    return jsonObject;
  }

  addDefaultRoleForNewClient(clientId?: string, errorOccurred?: boolean) {
    const defaultRole: IdmRole = {
      companyId: clientId,
      clientId: clientId,
      roles: {}
    };

    this.clientOnboardPhase = 'Creating default role';

    IdmRoleMethods.createIdmRole(defaultRole).then(() => {
      // in case on of the previous API calls failed, do not show success message after creating the default role
      if (!errorOccurred) {
        this.closeModalSuccess();
      }
    }, error => {
      if (error.status === 403) {
        const [title, msg] = ['Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [CREATE IdMRole].`];
        this.alertService.showError(title, msg);
      } else {
        this.alertService.showError('Error', 'Error occurred while attempting to create client roles');
      }
    });
  }

  manageClientRoles(clientId?: string, errorOccurred?: boolean) {
    if (clientId) {
      this.addDefaultRoleForNewClient(clientId, errorOccurred);

      // EXISTING CLIENT
    } else {
      const clientRolesRequestMap = new Map<string, IdmRole>();

      if (this.clientRoles && this.clientRoles.size) {
        this.clientRoles.forEach((roleValue, roleName) => {
          const companyId = roleValue['companyId'];
          if (!clientRolesRequestMap.has(companyId)) {
            const newRole = new IdmRole();
            newRole.clientId = this._selectedClient.id;
            newRole.companyId = companyId;
            newRole.roles = {};
            newRole.roles[roleName] = roleValue['roleValue'];

            clientRolesRequestMap.set(companyId, newRole);
          } else {
            clientRolesRequestMap.get(companyId).roles[roleName] = roleValue['roleValue'];
          }
        });
      }

      let isErrorThrown: boolean = false;
      let isSuccessShown: boolean = false;
      let totalChanges: number = clientRolesRequestMap.size;

        // no roles left for a given companyId
        if (this.rolesToDelete) {
          totalChanges += this.rolesToDelete.size;

          this.rolesToDelete.forEach(companyId => {
            const deleteUpdatePayload: IdmRole = {
              companyId: companyId,
              clientId: this._selectedClient.id,
              roles: {}
            };

            IdmRoleMethods.updateIdmRoleByCompanyId(deleteUpdatePayload).then(() => {
              totalChanges--;
              if (totalChanges < 1) {
                if (!isSuccessShown) {
                  this.closeModalSuccess(true);
                }

                isSuccessShown = true;
              }
            }, error => {
              if (!isErrorThrown) {
                if (error.status === 403) {
                  const [title, msg] = ['Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [UPDATE IdMRole].`];
                  this.alertService.showError(title, msg);
                } else {
                  this.alertService.showError('Error', 'Error occurred while attempting to delete client roles');
                }
              }

              isErrorThrown = true;
            });
          });
        }

        // create/update roles (based on companyId)
        if (clientRolesRequestMap.size > 0) {
          clientRolesRequestMap.forEach((clientRole, companyId) => {
            if (this._selectedClient && this.rolesExistButEmpty) {
              IdmRoleMethods.updateIdmRoleByCompanyId(clientRole).then(() => {
                totalChanges--;
                if (totalChanges < 1) {
                  if (!isSuccessShown) {
                    this.closeModalSuccess(true);
                  }

                  isSuccessShown = true;
                }
              }, error => {
                if (!isErrorThrown) {
                  if (error.status === 403) {
                    const [title, msg] = ['Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [UPDATE IdMRole].`];
                    this.alertService.showError(title, msg);
                  } else {
                    this.alertService.showError('Error', 'Error occurred while attempting to update client roles');
                  }
                }

                isErrorThrown = true;
              });

            } else {
              IdmRoleMethods.createIdmRole(clientRole).then(() => {
                if (this._selectedClient) {
                  totalChanges--;
                  if (totalChanges < 1) {
                    if (!isSuccessShown) {
                      this.closeModalSuccess(true);
                    }

                    isSuccessShown = true;
                  }
                } else {
                  totalChanges--;
                  if (totalChanges < 1) {
                    if (!isSuccessShown) {
                      this.closeModalSuccess();
                    }

                    isSuccessShown = true;
                  }
                }
              }, error => {
                if (!isErrorThrown) {
                  if (error.status === 403) {
                    const [title, msg] = ['Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [CREATE IdMRole].`];
                    this.alertService.showError(title, msg);
                  } else {
                    this.alertService.showError('Error', 'Error occurred while attempting to create client roles');
                  }
                }

              isErrorThrown = true;
            });
          }
        });
      }
    }
  }

  addRole() {
    const roleNameFormValueTrim = this.clientOnBoardForm.get('roleName').value?.trim();
    const roleNameFormValueSanitized = roleNameFormValueTrim?.replace(/\s/g, '').toLowerCase();
    const currentRoleEditNameTrim = this.currentRoleEditName?.trim();
    const currentRoleEditNameSanitized = currentRoleEditNameTrim?.replace(/\s/g, '').toLowerCase();

    const currentRoleNamesSanitized = [];
    Array.from(this.clientRoles.keys()).forEach(roleName => {
      currentRoleNamesSanitized.push(roleName.replace(/\s/g, '').toLowerCase());
    });

    if (currentRoleNamesSanitized.length > 0 && roleNameFormValueSanitized.length > 0) {
      if (this.currentRoleEditName) {
        if (currentRoleEditNameSanitized !== roleNameFormValueSanitized) {
          if (currentRoleNamesSanitized.includes(roleNameFormValueSanitized)) {
            this.alertService.showError('Error', 'Duplicate role name');
          } else {
            this.clientRoles.set(roleNameFormValueTrim, {
              roleValue: this.perms.selected,
              companyId: this.selectedRoleCompanyId
            });
            // in case the role name has been updated, delete the old name in the map
            this.clientRoles.delete(this.currentRoleEditName);
            this.resetRoleInputs();
            this.rolesChanged = true;
          }
        } else {
          this.clientRoles.set(currentRoleEditNameTrim, {
            roleValue: this.perms.selected,
            companyId: this.selectedRoleCompanyId
          });
          this.resetRoleInputs();
          this.rolesChanged = true;
        }
      } else {
        if (currentRoleNamesSanitized.includes(roleNameFormValueSanitized)) {
          this.alertService.showError('Error', 'Duplicate role name');
        } else {
          this.clientRoles.set(roleNameFormValueTrim, {
            roleValue: this.perms.selected,
            companyId: this.selectedRoleCompanyId
          });
          this.resetRoleInputs();
          this.rolesChanged = true;
        }
      }
    } else if (roleNameFormValueSanitized.length > 0) {
        this.clientRoles.set(roleNameFormValueTrim, {
          roleValue: this.perms.selected,
          companyId: this.selectedRoleCompanyId
        });
        this.resetRoleInputs();
        this.rolesChanged = true;
    }
  }

  editRole(role: any) {
    this.currentRoleEditName = role.key;
    this.selectedRoleCompanyId = role.value.companyId;
    this.clientOnBoardForm.get('roleName').setValue(role.key);
    this.perms.selected = role.value.roleValue;
    this.isSuperUserSelected = this.perms.selected.includes(this.SUPER_USER_PERM_CODE);
    this.permsChanged = false;
  }

  deleteRole() {
    this.clientRoles.delete(this.currentRoleEditName);

    let allRolesDeletedForCompanyId: boolean = true;
    this.clientRoles.forEach(value => {
      if (value['companyId'] === this.selectedRoleCompanyId) {
        allRolesDeletedForCompanyId = false;
        return;
      }
    });

    if (allRolesDeletedForCompanyId || this.clientRoles.size < 1) {
      this.rolesToDelete.add(this.selectedRoleCompanyId);
    }
    if (this._selectedClient && this.clientRoles.size < 1) {
      this.isRemoveAllClientRoles = true;
    }

    this.resetRoleInputs();
    this.rolesChanged = true;
  }

  resetRoleInputs() {
    this.clientOnBoardForm.get('roleName').reset();
    this.perms.selected = [];
    this.isSuperUserSelected = false;
    this.currentRoleEditName = '';
    this.selectedRoleCompanyId = '';
  }

  selectionChanged(event: any) {
    if (event.includes(this.SUPER_USER_PERM_CODE)) {
      this.perms.selected = [this.SUPER_USER_PERM_CODE];
      this.isSuperUserSelected = !this.isSuperUserSelected;
    }

    if (!this.perms.selected.includes(this.SUPER_USER_PERM_CODE)) {
      this.isSuperUserSelected = false;
    }

    this.permsChanged = true;
  }

  bytesToSize(bytes): string {
    return FileSizeCalc.bytesToSize(bytes);
  }

  roleNameChange(event) {
    this.permsChanged = event.code !== 'Space';
  }

  notify(event: any) {
    this.copyIdToClipboard = event;

    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      this.copyIdToClipboard = '';
    }, 3000);
  }

  openCompanyIdModal() {
    this.showCompanyIdModal = true;
  }

  onCompanyIdSelect(companyId: any) {
    this.selectedRoleCompanyId = companyId;
    this.permsChanged = true;
  }

  companyIdChange(companyId) {
    this.isValidCompanyId = companyId.replace(/\s/g, '').length > 0;
    this.companyIdToAdd = companyId.trim();
  }

  closeCompanyIdModal() {
    this.companyIdToAdd = '';
    this.showCompanyIdModal = false;
    this.createCompanyIdLoading = false;
  }

  saveCompanyId() {
    this.createCompanyIdLoading = true;

    const idmRole: IdmRole = {
      companyId: this.companyIdToAdd,
      clientId: this._selectedClient.id
    };

    IdmRoleMethods.createIdmRole(idmRole).then(resp => {
      this.companyIds.push(this.companyIdToAdd);
      this.alertService.showSuccessToast(`CompanyId '${this.companyIdToAdd}' created`, undefined);
      this.closeCompanyIdModal();

    }, error => {
      this.createCompanyIdLoading = false;
      this.companyIdToAdd = '';
      if (error.status === 403) {
        this.alertService.showError('Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [CREATE IdMRole].`);
      } else {
        this.alertService.showError('Error', `Unable to create companyId ${this.companyIdToAdd}. ${error.error.message}`);
      }
    });
  }

  unsorted() {}

  track(item: any, index: number) {
    return index;
  }

  onAddContentType(e) {
    e.preventDefault();
    this.contentTypeFormArray.push(new FormGroup({
      key: new FormControl({value: '', disabled: false}, [Validators.required]),
      label: new FormControl({value: '', disabled: false}, [Validators.required])
    }));
  }

  removeContentType(index) {
    this.contentTypeFormArray.removeAt(index);
    this.clientOnBoardForm.markAsDirty();
  }

  markFormDirty() {
    this.clientOnBoardForm.markAsDirty();
  }
  onAddUserAttributes(e) {
    e.preventDefault();
    this.userAttributesFormArray.push(new FormGroup({
      ipmAttribute: new FormControl({value: '', disabled: false}, [Validators.required, Validators.pattern('[a-zA-Z0-9.]*')]),
      dpLabel: new FormControl({value: '', disabled: false}, [Validators.required]),
    }));
  }
  removeUserAttributes(index) {
    this.userAttributesFormArray.removeAt(index);
    this.clientOnBoardForm.markAsDirty();
  }

  onAddOtherAttributes(e) {
    e.preventDefault();
    this.otherAttributesFormArray.push(new FormGroup({
      attributeValue: new FormControl({value: '', disabled: false}, [Validators.required, Validators.pattern('[a-zA-Z0-9.]*')]),
      label: new FormControl({value: '', disabled: false}, [Validators.required]),
    }));
  }
  removeOtherAttributes(index) {
    this.otherAttributesFormArray.removeAt(index);
    this.clientOnBoardForm.markAsDirty();
  }

  onAddDeliveryMethods(e) {
    e.preventDefault();
    this.deliveryMethodsFormArray.push(new FormGroup({
      name: new FormControl({value: '', disabled: false}, [Validators.required]),
      icon: new FormControl({value: 'envelope', disabled: false}, [Validators.required]),
      label: new FormControl({value: '', disabled: false}, [Validators.required]),
      displayType: new FormControl({value: 'inputbox', disabled: false}, [Validators.required]),
      enableTandC: new FormControl({value: false, disabled: false}, []),
    }));
  }

  removeDeliveryMethods(index) {
    this.deliveryMethodsFormArray.removeAt(index);
    this.clientOnBoardForm.markAsDirty();
  }

  onIconPickerSelect(e, i) {
    this.deliveryMethodsFormArray.controls[i].get('icon').setValue(e);
    this.clientOnBoardForm.markAsDirty();
  }

  isIIMNativeClient(client: IimClient) {
    return Object.keys(client.archiveProviders).includes('IIM_Native');
  }

  addEntitlement(e) {
    e.preventDefault();
    this.entitlementFormArray.push(new FormGroup({
      clientEntitlementName: new FormControl({value: '', disabled: false}, [Validators.required]),
      clientEntitlementType: new FormControl({value: true, disabled: false}, []),
    }));
  }

  removeEntitlement(index) {
    this.entitlementFormArray.removeAt(index);
    this.clientOnBoardForm.markAsDirty();
  }

  isPrintDefaultChange(isChecked: boolean) {
    if (isChecked) {
      this.clientOnBoardForm.get('isCustomErrorMsgIfNoPreferences').disable();

    } else {
      this.clientOnBoardForm.get('isCustomErrorMsgIfNoPreferences').enable();
    }
  }

  isCustomErrorChange(isChecked: boolean) {
    if (isChecked) {
      this.clientOnBoardForm.get('isPrintDefaultIfNoPreferences').disable();

    } else {
      this.clientOnBoardForm.get('isPrintDefaultIfNoPreferences').enable();
    }
  }
}
