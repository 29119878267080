import { ClrDatagridComparatorInterface } from '@clr/angular';

  export class CustomDocumentComparator implements ClrDatagridComparatorInterface<Object> {
    propertyName: any;

    setPropertyName(propertyName:any){
      this.propertyName = propertyName;
    }

    compare(a: any, b: any) {
      const value = a.metadata['indexes.' + this.propertyName].toLowerCase();
      const value1 = b.metadata['indexes.' + this.propertyName].toLowerCase();
      if(!isNaN(value) && !isNaN(value1)) {
         return value - value1;
      }
      return (value > value1 ? 1 : (value < value1 ? -1 : 0));
    }
  }
