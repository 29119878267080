
    <div class = "doctype-chart" style="display: block;height:100%">
        <canvas baseChart
        height="100%"
          [data]="doughnutChartData"
          [labels]="doughnutChartLabels"
          [colors]="chartColorsDocTypeIndividual"
          [legend]="'false'"
          [options]="chartOptions"
          [chartType]="doughnutChartType">
        </canvas>
      </div>
  