<clr-modal id="document-type-modal" [clrModalOpen]="modalEnabled===true" (clrModalOpenChange)="closeModal('clrModalOpenChange')"
           [clrModalStaticBackdrop]="true" [clrModalSize]="'xl'">
  <div class="modal-title">
    <div class="rectangle r1"></div>
    <div class="rectangle r2"></div>
    <h3 class="modal-title-text text1">
      <ng-container *ngIf="!currentUser?.selectedClient?.uiperms?.doctype.update">
        View Document Type [{{_selectedDocumentType?.name}}]
        <button class="btn btn-icon btn-link copy-to-clipboard-btn" title="Copy Document Type Id" [appCopyClipboard]="_selectedDocumentType?.id" (copied)="notify($event)">
          <clr-icon shape="copy-to-clipboard"></clr-icon>
        </button>

        <span *ngIf="_selectedDocumentType?.id == copyIdToClipboard" class="clipboard-copy-success clipboard-copy-success-modal">
          <clr-icon shape="check" size="14"></clr-icon>
          Copied
        </span>
      </ng-container>

      <ng-container *ngIf="isNewDocumentType() && currentUser?.selectedClient?.uiperms?.doctype.create">New Document Type</ng-container>

      <ng-container *ngIf="!isNewDocumentType() && currentUser?.selectedClient?.uiperms?.doctype.update">
        Edit Document Type [{{_selectedDocumentType?.name}}]
        <button class="btn btn-icon btn-link copy-to-clipboard-btn" title="Copy Document Type Id" [appCopyClipboard]="_selectedDocumentType?.id" (copied)="notify($event)">
          <clr-icon shape="copy-to-clipboard"></clr-icon>
        </button>

        <span *ngIf="_selectedDocumentType.id == copyIdToClipboard" class="clipboard-copy-success clipboard-copy-success-modal">
          <clr-icon shape="check" size="14"></clr-icon>
          Copied
        </span>
      </ng-container>
    </h3>
  </div>
  <div class="modal-body modal-lg">
    <form class="document-type-form" clrForm [formGroup]="documentTypeForm">
      <clr-tabs>
          <clr-tab>
              <button clrTabLink id="link1">General Info</button>
              <clr-tab-content id="doc-type-general-tab" *clrIfActive="true">
                  <clr-input-container class="form-control">
                    <label class="clr-col-xs-12" for="document-type-modal__document-type-name">Document Type Name:</label>
                    <input class="search-index-input clr-col-xs-12" type="text" clrInput placeholder="Enter Document Name" name="document-type-modal__document-type-name"
                            id="document-type-modal__document-type-name" formControlName="documentTypeName"/>
                    <clr-control-error>Please provide a valid name.</clr-control-error>
                  </clr-input-container>
                  <clr-input-container class="form-control">
                    <label class="clr-col-xs-12" for="document-type-modal__document-type-description">Document Description:</label>
                    <input class=" search-index-input clr-col-xs-12" type="text" clrInput placeholder="Enter Document Description" name="document-type-modal__document-type-description"
                            id="document-type-modal__document-type-description" formControlName="documentTypeDescription"/>
                    <clr-control-error>Please provide a valid description.</clr-control-error>
                  </clr-input-container>

                  <clr-select-container class="form-control clr-select-wrapper_height">
                    <label class="clr-col-xs-12" for="document-type-modal__archiveProvider">Archive Provider:</label>
                    <select class="search-index-input archive-provider-dropdown clr-col-xs-12" clrSelect id="document-type-modal__archiveProvider" name="document-type-modal__archiveProvider"
                            formControlName="archiveProvider">
                      <option selected disabled value="null">Select an Archive Provider</option>
                      <option *ngFor="let provider of archiveProviders" value="{{provider}}">{{provider}}</option>
                    </select>
                    <clr-control-error>Please select an Archive Provider</clr-control-error>
                  </clr-select-container>
                <clr-input-container class="form-control">
                  <label class="clr-col-xs-12" for="document-type-modal__document-max-download">Maximum Documents To Download:</label>
                  <input class=" search-index-input clr-col-xs-12" type="number" clrInput placeholder="Enter Maximum # of Documents" name="document-type-modal__document-max-download"
                         id="document-type-modal__document-max-download" onkeydown='return event.keyCode !== 69' formControlName="maxDocumentsToDownload"/>
                  <clr-control-error>Please provide a valid Number.</clr-control-error>
                </clr-input-container>
                  <clr-input-container class="form-control" *ngIf="showDocumentRetention">
                    <label class="clr-col-xs-12" for="document-type-modal__document-retention">Retention Policy:</label>
                    <input class=" search-index-input clr-col-xs-12" type="number" clrInput placeholder="Enter Retention Days" name="document-type-modal__document-retention"
                           id="document-type-modal__document-retention" onkeydown='return event.keyCode !== 69' formControlName="documentRetention"/>
                    <clr-control-error>Please provide a valid Number.</clr-control-error>
                  </clr-input-container>
              </clr-tab-content>
          </clr-tab>
          <clr-tab>
              <button clrTabLink>Search Indexes</button>
              <clr-tab-content id="doc-type-indexes-tab" *clrIfActive>

                <div class="clr-col-xs-12 clr-col-sm-12 add-index-parent">
                  <button *ngIf="currentUser?.selectedClient?.uiperms?.doctype.create" (click)="onAddIndex($event)" class="add-index-btn btn btn-link btn-no-caps"><clr-icon shape="plus-circle"></clr-icon>&nbsp;Add Index</button>
                </div>

                <p class="indexes-load-message" *ngIf="documentTypeForm.get('searchIndexFormArray').length == 0">
                  No added Search Indexes <clr-icon shape="exclamation-circle"></clr-icon>
                </p>

                  <div class="search-index-drag search-index-list" formArrayName="searchIndexFormArray" *ngFor="let searchIndex of searchIndexFormArray.controls;let i=index;trackBy:track" data-index="{{i}}" draggable='true' (dragstart)="startRowDrag($event)" (dragover)="$event.preventDefault()" (drop)="stopRowDrag($event)">
                    <div class="clr-row searchIndexFormContainer" formGroupName="{{i}}">
                      <div class="clr-row">
                      <clr-input-container class="form-control clr-col-3 table-input-container">
                        <label class="clr-col-xs-0" >Search Index Label</label>
                        <input
                              type="text"
                              clrInput
                              formControlName="label"
                              name="document-type-modal__search-index-label"
                              placeholder="Search Index Label">
                              <clr-control-error class="input-error-msg">Please provide a search index label.</clr-control-error>
                      </clr-input-container>
                      <div class="clr-col-2 no-padding datatype-container">
                          <div class="clr-row>">
                            <div class="document-type-modal__search-index-action-status clr-col-12">
                              <label class="clr-col-xs-0" >DataType</label>
                              <select class=" search-index-datatype-item document-type-modal__search-index"
                              title="Select Search Index Data Type"
                              type="text"
                              formControlName="dataType"
                              name="options"
                              (change)="dataTypeChange(searchIndex)">
                                <option value="String">String</option>
                                <option value="Number">Number</option>
                                <option value="Integer">Integer</option>
                                <option value="Date">Date</option>
                                <option value="DateTime">DateTime</option>
                                <option value="Time">Time</option>
                              </select>
                            </div>

                          </div>
                      </div>

                      <clr-input-container class="form-control clr-col-3 table-input-container" *ngIf="!['Date', 'DateTime', 'Time'].includes(searchIndex.get('dataType').value)">
                        <label class="clr-col-xs-0">Min</label>
                        <input type="number" clrInput formControlName="min" name="document-type-modal__search-index-index" placeholder="Min" (input)="onMinChange($event, searchIndex)" onkeydown='return event.keyCode !== 69' />
                          <clr-control-error class="input-error-msg" *clrIfError="'min'">Value cannot be less than 1</clr-control-error>
                          <clr-control-error class="input-error-msg" *clrIfError="'customInt'">Value should not be decimal</clr-control-error>
                          <clr-control-error class="input-error-msg" *clrIfError="'custom'">Value cannot be less than min value</clr-control-error>
                      </clr-input-container>
                      <clr-input-container class="form-control clr-col-3 table-input-container" *ngIf="searchIndex.get('dataType').value === 'Date'">
                        <label class="clr-col-xs-0">Min</label>
                        <input type="date" clrInput formControlName="min" name="document-type-modal__search-index-index" placeholder="Min"
                               (input)="onMinChange($event, searchIndex)">

                      </clr-input-container>

                      <clr-input-container class="form-control clr-col-3 table-input-container" *ngIf="searchIndex.get('dataType').value === 'DateTime'">
                        <label class="clr-col-xs-0">Min</label>
                        <input type="datetime-local" step=1 clrInput formControlName="min" name="document-type-modal__search-index-index" placeholder="Min"
                               (input)="onMinChange($event, searchIndex)">
                      </clr-input-container>
                      <clr-input-container class="form-control clr-col-3 table-input-container" *ngIf="searchIndex.get('dataType').value === 'Time'">
                        <label class="clr-col-xs-0">Min</label>
                        <input type="time" step=1 clrInput formControlName="min" name="document-type-modal__search-index-index" placeholder="Min"
                               (input)="onMinChange($event, searchIndex)">
                      </clr-input-container>
                            <clr-input-container class="form-control clr-col-3 table-input-container" *ngIf="!['Date', 'DateTime', 'Time'].includes(searchIndex.get('dataType').value)">
                              <label class="clr-col-xs-0">Max</label>
                              <input type="number" clrInput formControlName="max" name="document-type-modal__search-index-index" placeholder="Max" onkeydown='return event.keyCode !== 69'
                              >
                              <clr-control-error class="input-error-msg" *clrIfError="'customInt'">Value should not be decimal</clr-control-error>
                              <clr-control-error class="input-error-msg" *clrIfError="'min'">Value cannot be less than 1</clr-control-error>
                              <clr-control-error class="input-error-msg" *clrIfError="'custom'">Value cannot be less than min value</clr-control-error>
                            </clr-input-container>
                            <clr-input-container class="form-control clr-col-3 table-input-container" *ngIf="searchIndex.get('dataType').value === 'Date'">
                              <label class="clr-col-xs-0">Max</label>
                              <input type="date" clrInput formControlName="max" name="document-type-modal__search-index-index" placeholder="Max"
                                     >
                              <clr-control-error class="input-error-msg">Value cannot be less than min value</clr-control-error>
                            </clr-input-container>
                      <clr-input-container class="form-control clr-col-3 table-input-container" *ngIf="searchIndex.get('dataType').value === 'DateTime'">
                        <label class="clr-col-xs-0">Max</label>
                        <input type="datetime-local" step=1 clrInput formControlName="max" name="document-type-modal__search-index-index" placeholder="Min"
                               >
                        <clr-control-error class="input-error-msg">Value cannot be less than min value</clr-control-error>
                      </clr-input-container>
                      <clr-input-container class="form-control clr-col-3 table-input-container" *ngIf="searchIndex.get('dataType').value === 'Time'">
                        <label class="clr-col-xs-0">Max</label>
                        <input type="time" step=1 clrInput formControlName="max" name="document-type-modal__search-index-index" placeholder="Min"
                               >
                        <clr-control-error class="input-error-msg">Value cannot be less than min value</clr-control-error>
                      </clr-input-container>
                            <div class="clr-col-1 delete-btn-container">
                              <button class="btn-search-indexes btn-no-caps" *ngIf="searchIndex.value.used !== true" (click)="removeSearchIndex(i)" [disabled]="searchIndex.get('searchIndex').disabled">
                                <clr-icon class="remove-search-index-icon" shape="trash"></clr-icon>
                              </button>
                              <div class="index-used-check" *ngIf="searchIndex['used'] == true">
                                <clr-icon class="index-used-check-icon" shape="check" title="Search Index in Use"></clr-icon>
                              </div>
                            </div>
                          </div>
                          <div class="clr-row">
                            <clr-input-container class="form-control clr-col-3 table-input-container">
                              <label class="clr-col-xs-0" >Search Index Key</label>
                              <input
                                   type="text"
                                   clrInput
                                   formControlName="searchIndex"
                                   name="document-type-modal__search-index-index"
                                   placeholder="Search Index Key"
                                   (change)="changeSearchIndex(i)"
                                   (focus)="onSearchIndexKeyFocus(searchIndex.value)"
                                   #searchIndex>
                                   <clr-control-error class="input-error-msg">Please provide a search index key.</clr-control-error>
                            </clr-input-container>
                            <div class="clr-col-2 no-padding datatype-container">
                              <div class="clr-row>">
                                <div class="document-type-modal__search-index-action-status clr-col-12">
                                  <label class="clr-col-xs-0" >Mapping Group</label>
                                  <select class=" search-index-datatype-item document-type-modal__search-index"
                                          title="Select Data Mapping Group"
                                          type="text"
                                          formControlName="dataMappingName"
                                          name="options"
                                          >
                                    <option value="">None</option>
                                    <option [value]="group.name" *ngFor="let group of dataMappingGroups">{{group.name}}</option>
                                  </select>
                                </div>

                              </div>
                            </div>
                            <div class="form-control clr-col-4 table-input-container operator-multiselect" *ngIf="!['String'].includes(searchIndex.get('dataType').value)">
                              <label class="clr-col-xs-0" >Operator</label>
                              <ng-multiselect-dropdown name="document-type-modal__search-index-index" [placeholder]="'Select Operators'"
                              [settings]="dropdownSettings"
                              formControlName="operators"
                              [data]="operatorList">
                              </ng-multiselect-dropdown>
                            </div>
                            <div class="form-control clr-col-4 table-input-container"
                              *ngIf="['String'].includes(searchIndex.get('dataType').value)">
                              <label class="clr-col-xs-0" >Operator</label>
                              <ng-multiselect-dropdown name="document-type-modal__search-index-index" [placeholder]="'Select Operators'"
                              [settings]="dropdownSettings"
                              formControlName="operators"
                              [data]="operatorStringList">
                              </ng-multiselect-dropdown>
                            </div>
                          </div>
                          <div class="clr-row last-row">
                            <clr-radio-container clrInline class="form-control clr-col-3 table-input-container">
                              <label class="clr-col-xs-0" >Searchable?</label>
                              <clr-radio-wrapper>
                                <input type="radio" clrRadio [value]="true" formControlName="searchable"/>
                                <label>Yes</label>
                              </clr-radio-wrapper>
                              <clr-radio-wrapper>
                                <input type="radio" clrRadio [value]="false" formControlName="searchable"/>
                                <label>No</label>
                              </clr-radio-wrapper>
                            </clr-radio-container>

                            <clr-radio-container clrInline class="form-control clr-col-3 table-input-container" *ngIf="searchIndex.get('searchable').value === true">
                              <label class="clr-col-xs-0" >Advanced Search?</label>
                              <clr-radio-wrapper>
                                <input type="radio" clrRadio [value]="true" formControlName="isAdvancedSearch"/>
                                <label>Yes</label>
                              </clr-radio-wrapper>
                              <clr-radio-wrapper>
                                <input type="radio" clrRadio [value]="false" formControlName="isAdvancedSearch"/>
                                <label>No</label>
                              </clr-radio-wrapper>
                            </clr-radio-container>

                            <clr-radio-container clrInline class="form-control clr-col-3 table-input-container">
                              <label class="clr-col-xs-0" >Show In Result?</label>
                              <clr-radio-wrapper>
                                <input type="radio" clrRadio [value]="true" formControlName="showInResult"/>
                                <label>Yes</label>
                              </clr-radio-wrapper>
                              <clr-radio-wrapper>
                                <input type="radio" clrRadio [value]="false" formControlName="showInResult"/>
                                <label>No</label>
                              </clr-radio-wrapper>
                            </clr-radio-container>
                          </div>
                    </div>
                  </div>
              </clr-tab-content>
          </clr-tab>

        <clr-tab>
          <button clrTabLink>Sorting</button>
          <clr-tab-content id="doc-type-sorting-tab" *clrIfActive>

            <div class="clr-col-xs-12 clr-col-sm-12 add-index-parent">
              <button *ngIf="currentUser?.selectedClient?.uiperms?.doctype.create" [title]="checkNumOfSortingsAgainstNumOfSearchIndexes() ? 'Cannot add more sortings than there are search indexes': 'Add new sorting'" [disabled]="checkNumOfSortingsAgainstNumOfSearchIndexes()" (click)="onAddSorting($event)" class="add-index-btn add-sorting-btn btn btn-link btn-no-caps">
                <clr-icon shape="plus-circle"></clr-icon>&nbsp;Add Sorting
              </button>
              <span>
                <a role="tooltip" aria-haspopup="true" class="tooltip tooltip-md tooltip-bottom-right">
                  <clr-icon shape="info-circle" size="24"></clr-icon>
                  <span class="tooltip-content">
                    Search result will be sorted using ALL of the added sortings, which will be applied in the order they have been added, starting with number 1 as a default<br>
                    ____________________________<br><br>
                  </span>
                </a>
              </span>
            </div>

            <div class="no-sorting-message" *ngIf="sortingFormArray?.controls.length == 0">
              <p>No added Sortings<clr-icon shape="exclamation-circle"></clr-icon></p>
            </div>

            <div class="" formArrayName="sortingFormArray" *ngFor="let sorting of sortingFormArray.controls;let i=index;trackBy:track">
              <div class="clr-row sorting-group" formGroupName="{{i}}">
                <p class="sort-number">{{i + 1}}) </p>

                <div class="clr-col-2 no-padding sorting-container">
                  <div class="clr-row>">
                    <div class="document-type-modal__search-index-action-status clr-col-12">
                      <label class="clr-col-xs-0" >Search Index Key</label>
                      <select class=" search-index-datatype-item document-type-modal__search-index"
                              title="Select Search Index Data Type"
                              type="text"
                              (change)="onSortSearchIndexKeyChanged(i, sortSearchIndexKey.value)"
                              #sortSearchIndexKey
                              formControlName="searchIndexKey">
                        <option disabled value="">Select Key</option>
                        <option [hidden]="usedSearchIndexKeys.has(sk)" *ngFor="let sk of getSearchIndexOptions()" value="{{sk}}">{{sk}}</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="clr-col-2 no-padding">
                  <div class="clr-row>">
                    <div class="document-type-modal__search-index-action-status clr-col-12">
                      <label class="clr-col-xs-0" >Sort Order</label>
                      <select class=" search-index-datatype-item document-type-modal__search-index"
                              title="Select Default Sorting"
                              type="text"
                              formControlName="sortOrder">
                        <option disabled value="NONE">Select order</option>
                        <option value="ASC">Ascending</option>
                        <option value="DESC">Descending</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="remove-sorting btn-link btn-icon">
                  <button  class="btn btn-no-caps" title="Remove sorting" (click)="removeSorting(i)">
                    <clr-icon class="remove-sorting-icon" size="19" shape="trash"></clr-icon>
                  </button>
                </div>
              </div>
            </div>

          </clr-tab-content>
        </clr-tab>

        <clr-tab>
          <button clrTabLink>Group By</button>
          <clr-tab-content id="" *clrIfActive>
            <clr-select-container class="form-control clr-select-wrapper_height">
              <label class="clr-col-xs-12" for="group_by_index">Group By Index:</label>
              <select class="search-index-input archive-provider-dropdown clr-col-xs-12" clrSelect id="group_by_index" name="group_by_index"
                      formControlName="groupByIndex">
                <option [disabled]="!getSearchIndexOptions()?.length" value="null">Select Search Index</option>
                <option *ngFor="let si of getSearchIndexOptions()" value="{{si}}">{{si}}</option>
              </select>
            </clr-select-container>
          </clr-tab-content>
        </clr-tab>

        <clr-tab>
          <button clrTabLink>Investor Attributes</button>
          <clr-tab-content id="doc-type-investor-attributes-tab" *clrIfActive>
            <div class="clr-row">
              <div class="document-type-modal__search-index-action-status clr-col-4" style="margin-left: -10px;">
                <label class="clr-col-xs-0" >Open on load</label>
                <select class=" search-index-datatype-item document-type-modal__search-index"
                        title="Select Open on load"
                        type="text"
                        formControlName="openOnLoad"
                        name="openOnLoad"
                        >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
            </div>
            <div class="clr-col-xs-12 clr-col-sm-12 add-index-parent">
              <button *ngIf="currentUser?.selectedClient?.uiperms?.doctype.create" (click)="onAddFilter($event)" class="add-index-btn btn btn-link btn-no-caps"><clr-icon shape="plus-circle"></clr-icon>&nbsp;Add Filter</button>
            </div>

            <p class="indexes-load-messages" *ngIf="documentTypeForm.get('additionalAttrFormArray').length == 0">
              No added Filters <clr-icon shape="exclamation-circle"></clr-icon>
            </p>

            <div class="search-index-list" formArrayName="additionalAttrFormArray"
                 *ngFor="let searchIndex of additionalAttrFormArray.controls;let i=index;trackBy:track">
              <div class="clr-row searchIndexFormContainer" formGroupName="{{i}}">
                <div class="clr-row">
                  <div class="clr-col-4 no-padding datatype-container">
                    <div class="clr-row>">
                      <div class="document-type-modal__search-index-action-status clr-col-12">
                        <label class="clr-col-xs-0" >Element Type</label>
                        <select class=" search-index-datatype-item document-type-modal__search-index"
                                title="Select Element Type"
                                type="text"
                                formControlName="elementType"
                                name="options"
                                (change)="elementTypeChange(searchIndex)">
                          <option value="dateDropdown">Date Dropdown</option>
                          <option value="customDateWithRanges">Custom Date Dropdown with Ranges</option>
                          <option value="multiSelectDropdown">Multi Select Dropdown</option>
                          <option value="inputBox">InputBox</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="clr-col-7 no-padding datatype-container" *ngIf="['dateDropdown', 'customDateWithRanges'].includes(searchIndex.get('elementType').value)">
                  </div>
                  <clr-input-container class="form-control clr-col-4 table-input-container"
                    *ngIf="searchIndex.get('elementType').value !== 'dateDropdown' && searchIndex.get('elementType').value !== 'customDateWithRanges'">
                    <label class="clr-col-xs-0" >Placeholder Label</label>
                    <input
                      type="text"
                      clrInput
                      formControlName="placeholderLabel"
                      name="document-type-modal__search-index-label"
                      placeholder="Placeholder Label">
                    <clr-control-error class="input-error-msg">Please provide a placeholder label.</clr-control-error>
                  </clr-input-container>
                  <clr-input-container class="form-control clr-col-3 table-input-container"
                    *ngIf="searchIndex.get('elementType').value === 'multiSelectDropdown'">
                    <label class="clr-col-xs-0" >Delimiter</label>
                    <input
                      type="text"
                      maxlength="1"
                      clrInput
                      formControlName="delimiter"
                      name="document-type-modal__search-index-label"
                      placeholder="Delimiter">
                    <clr-control-error class="input-error-msg">Please provide a delimiter.</clr-control-error>
                  </clr-input-container>

                  <div class="clr-col-3 no-padding datatype-container" *ngIf="searchIndex.get('elementType').value === 'inputBox'">
                  </div>
                  <div class="clr-col-1 delete-btn-container">
                    <button class="btn-search-indexes btn-no-caps btn-filters" (click)="removeFilter(i)">
                      <clr-icon class="remove-search-index-icon" shape="trash"></clr-icon>
                    </button>
                  </div>
                </div>
                <div class="clr-row last-row" [ngClass]="searchIndex.get('elementType').value === 'customDateWithRanges' ? 'date-ranges-class' : ''">
                  <div class="no-padding datatype-container" *ngIf="searchIndex.get('elementType').value !== 'multiSelectDropdown'" [ngClass]="searchIndex.get('elementType').value === 'customDateWithRanges' ? 'custom-date-range-search-index' : ''">
                    <div class="clr-row>">
                      <div class="document-type-modal__search-index-action-status clr-col-12">
                        <label class="clr-col-xs-0" >Search Index</label>
                        <select class=" search-index-datatype-item document-type-modal__search-index"
                                title="Select Search Index"
                                type="text"
                                formControlName="searchIndex"
                                name="searchIndex"
                                (change)="filterSearchIndexChange()">
                          <option disabled value="">None</option>
                          <option *ngFor="let option of getSearchIndexOptions()" value="{{option}}">{{option}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="form-control clr-col-4 table-input-container" *ngIf="searchIndex.get('elementType').value === 'multiSelectDropdown'">
                    <label class="clr-col-xs-0" >Search Indexes</label>
                    <ng-multiselect-dropdown name="document-type-modal__search-index-index" [placeholder]="'Select Search Indexes'"
                                             [settings]="dropdownSettings"
                                             formControlName="searchIndexes"
                                             (onSelect)="filterSearchIndexChange()"
                                             (onDeSelect)="filterSearchIndexChange()"
                                             (onSelectAll)="filterSearchIndexChange()"
                                             (onDeSelectAll)="filterSearchIndexChange()"
                                             [data]="searchIndexMultiOptions">
                    </ng-multiselect-dropdown>
                  </div>
                  <div class="clr-col-3 no-padding datatype-container" *ngIf="searchIndex.get('elementType').value === 'dateDropdown'">
                    <div class="clr-row>">
                      <div class="document-type-modal__search-index-action-status clr-col-12">
                        <label class="clr-col-xs-0" >Default Selected</label>
                        <select class="search-index-datatype-item document-type-modal__search-index"
                                title="Select Default Selected"
                                type="text"
                                formControlName="defaultSelected"
                                name="defaultSelected">
                          <option disabled value="">None</option>
                          <option value="Year to Date">Year to Date</option>
                          <option value="Previous Year">Previous Year</option>
                          <option value="Last 12 Months">Last 12 Months</option>
                          <option value="Last 30 Days">Last 30 Days</option>
                          <option value="Last 7 Days">Last 7 Days</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="ranges-group" *ngIf="searchIndex.get('elementType').value === 'customDateWithRanges'">
                    <div class="add-range-btn">
                      <button class="btn btn-link btn-no-caps add-more-btn" (click)="onAddDateRange($event, i)">
                        <clr-icon shape="plus-circle"></clr-icon>&nbsp;Add Date Range
                      </button>
                    </div>

                    <div class="" formArrayName="dateRangesFormArray" *ngFor="let dateRange of getRangesFormControls(i);let rangeIndex=index;trackBy:track">
                      <div class="dateRangeContainer" formGroupName="{{rangeIndex}}">
                        <div class="clr-row">
                          <div class="range-inputs-group" [ngClass]="rangeType.value !== 'Custom' ? 'preset-range' : ''">


                            <div class="document-type-modal__search-index-action-status range-type-select" [ngClass]="rangeType.value !== 'Custom' ? 'preset-range-select' : ''">
                              <label class="range-type-label">Range Type:</label>
                              <select class="search-index-datatype-item document-type-modal__search-index"
                                      title="Range Type"
                                      type="text"
                                      formControlName="rangeType"
                                      name="rangeType"
                                      (change)="rangeTypeSelectChange(i, rangeIndex, $event.target.value)"
                                      #rangeType>
                                <option disabled value="">None</option>
                                <option value="Year to Date">Year to Date</option>
                                <option value="Previous Year">Previous Year</option>
                                <option value="Custom">Custom</option>
                              </select>
                            </div>

                            <clr-input-container class="table-input-container date-range-input-container">
                              <label class="clr-col-xs-0 range-label">Range Label:</label>
                              <input class="search-index-input clr-col-xs-12"  type="text" clrInput formControlName="rangeLabel" />
                              <clr-control-error *clrIfError="'pattern'">Label cannot contain special characters..</clr-control-error>
                              <clr-control-error *clrIfError="'whitespace'">Label cannot be empty or start/end with a space.</clr-control-error>
                            </clr-input-container>
                            <clr-input-container class="table-input-container date-range-input-container" [hidden]="rangeType.value !== 'Custom'">
                              <label class="clr-col-xs-0 range-label">Start (days before today):</label>
                              <input class="search-index-input clr-col-xs-12"  type="number" clrInput formControlName="rangeStart" (keydown)="onKeyDownNumber($event)" />
                              <clr-control-error>Please provide a valid number.</clr-control-error>
                            </clr-input-container>
                            <clr-input-container class="table-input-container date-range-input-container" [hidden]="rangeType.value !== 'Custom'">
                              <label class="clr-col-xs-0 range-label">End (days before today):</label>
                              <input class="search-index-input clr-col-xs-12"  type="number" clrInput formControlName="rangeEnd" (keydown)="onKeyDownNumber($event)" />
                              <clr-control-error>Please provide a valid number.</clr-control-error>
                            </clr-input-container>
                          </div>

                          <div class="remove-date-range btn-link btn-icon">
                            <button  class="btn btn-no-caps" title="Remove date range" (click)="removeDateRange(i, rangeIndex)">
                              <clr-icon class="remove-date-range-icon" size="19" shape="trash"></clr-icon>
                            </button>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </clr-tab-content>
        </clr-tab>
        <clr-tab>
          <button clrTabLink>Entitlements</button>
          <clr-tab-content id="doc-type-entitlements-tab" *clrIfActive>
            <div class="clr-row">
              <div class="document-type-modal__search-index-action-status clr-col-4 entitlements-tab">
                <label class="clr-col-xs-0 label-margin" >Account ID Search Index Key</label>
                <select class=" search-index-datatype-item document-type-modal__search-index"
                        title="Select Search index key"
                        type="text"
                        formControlName="accountIdSearchIndexKey"
                        name="accountIdSearchIndexKey">
                  <option selected disabled value="null">Select Key</option>
                  <option *ngFor="let option of getSearchIndexOptions()" value="{{option}}">{{option}}</option>
                </select>
              </div>
            </div>
          </clr-tab-content>
        </clr-tab>
      </clr-tabs>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="closeModal('cancel')">Cancel</button>
    <button id="new-document-type-submit-trigger" type="submit" (click)="onSubmit()"
            [disabled]="!saveButtonEnabled()" class="btn btn-primary" [clrLoading]="processingModalRequest">
      <ng-container *ngIf="isNewDocumentType()">Add</ng-container>
      <ng-container *ngIf="!isNewDocumentType()">Save</ng-container>
    </button>
  </div>
</clr-modal>
