import { Component, Input, OnInit } from '@angular/core';
import { IimUser } from 'ui-sdk/models/iim-user.model';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-client-dropdown',
  templateUrl: './client-dropdown.component.html',
  styleUrls: ['./client-dropdown.component.scss']
})
export class ClientDropdownComponent implements OnInit {
  @Input() user: IimUser;
  @Input() control: FormControl = new FormControl();

  constructor() { }

  ngOnInit() {
  }

}
