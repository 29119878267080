  <div [@fadeInLeft]>
    <div class="clr-row site-mapping-container">
      <div class="clr-col-xs-12 clr-col-sm-12 site-mapping-header-item ">
        <button *ngIf="currentUser?.selectedClient?.uiperms?.sitemappings?.create" (click)="addNewSiteMapping()" class="btn btn-link btn-no-caps"><clr-icon shape="plus-circle"></clr-icon> New Site Mapping</button>
      </div>
      <div class="clr-col-xs-12 clr-col-md site-mapping-header-item  site-mapping-header-item-end">
        Show
        <div class="clr-select-wrapper">
          <select class="clr-select" name="pageAmount" id="pageAmount" [formControl]="tableControls.pageSize">
            <option [ngValue]="10">10</option>
            <option [ngValue]="25">25</option>
            <option [ngValue]="50">50</option>
          </select>
        </div>
        items per page
      </div>
    </div>

    <div class="clr-row">
      <div class="clr-col-xs-12 clr-col-sm-12">
        <clr-datagrid [clrDgLoading]="loading" class="main-dg">
          <clr-dg-column>
            CompanyId
            <clr-dg-filter [clrDgFilter]="companyId">
              <text-filter #companyId propertyName="companyId"></text-filter>
            </clr-dg-filter>
          </clr-dg-column>
          <clr-dg-column>
            ClientId
            <clr-dg-filter [clrDgFilter]="clientId">
              <text-filter #clientId propertyName="clientId"></text-filter>
            </clr-dg-filter>
          </clr-dg-column>
          <clr-dg-column>
            ExternalClientId
            <clr-dg-filter [clrDgFilter]="externalClientId">
              <text-filter #externalClientId propertyName="externalClientId"></text-filter>
            </clr-dg-filter>
          </clr-dg-column>
          <clr-dg-column class="table-actions">
            Actions
          </clr-dg-column>

          <clr-dg-row *clrDgItems="let siteMapping of allSiteMappings" [clrDgItem]="siteMapping">

            <clr-dg-cell class="datagrid-align-cells" title="companyId">
              <button class="btn btn-icon btn-link copy-to-clipboard-btn" title="Copy Company Id" [appCopyClipboard]="siteMapping?.companyId" (copied)="notify($event)">
                <clr-icon class="copy-to-clipboard-icon" shape="copy-to-clipboard"></clr-icon>
              </button>
              <p class="dg-cell"> {{siteMapping?.companyId}} </p>
              <span *ngIf="siteMapping?.companyId == copyIdToClipboard" class="clipboard-copy-success">
                <clr-icon shape="check" size="14"></clr-icon>
                Copied
              </span>

            </clr-dg-cell>
            <clr-dg-cell class="datagrid-align-cells site-mapping-cell" title="clientId">{{siteMapping?.clientId?.length > 0 ? siteMapping.clientId : '-'}}</clr-dg-cell>
            <clr-dg-cell class="datagrid-align-cells site-mapping-cell" title="externalClientId">{{siteMapping?.externalClientId?.length > 0 ? siteMapping.externalClientId : '-'}}</clr-dg-cell>
            <clr-dg-cell class="table-actions-cell" >
              <div class="btn-group btn-link btn-icon">
                <button [title]="'View Site Mapping'" [clrLoading]="companyIdLoadingEdit == siteMapping?.companyId"  *ngIf="!currentUser?.selectedClient?.uiperms?.sitemappings?.read" class="btn btn-no-caps" (click)="editSiteMapping(siteMapping)">
                  <clr-icon shape="eye"></clr-icon>
                </button>
                <button [title]="'Edit Site Mapping'" [clrLoading]="companyIdLoadingEdit == siteMapping?.companyId" *ngIf="currentUser?.selectedClient?.uiperms?.sitemappings?.update" class="btn btn-no-caps" (click)="editSiteMapping(siteMapping)">
                  <clr-icon shape="pencil"></clr-icon>
                </button>
                <button [title]="'Delete Site Mapping'" [clrLoading]="companyIdLoadingDelete == siteMapping?.companyId" *ngIf="currentUser?.selectedClient?.uiperms?.sitemappings?.delete" class="btn btn-no-caps" (click)="removeSiteMapping(siteMapping)">
                  <clr-icon shape="trash"></clr-icon>
                </button>
              </div>
            </clr-dg-cell>
          </clr-dg-row>

          <clr-dg-footer>
            <clr-dg-pagination #pagination [clrDgPageSize]="tableControls.pageSize.value">
              {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
              of {{pagination.totalItems}} mappings
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
      </div>
    </div>
  </div>

  <div *ngIf="siteMappingModal_enabled">
    <app-site-mapping-modal [modalEnabled]="siteMappingModal_enabled" (close)="siteMappingModal_enabled = false" (created)="onSiteMappingChanged()" [selectedSiteMapping]="selectedSiteMapping" [clients]="clients"></app-site-mapping-modal>
  </div>
