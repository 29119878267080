<div [@fadeInLeft]>
  <div class="clr-row client-admin-header-container">
    <div class="clr-col-xs-12 clr-col-sm-12 client-admin-header-item">
      <button *ngIf="currentUser?.selectedClient?.uiperms?.client.create" (click)="addNewClient()" class="btn btn-link btn-no-caps"><clr-icon shape="plus-circle"></clr-icon>&nbsp;Onboard New Client</button>
    </div>
    <div class="clr-col-xs-12 clr-col-md client-admins-header-item client-admin-header-item-end">
      Show
      <div class="clr-select-wrapper">
        <select class="clr-select" name="pageAmount" id="pageAmount" [formControl]="tableControls.pageSize">
          <option [ngValue]="10">10</option>
          <option [ngValue]="25">25</option>
          <option [ngValue]="50">50</option>
        </select>
      </div>
      items per page
    </div>
  </div>

  <div class="clr-row">
    <div class="clr-col-xs-12 clr-col-sm-12">
      <clr-datagrid [clrDgLoading]="clients.loading" class="main-dg">
        <clr-dg-column class="client-admin-item-align-cells " [clrDgField]="'name'">
          Client Name
          <clr-dg-filter [clrDgFilter]="name">
            <text-filter #name propertyName="name"></text-filter>
          </clr-dg-filter>
        </clr-dg-column>

       <clr-dg-column class="client-admin-item-align-cells " [clrDgField]="'externalClientId'">
         External Client ID
         <clr-dg-filter [clrDgFilter]="externalClientId">
           <object-keys-text-filter #externalClientId propertyName="externalClientId"></object-keys-text-filter>
        </clr-dg-filter>
      </clr-dg-column>
      <clr-dg-column class="client-admin-item-align-cells " [clrDgField]="'dateCreated'">
        Date Created
        <clr-dg-filter [clrDgFilter]="dateCreated">
          <date-filter #dateCreated propertyName="dateCreated"></date-filter>
       </clr-dg-filter>
     </clr-dg-column>
        <clr-dg-column class="table-actions" *ngIf="currentUser?.selectedClient?.uiperms?.client.delete || currentUser?.selectedClient?.uiperms?.client.update">
          Actions
        </clr-dg-column>

        <clr-dg-row *clrDgItems="let client of clients.current" [clrDgItem]="client">

          <clr-dg-cell class="client-admin-item-align-cells" title="{{client.id}}">
            <button class="btn btn-icon btn-link copy-to-clipboard-btn" title="Copy Client Id" [appCopyClipboard]="client.id" (copied)="notify($event)">
              <clr-icon class="copy-to-clipboard-icon" shape="copy-to-clipboard"></clr-icon>
            </button>
            {{client.name}}

            <span *ngIf="client.id == copyIdToClipboard" class="clipboard-copy-success">
              <clr-icon shape="check" size="14"></clr-icon>
              Copied
            </span>

          </clr-dg-cell>
          <clr-dg-cell class="client-admin-item-align-cells">
            {{client.externalClientId}}
          </clr-dg-cell>
          <clr-dg-cell class="client-admin-item-align-cells">
            {{client.dateCreated | date: 'MM/dd/yyyy'}}
          </clr-dg-cell>
          <clr-dg-cell class="table-actions" *ngIf="currentUser?.selectedClient?.uiperms?.client.delete || currentUser?.selectedClient?.uiperms?.client.update" >
            <div class="btn-group btn-link btn-icon">
              <button [title]="'Edit Client'" class="btn btn-no-caps" *ngIf="currentUser?.selectedClient?.uiperms?.client.update" [clrLoading]="editClientLoadingId == client.id" (click)="editClient(client)">
                <clr-icon shape="pencil"></clr-icon>
              </button>
              <button [title]="'Delete Client'" *ngIf="currentUser?.selectedClient?.uiperms?.client.delete" class="btn btn-no-caps" (click)="removeClient(client)">
                <clr-icon shape="trash"></clr-icon>
              </button>
            </div>
          </clr-dg-cell>
        </clr-dg-row>

        <clr-dg-footer>
          <clr-dg-pagination #pagination [clrDgPageSize]="tableControls.pageSize.value">
            {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
            of {{pagination.totalItems}} clients
          </clr-dg-pagination>
        </clr-dg-footer>
      </clr-datagrid>
    </div>
  </div>
</div>

  <app-client-onboard-modal (close)="closeModal()" (changed)="onClientsChanged()"
                            [modalEnabled]="clientOnboardModal_enabled" [selectedClient]="currentClient" [selectedRoleName]="editRoleRedirectName"
                            [archiveProviders]="archiveProviders" [permissions]="permissions" [existingClientIdmRoles]="clientIdmRole"
                            [loading]="permsLoading"></app-client-onboard-modal>
