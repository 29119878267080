import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {AuthClass, DocumentMethods, environment, environment as sdkEnvironment, HttpMethodsEnum, UserMethods} from 'ui-sdk';
import {IimUser} from 'ui-sdk/models/iim-user.model';
import {Subscription} from 'rxjs';
import {AlertService} from '../../services/alert.service';
import {Router} from '@angular/router';
import {HttpHeaders} from '@angular/common/http';
import {CSVExportService} from '../../services/export-csv.service';
import {ReportPageComponent} from '../report-page/report-page.component';
import {CustomDocumentComparator} from '../../components/table/comparators/customdocument.comparator';
import { v4 as uuidv4 } from 'uuid';
import {KeyValue} from '@angular/common';

@Component({
  selector: 'app-batch-transaction-page',
  templateUrl: './batch-transaction-page.component.html',
  styleUrls: ['./batch-transaction-page.component.scss']
})
export class BatchTransactionPageComponent implements OnInit, OnDestroy {
  baseUrl = sdkEnvironment.baseUrl;

  transactionCountThreshold = ReportPageComponent.TRANSACTION_COUNT_THRESHOLD;

  queryParams = {};
  batchId: string;
  batchReportId: string;
  transactionCount = 0;
  documentTypeId: string;
  documentTypeName: string;

  exportFileName: string;
  columnIndexKeys: Array<string>;
  columnIndexesMap: any;
  columnIndexesMapTEMP: any;
  columnIndexesMapPLACEHOLDER = new Map();
  metadataColumnIndexes: any;
  flattenedReportJsonArr: any;

  allBatchTransactionsTEMP: Array<any>;
  allBatchTransactions: Array<any>;
  loading = false;
  additionalDataLoadingId: string = '';

  reportStatusCheckCounter = 0;

  exportingReport = false;

  currentUser: IimUser;
  subscriptions: Subscription = new Subscription();

  tableSort: {};
  customTableSort = new Map();
  tableControls: { propertiesFilter: FormControl, pageSize: FormControl, textFilter: FormControl } = {
    propertiesFilter: new FormControl([]),
    pageSize: new FormControl(50),
    textFilter: new FormControl('')
  };

  multipleFileDownloadModal_enabled = false;
  documentDataModal: any = {};

  actionsInProgress: {view: string, download: string} = {
    view: '',
    download: ''
  };

  documents: { selected: Array<any>; current: Array<any> } = {
    selected: [],
    current: []
  };

  constructor(private alertService: AlertService,
              private router: Router,
              private csvExportService: CSVExportService) { }

  ngOnInit(): void {
    const userSubscription = UserMethods.currentUser$.subscribe(
      (user: IimUser) => this.currentUser = user
    );
    this.subscriptions.add(userSubscription);

    this.afterRedirectOnLoad();
  }

  afterRedirectOnLoad() {
    this.batchId = this.getQueryParamValue('batchId');
    this.batchReportId = this.getQueryParamValue('batchReportId');
    this.documentTypeId = this.getQueryParamValue('documentTypeId');
    this.documentTypeName = this.getQueryParamValue('documentTypeName');
    this.transactionCount = Number(this.getQueryParamValue('transactionCount'));

    this.extractAllSearchIndexes();

    if (this.batchReportId !== null && this.transactionCount > 0) {
      this.getBatchTransactions();

    // no existing report found; waiting for generate to finish
    } else {
      this.loading = true;
      this.checkReportStatus();
    }
  }

  checkReportStatus() {
    this.reportStatusCheckCounter++;

    this.getBatchReportById().then(reportStatusObj => {

      if ((reportStatusObj.status === 'SUBMITTED' || reportStatusObj.status === 'PROCESSING') && this.reportStatusCheckCounter < 10) {
        const timeoutLength = 500 + (this.reportStatusCheckCounter * 200);
        setTimeout(() => {
          this.checkReportStatus();
        }, timeoutLength);

      } else if (reportStatusObj.status === 'COMPLETED') {
        this.batchId = reportStatusObj.batchId;
        this.batchReportId = reportStatusObj.id;
        this.documentTypeId = reportStatusObj.documentTypeId;
        this.documentTypeName = reportStatusObj.documentTypeName;
        this.transactionCount = Number(reportStatusObj.transactionCount);

        this.getBatchTransactions();

      } else if (reportStatusObj.status === 'FAILED') {
        this.alertService.showError('Error', `Error occurred while attempting to get Batch Transactions. Status Reasons [${reportStatusObj.statusReason}]`);
        this.loading = false;

      } else {
        this.alertService.showError('Oops, looks like something went wrong', 'Unable to get Batch Transactions.');
        this.loading = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    localStorage.removeItem('batchId');
    // localStorage.removeItem(`${this.batchId}_indexes`);
  }

  getQueryParamValue(queryParamKey: string): string {
    if (Object.keys(this.queryParams)?.length < 1) {
      this.setQueryParams();
    }

    if (this.queryParams.hasOwnProperty(queryParamKey)) {
      const rawQueryParamValueEnc = this.queryParams[queryParamKey];
      const rawQueryParamValue = decodeURIComponent(rawQueryParamValueEnc);

      return rawQueryParamValue;
    }

    return null;
  }

  setQueryParams() {
    const url = this.router.routerState.snapshot.url;
    const queryParamsArr = url.substring(url.indexOf('?') + 1).split('&');

    this.queryParams = {};
    queryParamsArr.forEach(param => {
      const key = param.substring(0, param.indexOf('='));
      this.queryParams[key] = param.substring(param.indexOf('=') + 1);
    });
  }

  extractAllSearchIndexes() {
    // tslint:disable-next-line:max-line-length
    const columnIndexes = JSON.parse(localStorage.getItem(`${this.batchId}_indexes`));
    this.columnIndexKeys = columnIndexes.map(index => index.key);

    this.columnIndexesMapTEMP = new Map();
    columnIndexes.forEach(index => {
      this.columnIndexesMapTEMP.set(index.key, index);
    });
  }

  getAdditionalData(isRowExpanded: boolean, document: Object) {
    if (isRowExpanded) {
      this.additionalDataLoadingId = document['id'];

      DocumentMethods.getAdditionalData(document['documentTypeId'], document['id']).then(additionalData => {
        this.documents.current.forEach(docObject => {
          if (docObject['id'] === document['id']) {
            docObject['additionalData'] = additionalData;
          }
        });

        this.additionalDataLoadingId = '';
      }, error => {
        if (error.status === 403) {
          const [title, msg] = ['Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [GET AdditionalData].`];
          this.alertService.showError(title, msg);
        } else if (error.status !== 400 && !error.message.includes('Additional data not found')) {
          const [title, msg] = ['Error', 'Unable to load additional data. Please try again.'];
          this.alertService.showError(title, msg);
        }

        this.additionalDataLoadingId = '';
      });
    }
  }

  isUrl(searchIndexValue): boolean {
    // tslint:disable-next-line:max-line-length
    const regexp = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

    return regexp.test(searchIndexValue);
  }

  exportReportController() {
    if (this.transactionCount > ReportPageComponent.TRANSACTION_COUNT_THRESHOLD) {
      this.exportReport(this.allBatchTransactionsTEMP);

    } else {
      this.exportReport(this.allBatchTransactions);
    }
  }

  exportReport(batchTransactionsToExport: Array<any>) {
    this.flattenedReportJsonArr = [];

    function zeroPad(num, places) {
      return String(num).padStart(places, '0');
    }

    this.exportFileName = '';

    batchTransactionsToExport.forEach(transaction => {
      const document = {};
      document['Name'] = transaction['fileNames'] ? transaction['fileNames'].join(' , ') : '-';
      document['DocumentId'] = transaction['id'] ? transaction['id'] : '-';
      document['DocumentTypeName'] = transaction['documentTypeName'] ? transaction['documentTypeName'] : '-';
      document['DocumentStatus'] = transaction['status'] ? transaction['status'] : '-';
      document['StorageType'] = transaction['storageType'] ? transaction['storageType'] : '-';
      document['ClientId'] = transaction['clientId'] ? transaction['clientId'] : '-';
      document['LegalHold'] = !!transaction['legalHold'] ? transaction['legalHold'] : false;
      document['MediaType'] = transaction['mediaType'] ? transaction['mediaType'] : '-';
      document['Errasure'] = !!transaction['errasure'] ? transaction['errasure'] : false;

      if (!!transaction['metadata']) {
        const metadata = transaction['metadata'];
        let searchKeyIndex = 0;
        Object.keys(metadata).forEach(metadataKey => {
          const normalizedKey = metadataKey.substring(metadataKey.indexOf('indexes.') + 8);

          if (this.exportFileName === '' && normalizedKey.toLowerCase().includes('batchid')) {
            this.exportFileName = metadata[metadataKey];
          }

          if (metadata[metadataKey] === '~~No_Value~~') {
            document[`INDEX${zeroPad(searchKeyIndex, 4)}_${normalizedKey}`] = '';

          } else {
            document[`INDEX${zeroPad(searchKeyIndex, 4)}_${normalizedKey}`] = (metadata[metadataKey] ? metadata[metadataKey] : '');
          }

          searchKeyIndex++;
        });
      }

      this.flattenedReportJsonArr.push(document);
    });

    this.generateReportCsv();
  }

  generateReportCsv() {
    const exportOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'Export Batch Report',
      useBom: true,
      noDownload: false,
      headers: ['Document Name',
        'Document Id',
        'Document Type Name',
        'Document Status',
        'Storage Type',
        'ClientId',
        'Legal Hold',
        'Media Type',
        'Errasure'
      ]
    };

    this.columnIndexKeys.forEach(key => {
      const label = this.columnIndexesMap.get(key)['label'];
      if (!exportOptions.headers.includes(label)) {
        exportOptions.headers.push(label);
      }
    });

    this.csvExportService.generateCsv(this.flattenedReportJsonArr, this.exportFileName, exportOptions);
  }

  getBatchTransactions() {
    this.loading = true;

    let url = '';
    this.getPresignedUrl().then(presignedUrlResponse => {
      url = presignedUrlResponse['downloadUrl'];

      this.getBatchReportContent(url).then(async contentResponse => {
        this.allBatchTransactionsTEMP = JSON.parse(await  contentResponse.text());

        this.normalizeTransactions();

        if (this.transactionCount > ReportPageComponent.TRANSACTION_COUNT_THRESHOLD) {
          const [title, msg] = ['Max Transaction threshold exceeded', `The Total number of Batch Transactions (${this.transactionCount}) exceeds the max allowed viewing threshold (${ReportPageComponent.TRANSACTION_COUNT_THRESHOLD}). Please click 'OK' if you wish to export all of the Batch Transactions. Otherwise you can click 'Cancel' to get redirected to the home page.`];
          this.alertService.confirmAction(title, msg).then(confirmed => {
            if (confirmed) {
              this.columnIndexesMap = new Map(this.columnIndexesMapTEMP);
              this.columnIndexesMapTEMP.clear();
              this.loading = false;

              this.exportReportController();

            } else {
              this.router.navigate(['/']);
            }
          });

        } else {
          this.columnIndexesMap = new Map(this.columnIndexesMapTEMP);
          this.columnIndexesMapTEMP.clear();
          this.setComparator();
          this.loading = false;

          this.allBatchTransactions = JSON.parse(JSON.stringify(this.allBatchTransactionsTEMP));
          this.allBatchTransactionsTEMP = null;
        }

      }, error => {
        this.loading = false;
        this.alertService.showError('Error', 'Error occurred while attempting get batch transactions.');
      });
    }, error => {
      this.loading = false;
      this.alertService.showError('Error', 'Error occurred while attempting get batch transactions.');
    });
  }

  normalizeTransactions() {
    this.allBatchTransactionsTEMP.forEach(transaction => {
      let tempTransactionMetadataObj = {};

      this.columnIndexKeys.forEach(indexKey => {
        if (!transaction.hasOwnProperty('metadata') || Object.entries(transaction['metadata']).length === 0) {
          transaction['metadata'] = {};
        } else {
          const index = indexKey.split(transaction.documentTypeName);
          let key = index[1] ? `indexes.${index[1]}` : `indexes.${index[0]}`;
          key = key .split(' ').join('');

          if (!transaction.metadata.hasOwnProperty(key)) {
            tempTransactionMetadataObj[`indexes.${indexKey}`] = '~~No_Value~~';

          } else {
            tempTransactionMetadataObj[`indexes.${indexKey}`] = transaction.metadata[key];
          }
        }
      });

      transaction.metadata = JSON.parse(JSON.stringify(tempTransactionMetadataObj));
      tempTransactionMetadataObj = {};

      transaction['UI_UUID'] = uuidv4();
    });
  }

  getComparator(columnName: string) {
    return this.customTableSort.get(columnName);
  }

  setComparator() {
    this.columnIndexesMap.forEach(element => {
      const documentCustomComparator = new CustomDocumentComparator();
      documentCustomComparator.setPropertyName(element.key);
      this.customTableSort.set(element.key, documentCustomComparator);
    });
  }

  viewDocument(documentInfo: any) {
    if (documentInfo.fileNames.length > 1) {
      this.multipleFileDownloadModal_enabled = true;
      this.documentDataModal = documentInfo;
    } else {
      this.viewSingleFile({ document: documentInfo, fileName: documentInfo.fileName, singleFile: true });
    }
  }

  viewSingleFile({document, fileName, singleFile}) {
    this.actionsInProgress.view = singleFile ? document.UI_UUID : fileName;
    let url = '';

    // tslint:disable-next-line:max-line-length
    DocumentMethods.getPresignedUrl(document.id, document.documentTypeId, fileName, document.mediaType).then(presignedUrlResponse => {
      url = presignedUrlResponse['downloadUrl'];
      window.open(url, '_blank');
      this.actionsInProgress.view = '';
    }, error => {
      this.actionsInProgress.view = '';
    });
  }

  downloadDocument(documentInfo: any) {
    if (documentInfo.fileNames.length > 1) {
      this.multipleFileDownloadModal_enabled = true;
      this.documentDataModal = documentInfo;
    } else {
      this.downloadSingleFile({document: documentInfo, fileName: documentInfo.fileName, singleFile: true});
    }
  }

  downloadSingleFile({document, fileName,  singleFile}) {
    this.actionsInProgress.download = singleFile ? document.UI_UUID : fileName;
    const type = document['mediaType'];
    let url = '';

    // tslint:disable-next-line:max-line-length
    DocumentMethods.getPresignedUrl(document.id, document.documentTypeId, fileName, document.mediaType).then(presignedUrlResponse => {
      url = presignedUrlResponse['downloadUrl'];

      DocumentMethods.getDocumentContent(url).then(contentResponse => {
        const a = window.document.createElement('a');
        window.document.body.appendChild(a);
        a.style.display = 'none';
        a.href = window.URL.createObjectURL(
          new Blob([contentResponse], { type: type })
        );
        fileName = fileName.replaceAll('[', '').replaceAll(']', '').replaceAll('_', '').replaceAll('"', '');
        a.setAttribute('download', fileName);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        this.actionsInProgress.download = '';
      }, error => {
        this.actionsInProgress.download = '';
      });
    }, error => {
      this.actionsInProgress.download = '';
    });
  }

  getBatchReportById(): Promise<any> {
    const urlPath = `${environment.baseUrl}/client/${this.currentUser.selectedClient.clientId}/documentType/${this.documentTypeId}/batchReport/${this.batchReportId}`;

    return AuthClass.doAuthenticatedCall(HttpMethodsEnum.GET, urlPath).toPromise();
  }

  getPresignedUrl(): Promise<any> {
    const urlPath = `${environment.baseUrl}/client/${this.currentUser.selectedClient.clientId}/documentType/${this.documentTypeId}/batchReport/${this.batchReportId}/content`;

    return AuthClass.doAuthenticatedCall(HttpMethodsEnum.GET, urlPath).toPromise();
  }

  getBatchReportContent(url: string): Promise<any> {
    const customHeaders = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Access-Control-Allow-Origin':  '*'
      }),
      'responseType': 'blob' as 'json'
    };

    return AuthClass.doAuthenticatedCall(HttpMethodsEnum.GET, url, null, true, customHeaders).toPromise();
  }

  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }
}
