import { HttpHeaders } from '@angular/common/http';
import {AuthClass} from 'ui-sdk';

const token: string = localStorage.getItem(AuthClass.IIM_AUTH_TOKEN);

export const httpOptions = {
headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': token?.length > 0 ? token : ''
    }),
};
