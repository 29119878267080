<div [@fadeInLeft]>
  <div class="clr-row document-type-header-container">
    <div class="clr-col-xs-12 clr-col-sm-12 document-type-header-item">
      <button *ngIf="currentUser?.selectedClient?.uiperms?.doctype.create" (click)="addNewDocumentType()" class="btn btn-link btn-no-caps"><clr-icon shape="plus-circle"></clr-icon>&nbsp;New Document Type</button>
    </div>
    <div class="clr-col-xs-12 clr-col-md document-type-header-item document-type-header-item-end">
      Show
      <div class="clr-select-wrapper">
        <select class="clr-select" name="pageAmount" id="pageAmount" [formControl]="tableControls.pageSize">
          <option [ngValue]="10">10</option>
          <option [ngValue]="25">25</option>
          <option [ngValue]="50">50</option>
        </select>
      </div>
      items per page
    </div>
  </div>

  <div class="clr-row">
    <div class="clr-col-xs-12 clr-col-sm-12">
      <clr-datagrid [clrDgLoading]="documentTypes.loading" class="main-dg">
          <clr-dg-column class="status-toggle text" *ngIf="currentUser?.selectedClient?.uiperms?.doctype.update" [clrDgSortBy]="activeStatusComparator" [clrDgField]="'status'">
              Active
            <clr-dg-filter #leftFilter [clrDgFilter]="booleanFilter">
             <boolean-filter #booleanFilter propertyName="status" propertyValue="Active"></boolean-filter>
            </clr-dg-filter>
          </clr-dg-column>
          <clr-dg-column class="text" [clrDgField]="'name'">
              Document Name
            <clr-dg-filter [clrDgFilter]="name">
              <text-filter #name propertyName="name"></text-filter>
            </clr-dg-filter>
          </clr-dg-column>
          <clr-dg-column class="text" [clrDgField]="'description'">
              Document Description
            <clr-dg-filter [clrDgFilter]="description">
              <text-filter #description propertyName="description"></text-filter>
            </clr-dg-filter>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'archiveProvider'" class="narrow-column text">
            Archive Provider
            <clr-dg-filter [clrDgFilter]="archiveProvider">
              <text-filter #archiveProvider propertyName="archiveProvider"></text-filter>
            </clr-dg-filter>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'dateCreated'" class="narrow-column text">
              Created On
            <clr-dg-filter [clrDgFilter]="createdOnFilter">
              <date-filter #createdOnFilter propertyName="dateCreated"></date-filter>
            </clr-dg-filter>
          </clr-dg-column>
        <clr-dg-column class="table-actions text">
            Actions
        </clr-dg-column>
        <clr-dg-row class="document-type-row" [ngClass]='docType.status == "Active" ? "" : "text-faded"' *clrDgItems="let docType of documentTypes.current; let i = index;" [clrDgItem]="docType" draggable='true' (dragstart)="startRowDrag($event)" (dragover)="$event.preventDefault()" (drop)="stopRowDrag($event)">
          <clr-dg-cell class="document-type-align-cells status-toggle" *ngIf="currentUser?.selectedClient?.uiperms?.doctype.update">
            <div class="clr-control-container">
              <div class="clr-toggle-wrapper" [title]='docType.status == "Active"? "Inactivate": "Activate"'>
                <input type="checkbox" clrToggle #documentTypeToggleValue (click)="prepUpdateDocumentTypeStatus(docType, documentTypeToggleValue.checked)"  [checked]='docType.status == "Active"' name="toggle">
              </div>
            </div>
          </clr-dg-cell>
          <clr-dg-cell class="document-type-align-cells" title="{{docType.id}}">
            <button class="btn btn-icon btn-link copy-to-clipboard-btn" title="Copy Document Type Id" [appCopyClipboard]="docType.id" (copied)="notify($event)">
              <clr-icon class="copy-to-clipboard-icon" shape="copy-to-clipboard"></clr-icon>
            </button>
            {{docType.name}}

            <span *ngIf="docType.id == copyIdToClipboard" class="clipboard-copy-success">
              <clr-icon shape="check" size="14"></clr-icon>
              Copied
            </span>
          </clr-dg-cell>
          <clr-dg-cell class="document-type-align-cells">{{docType.description}}</clr-dg-cell>z
          <clr-dg-cell class="document-type-align-cells narrow-column">{{docType.archiveProvider}}</clr-dg-cell>
          <clr-dg-cell class="document-type-align-cells narrow-column">{{docType.dateCreated | date: 'MM/dd/yyyy' }}</clr-dg-cell>
          <clr-dg-cell class="table-actions">
            <div class="btn-group btn-link btn-icon">
              <button [title]="'View Document Type'" *ngIf="!currentUser?.selectedClient?.uiperms?.doctype.update" class="btn btn-no-caps" [disabled]='docType.status != "Active"' (click)="editDocumentType(docType)">
                <clr-icon shape="eye"></clr-icon>
              </button>
              <button [title]="'Edit Document Type'" *ngIf="currentUser?.selectedClient?.uiperms?.doctype.update" class="btn btn-no-caps" [disabled]='docType.status != "Active"' (click)="editDocumentType(docType)">
                <clr-icon shape="pencil"></clr-icon>
              </button>
              <button [title]="'Delete Document Type'" *ngIf="currentUser?.selectedClient?.uiperms?.doctype.delete" class="btn btn-no-caps" [disabled]='docType.status != "Active" || !docType.isDeletable' [hidden]='!docType.isDeletable'  (click)="deleteDocumentType(docType)">
                <clr-icon *ngIf='docType.isDeletable' shape="trash"></clr-icon>
              </button>
            </div>
          </clr-dg-cell>
        </clr-dg-row>
        <clr-dg-footer>

          <clr-dg-pagination #pagination [clrDgPageSize]="tableControls.pageSize.value">
            {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
            of {{pagination.totalItems}} document types
          </clr-dg-pagination>
        </clr-dg-footer>
      </clr-datagrid>
    </div>
  </div>
</div>
<app-document-type-modal (created)="onDocumentTypeCreated($event)" (updated)="onDocumentTypeUpdated($event)" (close)="closeSearchIndexModal()" [selectedDocumentType]="selectedDocumentType" [archiveProviders]="archiveProviders" [dataMappingGroups]="dataMappingGroups" [modalEnabled]="modalEnabled" ></app-document-type-modal>
<div class="table-footer-options">
  <div class="clr-control-container">
    <div class="clr-toggle-wrapper" [title]='showInactiveDocs ? "Inactive": "Active"'>
      <input type="checkbox" clrToggle (click)="toggleVisibilityInactiveDocs()" [checked]='showInactiveDocs' name="toggle">
    </div>
    <div class="inactive-toggle-label">Show Inactive Document Types</div>
  </div>
  <div>
    <button type="button" (click)="updateDocumentTypeOrder()" [disabled]="disableUpdateOrder" class="btn btn-no-caps btn-br save-table-state">
      Update Order
    </button>
  </div>
</div>
