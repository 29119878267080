import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {fadeInLeftAnimation} from '../../animations';
import {IdmRoleMethods} from 'ui-sdk';
import {AlertService} from '../../services/alert.service';
import {Subscription} from 'rxjs';
import {UserMethods} from 'ui-sdk';
import { IimUser } from 'ui-sdk/models/iim-user.model';
import {IdmRole} from 'ui-sdk/models/idm-role.model';
import {ClientMethods} from 'ui-sdk';
import {IimClient} from 'ui-sdk/models/iim-client.model';

@Component({
  selector: 'app-site-mapping-page',
  templateUrl: './site-mapping-page.component.html',
  styleUrls: ['./site-mapping-page.component.scss'],
  animations: [fadeInLeftAnimation]
})
export class SiteMappingPageComponent implements OnInit, OnDestroy {
  allSiteMappings: Array<IdmRole>;
  loading: boolean = false;
  companyIdLoadingEdit: string = '';
  companyIdLoadingDelete: string = '';

  selectedSiteMapping: IdmRole;
  siteMappingModal_enabled = false;

  copyIdToClipboard: string;
  timer;

  clients: Array<IimClient> = [];

  subscriptions: Subscription = new Subscription();
  currentUser: IimUser;

  tableControls: { propertiesFilter: FormControl, pageSize: FormControl, textFilter: FormControl } = {
    propertiesFilter: new FormControl([]),
    pageSize: new FormControl(10),
    textFilter: new FormControl('')
  };

  constructor(private alertService: AlertService) { }

  ngOnInit(): void {
    const userSubscription = UserMethods.currentUser$.subscribe((user: IimUser) => this.onUserLoaded(user));
    this.subscriptions.add(userSubscription);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onUserLoaded(user: IimUser) {
    this.currentUser = user;
    if (this.currentUser) {
      if (this.currentUser.selectedClient.uiperms.datamapping.read) {
        this.getSiteMappings();
      }
    }
  }

  getSiteMappings() {
    this.loading = true;

    IdmRoleMethods.getIdmRoles().then(siteMappings => {
      this.allSiteMappings = siteMappings;
      this.loading = false;
    }, error => {
      this.loading = false;

      if (error.status === 403) {
          const [title, msg] = ['Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [GET IdMRole].`];
          this.alertService.showError(title, msg);
      } 
    });
  }

  removeSiteMapping(siteMapping: IdmRole): void {
    let [title, msg] = [`Delete Site Mapping`,
      `Are you sure you want to delete SiteMapping '${siteMapping.companyId}'? \n\n ** This will remove the clientId, externalClientId and roles associated with this companyId, however, it will not delete the companyId.`];
    this.alertService.confirmDangerousAction(title, msg).then(confirmed => {
      if (confirmed) {
        this.companyIdLoadingDelete = siteMapping.companyId;

        IdmRoleMethods.deleteIdmRoleByCompanyId(siteMapping.companyId).then(() => {
          this.companyIdLoadingDelete = '';
          this.onSiteMappingChanged();
          this.alertService.showSuccessToast(`Site Mapping '${siteMapping.companyId}' Deleted`, undefined);

        }, deleteError => {
          this.companyIdLoadingDelete = '';
          if (deleteError.status === 403) {
            [title, msg] = ['Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [DELETE IdMRole].`];
            this.alertService.showError(title, msg);
          } else {
            [title, msg] = ['Error', 'Could not retrieve Users. Please try again.'];
            this.alertService.showError(title, msg);
          }
        });
      }
    });
  }

  addNewSiteMapping() {
    this.selectedSiteMapping = null;
    this.getClients();
  }

  editSiteMapping(siteMapping) {
    this.selectedSiteMapping = siteMapping;
    this.companyIdLoadingEdit = this.selectedSiteMapping.companyId;
    this.getClients();
  }

  getClients(): void {
    this.subscriptions.add(ClientMethods.getClients().subscribe({
      next: clients  => this.loadClientHandler(clients),
      error: this.loadClientErrorHandler
    }));
  }

  loadClientHandler(clients: any): void {
    this.clients = clients;
    this.companyIdLoadingEdit = '';
    this.siteMappingModal_enabled = true;
  }

  loadClientErrorHandler(): void {
    this.companyIdLoadingEdit = '';
  }

  onSiteMappingChanged() {
    this.getSiteMappings();
  }

  notify(event: any) {
    this.copyIdToClipboard = event;

    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      this.copyIdToClipboard = '';
    }, 3000);
  }
}
