<clr-modal [clrModalOpen]="true" (clrModalOpenChange)="closeModal()" [clrModalStaticBackdrop]="false" [clrModalSize]="'xl'" >
  <div class="modal-title">
    <div class="rectangle r1"></div>
    <div class="rectangle r2"></div>
    <h3 class="modal-title-text">
      <ng-container>Legal Hold(s) Overview</ng-container>
    </h3>
  </div>
  <div class="modal-body">
      <clr-datagrid>
        <clr-dg-column>
          Legal Hold Name
        </clr-dg-column>
        <clr-dg-column>
          Description
        </clr-dg-column>
        <clr-dg-column>
          Created Date
        </clr-dg-column>
        <clr-dg-column>
          Account Number
        </clr-dg-column>

        <clr-dg-placeholder>No Legal Holds have been found for this document!</clr-dg-placeholder>

        <clr-dg-row  *clrDgItems="let legalHold of legalHoldsInfo" [clrDgItem]="legalHold">
          <clr-dg-cell class="">{{ legalHold.name ? legalHold.name : legalHold.legalHoldId}}</clr-dg-cell>
          <clr-dg-cell class="">{{legalHold.comment}}</clr-dg-cell>
          <clr-dg-cell class="">{{legalHold.createDate | date:'fullDate'}}</clr-dg-cell>z
          <clr-dg-cell class="">{{legalHold.accountNumber ? legalHold.accountNumber : "N/A"}}</clr-dg-cell>
        </clr-dg-row>
        <clr-dg-footer>Active Legal Holds: {{legalHoldsInfo.length}}</clr-dg-footer>
      </clr-datagrid>

  </div>

</clr-modal>
