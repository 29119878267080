<clr-modal id="client-modal" [clrModalOpen]="modalEnabled===true" (clrModalOpenChange)="closeModal()"
           [clrModalStaticBackdrop]="false" [clrModalSize]="'lg'">
  <div class="modal-title">
    <div class="rectangle r1"></div>
    <div class="rectangle r2"></div>
    <h3 class="modal-title-text text1">
      <ng-container *ngIf="!_selectedClient">Client Onboarding</ng-container>
      <ng-container *ngIf="_selectedClient">
        Edit Client [{{_selectedClient?.name}}]
        <button class="btn btn-icon btn-link copy-to-clipboard-btn" title="Copy Client Id" [appCopyClipboard]="_selectedClient?.id" (copied)="notify($event)">
          <clr-icon shape="copy-to-clipboard"></clr-icon>
        </button>

        <span *ngIf="_selectedClient.id == copyIdToClipboard" class="clipboard-copy-success clipboard-copy-success-modal">
            <clr-icon shape="check" size="14"></clr-icon>
            Copied
        </span>
      </ng-container>
    </h3>
  </div>
  <div class="modal-body">
    <form class="client-onboard-main-area-width"
          id="client-onboard-form"
          clrForm
          [formGroup]="clientOnBoardForm">

          <clr-tabs>
            <clr-tab *ngIf="!_selectedClient">
              <button clrTabLink>General Info</button>
              <clr-tab-content id="doc-type-general-tab" *clrIfActive="!_selectedClient">
                <clr-input-container class="form-control">
                  <label class="clr-col-xs-12" for="client-onboard-modal__client-name">Client Name: *</label>
                  <input class="clr-col-xs-12" type="text" clrInput placeholder="Enter Client Name" name="client-onboard-modal__client-name"
                         id="client-onboard-modal__client-name" formControlName="clientName"/>
                  <clr-control-error>Please provide a valid name.</clr-control-error>
                </clr-input-container>
                <clr-select-container class="form-control">
                  <label class="clr-col-xs-12" for="client-onboard-modal__archiveProvider">Archive Provider: *</label>
                  <select class="clr-col-xs-12" clrSelect id="client-onboard-modal__archiveProvider" name="client-onboard-modal__archiveProvider"
                          formControlName="archiveProvider" (change)="onArchiveProviderChange($event.target.value)">
                    <option selected disabled value="null">Select an Archive Provider</option>
                    <option *ngFor="let provider of archiveProviders">{{provider}}</option>
                  </select>
                  <clr-control-error>Please select an Archive Provider</clr-control-error>
                </clr-select-container>

                <clr-select-container class="form-control" *ngIf="clientOnBoardForm.value.archiveProvider === 'IIM_Native'">
                  <label class="clr-col-xs-12" for="preprocessor_select_onboard">File Upload Preprocessor:</label>
                  <select class="clr-col-xs-12" clrSelect id="preprocessor_select_onboard" name="preprocessor_select"
                          formControlName="fileUploadPreprocessor">
                    <option selected disabled value="null">Select upload preprocessor</option>
                    <option *ngFor="let preprocessor of fileUploadPreprocessorOptions" value="{{preprocessor}}">{{preprocessor}}</option>
                  </select>
                </clr-select-container>

                <clr-input-container class="form-control" *ngIf="clientOnBoardForm.value.archiveProvider && clientOnBoardForm.value.archiveProvider !== 'IIM_Native'">
                  <label class="clr-col-xs-12" for="client-onboard-modal__username">User Name: *</label>
                  <input class="clr-col-xs-12" type="text" clrInput placeholder="Enter User Name" name="client-onboard-modal__username"
                         id="client-onboard-modal__username" formControlName="username" autocomplete="off" />
                  <clr-control-error>Please provide a valid user name.</clr-control-error>
                </clr-input-container>
                <clr-input-container class="form-control" *ngIf="clientOnBoardForm.value.archiveProvider && clientOnBoardForm.value.archiveProvider !== 'IIM_Native'">
                  <label class="clr-col-xs-12" for="client-onboard-modal__password">Password: *</label>
                  <input class="clr-col-xs-12" type="password" clrInput placeholder="Enter Password" name="client-onboard-modal__password"
                         id="client-onboard-modal__password" formControlName="password" autocomplete="off" />
                  <clr-control-error>Please provide a valid password.</clr-control-error>
                </clr-input-container>
                <clr-input-container class="form-control" *ngIf="clientOnBoardForm.value.archiveProvider && clientOnBoardForm.value.archiveProvider !== 'IIM_Native'">
                  <label class="clr-col-xs-12" for="client-onboard-modal__url">URL:</label>
                  <input class="clr-col-xs-12" type="text" clrInput placeholder="Enter URL" name="client-onboard-modal__url"
                         id="client-onboard-modal__url" formControlName="url"/>
                  <clr-control-error>Please provide a valid url.</clr-control-error>
                </clr-input-container>
          </clr-tab-content>
        </clr-tab>
        <clr-tab *ngIf="!_selectedClient && currentLoginUser?.selectedClient?.uiperms?.customlogo.create">
          <button clrTabLink>Brand Info</button>
          <clr-tab-content id="brand-info-tab" *clrIfActive="false">
            <div class="logo-container">
              <label for="client-logo-modal__file" class="brand-labels">Logo</label>
              <div class="dropzone" appDnd (click)="fileDropRef.click()" (onFileDropped)="fileBrowserHandler($event)" *ngIf="!logoFile">
                <input hidden
                        type="file"
                        #fileDropRef
                        (change)="onFileChange($event.target.files[0])"
                        class="fileInput"
                        id="client-logo-modal__file"
                        name="client-logo-modal__file"
                        accept=" image/bmp,image/cis-cod,	image/ief, image/jpeg, 	image/svg+xml, image/tiff, image/x-cmu-raster,image/x-cmx,image/x-icon,image/x-portable-anymap,image/x-portable-bitmap,image/x-portable-graymap,image/x-portable-pixmap,
image/x-rgb,image/x-xbitmap, image/x-xpixmap, image/x-xwindowdump"
                        />
                <clr-icon class="upload-icon" shape="upload" size="68"></clr-icon>
                <h4 class="upload-box-text">Drag and drop a file here or click to upload.</h4>
              </div>
              <div *ngIf="logoFile">
                <div class="clr-form-control files-list">
                  <div class="file-info">
                    <clr-icon class="file-icon" shape="file" size="36"></clr-icon>
                    <div class="file-name" title="{{logoFile.name}}">
                      {{ logoFile.name }}
                      <div class="file-size">
                        {{ bytesToSize(logoFile.size) }}
                      </div>
                    </div>
                  </div>
                  <button class="delete-file" (click)="deleteSelectedFile()">
                    <clr-icon shape="trash" size="20"></clr-icon>
                  </button>
                </div>
              </div>
            </div>
          </clr-tab-content>
        </clr-tab>

        <clr-tab *ngIf="_selectedClient">
            <button clrTabLink>General Info</button>
            <clr-tab-content id="doc-type-general-tab" *clrIfActive="_selectedClient">
              <clr-input-container class="form-control">
                <label class="clr-col-xs-12" for="client-onboard-modal__client-name">Client Name: *</label>
                <input class="clr-col-xs-12" type="text" clrInput placeholder="Enter Client Name" name="client-onboard-modal__client-name"
                       id="client-onboard-modal__client-name" formControlName="clientName"/>
                <clr-control-error>Please provide a valid name.</clr-control-error>
              </clr-input-container>

              <clr-input-container class="form-control" >
                <label class="clr-col-xs-12" for="client-onboard-modal__username">External Client ID:</label>
                <input class="clr-col-xs-12" type="text" clrInput placeholder="Enter External Client ID" name="client-onboard-modal__externalClientId"
                       id="client-onboard-modal__externalClientId" formControlName="externalClientId"/>
                <clr-control-error>Please provide a external client id.</clr-control-error>
              </clr-input-container>

              <clr-select-container class="form-control" *ngIf="isIIMNativeClient(_selectedClient)">
                <label class="clr-col-xs-12" for="preprocessor_select_edit">File Upload Preprocessor:</label>
                <select class="clr-col-xs-12" clrSelect id="preprocessor_select_edit" name="preprocessor_select"
                        formControlName="fileUploadPreprocessor">
                  <option selected disabled value="null">Select upload preprocessor</option>
                  <option *ngFor="let preprocessor of fileUploadPreprocessorOptions" value="{{preprocessor}}">{{preprocessor}}</option>
                </select>
              </clr-select-container>

        </clr-tab-content>
        </clr-tab>
        <clr-tab *ngIf="_selectedClient">
          <button clrTabLink>Roles</button>
          <clr-tab-content id="roles-tab" *clrIfActive="false">
              <div class="roles-tab-content">
                <div class="existing-roles-group">
                  <p class="clr-control-label client-roles-section-label">Client Roles:</p>
                  <button [@fadeIn] class="btn role-btn" [ngClass]="currentRoleEditName == role.key ? 'active': ''" type="button" *ngFor="let role of clientRoles | keyvalue: unsorted" title="{{role.key}}" (click)="editRole(role)">{{role.key}}</button>

                  <p *ngIf="clientRoles.size < 1 && !loading">
                    Client has no roles
                    <clr-icon shape="filter-grid" size="28"></clr-icon>
                  </p>

                  <p *ngIf="loading">
                    Loading Roles <span class="spinner spinner-inline"></span>
                  </p>
                </div>

<!--                <div class="clr-checkbox-wrapper">-->
<!--                  <input type="checkbox" id="default_role_check" name="default_role_check" class="clr-checkbox">-->
<!--                  <label class="clr-control-label text-dark clr-col-md-auto default-role-check" for="default_role_check">Set as default role for federated access</label>-->
<!--                </div>-->

                <div class="add-role-group">
                  <div>
                    <div>
                      <div class="companyid-group">
                        <clr-select-container class="role-name-input companyid-select">
                          <label>Company Id: *</label>
                          <select clrSelect (change)="onCompanyIdSelect($event.target.value)">
                            <option [selected]="!selectedRoleCompanyId" disabled value="null">Select CompanyId</option>
                            <option *ngFor="let companyId of companyIds" value="{{companyId}}" [selected]="selectedRoleCompanyId === companyId">{{companyId}}</option>
                          </select>
                        </clr-select-container>

                        <button type="button" class="btn btn-icon btn-link add-companyid-btn" title="Add New CompanyId" (click)="openCompanyIdModal()">
                          <clr-icon shape="plus-circle" size="20"></clr-icon>
                        </button>
                      </div>

                      <clr-input-container class="role-name-input role-name-txt">
                        <label for="role_name_input">Role Name: *</label>
                        <input type="text" clrInput id="role_name_input" placeholder="Enter Role Name" name="client-onboard-modal__client-name" formControlName="roleName" (keydown)="roleNameChange($event)" />
                        <clr-control-error>Please provide a valid name.</clr-control-error>
                      </clr-input-container>
                    </div>

                    <div class="add-role-btn-group">
                      <button type="button" class="btn inverse" title="Clear" [disabled]="!selectedRoleCompanyId" (click)="resetRoleInputs()">
                        Clear
                      </button>

                      <button type="button" class="btn btn-warning" title="Delete role" [disabled]="!this.currentRoleEditName" (click)="deleteRole()">
                        Delete Role
                      </button>

                      <button type="button" class="btn btn-primary" [title]="currentRoleEditName?.length > 0 ? 'Update Role' : 'Add Role'"
                              [disabled]="!clientOnBoardForm.get('roleName').value || this.perms.selected.length < 1 || !this.selectedRoleCompanyId  || (currentRoleEditName?.length > 0 && !permsChanged)" (click)="addRole()">
                        <span *ngIf="currentRoleEditName?.length < 1">
                          Add Role
                        </span>
                        <span *ngIf="currentRoleEditName?.length > 0">
                          Update Role
                        </span>
                      </button>
                    </div>
                  </div>

                  <clr-datagrid class="perms-grid" [clrDgLoading]="loading" [(clrDgSelected)]="perms.selected" (clrDgSelectedChange)="selectionChanged($event)">
                    <clr-dg-column>
                      Permission
                    </clr-dg-column>
                    <clr-dg-row *ngFor="let perm of _permissions | keyvalue: unsorted" [clrDgItem]="perm.value.permissionCode" [clrDgSelectable]="!isSuperUserSelected || perm.key == 'SuperUser'">
                      <clr-dg-cell class="perms-cell">
                        <clr-icon shape="administrator" size="18" *ngIf="perm.key.endsWith('SuperUser')"></clr-icon>
                        <clr-icon shape="cluster" size="18" *ngIf="perm.key.endsWith('ArchiveApiProvider') || perm.key.endsWith('ArchiveApiProviders')"></clr-icon>
                        <clr-icon shape="tasks" size="18" *ngIf="perm.key.endsWith('EventStatus')"></clr-icon>
                        <clr-icon shape="bank" size="18" *ngIf="perm.key.endsWith('Client') || perm.key.endsWith('Clients') || perm.key.endsWith('ClientById')"></clr-icon>
                        <clr-icon shape="paint-roller" size="18" *ngIf="perm.key.endsWith('ClientCustomLogo')"></clr-icon>
                        <clr-icon shape="upload-cloud" size="18" *ngIf="perm.key.endsWith('UploadDocument') || perm.key.endsWith('UploadDocumentLegacy')"></clr-icon>
                        <clr-icon shape="download-cloud" size="18" *ngIf="perm.key.endsWith('DocumentDownload') || perm.key.endsWith('DocumentContent') || perm.key.endsWith('DocumentContentLegacy')"></clr-icon>
                        <clr-icon shape="hard-drive" size="18" *ngIf="perm.key.endsWith('DocumentMetadata')"></clr-icon>
                        <clr-icon shape="process-on-vm" size="18" *ngIf="perm.key.endsWith('RunningEvents')"></clr-icon>
                        <clr-icon shape="search" size="18" *ngIf="perm.key.endsWith('SearchDocument')"></clr-icon>
                        <clr-icon shape="pod" size="18" *ngIf="perm.key.endsWith('ClientDocType') || perm.key.endsWith('ClientDocTypeById') || perm.key.endsWith('ClientDocTypes')"></clr-icon>
                        <clr-icon shape="shield-x" size="18" *ngIf="perm.key.endsWith('LegalHold') || perm.key.endsWith('LegalHolds')"></clr-icon>
                        <clr-icon shape="tags" size="18" *ngIf="perm.key.endsWith('DocTypeSearchIndexes')"></clr-icon>
                        <clr-icon shape="ruler-pencil" size="18" *ngIf="perm.key.endsWith('ClientPreference') || perm.key.endsWith('ClientPreferences')"></clr-icon>
                        <clr-icon shape="floppy" size="18" *ngIf="perm.key.endsWith('SavedSearch') || perm.key.endsWith('SavedSearchById') || perm.key.endsWith('SavedSearches')"></clr-icon>
                        <clr-icon shape="key" size="18" *ngIf="perm.key.endsWith('Permissions')"></clr-icon>
                        <clr-icon shape="network-settings" size="18" *ngIf="perm.key.endsWith('SiteMappings') || perm.key.endsWith('SiteMappingById')"></clr-icon>
                        <clr-icon shape="map" size="18" *ngIf="perm.key.endsWith('DataMappings') || perm.key.endsWith('DataMapping') || perm.key.endsWith('DataMappingById')"></clr-icon>
                        <clr-icon shape="briefcase" size="18" *ngIf="perm.key.endsWith('Reports') || perm.key.endsWith('Report') || perm.key.endsWith('ReportById')"></clr-icon>

                        {{ perm.key }}

                        <clr-icon class="super-user-warning" shape="exclamation-circle" size="18" *ngIf="perm.key == 'SuperUser'"></clr-icon>
                        <span class="super-user-warning" *ngIf="perm.key == 'SuperUser'">Use permission with caution</span>
                      </clr-dg-cell>
                      <clr-dg-row-detail *clrIfExpanded>
                        Description: {{ perm.value.description }}<br><br>
                        {{ perm.value.httpMethod }} {{ perm.value.path }}<br><br>
                        Permision Code: [ {{ perm.value.permissionCode }} ]
                      </clr-dg-row-detail>
                    </clr-dg-row>
                  </clr-datagrid>
                </div>
              </div>

              </clr-tab-content>
            </clr-tab>

            <clr-tab *ngIf="_selectedClient">
              
              <button clrTabLink>Delivery Preferences</button>
              <clr-tab-content id="client-onboard-general-tab" *clrIfActive="!_selectedClient">
                <div class="print-default-check" *ngIf="this._selectedClient.name=='Northern Trust'">
                  <label class="clr-control-label client-delivery-pref" for="allow_pref_update_check">Account Number search field utilizes NT Functional/Group Number</label>
                </div>
                <div class="print-default-check">
                  <label class="clr-control-label client-delivery-pref" for="allow_pref_update_check">Allow client to update delivery preferences</label>
                  <input type="checkbox" id="allow_pref_update_check" name="allow_pref_update_check" class="clr-checkbox" formControlName="allowPreferenceUpdate">
                </div>
                <div class="print-default-check" *ngIf="clientOnBoardForm.value.allowPreferenceUpdate === true">
                  <label class="clr-control-label client-delivery-pref" for="allow_empty_pref">Allow empty delivery preferences</label>
                  <input type="checkbox" id="allow_empty_pref" name="allow_empty_pref" class="clr-checkbox" formControlName="allowEmptyPreferences">
                </div>
                <div class="print-default-check">
                  <label class="clr-control-label client-delivery-pref" for="search_by_subclientid">Allow client to search by SubClientId</label>
                  <input type="checkbox" id="search_by_subclientid" name="search_by_subclientid" class="clr-checkbox" formControlName="allowSearchBySubClientId">
                </div>
                <div class="print-default-check">
                  <label class="clr-control-label client-delivery-pref" for="print_default_no_pref">Set all content types to PRINT if no preferences found</label>
                  <input type="checkbox" id="print_default_no_pref" (change)="isPrintDefaultChange($event.target.checked)" name="print_default_no_pref" class="clr-checkbox" formControlName="isPrintDefaultIfNoPreferences">
                </div>

                <div class="print-default-check">
                  <label class="clr-control-label client-delivery-pref" for="is_custom_error">Display a custom error message if no preferences/account number found</label>
                  <input type="checkbox" id="is_custom_error" (change)="isCustomErrorChange($event.target.checked)" name="is_custom_error" class="clr-checkbox" formControlName="isCustomErrorMsgIfNoPreferences">
                </div>

                <clr-textarea-container class="form-control" *ngIf="clientOnBoardForm.value.isCustomErrorMsgIfNoPreferences === true">
                  <label class="clr-col-xs-12" for="client-onboard-modal-tooltip">Custom Error Message:</label>
                  <textarea clrTextarea rows="6" class="search-index-input clr-col-xs-12" placeholder="Error message here..." name="custom-error-message"
                            formControlName="customErrorMsgNoPreferences" id="custom-error-message"></textarea>
                </clr-textarea-container>

                <p class="clr-control-label client-delivery-pref">Terms And Conditions:</p>
                <clr-select-container class="form-control clr-select-wrapper_height">
                  <label class="clr-col-xs-12" for="client-onboard-modal">Enable:</label>
                  <select class="search-index-input client-onboard-dropdown clr-col-xs-12" clrSelect id="client-onboard-modal" name="client-onboard"
                          formControlName="enablePreference">
                    <option selected value="true">Yes</option>
                    <option selected value="false">No</option>
                  </select>
                </clr-select-container>
                <clr-textarea-container class="form-control">
                  <label class="clr-col-xs-12" for="client-onboard-modal__terms">Terms And Conditions Text:</label>
                  <textarea clrTextarea rows="6" class="search-index-input clr-col-xs-12" placeholder="EnterText" name="client-onboard-modal__terms"
                            formControlName="termsHtml" id="client-onboard-modal__terms"></textarea>
                </clr-textarea-container>

                <clr-select-container>
                  <label class="clr-col-xs-12" for="client-onboard-modal">Paperless:</label>
                  <select class="search-index-input client-onboard-dropdown clr-col-xs-12" clrSelect id="client-onboard-modal-paperless" name="client-onboard-paperless"
                          formControlName="goPaperless">
                    <option selected value="false">No</option>
                    <option value="true">Yes</option>
                  </select>
                </clr-select-container>

                <clr-select-container>
                  <label class="clr-col-xs-12" for="client-onboard-modal">Footer:</label>
                  <select class="search-index-input client-onboard-dropdown clr-col-xs-12" clrSelect id="client-onboard-modal-footer" name="client-onboard-footer"
                          formControlName="setFooter">
                    <option selected value="false">No</option>
                    <option value="true">Yes</option>
                  </select>
                </clr-select-container>

                <clr-input-container class="form-control" >
                  <label class="clr-col-xs-12" for="client-onboard-modal-max-email">Max number of Email:</label>
                  <input class="search-index-input clr-col-xs-12" style="width: 185px" type="number" clrInput placeholder="Enter Number of Email" name="client-onboard-modal-max-email"
                         id="client-onboard-modal-max-email" min="1" onkeydown='return event.keyCode !== 69' formControlName="maxEmail"/>
                         <clr-control-error>Please provide a valid Number.</clr-control-error>
                </clr-input-container>

                <clr-input-container class="form-control" >
                  <label class="clr-col-xs-12" for="client-onboard-modal-max-number">Max number of SMS:</label>
                  <input class="search-index-input clr-col-xs-12" style="width: 185px" type="number" clrInput placeholder="Enter Number of Mobile Number" name="client-onboard-modal-max-number"
                         id="client-onboard-modal-max-number" min="1" onkeydown='return event.keyCode !== 69' formControlName="maxMobileNumber"/>
                         <clr-control-error>Please provide a valid Number.</clr-control-error>
                </clr-input-container>

                <clr-textarea-container class="form-control">
                  <label class="clr-col-xs-12" for="client-onboard-modal-tooltip">Tooltip Text:</label>
                  <textarea clrTextarea rows="6" class="search-index-input clr-col-xs-12" placeholder="EnterText" name="client-onboard-modal-tooltip"
                            formControlName="toolTipContent" id="client-onboard-modal-tooltip"></textarea>
                </clr-textarea-container>

                <clr-select-container class="form-control clr-select-wrapper_height">
                  <label class="clr-col-xs-12" for="client-onboard-modal">Content Type Option:</label>
                  <select class="search-index-input client-onboard-dropdown clr-col-xs-12" clrSelect id="client-onboard-modal-content-type-option" name="client-onboard-content-type-option"
                          formControlName="contentTypeOption">
                    <option value="SINGLE">SINGLE</option>
                    <option value="MULTIPLE">MULTIPLE</option>
                  </select>
                </clr-select-container>

                <p class="clr-control-label client-delivery-pref">Content Types:</p>
                <button class="btn btn-link btn-no-caps add-content-btn" (click)="onAddContentType($event)">
                  <clr-icon shape="plus-circle"></clr-icon>&nbsp;Add content Types
                </button>
                <!-- <div formArrayName="contentTypeFormArray" *ngFor="let contentType of contentTypeFormArray.controls;let i=index;trackBy:track">
                  <div class="clr-row" formGroupName="{{i}}">
                    <clr-input-container class="content-type-align">
                      <label class="clr-col-xs-12">{{i+1}}) Name:</label>
                      <input class="search-index-input clr-col-xs-12" style="width: 185px;" type="text" clrInput
                             placeholder="Enter name" formControlName="content" (change)="clientOnBoardForm.markAsDirty()"/>
                             <clr-control-error>Please provide a content type.</clr-control-error>
                    </clr-input-container>
                    <div class="remove-contentType btn-link btn-icon">
                      <button  class="btn btn-no-caps" title="Remove name" (click)="removeContentType(i)">
                        <clr-icon class="remove-contentType-icon" size="19" shape="trash"></clr-icon>
                      </button>
                    </div>
                  </div>
                </div> -->
                <div formArrayName="contentTypeFormArray" *ngFor="let contentType of contentTypeFormArray.controls;let i=index;trackBy:track">
                  <div class="deliveryMethodContainer" formGroupName="{{i}}">
                    <div class="clr-row">
                      <clr-input-container class="form-control clr-col-5 table-input-container">
                        <label class="clr-col-xs-0">Key:</label>
                        <input class="search-index-input clr-col-xs-12" type="text" clrInput
                               placeholder="Enter key" formControlName="key" (change)="clientOnBoardForm.markAsDirty();clientOnBoardForm.updateValueAndValidity()"/>
                        <clr-control-error>Please provide a valid key.</clr-control-error>
                      </clr-input-container>
                      <clr-input-container class="form-control clr-col-5 table-input-container">
                        <label class="clr-col-xs-0">Label:</label>
                        <input class="search-index-input clr-col-xs-12" type="text" clrInput
                               placeholder="Enter label" formControlName="label" (change)="clientOnBoardForm.markAsDirty();clientOnBoardForm.updateValueAndValidity()"/>
                        <clr-control-error>Please provide a valid label.</clr-control-error>
                      </clr-input-container>
                      <div class="clr-col-1"></div>
                      <div class="remove-contentType btn-link btn-icon clr-col-1">
                        <button  class="btn btn-no-caps" title="Remove name" (click)="removeContentType(i)">
                          <clr-icon class="remove-contentType-icon" size="19" shape="trash"></clr-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <p class="clr-control-label client-delivery-pref">Delivery Methods settings:</p>
                  <button class="btn btn-link btn-no-caps add-more-btn" (click)="onAddDeliveryMethods($event)">
                    <clr-icon shape="plus-circle"></clr-icon>&nbsp;Add Delivery Methods
                  </button>
                  <div formArrayName="deliveryMethodsFormArray" *ngFor="let contentType of deliveryMethodsFormArray.controls;let i=index;trackBy:track">
                    <div class="deliveryMethodContainer" formGroupName="{{i}}">
                      <div class="clr-row">
                        <clr-input-container class="form-control clr-col-5 table-input-container">
                          <label class="clr-col-xs-0">Name:</label>
                          <input class="search-index-input clr-col-xs-12"  type="text" clrInput
                                 placeholder="Enter name" formControlName="name" (change)="clientOnBoardForm.markAsDirty()"/>
                                 <clr-control-helper>Separate by the pipe/vertical bar symbol ("|")</clr-control-helper>
                                 <clr-control-error>Please provide a valid name.</clr-control-error>
                        </clr-input-container>
                        <clr-input-container class="form-control clr-col-5 table-input-container">
                          <label class="clr-col-xs-0">label:</label>
                          <input class="search-index-input clr-col-xs-12"  type="text" clrInput
                                 placeholder="Enter label" formControlName="label" (change)="clientOnBoardForm.markAsDirty()"/>
                                 <clr-control-error>Please provide a valid label.</clr-control-error>
                        </clr-input-container>
                        <div class="clr-col-1"></div>
                        <div class="remove-contentType btn-link btn-icon clr-col-1">
                          <button  class="btn btn-no-caps" title="Remove name" (click)="removeDeliveryMethods(i)">
                            <clr-icon class="remove-contentType-icon" size="19" shape="trash"></clr-icon>
                          </button>
                        </div>
                      </div>
                      <div class="clr-row">
                        <div class="clr-col-5 no-padding datatype-container">
                          <div class="clr-row>">
                            <div class="vertical-select clr-col-12">
                              <label class="clr-col-xs-0" >Display Type</label>
                              <select class=" search-index-datatype-item document-type-modal__search-index"
                                      title="Select Display Type"
                                      type="text"
                                      formControlName="displayType"
                                      name="displayType"
                                      >
                                <option value="inputbox">Inputbox</option>
                              </select>
                            </div>

                          </div>
                        </div>

                        <div class="clr-col-5 no-padding datatype-container">
                          <div class="clr-row>">
                            <div class="vertical-select clr-col-12">
                              <label class="clr-col-xs-0" >Icon</label>
                              <select class=" search-index-datatype-item document-type-modal__search-index"
                                      title="Select Display Type"
                                      type="text"
                                      formControlName="icon"
                                      name="icon"
                                      (change)="markFormDirty()"
                                      >
                                <option value="envelope">Email</option>
                                <option value="comment">Text</option>
                                <option value="print">Print</option>
                                <option value="fax">Fax</option>
                                <option value="envelope-open-text">Mail</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="clr-col-1 iconDiv">
                          <div *ngIf="deliveryMethodsFormArray.controls[i].get('icon').value">
                          <fa-icon [icon]="deliveryMethodsFormArray.controls[i].get('icon').value" size="lg"></fa-icon>
                          </div>
                        </div>
                      </div>
                      <div class="clr-row">
                        <div class="clr-col-12 no-padding">
                          <div class="d-inline-block inactive-label">
                            <input type="checkbox" [id]="'show-terms-checkbox_'+i" class="align"  [name]="'show-terms-checkbox_'+i" formControlName="enableTandC" (click)="markFormDirty()">
                            <label [for]="'show-terms-checkbox_'+i" class="clr-col-12">only display Terms & Conditions if preference is selected.</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <p class="clr-control-label client-delivery-pref">User Attributes:</p>
                  <button class="btn btn-link btn-no-caps add-more-btn" (click)="onAddUserAttributes($event)">
                    <clr-icon shape="plus-circle"></clr-icon>&nbsp;Add User Attribute
                  </button>
                  <div formArrayName="userAttributesFormArray" *ngFor="let contentType of userAttributesFormArray.controls;let i=index;trackBy:track">
                    <div class="deliveryMethodContainer" formGroupName="{{i}}">
                      <div class="clr-row">
                        <clr-input-container class="form-control clr-col-5 table-input-container">
                          <label class="clr-col-xs-0">IPM Attribute:</label>
                          <input class="search-index-input clr-col-xs-12" type="text" clrInput
                                 placeholder="Enter key" formControlName="ipmAttribute" (change)="clientOnBoardForm.markAsDirty()"/>
                          <clr-control-error>Please provide a valid attribute.</clr-control-error>
                        </clr-input-container>
                        <clr-input-container class="form-control clr-col-5 table-input-container">
                          <label class="clr-col-xs-0">DP Label:</label>
                          <input class="search-index-input clr-col-xs-12" type="text" clrInput
                                 placeholder="Enter label" formControlName="dpLabel" (change)="clientOnBoardForm.markAsDirty()"/>
                          <clr-control-error>Please provide a valid label.</clr-control-error>
                        </clr-input-container>
                        <div class="clr-col-1"></div>
                        <div class="remove-contentType btn-link btn-icon clr-col-1">
                          <button  class="btn btn-no-caps" title="Remove name" (click)="removeUserAttributes(i)">
                            <clr-icon class="remove-contentType-icon" size="19" shape="trash"></clr-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <p class="clr-control-label client-delivery-pref">Other Attributes:</p>
                  <button class="btn btn-link btn-no-caps add-more-btn" (click)="onAddOtherAttributes($event)">
                    <clr-icon shape="plus-circle"></clr-icon>&nbsp;Add other Attribute
                  </button>
                  <div formArrayName="otherAttributesFormArray" *ngFor="let contentType of otherAttributesFormArray.controls;let i=index;trackBy:track">
                    <div class="deliveryMethodContainer" formGroupName="{{i}}">
                      <div class="clr-row">
                        <clr-input-container class="form-control clr-col-5 table-input-container">
                          <label class="clr-col-xs-0">Attribute:</label>
                          <input class="search-index-input clr-col-xs-12" type="text" clrInput
                                 placeholder="Enter key" formControlName="attributeValue" (change)="clientOnBoardForm.markAsDirty()"/>
                          <clr-control-error>Please provide a valid attribute.</clr-control-error>
                        </clr-input-container>
                        <clr-input-container class="form-control clr-col-5 table-input-container">
                          <label class="clr-col-xs-0">Label:</label>
                          <input class="search-index-input clr-col-xs-12" type="text" clrInput
                                 placeholder="Enter label" formControlName="label" (change)="clientOnBoardForm.markAsDirty()"/>
                          <clr-control-error>Please provide a valid label.</clr-control-error>
                        </clr-input-container>
                        <div class="clr-col-1"></div>
                        <div class="remove-contentType btn-link btn-icon clr-col-1">
                          <button  class="btn btn-no-caps" title="Remove name" (click)="removeOtherAttributes(i)">
                            <clr-icon class="remove-contentType-icon" size="19" shape="trash"></clr-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
          </clr-tab-content>
        </clr-tab>
        <clr-tab *ngIf="_selectedClient">
          <button clrTabLink>Client Entitlement</button>
          <clr-tab-content>
            <clr-select-container class="form-control clr-select-wrapper_height">
              <label class="clr-col-xs-12" for="otp_select">OTP:</label>
              <select class="search-index-input client-onboard-dropdown clr-col-xs-12" clrSelect id="otp_select" name="client-onboard"
                      formControlName="otp">
                <option [ngValue]="true">Yes</option>
                <option [ngValue]="false">No</option>
              </select>
            </clr-select-container>
            <clr-select-container class="form-control clr-select-wrapper_height" *ngIf="clientOnBoardForm.value.otp">
              <label class="clr-col-xs-12" for="otp_options_select">OTP options:</label>
              <select class="search-index-input client-onboard-dropdown clr-col-xs-12" clrSelect id="otp_options_select" name="client-onboard"
                      formControlName="otpOptions">
                <option value="EMAIL">Email</option>
                <option value="SMS">SMS</option>
                <option value="BOTH">Both</option>
              </select>
            </clr-select-container>
            <div class="entitlement-align">
              <button class="btn btn-link btn-no-caps add-entitlement-btn" (click)="addEntitlement($event)">
                <clr-icon shape="plus-circle"></clr-icon>&nbsp;Add Entitlement
              </button>
            </div>

            <div formArrayName="entitlementFormArray" *ngFor="let entitlement of entitlementFormArray.controls;let i=index;trackBy:track">
              <div class="deliveryMethodContainer" formGroupName="{{i}}">
                <div class="clr-row">
                  <clr-input-container class="form-control clr-col-5 table-input-container">
                    <label class="clr-col-xs-0">Enter Name:</label>
                    <input class="search-index-input clr-col-xs-12" type="text" clrInput
                           placeholder="Enter key" formControlName="clientEntitlementName" (change)="clientOnBoardForm.markAsDirty()"/>
                    <clr-control-error>Please provide a valid name.</clr-control-error>
                  </clr-input-container>
                  <clr-radio-container clrInline class="form-control clr-col-3 table-input-container">
                    <label class="clr-col-xs-0">Required:</label>
                      <clr-radio-wrapper>
                        <input type="radio" clrRadio [value]="true" formControlName="clientEntitlementType"/>
                        <label>Yes</label>
                      </clr-radio-wrapper>
                      <clr-radio-wrapper>
                        <input type="radio" clrRadio [value]="false" formControlName="clientEntitlementType"/>
                        <label>No</label>
                      </clr-radio-wrapper>
                  </clr-radio-container>
                  <div class="clr-col-1"></div>
                  <div class="remove-contentType btn-link btn-icon clr-col-1">
                    <button  class="btn btn-no-caps" title="Remove name" (click)="removeEntitlement(i)">
                      <clr-icon class="remove-contentType-icon" size="19" shape="trash"></clr-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </clr-tab-content>
        </clr-tab>

        <clr-tab  *ngIf="_selectedClient">
          <button clrTabLink>Search Results</button>
          <clr-tab-content>
            <clr-select-container class="form-control clr-select-wrapper_height">
              <label class="clr-col-xs-12" for="action_bttns">Action Buttons</label>
              <select class="clr-col-xs-12" clrSelect id="action_bttns" name="action_bttns" formControlName="searchResultsActionButtons">
                <option value="viewAndDownloadDocument">View & Download Document</option>
                <option value="viewBatchAndGenerateReport">View Batch & Generate Report</option>
              </select>
            </clr-select-container>
          </clr-tab-content>
        </clr-tab>
      </clr-tabs>
    </form>
  </div>

  <div class="modal-footer">
    <div class="client-onboard-phase">
      <p *ngIf="clientOnboardPhase?.length > 0">
        {{clientOnboardPhase}}...
      </p>
    </div>
    <div>
      <button type="button" class="btn btn-outline cancel-btn" (click)="closeModal()">Cancel</button>
      <button id="client-onboard-submit-trigger" [disabled]="!saveButtonEnabled()" (click)="onSubmitClientOnBoard()" [clrLoading]="uploadInProgress" class="btn btn-primary">Save</button>
    </div>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="showCompanyIdModal" class="add-companyid-modal" (clrModalOpenChange)="closeCompanyIdModal()" [clrModalStaticBackdrop]="false" [clrModalSize]="'sm'">
  <h4 class="modal-title">Add New CompanyId</h4>
  <div class="modal-body perms-modal">
    <clr-input-container>
      <label>CompanyId</label>
      <input class="clr-col-xs-12 add-companyid-modal-input" type="text" clrInput (keyup)="companyIdChange($event.target.value)" [value]="companyIdToAdd" placeholder="Enter id"/>
    </clr-input-container>
    <div class="companyid-modal-buttons">
      <button type="button" class="btn btn-outline" (click)="closeCompanyIdModal()">Cancel</button>
      <button class="btn btn-primary" [disabled]="!companyIdToAdd" [clrLoading]="createCompanyIdLoading" (click)="saveCompanyId()">Add</button>
    </div>
  </div>
</clr-modal>
