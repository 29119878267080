import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';
import moment from 'moment';

@Component({
  selector: 'search-index-metadata-filter',
  template: `
    <input *ngIf="propertyType == 'String'" type="text" class="clr-input" name="search-index-metadata-filter" [formControl]="control"/>
    <input *ngIf="propertyType == 'Number'" type="text" class="clr-input" name="search-index-metadata-filter" [formControl]="control"/>
    <input *ngIf="propertyType == 'Date'" type="date" clrDate name="date-filter" [formControl]="control"/>
    <input *ngIf="propertyType == 'DateTime'" type="date" clrDate name="date-filter" [formControl]="control"/>
    <button class="btn btn-sm btn-block btn-clear-filter" (click)="clearFilter()">Clear</button>
  `,
  styles: [`
    .btn-clear-filter {
      margin-top: 0.5rem;
    }
  `],
})
export class SearchIndexMetadataFilter implements OnInit, OnDestroy {
  @Input() propertyName: string = null;
  @Input() propertyType: string = null;
  control: FormControl = new FormControl(null);

  private _changes = new Subject<any>();
  subscriptions: Subscription = new Subscription();

  // We do not want to expose the Subject itself, but the Observable which is read-only
  public get changes(): Observable<any> {
    return this._changes.asObservable();
  }

  ngOnInit(): void {
    this.subscriptions.add(this.control.valueChanges.subscribe(value => {
      this._changes.next(true);
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  clearFilter() {
    this.control.setValue(null);
    this._changes.next(true);
  }

  accepts(item: any): boolean {
    if (!this.control.value) {
      return true;
    }
    if (this.propertyType === 'Date' || this.propertyType === 'DateTime') {
      const input = moment(this.control.value, 'MM/DD/YYYY').format('YYYY-MM-DD');
      const value = item.metadata['indexes.' + this.propertyName];
      return value && new Date(value).toISOString().split('T')[0] === input;
    }if (this.propertyType === 'Number' || this.propertyType === 'String') {
      const input = this.control.value.trim();
      const value = item.metadata['indexes.' + this.propertyName];
      return value && value.toString().indexOf(input) > -1;
    }
  }

  isActive(): boolean {
    return this.control.value !== null;
  }
}
