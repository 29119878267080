<clr-modal id="data-mapping-modal" class="function-designer-modal" [clrModalOpen]="modalEnabled===true" [clrModalStaticBackdrop]="false" (clrModalOpenChange)="closeModal()" [clrModalSize]="'xl'">
  <div class="modal-title">
    <div class="rectangle r1"></div>
    <div class="rectangle r2"></div>
    <h3 class="modal-title-text">
      <ng-container>Data Transform Function Designer</ng-container>
    </h3>
  </div>
  <div class="modal-body">
    <form class="data-transform-form" clrForm [formGroup]="dataTransformFunctionForm">
      <div class="main-area-group">
        <div class="function-designer-group">
          <div class="function-section">
            <clr-select-container class="form-control select-top-margin">
              <label class="clr-control-label clr-col-xs-12" for="function_type">
                1) Transform Function Type:
                <span>
                  <a role="tooltip" aria-haspopup="true" class="tooltip tooltip-md tooltip-bottom-right">
                    <clr-icon shape="info-circle" size="24"></clr-icon>
                    <span class="tooltip-content">
                      {{functionInfo.ReplaceValue.description}}
                    </span>
                  </a>
                </span>
              </label>
              <select class="transform-input clr-col-xs-12" clrSelect id="function_type" name="function_type" formControlName="functionType">
                <option selected disabled value="null">Select function type</option>
                <option value="ReplaceValue">ReplaceValue</option>
              </select>
            </clr-select-container>

            <div *ngIf="dataTransformFunctionForm.get('functionType').value">
              <clr-input-container class="form-control">
                <label class="clr-col-xs-12" for="function_name">
                  2) Transform Function Name:
                  <span>
                  <a role="tooltip" aria-haspopup="true" class="tooltip tooltip-md tooltip-bottom-right">
                    <clr-icon shape="info-circle" size="24"></clr-icon>
                    <span class="tooltip-content">
                      Please specify a unique name for a function (no duplicate names allowed within a single Data Mapping Group)
                      ________________________<br><br>
                    </span>
                  </a>
                </span>
                </label>
                <input class="transform-input clr-col-xs-12" type="text" (change)="checkFunctionNameUniqueness($event.target.value)" clrInput placeholder="Enter function name" name="data-mapping-modal__document-type-name"
                       id="function_name" formControlName="functionName"/>
                <clr-control-error class="input-error-msg">Please provide Function Name.</clr-control-error>
              </clr-input-container>
              <p class="custom-error-message" *ngIf="isDuplicateFunctionName">Sorry, that function name already exists in this Data Mapping Group </p>
            </div>
          </div>

          <div class="target-section">
            <div *ngIf="dataTransformFunctionForm.get('functionName').value">
              <clr-select-container class="form-control select-top-margin">
                <label class="clr-control-label clr-col-xs-12" for="target_type">
                  3) Replacement Target Type:
                  <span>
                  <a role="tooltip" aria-haspopup="true" class="tooltip tooltip-md tooltip-top-right">
                    <clr-icon shape="info-circle" size="24"></clr-icon>
                    <span class="tooltip-content">
                      {{functionInfo.ReplaceValue.arguments.target.fields.dataType.description}}
                      ________________________<br><br>
                    </span>
                  </a>
                </span>
                </label>
                <select class="transform-input clr-col-xs-12" clrSelect id="target_type" name="target_type" formControlName="targetType" (change)="++inputOrder; onTargetTypeChange($event.target.value)">
                  <option selected disabled value="null">Select target type</option>
                  <option value="key">Key</option>
                  <option value="value">Value</option>
                  <option value="regex">Regex</option>
                  <option value="currency">Currency</option>
                </select>
                <clr-control-error class="input-error-msg">Please Select Target Type.</clr-control-error>
              </clr-select-container>
            </div>

            <div *ngIf="dataTransformFunctionForm.get('targetType').value == 'value'">
              <clr-select-container class="form-control clr-select-wrapper_height site-mapping-input">
                <label class="clr-control-label clr-col-xs-12" for="target_data_type">
                  4) Replacement Target Data Type:
                  <span>
                <a role="tooltip" aria-haspopup="true" class="tooltip tooltip-md tooltip-top-right">
                  <clr-icon shape="info-circle" size="24"></clr-icon>
                  <span class="tooltip-content">
                    {{functionInfo.ReplaceValue.arguments.target.fields.type.description}}
                    ________________________<br><br>
                  </span>
                </a>
              </span>
                </label>
                <select class="transform-input clr-col-xs-12" clrSelect id="target_data_type" name="target_data_type" formControlName="targetDataType" (change)="this.comparisonOperators = [onDataTypeChange($event.target.value)]">
                  <option selected disabled value="null">Select data type</option>
                  <option value="string">String</option>
                  <option value="number">Number</option>
                  <option value="any">Any</option>
                </select>
                <clr-control-error class="input-error-msg">Please Select Target Data Type.</clr-control-error>
              </clr-select-container>

              <div *ngIf="dataTransformFunctionForm.get('targetDataType').value">
                <clr-select-container class="form-control clr-select-wrapper_height site-mapping-input">
                  <label class="clr-control-label clr-col-xs-12" for="target_operator">
                    5) Comparison Operator:
                    <span>
                    <a role="tooltip" aria-haspopup="true" class="tooltip tooltip-md tooltip-top-right">
                      <clr-icon shape="info-circle" size="24"></clr-icon>
                      <span class="tooltip-content">
                       {{functionInfo.ReplaceValue.arguments.target.fields.operator.description}}
                        ________________________<br><br>
                      </span>
                    </a>
                  </span>
                  </label>
                  <select class="transform-input clr-col-xs-12" clrSelect id="target_operator" name="target_operator" formControlName="targetOperator">
                    <option selected disabled value="null">Select comparator</option>
                    <option *ngFor="let operator of comparisonOperators[0] | keyvalue" value="{{operator.key}}">{{operator.value}}</option>
                  </select>
                  <clr-control-error class="input-error-msg">Please Select Comparison Operator.</clr-control-error>
                </clr-select-container>
              </div>

              <div *ngIf="dataTransformFunctionForm.get('targetOperator').value && !['EX', 'NEX'].includes(dataTransformFunctionForm.get('targetOperator').value)">
                <clr-input-container class="form-control" *ngIf="dataTransformFunctionForm.get('targetDataType').value == 'string' || dataTransformFunctionForm.get('targetDataType').value == 'any'">
                  <label class="clr-control-label clr-col-xs-12" for="target_value_string">
                    6) Replacement Target Value:
                    <span>
                    <a role="tooltip" aria-haspopup="true" class="tooltip tooltip-md tooltip-top-right">
                      <clr-icon shape="info-circle" size="24"></clr-icon>
                      <span class="tooltip-content">
                        {{functionInfo.ReplaceValue.arguments.replacement.fields.type.description}}<br>
                      Values Accepted: {{functionInfo.ReplaceValue.arguments.replacement.fields.type.valuesAccepted}}
                        ________________________<br><br>
                      </span>
                    </a>
                  </span>
                  </label>
                  <input class="clr-col-xs-12 transform-input" type="text" clrInput placeholder="Enter value" id="target_value_string" formControlName="targetValue"/>
                  <clr-control-error class="input-error-msg">Please Enter target value.</clr-control-error>
                </clr-input-container>

                <clr-input-container class="form-control" *ngIf="dataTransformFunctionForm.get('targetDataType').value == 'number'">
                  <label class="clr-control-label clr-col-xs-12" for="target_value_number">
                    6) Replacement Target Value:
                    <span>
                    <a role="tooltip" aria-haspopup="true" class="tooltip tooltip-md tooltip-bottom-right">
                      <clr-icon shape="info-circle" size="24"></clr-icon>
                      <span class="tooltip-content">
                        {{functionInfo.ReplaceValue.arguments.replacement.fields.type.description}}<br>
                      Values Accepted: {{functionInfo.ReplaceValue.arguments.replacement.fields.type.valuesAccepted}}
                        ________________________<br><br>
                      </span>
                    </a>
                  </span>
                  </label>
                  <input class="clr-col-xs-12 transform-input" type="number" clrInput placeholder="Enter value" id="target_value_number" formControlName="targetValue"/>
                  <clr-control-error class="input-error-msg">Please Enter target value.</clr-control-error>
                </clr-input-container>
              </div>
            </div>
          </div>

          <ng-container *ngIf="dataTransformFunctionForm.get('targetType').value == 'key'">
            <div formArrayName="conditionTargetFormArray" *ngFor="let conditionTarget of dataTransformFunctionForm.get('conditionTargetFormArray').controls; let i=index; trackBy:track">
              <div [formGroup]="conditionTarget" class="condition-section">
                <clr-input-container class="form-control select-top-margin">
                  <label class="clr-control-label clr-col-xs-12" for="condition_target_key">
                    4.{{i+1}}a) Condition Target Key:
                    <span>
                        <a role="tooltip" aria-haspopup="true" class="tooltip tooltip-md tooltip-top-right">
                          <clr-icon shape="info-circle" size="24"></clr-icon>
                          <span class="tooltip-content">
                            {{functionInfo.ReplaceValue.arguments.conditions.fields.key.description}}<br>
                            Values Accepted: {{functionInfo.ReplaceValue.arguments.conditions.fields.key.valuesAccepted}}
                            ________________________<br><br>
                          </span>
                        </a>
                      </span>
                  </label>
                  <input class="clr-col-xs-12 transform-input" type="text" clrInput placeholder="Enter value" id="condition_target_key" formControlName="conditionTargetKey" />
                  <clr-control-error class="input-error-msg">Please Enter target Key.</clr-control-error>
                </clr-input-container>

                <div *ngIf="conditionTarget.get('conditionTargetKey').value">
                  <clr-select-container class="form-control clr-select-wrapper_height site-mapping-input">
                    <label class="clr-control-label clr-col-xs-12" for="condition_data_type">
                      4.{{i+1}}b) Condition Target Data Type:
                      <span>
                        <a role="tooltip" aria-haspopup="true" class="tooltip tooltip-md tooltip-top-right">
                          <clr-icon shape="info-circle" size="24"></clr-icon>
                          <span class="tooltip-content">
                            {{functionInfo.ReplaceValue.arguments.conditions.fields.dataType.description}}
                            ________________________<br><br>
                          </span>
                        </a>
                      </span>
                    </label>
                    <select class="transform-input clr-col-xs-12" clrSelect id="condition_data_type" name="condition_data_type" formControlName="conditionTargetDataType" (change)="onConditionDataTypeChange($event.target.value, i)">
                      <option selected disabled value="null">Select data type</option>
                      <option value="string">String</option>
                      <option value="number">Number</option>
                      <option value="any">Any</option>
                    </select>
                    <clr-control-error class="input-error-msg">Please Select Data Type.</clr-control-error>
                  </clr-select-container>
                </div>

                <div *ngIf="conditionTarget.get('conditionTargetDataType').value">
                  <clr-select-container class="form-control clr-select-wrapper_height site-mapping-input">
                    <label class="clr-control-label clr-col-xs-12" for="condition_operator">
                      4.{{i+1}}c) Comparison Operator:
                      <span>
                      <a role="tooltip" aria-haspopup="true" class="tooltip tooltip-md tooltip-top-right">
                        <clr-icon shape="info-circle" size="24"></clr-icon>
                        <span class="tooltip-content">
                          {{functionInfo.ReplaceValue.arguments.conditions.fields.operator.description}}
                          ________________________<br><br>
                        </span>
                      </a>
                    </span>
                    </label>
                    <select class="transform-input clr-col-xs-12" clrSelect id="condition_operator" name="condition_operator" formControlName="conditionTargetOperator">
                      <option selected disabled value="null">Select comparator</option>
                      <option *ngFor="let operator of comparisonOperators[i] | keyvalue" value="{{operator.key}}">{{operator.value}}</option>
                    </select>
                    <clr-control-error class="input-error-msg">Please Select Operator.</clr-control-error>
                  </clr-select-container>
                </div>

                <div *ngIf="conditionTarget.get('conditionTargetOperator').value && !['EX', 'NEX'].includes(conditionTarget.get('conditionTargetOperator').value)">
                  <clr-input-container class="form-control site-mapping-input" *ngIf="conditionTarget.get('conditionTargetDataType').value == 'string'">
                    <label class="clr-control-label clr-col-xs-12" for="condition_value_string">
                      4.{{i+1}}d) Condition Value:
                      <span>
                      <a role="tooltip" aria-haspopup="true" class="tooltip tooltip-md tooltip-top-right">
                        <clr-icon shape="info-circle" size="24"></clr-icon>
                        <span class="tooltip-content">
                          {{functionInfo.ReplaceValue.arguments.conditions.fields.value.description}}<br>
                          Values Accepted: {{functionInfo.ReplaceValue.arguments.conditions.fields.value.valuesAccepted}}
                          ________________________<br><br>
                        </span>
                      </a>
                    </span>
                    </label>
                    <input class="clr-col-xs-12 transform-input" type="text" clrInput placeholder="Enter text value" id="condition_value_string" formControlName="conditionTargetValue"/>
                    <clr-control-error class="input-error-msg">Please Enter Condition Value.</clr-control-error>
                  </clr-input-container>

                  <clr-input-container class="form-control site-mapping-input" *ngIf="conditionTarget.get('conditionTargetDataType').value == 'number'">
                    <label class="clr-control-label clr-col-xs-12" for="condition_value_number">
                      4.{{i+1}}d) Condition Value:
                      <span>
                      <a role="tooltip" aria-haspopup="true" class="tooltip tooltip-md tooltip-top-right">
                        <clr-icon shape="info-circle" size="24"></clr-icon>
                        <span class="tooltip-content">
                          {{functionInfo.ReplaceValue.arguments.conditions.fields.value.description}}<br>
                          ValuesAccepted: {{functionInfo.ReplaceValue.arguments.conditions.fields.value.valuesAccepted}}
                          ________________________<br><br>
                        </span>
                      </a>
                    </span>
                    </label>
                    <input class="clr-col-xs-12 transform-input" type="number" clrInput placeholder="Enter number value" id="condition_value_number" formControlName="conditionTargetValue"/>
                    <clr-control-error class="input-error-msg">Please Enter Condition Value.</clr-control-error>
                  </clr-input-container>
                </div>
              </div>
            </div>
          </ng-container>

          <div *ngIf="enableAddCondition() && dataTransformFunctionForm.get('targetType').value != 'regex' && dataTransformFunctionForm.get('targetType').value != 'currency'" class="clr-col-xs-12 clr-col-sm-12 document-type-data-header-item">
            <button (click)="onAddConditions()" class="add-index-label btn btn-link btn-no-caps add-condition"><clr-icon shape="plus-circle"></clr-icon>&nbsp;Add condition (implicit AND)</button>
          </div>

          <div *ngIf="enableReplacementConditions()">
            <div class="transform-section function-section">
              <clr-select-container *ngIf="dataTransformFunctionForm.get('targetType').value != 'regex' && dataTransformFunctionForm.get('targetType').value != 'currency'" class="form-control clr-select-wrapper_height site-mapping-input">
                <label class="clr-control-label clr-col-xs-12" for="replacement_type">
                  <span *ngIf="['EX', 'NEX'].includes(dataTransformFunctionForm.get('targetOperator').value) && dataTransformFunctionForm.get('targetType').value ==='value'">6)</span>
                  <span *ngIf="(!['EX', 'NEX'].includes(dataTransformFunctionForm.get('targetOperator').value) && dataTransformFunctionForm.get('targetType').value ==='value')">7)</span>
                  <span *ngIf="dataTransformFunctionForm.get('targetType').value ==='key' ">5)</span>
                  Replacement Type:
                  <span>
                        <a role="tooltip" aria-haspopup="true" class="tooltip tooltip-md tooltip-top-right">
                          <clr-icon shape="info-circle" size="24"></clr-icon>
                          <span class="tooltip-content">
                            {{functionInfo.ReplaceValue.arguments.replacement.fields.type.description}}
                            ________________________<br><br>
                          </span>
                        </a>
                      </span>
                </label>
                <select class="transform-input clr-col-xs-12" clrSelect id="replacement_type" name="replacement_type" formControlName="replacementType">
                  <option selected disabled value="null">Select Replacement type</option>
                  <option value="key">Key</option>
                  <option value="value">Value</option>
                </select>
                <clr-control-error class="input-error-msg">Please Select Replacement Type.</clr-control-error>
              </clr-select-container>
              <div *ngIf="dataTransformFunctionForm.get('targetType').value == 'regex'" >
                <clr-input-container class="form-control select-top-margin">
                  <label class="clr-control-label clr-col-xs-12" for="replacement_show_value">
                    <span *ngIf="dataTransformFunctionForm.get('targetType').value == 'regex'">4) </span>
                    <span *ngIf="dataTransformFunctionForm.get('targetType').value == 'regex'">
                      Regular Expression:
                    </span>
                    <span>
                      <a role="tooltip" aria-haspopup="true" class="tooltip tooltip-md tooltip-top-right">
                        <clr-icon shape="info-circle" size="24"></clr-icon>
                        <span class="tooltip-content">
                          Regular expression<br>
                          ________________________<br><br>
                        </span>
                      </a>
                    </span>
                  </label>
                  <input class="clr-col-xs-12 transform-input" type="text" clrInput placeholder="Enter value" id="replacement_show_regex" formControlName="replacementRegex"/>
                </clr-input-container>
                <p class="regex-error" *ngIf="dataTransformFunctionForm.get('replacementRegex').touched && dataTransformFunctionForm.get('replacementRegex').errors && dataTransformFunctionForm.get('replacementRegex').value">Invalid regular expression (make sure to escape backslash characters, e.g. \\).</p>
              </div>

              <div *ngIf="dataTransformFunctionForm.get('targetType').value == 'currency'" class="hide-currency-check">
                <label class="clr-control-label clr-col-lg-12" for="hide-currency-symbol">4) Do <span class="bigger-txt">NOT</span> display currency symbol</label>
                <input type="checkbox" id="hide-currency-symbol" name="hide-currency-symbol" class="clr-checkbox" formControlName="hideCurrencySymbol">
              </div>

              <div *ngIf="dataTransformFunctionForm.get('replacementType').value
              && dataTransformFunctionForm.get('replacementType').value == 'value'
              && dataTransformFunctionForm.get('targetType').value != 'regex'
              && dataTransformFunctionForm.get('targetType').value != 'currency'" >
                <clr-input-container class="form-control select-top-margin">
                  <label class="clr-control-label clr-col-xs-12" for="replacement_show_value">
                    <span *ngIf="['EX', 'NEX'].includes(dataTransformFunctionForm.get('targetOperator').value) && dataTransformFunctionForm.get('targetType').value ==='value'">7) </span>
                    <span *ngIf="(!['EX', 'NEX'].includes(dataTransformFunctionForm.get('targetOperator').value) && dataTransformFunctionForm.get('targetType').value ==='value')">8) </span>
                    <span *ngIf="dataTransformFunctionForm.get('targetType').value ==='key'">6)</span>
                    Replacement Value:
                    <span>
                      <a role="tooltip" aria-haspopup="true" class="tooltip tooltip-md tooltip-top-right">
                        <clr-icon shape="info-circle" size="24"></clr-icon>
                        <span class="tooltip-content">
                          {{functionInfo.ReplaceValue.arguments.replacement.fields.value.description}}<br>
                            Values Accepted: {{functionInfo.ReplaceValue.arguments.replacement.fields.value.valuesAccepted}}
                          ________________________<br><br>
                        </span>
                      </a>
                    </span>
                  </label>
                  <input class="clr-col-xs-12 transform-input" type="text" clrInput placeholder="Enter value" id="replacement_show_value" formControlName="replacementValue"/>
                  <clr-control-error class="input-error-msg">Please Enter Replacement Value.</clr-control-error>
                </clr-input-container>
              </div>
              <div *ngIf="dataTransformFunctionForm.get('replacementType').value
              && dataTransformFunctionForm.get('replacementType').value=='key'
              && dataTransformFunctionForm.get('targetType').value != 'regex'
              && dataTransformFunctionForm.get('targetType').value != 'currency'">
                <clr-input-container class="form-control select-top-margin">
                  <label class="clr-control-label clr-col-xs-12" for="replacement_show_key">
                    <span *ngIf="['EX', 'NEX'].includes(dataTransformFunctionForm.get('targetOperator').value) && dataTransformFunctionForm.get('targetType').value ==='value'">7)</span>
                    <span *ngIf="(!['EX', 'NEX'].includes(dataTransformFunctionForm.get('targetOperator').value) && dataTransformFunctionForm.get('targetType').value ==='value')">8)</span>
                    <span *ngIf="dataTransformFunctionForm.get('targetType').value ==='key'">6)</span>
                    Replacement Key:
                    <span>
                      <a role="tooltip" aria-haspopup="true" class="tooltip tooltip-md tooltip-top-right">
                        <clr-icon shape="info-circle" size="24"></clr-icon>
                        <span class="tooltip-content">
                          {{functionInfo.ReplaceValue.arguments.replacement.fields.key.description}}<br>
                            Values Accepted: {{functionInfo.ReplaceValue.arguments.replacement.fields.key.valuesAccepted}}
                          ________________________<br><br>
                        </span>
                      </a>
                    </span>
                  </label>
                  <input class="clr-col-xs-12 transform-input" type="text" clrInput placeholder="Enter Key" id="replacement_show_key" formControlName="replacementKey"/>
                  <clr-control-error class="input-error-msg">Please Enter Replacement Key.</clr-control-error>
                </clr-input-container>
              </div>
            </div>
          </div>
        </div>

        <div class="function-simulation-group">
          <div class="simulate-btn">
            <button type="submit" class="btn btn-outline"
                    [disabled]="!(dataTransformFunctionForm.get('replacementValue').value
                    || dataTransformFunctionForm.get('replacementKey').value
                    || (dataTransformFunctionForm.get('replacementRegex').value && dataTransformFunctionForm.get('replacementRegex').valid)
                    || dataTransformFunctionForm.get('targetType').value === 'currency')"
                    (click)="simulateFunction()">
              <clr-icon shape="bolt"></clr-icon>
               Simulate
            </button>
            <span>
              <a role="tooltip" aria-haspopup="true" class="tooltip tooltip-md tooltip-bottom-right">
                <clr-icon shape="info-circle" size="24"></clr-icon>
                <span class="tooltip-content">
                  Run a simulation of your data transform function.
                  ________________________<br><br>

                  Sample JSON data will be used to showcase the output of your custom data transform function.<br><br>
                  Two of the sample JSON objects ('object1' and 'object2') display both, the data eligible for transformation
                  (meets specified criteria),
                  as well as the unchanged data (not meeting the transformation condition)
                  ________________________<br><br>
                </span>
              </a>
            </span>
          </div>

          <div class="json-sample-group"
               *ngIf="dataTransformFunctionForm.get('replacementValue').value
               || dataTransformFunctionForm.get('replacementKey').value
               || dataTransformFunctionForm.get('replacementRegex').value
               || dataTransformFunctionForm.get('targetType').value == 'currency'">
            <div class="json-sample">
              <div class="clr-form-control">
                <label class="clr-control-label">JSON Input</label>
                <div class="clr-control-container description-text-area">
                  <div class="clr-textarea-wrapper">
                    <textarea readonly class="clr-textarea json-sample-textarea">{{(jsonInputSample | json) != '{}' ? (jsonInputSample | json) : ""}}</textarea>
                  </div>
                </div>
              </div>
            </div>

            <clr-icon class="json-sample-arrow" size="23" shape="arrow"></clr-icon>

            <div class="json-sample">
              <div class="clr-form-control">
                <label class="clr-control-label">JSON Output</label>
                <div class="clr-control-container description-text-area">
                  <div class="clr-textarea-wrapper">
                    <textarea readonly class="clr-textarea json-sample-textarea">{{(jsonOutputSample | json) != '{}' ? (jsonOutputSample | json) : ""}}</textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="closeModal()">Cancel</button>
    <button id="new-document-type-submit-trigger" type="submit" [disabled]="isFormInvalid()" (click)="onAddTransformFunction()" class="btn btn-primary">
      <span *ngIf="!functionData">Add</span>
      <span *ngIf="functionData">Save</span>
    </button>
  </div>
</clr-modal>
