import { Component, OnInit, Input } from '@angular/core';
import { IimUser } from 'ui-sdk/models/iim-user.model';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ClientService } from 'ui-sdk';
import { UserMethods } from 'ui-sdk';
import {IimClient} from 'ui-sdk/models/iim-client.model';
import {AuthClass} from 'ui-sdk';
import {AlertService} from '../../../services/alert.service';
import {RefreshTokenRequestModel} from 'ui-sdk/models/refresh-token-request.model';

@Component({
  selector: 'app-client-selection-modal',
  templateUrl: './client-selection-modal.component.html',
  styleUrls: ['./client-selection-modal.component.scss']
})
export class ClientSelectionModalComponent implements OnInit {
  loading: boolean = false;
  currentUser: IimUser;
  @Input() modalEnabled: boolean;
  selectedClient = null;
  clients: Array<IimClient>;

  subscriptions: Subscription = new Subscription();
  constructor(private router: Router,
              private clientService: ClientService,
              private alertService: AlertService) { }

  ngOnInit(): void {
    this.subscriptions.add(UserMethods.currentUser$.subscribe(user => {
      this.currentUser = Object.assign({}, user);
      this.clients = this.currentUser.clients?.filter(c => c.permissions?.length > 0);
    }));

  }

  onSubmit() {
    this.loading = true;
    const selectedClient = this.clients.filter(c => c.clientId === this.selectedClient);

    if (this.clients && this.clients.length > 1) {
      const refreshRequest: RefreshTokenRequestModel = {
        userName: localStorage.getItem('currentUser'),
        refreshToken: localStorage.getItem('IIM-refresh-token'),
        currentClientId: selectedClient[0].clientId
      };

      AuthClass.refreshToken(refreshRequest).toPromise().then(refreshTokenResponse => {
        this.loading = false;

        localStorage.setItem(AuthClass.IIM_AUTH_TOKEN, refreshTokenResponse.access_token);
        localStorage.setItem(AuthClass.IIM_REFRESH_TOKEN, refreshTokenResponse.refresh_token);

        this.clientService.switchClient(selectedClient[0], true);
        this.router.navigate(['/']);
      }, error => {
        this.loading = false;
        // tslint:disable-next-line:max-line-length
        const [title, msg] = ['Error', `Error occurred while attempting to get access token for client [ ${selectedClient[0].name} ]`];
        this.alertService.showError(title, msg);
        this.router.navigate(['/login']);
      });
    } else {
      this.clientService.switchClient(selectedClient[0]);
      this.router.navigate(['/']);
    }
  }
}
