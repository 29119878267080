import {Component, EventEmitter, Input, OnInit, OnChanges, Output} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {AlertService} from '../../../services/alert.service';
import {FunctionInfo} from 'ui-sdk/models/data-mapping.model';
import RandExp from 'randexp';

@Component({
  selector: 'app-data-transform-modal',
  templateUrl: './data-transform-modal.component.html',
  styleUrls: ['./data-transform-modal.component.scss']
})
export class DataTransformModalComponent implements OnInit, OnChanges {
  dataTransformFunctionForm: FormGroup;

  @Input() modalEnabled: boolean;
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() dataTransformFunctionsChanged: EventEmitter<any> = new EventEmitter();
  @Input() functionData: any;
  @Input() dataTransformFunctions: Map<string, any> = new Map<string, any>();
  @Input() functionInfo: FunctionInfo;

  conditionTargetFormArray: Array<any>;
  comparisonOperators: Array<Map<string, string>> = [];

  jsonInputSample = {};
  jsonOutputSample = {};

  isDuplicateFunctionName = false;
  inputOrder: number = 5;

  TARGET_INDEX: string = 'targetIndex';
  SAMPLE_VALUE: string = 'sample value';
  SAMPLE_REPLACEMENT_VALUE = 'replacement value';

  constructor(private alertService: AlertService) { }

  ngOnInit(): void {
    this.dataTransformFunctionForm = this.createFormGroup();
  }

  ngOnChanges(): void {
    this.dataTransformFunctionForm = this.createFormGroup();
  }

  private createFormGroup(): FormGroup {
    this.conditionTargetFormArray = [];
    if (this.functionData?.args.conditions) {
        for (const condition in this.functionData?.args.conditions) {
          this.onAddConditions(this.functionData?.args.conditions[condition]);
        }
    } else {
      this.onAddConditions();
    }
    const formGroup = new FormGroup({
      // tslint:disable-next-line:max-line-length
      functionType: new FormControl({value: this.functionData ? this.functionData.functionType : '', disabled: false}, [Validators.required]),
      // tslint:disable-next-line:max-line-length
      functionName: new FormControl({value: this.functionData ? this.functionData.functionName : '', disabled: false}, [Validators.required]),

      // tslint:disable-next-line:max-line-length
      targetType: new FormControl({value: this.functionData ? this.functionData.args.target.type.toLowerCase() : '', disabled: false}, [Validators.required]),

      // tslint:disable-next-line:max-line-length
      targetDataType: new FormControl({value: this.functionData?.args.target.dataType ? this.functionData.args.target.dataType.toLowerCase() : '', disabled: false}, [Validators.required]),
      // tslint:disable-next-line:max-line-length
      targetOperator: new FormControl({value: this.functionData?.args.target ? this.functionData.args.target.operator : '', disabled: false}, [Validators.required]),
      // tslint:disable-next-line:max-line-length
      targetValue: new FormControl({value: this.functionData?.args.target.value ? this.functionData.args.target.value.toLowerCase() : '', disabled: false}, [Validators.required]),

      conditionTargetFormArray: new FormArray(this.conditionTargetFormArray),

      // tslint:disable-next-line:max-line-length
      replacementType: new FormControl({value: this.functionData?.args.replacement.type ? this.functionData.args.replacement.type.toLowerCase() : '', disabled: false}, [Validators.required]),
      // tslint:disable-next-line:max-line-length
      replacementKey: new FormControl({value: this.functionData?.args.replacement ? this.functionData.args.replacement.key : '', disabled: false}, [Validators.required]),
      // tslint:disable-next-line:max-line-length
      replacementValue: new FormControl({value: this.functionData?.args.replacement ? this.functionData.args.replacement.value : '', disabled: false}, [Validators.required]),
      // tslint:disable-next-line:max-line-length
      replacementRegex: new FormControl({value: this.functionData?.args.replacement ? this.functionData.args.replacement.value : '', disabled: false}, [Validators.required, this.validateRegularExpression]),

      hideCurrencySymbol: new FormControl({value: this.functionData?.args?.replacement?.value, disabled: false})
    });

    if (formGroup.controls.targetDataType.value) {
      this.comparisonOperators.push(this.onDataTypeChange(formGroup.controls.targetDataType.value));
    }

    this.conditionTargetFormArray.forEach(conditionTarget => {
      if (conditionTarget.controls.conditionTargetOperator.value) {
        this.comparisonOperators.push(this.onDataTypeChange(conditionTarget.controls.conditionTargetDataType.value));
      }
    });

    return formGroup;
  }

  validateRegularExpression(regex: FormControl) {
    try {
      // tslint:disable-next-line:no-unused-expression
      new RegExp(regex.value);
      return null;

    } catch (e) {
      return {
        valid: false
      };
    }
  }

  sanitizeRegularExpression(rawRegex: string) {
    if (rawRegex?.length > 1 && rawRegex.startsWith('/') && rawRegex.endsWith('/')) {
      rawRegex = rawRegex.substring(1, rawRegex.length - 1);
    }

    return rawRegex;
  }

  closeModal() {
    this.close.emit();
    this.dataTransformFunctionsChanged.emit(this.dataTransformFunctions);
    this.dataTransformFunctionForm.reset();
    this.jsonInputSample = {};
    this.jsonOutputSample = {};
    this.isDuplicateFunctionName = false;
  }

  isFormInvalid() {
    return !(
      this.dataTransformFunctionForm.controls.functionName.value &&
      (
        (
          // tslint:disable-next-line:max-line-length
          ['EX', 'NEX'].includes(this.dataTransformFunctionForm.controls.targetOperator.value || this.dataTransformFunctionForm.controls.targetOperator.value === 0)
          // tslint:disable-next-line:max-line-length
          || (['EX', 'NEX'].includes(this.conditionTargetFormArray[this.conditionTargetFormArray.length - 1].controls.conditionTargetOperator.value))
          || this.dataTransformFunctionForm.controls.targetValue.value
          || this.dataTransformFunctionForm.controls.targetType.value === 'regex'
          || this.dataTransformFunctionForm.controls.targetType.value === 'currency'
        )
        || (
          this.conditionTargetFormArray[this.conditionTargetFormArray.length - 1].controls.conditionTargetKey.value
          && (this.conditionTargetFormArray[this.conditionTargetFormArray.length - 1].controls.conditionTargetValue.value
          || this.conditionTargetFormArray[this.conditionTargetFormArray.length - 1].controls.conditionTargetValue.value === 0)
        )
      )
      && (
        this.dataTransformFunctionForm.controls.replacementKey.value
        || this.dataTransformFunctionForm.controls.replacementValue.value
        || (
          this.dataTransformFunctionForm.controls.replacementRegex.value &&
          this.dataTransformFunctionForm.controls.replacementRegex.valid
        )
        || this.dataTransformFunctionForm.controls.targetType.value === 'currency'
      )
    );
  }

  onAddTransformFunction() {
    const requestPayload: any = {
      functionType: this.dataTransformFunctionForm.get('functionType').value,
      args: {
        target: {
          type: this.dataTransformFunctionForm.get('targetType').value
        },
        replacement: {
          type: this.dataTransformFunctionForm.get('replacementType').value
        }
      }
    };

    if (requestPayload.args.target.type === 'value') {
      requestPayload.args.target = {
        ...requestPayload.args.target,
        dataType: this.dataTransformFunctionForm.get('targetDataType').value,
        operator: this.dataTransformFunctionForm.get('targetOperator').value,
      };
      if (!['EX', 'NEX'].includes(requestPayload.args.target.operator)) {
        requestPayload.args.target = {
          ...requestPayload.args.target,
          value: this.dataTransformFunctionForm.get('targetValue').value
        };
      }
    }
    if (requestPayload.args.replacement.type === 'value')  {
      requestPayload.args.replacement = {
        ...requestPayload.args.replacement,
        value: this.dataTransformFunctionForm.get('replacementValue').value
      };
    } else if (requestPayload.args.replacement.type === 'key') {
      requestPayload.args.replacement = {
        ...requestPayload.args.replacement,
        key: this.dataTransformFunctionForm.get('replacementKey').value
      };
    } else if (requestPayload.args.replacement.type === 'regex') {
      requestPayload.args.replacement = {
        ...requestPayload.args.replacement,
        value: this.sanitizeRegularExpression(this.dataTransformFunctionForm.get('replacementRegex').value)
      };
    } else if (requestPayload.args.replacement.type === 'currency') {
      requestPayload.args.replacement = {
        ...requestPayload.args.replacement,
        value: this.dataTransformFunctionForm.get('hideCurrencySymbol').value ? 'nosymbol' : ''
      };
    }

    this.conditionTargetFormArray.forEach((conditionFormGroup, index) => {
      if (requestPayload.args.target.type === 'key') {
        requestPayload.args = {
          ...requestPayload.args,
          conditions: {
            ...requestPayload.args.conditions,
            [`condition${index + 1}`]: {
              key: conditionFormGroup.get('conditionTargetKey').value,
              dataType: conditionFormGroup.get('conditionTargetDataType').value,
              operator: conditionFormGroup.get('conditionTargetOperator').value,
            }
          }
        };
        if (!['EX', 'NEX'].includes(requestPayload.args.conditions[`condition${index + 1}`].operator)) {
          requestPayload.args.conditions[`condition${index + 1}`] = {
            ...requestPayload.args.conditions[`condition${index + 1}`],
            value: conditionFormGroup.get('conditionTargetValue').value
          };
        }
      }
    });

    const functionName = this.dataTransformFunctionForm.get('functionName').value;
    if (this.functionData?.functionName) {
      if (functionName !== this.functionData.functionName && this.dataTransformFunctions.has(functionName)) {
        this.alertService.showError('Error', 'Other function with same name exists');
      } else {
          this.dataTransformFunctions.delete(this.functionData.functionName);
          this.dataTransformFunctions.set(functionName, requestPayload);
          this.closeModal();
      }
    } else {
      if (this.dataTransformFunctions.has(functionName)) {
        this.alertService.showError('Error', 'Duplicate function name');
      } else {
        this.dataTransformFunctions.set(functionName, requestPayload);
        this.closeModal();
      }
    }
  }

  simulateFunction() {
    const affectedObject = 'object1';
    const unaffectedObject = 'object2';

    this.jsonInputSample[affectedObject] = {};
    this.jsonInputSample[unaffectedObject] = {};
    this.jsonOutputSample[affectedObject] = {};
    this.jsonOutputSample[unaffectedObject] = {};

    // unaffected object sample
    this.jsonInputSample[unaffectedObject][this.TARGET_INDEX] = this.SAMPLE_VALUE;
    this.jsonOutputSample[unaffectedObject][this.TARGET_INDEX] = this.SAMPLE_VALUE;

    const targetType = this.dataTransformFunctionForm.get('targetType').value;

    // this is for KEY Target type
    if (targetType === 'key') {
      this.conditionTargetFormArray.forEach(conditionTarget => {
        const conditionTargetKey = conditionTarget.controls.conditionTargetKey.value;
        const conditionTargetDataType = conditionTarget.controls.conditionTargetDataType.value;
        const conditionTargetOperator = conditionTarget.controls.conditionTargetOperator.value;
        const conditionTargetValue = conditionTarget.controls.conditionTargetValue.value;

        this.replaceValues(affectedObject, unaffectedObject);
        // tslint:disable-next-line:max-line-length
        this.updateInputJson(affectedObject, unaffectedObject, conditionTargetKey, conditionTargetDataType, conditionTargetOperator, conditionTargetValue);
        this.updateInputJsonExists(affectedObject, unaffectedObject, conditionTargetOperator, conditionTargetKey);
      });

    // this is for VALUE Target type
    } else if (targetType === 'value') {
      const targetDataType = this.dataTransformFunctionForm.get('targetDataType').value;
      const targetValue = this.dataTransformFunctionForm.get('targetValue').value;
      const targetOperator = this.dataTransformFunctionForm.get('targetOperator').value;

      this.replaceValues(affectedObject, unaffectedObject);
      this.updateInputJson(affectedObject, unaffectedObject, this.TARGET_INDEX, targetDataType, targetOperator, targetValue);
      this.updateInputJsonExists(affectedObject, unaffectedObject, targetOperator, this.TARGET_INDEX);

    } else if (targetType === 'regex') {
      const regularExpression = this.sanitizeRegularExpression(this.dataTransformFunctionForm.get('replacementRegex').value);
      const randExp = new RandExp(regularExpression);
      randExp.max = 10;

      let targetValue = randExp.gen();
      this.replaceValues(affectedObject, unaffectedObject, targetValue);

      targetValue = 'prefix_' + targetValue + '_suffix';
      this.updateInputJson(affectedObject, unaffectedObject, this.TARGET_INDEX, 'string', 'EQ', targetValue);

    } else if (targetType === 'currency') {
      this.replaceValues(affectedObject, unaffectedObject);

      if (this.dataTransformFunctionForm.get('hideCurrencySymbol').value) {
        this.jsonOutputSample[affectedObject][this.TARGET_INDEX] = '1,000,000.00';
      }
    }
  }

  private updateInputJsonExists(affectedObject: string, unaffectedObject: string, operator: string, indexKey: string) {
    if (operator === 'EX') {
      this.jsonInputSample[affectedObject][indexKey] = this.SAMPLE_VALUE;

      delete this.jsonInputSample[unaffectedObject][indexKey];
      delete this.jsonOutputSample[unaffectedObject][indexKey];

    } else if (operator === 'NEX') {
      delete this.jsonInputSample[affectedObject][indexKey];

      this.jsonInputSample[unaffectedObject][indexKey] = this.SAMPLE_VALUE;
      this.jsonOutputSample[unaffectedObject][indexKey] = this.SAMPLE_VALUE;
    }
  }

  // tslint:disable-next-line:max-line-length
  private updateInputJson(affectedObject: string, unaffectedObject: string, indexKey: string, dataType: string, operator: string, value: string) {
    if (dataType === 'string') {
      if (['EQ', 'CNT', 'END', 'BGN'].includes(operator)) {
        this.jsonInputSample[affectedObject][indexKey] = value;
      }

    } else if (dataType === 'number') {
      const targetNumber = parseInt(value, 10);

      if (operator === 'EQ') {
        this.jsonInputSample[affectedObject][indexKey] = targetNumber;
      } else if (operator === 'GT') {
        this.jsonInputSample[affectedObject][indexKey] = targetNumber + 1;

      } else if (operator === 'GTE') {
        this.jsonInputSample[affectedObject][indexKey] = targetNumber;

      } else if (operator === 'LT') {
        this.jsonInputSample[affectedObject][indexKey] = targetNumber - 1;

      } else if (operator === 'LTE') {
        this.jsonInputSample[affectedObject][indexKey] = targetNumber;
      }

      if (['EQ', 'LT', 'LTE'].includes(operator)) {
        this.jsonInputSample[unaffectedObject][indexKey] = targetNumber + 1;
        this.jsonOutputSample[unaffectedObject][indexKey] = targetNumber + 1;

      } else if (['GT', 'GTE'].includes(operator)) {
        this.jsonInputSample[unaffectedObject][indexKey] = targetNumber - 1;
        this.jsonOutputSample[unaffectedObject][indexKey] = targetNumber - 1;
      }
    }
  }

  private replaceValues(affectedObject: string, unaffectedObject: string, sampleRegexValue?: string) {
    const replacementType = this.dataTransformFunctionForm.get('replacementType').value;

    if (replacementType === 'value') {
      const replacementValue = this.dataTransformFunctionForm.get('replacementValue').value;
      this.jsonOutputSample[affectedObject][this.TARGET_INDEX] = replacementValue ? replacementValue : '';

    } else if (replacementType === 'key') {
      const replacementKey = this.dataTransformFunctionForm.get('replacementKey').value;

      this.jsonInputSample[affectedObject][replacementKey] = this.SAMPLE_REPLACEMENT_VALUE;
      this.jsonInputSample[unaffectedObject][replacementKey] = this.SAMPLE_REPLACEMENT_VALUE;

      this.jsonOutputSample[affectedObject][this.TARGET_INDEX] = this.SAMPLE_REPLACEMENT_VALUE;
      this.jsonOutputSample[affectedObject][replacementKey] = this.SAMPLE_REPLACEMENT_VALUE;
      this.jsonOutputSample[unaffectedObject][replacementKey] = this.SAMPLE_REPLACEMENT_VALUE;

    } else if (replacementType === 'regex') {
      this.jsonOutputSample[affectedObject][this.TARGET_INDEX] = sampleRegexValue;

    } else if (replacementType === 'currency') {
      this.jsonInputSample[affectedObject][this.TARGET_INDEX] = '1000000';
      this.jsonOutputSample[affectedObject][this.TARGET_INDEX] = '$1,000,000.00';
    }
  }

  onConditionDataTypeChange(dataType: string, operatorIndex: number) {
    this.comparisonOperators[operatorIndex] = this.onDataTypeChange(dataType);
  }

  onDataTypeChange(dataType: string) {
    const operators = new Map<string, string>();
    if (dataType === 'number') {
      operators.set('EQ', 'Equals');
      operators.set('GT', 'Greater than');
      operators.set('GTE', 'Greater than or Equal to');
      operators.set('LT', 'Less than');
      operators.set('LTE', 'Less than or Equal to');
    } else if (dataType === 'string') {
      operators.set('EQ', 'Equals');
      operators.set('BGN', 'Begins with');
      operators.set('END', 'Ends with');
      operators.set('CNT', 'Contains');
    }
    operators.set('EX', 'Exists');
    operators.set('NEX', 'Not Exists');
    return operators;
  }

  onTargetTypeChange(targetType: string) {
    if (targetType === 'value') {
      this.dataTransformFunctionForm.get('conditionTargetFormArray').reset();
      this.dataTransformFunctionForm.get('replacementType').reset();

    } else if (targetType === 'key') {
      this.dataTransformFunctionForm.get('targetDataType').reset();
      this.dataTransformFunctionForm.get('targetOperator').reset();
      this.dataTransformFunctionForm.get('targetValue').reset();
      this.dataTransformFunctionForm.get('replacementType').reset();

    } else if (targetType === 'regex') {
      this.dataTransformFunctionForm.get('replacementType').setValue('regex');

    } else if (targetType === 'currency') {
      this.dataTransformFunctionForm.get('replacementType').setValue('currency');
    }

    this.dataTransformFunctionForm.get('replacementValue').reset();
    this.dataTransformFunctionForm.get('replacementRegex').reset();
    this.dataTransformFunctionForm.get('hideCurrencySymbol').reset();
    this.dataTransformFunctionForm.get('replacementKey').reset();
  }

  checkFunctionNameUniqueness(functionName: string): void {
    const funcNameSterilized = functionName?.trim();
    if (funcNameSterilized.length) {
      this.isDuplicateFunctionName = this.dataTransformFunctions.has(funcNameSterilized.trim());
    }
  }

  onAddConditions(condition ?: any) {
    const conditionTargetFormGroup = new FormGroup({
      conditionTargetKey: new FormControl({
        value: condition?.key ? condition.key.toLowerCase() : '',
        disabled: false
      }, [Validators.required]),
      conditionTargetDataType: new FormControl({
        value: condition?.dataType ? condition.dataType.toLowerCase() : '',
        disabled: false
      }, [Validators.required]),
      conditionTargetOperator: new FormControl({
        value: condition ? condition.operator : '',
        disabled: false
      }, [Validators.required]),
      conditionTargetValue: new FormControl({
        value: condition ? condition.value : '',
        disabled: false
      }, [Validators.required]),
    });
    this.conditionTargetFormArray.push(conditionTargetFormGroup);
  }

  enableAddCondition() {
    // tslint:disable-next-line:max-line-length
    const { conditionTargetOperator, conditionTargetValue } = this.conditionTargetFormArray[this.conditionTargetFormArray.length - 1].controls;
    return ['EX', 'NEX'].includes(conditionTargetOperator.value) || conditionTargetValue.value || conditionTargetValue.value === 0;
  }

  enableReplacementConditions() {
    const targetType = this.dataTransformFunctionForm.get('targetType').value;
    if (['regex', 'currency'].includes(targetType)) {
      return true;

    } else {
      const targetValue = this.dataTransformFunctionForm.get('targetValue').value;
      // tslint:disable-next-line:max-line-length
      const conditionTargetValue = this.conditionTargetFormArray[this.conditionTargetFormArray.length - 1].controls.conditionTargetValue.value;
      return ((targetValue === 0 || targetValue) && targetValue !== '') ||
        ((conditionTargetValue === 0 || conditionTargetValue) && conditionTargetValue !== '') ||
        // tslint:disable-next-line:max-line-length
        ['EX', 'NEX'].includes(this.conditionTargetFormArray[this.conditionTargetFormArray.length - 1].controls.conditionTargetOperator.value) ||
        ['EX', 'NEX'].includes(this.dataTransformFunctionForm.get('targetOperator').value);
    }
  }
}
