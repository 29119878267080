import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IimUser } from 'ui-sdk/models/iim-user.model';
import { AlertService } from '../../services/alert.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../services/user.service';
import { Subscription } from 'rxjs';
import { ClientService } from 'src/app/services/client.service';
import {UserMethods} from 'ui-sdk';

@Component({
  selector: 'app-table-bulk-actions',
  templateUrl: './table-bulk-actions.component.html',
  styleUrls: ['./table-bulk-actions.component.scss']
})
export class TableBulkActionsComponent implements OnInit, OnDestroy {
  @Input() selectedItems: Array<any> = [];

  bulkActionsInProgress = {
    deletion: false,
    legalHold: false,
    accessRequest: false,
    portability: false
  };

  currentUser: IimUser;
  subscriptions: Subscription = new Subscription();

  activeLegalHoldsInfo: any;

  constructor(private toastr: ToastrService,
              private alertService: AlertService,
              private userService: UserService,
              private clientService: ClientService) {
  }

  _legalHoldMessage: string = 'Flag for legal Hold';
  legalHoldModal_enabled = false;

  get legalHoldMessage() {
    return this._legalHoldMessage;
  }

  set legalHoldMessage(legalHoldMessage) {
    this._legalHoldMessage = legalHoldMessage;
  }

  ngOnInit() {
    const userSubscription = UserMethods.currentUser$.subscribe(
      (user: IimUser) => this.currentUser = user
    );
    this.subscriptions.add(userSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  flagForDeletion() {
    const title = 'Feature Coming Soon';
    const msg = `Please be patient, we'll get there in no time!`;
    this.alertService.showSuccess(title, msg);
  }

  advancedLegalHoldAction() {
    this.bulkActionsInProgress.legalHold = true;

    const getLegalHoldPayload = {
      documentIds: this.selectedItems.map(doc => doc.id)
    };

    this.clientService.getLegalHoldInfo(getLegalHoldPayload).subscribe(legalHoldsResponse  => {
      this.activeLegalHoldsInfo = legalHoldsResponse;
      this.legalHoldModal_enabled = true;
      this.bulkActionsInProgress.legalHold = false;
    }, error => {
      if (error.status === 403) {
        this.alertService.showError('Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [GET LegalHoldInfo].`);
      } else {
        this.alertService.showError('Error', `Unable to fetch Legal Hold Information for selected '${this.selectedItems.length}' document(s).`);
      }
    });
  }

  onLegalHoldCreated(documents: Array<any>) {
    // this.legalHoldModal_enabled = false;
    // Loop through the selected documents and update their legal hold status
    documents.forEach (doc => {
      doc.legalHold = true;  // Update the legal Hold Icon
    });
  }

  legalHoldEnabled(): boolean {
    this.legalHoldMessage = 'Manage legal holds for selected documents';
    if (this.selectedItems.length === 0) {
      this.legalHoldMessage = 'You must select documents to flag them for legal hold';
      return false;
    }
    if (!this.currentUser.selectedClient.uiperms.task.bulkActions.legalHold.create) {
      this.legalHoldMessage = 'No permission to select documents to flag for legal hold';
      return false;
    }

    const holdDocs = [];
    const noHoldDocs = [];
    let allActive = true;
    this.selectedItems.forEach(doc => {
      if (doc.status && doc.status !== 'Active') {
        allActive = false;
      }

      if (!!doc.legalHold && doc.legalHold) {
        holdDocs.push(doc);
      } else {
        noHoldDocs.push(doc);
      }
    });
    if (!allActive) {
      this.legalHoldMessage = 'Only Active documents may have their Legal Hold Status updated';
      return false;
    }

    return true;
  }

  flagForAccessRequest() {
    const title = 'Feature Coming Soon';
    const msg = `Please be patient, we'll get there in no time!`;
    this.alertService.showSuccess(title, msg);
  }

  packageForPortability() {

    const title = 'Feature Coming Soon';
    const msg = `Please be patient, we'll get there in no time!`;
    this.alertService.showSuccess(title, msg);
  }
}
