<clr-modal id="data-mapping-modal" [clrModalOpen]="modalEnabled===true" (clrModalOpenChange)="closeModal('clrModalOpenChange')"
           [clrModalStaticBackdrop]="true" [clrModalSize]="'xl'">
  <div class="modal-title">
    <div class="rectangle r1"></div>
    <div class="rectangle r2"></div>
    <h3 class="modal-title-text text1">
      <ng-container *ngIf="!selectedMappingGroup">New Data Mapping Group</ng-container>
      <ng-container *ngIf="selectedMappingGroup">
        Edit Data Mapping Group [{{selectedMappingGroup?.name}}]
      </ng-container>
    </h3>
  </div>
  <div class="modal-body modal-lg">
    <form class="document-type-form" [formGroup]="groupMappingForm">
      <clr-tabs>
        <clr-tab>
          <button clrTabLink id="link1">General Info</button>
          <clr-tab-content id="doc-type-general-tab" *clrIfActive="true">
            <clr-input-container class="form-control">
              <label class="clr-col-xs-12" for="data-mapping-modal__document-type-name">Group Name:</label>
              <input class="data-input clr-col-xs-12" type="text" clrInput placeholder="Enter Group Name" name="data-mapping-modal__document-type-name"
                     id="data-mapping-modal__document-type-name" formControlName="groupName"/>
              <clr-control-error>Please provide a valid name.</clr-control-error>
            </clr-input-container>

          </clr-tab-content>
        </clr-tab>
        <clr-tab>
          <button clrTabLink>Data Mapping</button>
          <clr-tab-content id="doc-type-indexes-tab" *clrIfActive>

            <div class="clr-col-xs-12 clr-col-sm-12 document-type-data-header-item">
              <button (click)="onAddMapping($event)" class="add-index-label btn btn-link btn-no-caps"><clr-icon shape="plus-circle"></clr-icon>&nbsp;Add Data Transform Function</button>
            </div>
            <clr-datagrid class="transform-functions-datagrid">
              <clr-dg-column>
                Function Name
              </clr-dg-column>
              <clr-dg-column>
                Function Type
              </clr-dg-column>
              <clr-dg-column class="table-actions">
                Actions
              </clr-dg-column>

              <clr-dg-row *clrDgItems="let func of dataTransformFunctions | keyvalue" [clrDgItem]="func">

                <clr-dg-cell class="datagrid-align-cells">
                  {{func.key}}
                </clr-dg-cell>

                <clr-dg-cell class="datagrid-align-cells">
                  {{func.value.functionType}}
                </clr-dg-cell>

                <clr-dg-cell class="table-actions-cell" >
                  <div class="btn-group btn-link btn-icon">
                    <button [title]="'Edit Data Mapping Function'" *ngIf="currentUser?.selectedClient?.uiperms?.datamapping?.update" class="btn btn-no-caps" (click)="editFunction(func)">
                      <clr-icon shape="pencil"></clr-icon>
                    </button>
                    <button [title]="'Delete Data Mapping Function'" *ngIf="currentUser?.selectedClient?.uiperms?.datamapping?.delete" class="btn btn-no-caps" (click)="removeFunction(func)">
                      <clr-icon shape="trash"></clr-icon>
                    </button>
                  </div>
                </clr-dg-cell>
              </clr-dg-row>
            </clr-datagrid>
          </clr-tab-content>
        </clr-tab>
      </clr-tabs>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="closeModal('cancel')">Cancel</button>
    <button id="new-document-type-submit-trigger" type="submit" (click)="saveMappingGroup()"
            [disabled]="saveButtonEnabled()" class="btn btn-primary" [clrLoading]="processingModalRequest">
      <ng-container *ngIf="isNewDataMapping()">Add</ng-container>
      <ng-container *ngIf="!isNewDataMapping()">Save</ng-container>
    </button>
  </div>
</clr-modal>

<app-data-transform-modal [modalEnabled]="isDataMappingFunctionDesignerEnabled" (dataTransformFunctionsChanged)="functionsChangedEventHandler($event)" (close)="isDataMappingFunctionDesignerEnabled = false" [functionData]="functionData" [dataTransformFunctions]="dataTransformFunctions" [functionInfo]="functionInfo"></app-data-transform-modal>
