export class HelperMethods {
  static deepEqual(object1, object2) {
    if (object1 === undefined && object2 === undefined) {
      return true;
    }

    if (object1 === undefined || object2 === undefined) {
      return false;
    }

    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = HelperMethods.isObject(val1) && HelperMethods.isObject(val2);
      if (areObjects && !HelperMethods.deepEqual(val1, val2) || !areObjects && val1 !== val2) {
        return false;
      }
    }

    return true;
  }

  static isObject(obj) {
    return obj != null && typeof obj === 'object';
  }
}
