export const OperatorMap = {
    String: [
      {value: 'eq', label: 'Equal', multi: false, relative: false},
      {value: 'neq', label: 'Not Equal', multi: false, relative: false},
      {value: 'like*', label: 'Begins with', multi: false, relative: false},
      {value: '*like*', label: 'Contains', multi: false, relative: false},
      {value: '*like', label: 'Ends with', multi: false, relative: false},
    ],
    Number: [
      {value: 'eq', label: 'Equal', multi: false, relative: false},
      {value: 'neq', label: 'Not Equal', multi: false, relative: false},
      {value: 'lt', label: 'Less Than', multi: false, relative: false},
      {value: 'lte', label: 'Less Than or Equal', multi: false, relative: false},
      {value: 'gt', label: 'Greater Than', multi: false, relative: false},
      {value: 'gte', label: 'Greater Than or Equal', multi: false, relative: false},
      {value: 'between', label: 'Between', multi: true, relative: false},
      {value: 'notbetween', label: 'Not Between', multi: true, relative: false},
    ],
    Date: [
      {value: 'eq', label: 'Equal', multi: false, relative: false},
      {value: 'neq', label: 'Not Equal', multi: false, relative: false},
      {value: 'lt', label: 'Less Than', multi: false, relative: false},
      {value: 'lte', label: 'Less Than or Equal', multi: false, relative: false},
      {value: 'gt', label: 'Greater Than', multi: false, relative: false},
      {value: 'gte', label: 'Greater Than or Equal', multi: false, relative: false},
      {value: 'between', label: 'Between', multi: true, relative: false},
      {value: 'notbetween', label: 'Not Between', multi: true, relative: false}
    ],
    DateTime: [
      {value: 'eq', label: 'Equal', multi: false, relative: false},
      {value: 'neq', label: 'Not Equal', multi: false, relative: false},
      {value: 'lt', label: 'Less Than', multi: false, relative: false},
      {value: 'lte', label: 'Less Than or Equal', multi: false, relative: false},
      {value: 'gt', label: 'Greater Than', multi: false, relative: false},
      {value: 'gte', label: 'Greater Than or Equal', multi: false, relative: false},
      {value: 'between', label: 'Between', multi: true, relative: false},
      {value: 'notbetween', label: 'Not Between', multi: true, relative: false}
    ],
    Integer: [
      {value: 'eq', label: 'Equal', multi: false, relative: false},
      {value: 'neq', label: 'Not Equal', multi: false, relative: false},
      {value: 'lt', label: 'Less Than', multi: false, relative: false},
      {value: 'lte', label: 'Less Than or Equal', multi: false, relative: false},
      {value: 'gt', label: 'Greater Than', multi: false, relative: false},
      {value: 'gte', label: 'Greater Than or Equal', multi: false, relative: false},
      {value: 'between', label: 'Between', multi: true, relative: false},
      {value: 'notbetween', label: 'Not Between', multi: true, relative: false},
    ],
    Decimal: [
      {value: 'eq', label: 'Equal', multi: false, relative: false},
      {value: 'neq', label: 'Not Equal', multi: false, relative: false},
      {value: 'lt', label: 'Less Than', multi: false, relative: false},
      {value: 'lte', label: 'Less Than or Equal', multi: false, relative: false},
      {value: 'gt', label: 'Greater Than', multi: false, relative: false},
      {value: 'gte', label: 'Greater Than or Equal', multi: false, relative: false},
      {value: 'between', label: 'Between', multi: true, relative: false},
      {value: 'notbetween', label: 'Not Between', multi: true, relative: false},
    ],
    Time: [
      {value: 'eq', label: 'Equal', multi: false, relative: false},
      {value: 'neq', label: 'Not Equal', multi: false, relative: false},
      {value: 'lt', label: 'Less Than', multi: false, relative: false},
      {value: 'lte', label: 'Less Than or Equal', multi: false, relative: false},
      {value: 'gt', label: 'Greater Than', multi: false, relative: false},
      {value: 'gte', label: 'Greater Than or Equal', multi: false, relative: false},
      {value: 'between', label: 'Between', multi: true, relative: false},
      {value: 'notbetween', label: 'Not Between', multi: true, relative: false}
    ]
  };
