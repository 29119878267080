import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { IimUser } from 'ui-sdk/models/iim-user.model';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ClrForm} from '@clr/angular';
import {AlertService} from '../../../services/alert.service';
import {UserMethods} from 'ui-sdk';
import {IimClient} from 'ui-sdk/models/iim-client.model';
import {IdmRole} from 'ui-sdk/models/idm-role.model';
import {IdmRoleMethods} from 'ui-sdk';

@Component({
  selector: 'app-site-mapping-modal',
  templateUrl: './site-mapping-modal.component.html',
  styleUrls: ['./site-mapping-modal.component.scss']
})
export class SiteMappingModalComponent implements OnInit {
  copyIdToClipboard: string;
  timer;

  _selectedSiteMapping: IdmRole = null;

  _clients: Array<IimClient> = [];
  loading: boolean = false;

  currentUser: IimUser;
  subscriptions: Subscription = new Subscription();


  @Input('clients')
  set clients(value) {
    if (value) {
      this._clients = value;
    }
  }

  @Input('selectedSiteMapping')
  set selectedSiteMapping(value) {
    if (value) {
      this._selectedSiteMapping = value;
    }
  }

  @Input() modalEnabled: boolean;
  @Output() created: EventEmitter<IdmRole> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  siteMappingForm: FormGroup;

  processingModalRequest: boolean = false;

  @ViewChild(ClrForm) clrForm;

  constructor(private alertService: AlertService) {}

  ngOnInit() {
    const userSubscription = UserMethods.currentUser$.subscribe((user: IimUser) => this.currentUser = user);
    this.subscriptions.add(userSubscription);
    this.siteMappingForm = this.createGroupMappingFormGroup();
  }

  isNewDataMapping() {
    return !!!this._selectedSiteMapping || (!!this._selectedSiteMapping && !!!this._selectedSiteMapping.companyId);
  }

  private createGroupMappingFormGroup() {
    return new FormGroup({
      companyId: new FormControl({
        value: this._selectedSiteMapping?.companyId ? this._selectedSiteMapping.companyId : null, disabled: false
      }, [Validators.required]),
      clientId: new FormControl({
        value: this._selectedSiteMapping?.clientId ? this._selectedSiteMapping.clientId : null, disabled: false
      }, [Validators.required]),
      externalClientId: new FormControl({
        value: this._selectedSiteMapping?.externalClientId ? this._selectedSiteMapping.externalClientId : null, disabled: false
      })
    });
  }

  onAddMapping(e) {
    e.preventDefault();
  }

  onSubmit() {
    if (this.siteMappingForm.valid) {
      this.saveMappingGroup();
    } else {
      this.clrForm.markAsDirty();
    }
  }

  saveButtonEnabled() {
    return this.siteMappingForm.dirty && this.siteMappingForm.valid;
  }

  saveMappingGroup() {
    this.processingModalRequest = true;
    const siteMappingPayload = Object.assign({}, this.siteMappingForm.getRawValue()); // If nested form groups, do a deep copy here

    if (this.isNewDataMapping()) {
      IdmRoleMethods.createIdmRole(siteMappingPayload).then(resp => {
        this.processingModalRequest = false;
        this.created.emit(this._selectedSiteMapping);
        this.alertService.showSuccessToast(`Site Mapping '${this.siteMappingForm.get('companyId').value}' Created`, undefined);
        this.closeModal();

      }, error => {
        this.processingModalRequest = false;
        if (error.status === 403) {
          this.alertService.showError('Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [CREATE IdMRole].`);
        } else {
          this.alertService.showError('Error', `Unable to Update data mapping group. Please try again.  ${error.error.message}`);
        }
      });
    } else {
      IdmRoleMethods.updateIdmRoleByCompanyId(siteMappingPayload).then(resp => {
        this.processingModalRequest = false;
        this.created.emit(this._selectedSiteMapping);
        this.alertService.showSuccessToast(`Site Mapping '${this.siteMappingForm.get('companyId').value}' Updated`, undefined);
        this.closeModal();

      }, error => {
        this.processingModalRequest = false;
        if (error.status === 403) {
          this.alertService.showError('Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [UPDATE IdMRole].`);
        } else {
          this.alertService.showError('Error', `Unable to Update data mapping group. Please try again.  ${error.error.message}`);
        }
      });
    }
  }

  closeModal() {
    this._selectedSiteMapping = null;
    this.siteMappingForm.reset();
    this.close.emit();
  }

  notify(event: any) {
    this.copyIdToClipboard = event;

    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      this.copyIdToClipboard = '';
    }, 3000);
  }
}
