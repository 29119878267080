<div [@fadeInLeft] class="content-area">
  <div class="clr-row">
    <div class="clr-col-xs-12 clr-col-md-6">
      <h3 class="dashboard__title">Welcome <ng-container *ngIf="currentUser?.firstName"> {{currentUser.firstName}}</ng-container>!</h3>
    </div>
    <div *ngIf="lastLoginTimestamp" class="clr-col-xs-12 clr-col-md-6 last-login-timestamp">
      <div class="last-login p3 text-md-right">
        &nbsp;Last Login: {{lastLoginTimestamp | date}}
      </div>
    </div>
  </div>
  <div class="dashboard__body">
    <div class="clr-row">
      <div class="clr-col-12 clr-col-sm-12 clr-col-md-6">
      </div>
    </div>
    <div class="clr-row">
      <div class="clr-col-xs-12 clr-col-sm-12">
        <app-news-list></app-news-list>
      </div>
    </div>
  </div>
</div>
