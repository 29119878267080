import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../../services/alert.service';
import { IimUser } from 'ui-sdk/models/iim-user.model';
import { IimDocumentType } from 'ui-sdk/models/iim-document-type.model';
import { Subscription } from 'rxjs';
import { fadeInLeftAnimation } from '../../animations';
import { IimClient } from 'ui-sdk/models/iim-client.model';
import { FormControl } from '@angular/forms';
import { ActiveStatusComparator } from './comparators/ActiveStatusComparator';
import { ClrDatagridFilter, ClrDatagridSortOrder } from '@clr/angular';
import {ArchiveProviderMethods} from 'ui-sdk';
import {ClientService} from 'ui-sdk';
import {UserMethods} from 'ui-sdk';
import {ClientMethods} from 'ui-sdk';
import {DocumentTypeMethods} from 'ui-sdk';
import {DataMappingMethods} from 'ui-sdk';
import {AuthClass, HttpMethodsEnum, environment} from 'ui-sdk';

@Component({
  selector: 'app-document-type-admin-page',
  templateUrl: './document-type-admin-page.component.html',
  styleUrls: ['./document-type-admin-page.component.scss'],
  animations: [fadeInLeftAnimation]
})

export class DocumentTypeAdminPageComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  currentUser: IimUser;
  currentClient: IimClient;

  documentTypes: {original: Array<any>, initial: Array<any>, current: Array<any>, loading: boolean, } = {
    original: [],
    initial: [],
    current: [],
    loading: false
  };

  public activeStatusComparator = new ActiveStatusComparator();

  selectedDocumentType: any = null;
  @ViewChild('leftFilter') leftFilter: ClrDatagridFilter;
  modalEnabled: boolean = false;

  archiveProviders: Array<string>;
  dataMappingGroups: Array<any>;

  tableSort: {'createDate': ClrDatagridSortOrder};

  tableControls: { propertiesFilter: FormControl, pageSize: FormControl, textFilter: FormControl } = {
    propertiesFilter: new FormControl([]),
    pageSize: new FormControl(10),
    textFilter: new FormControl('')
  };

  sourceRow: any;
  disableUpdateOrder: boolean = true;
  showInactiveDocs: boolean = false;

  copyIdToClipboard: string;
  timer;

  constructor(private alertService: AlertService,
              private clientService: ClientService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {

    this.subscriptions.add(
      activatedRoute.queryParams.subscribe(
        params => {
          if (params['clientId']) {
            this.clientService.switchClientById(params['clientId']);
          }
        }
      )
    );
  }

  ngOnInit() {
    const userSubscription = UserMethods.currentUser$.subscribe((user: IimUser) => this.onUserLoaded(user));
    this.subscriptions.add(userSubscription);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onUserLoaded(user: IimUser) {
    this.currentUser = user;
    if (this.currentUser) {
      if (this.currentUser.selectedClient.uiperms.doctype.read) {
        this.loadDocumentTypes();
      }
      if (this.currentUser.selectedClient.uiperms.client.read) {
        this.loadSelectedClient();
      }
    }
  }

  private loadSelectedClient() {
    ClientMethods.getClientById(this.currentUser.selectedClient.clientId).then(clientResponse => {
      this.currentClient = clientResponse;
    }, error => {
      if (error.status === 403) {
        this.alertService.showError('Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [GET ClientById].`);
      } 
    });
  }

  getDocumentTypes(getInactive?: boolean): Promise<Array<IimDocumentType>> {
    const urlPath = `${environment.baseUrl}/client/b5896799-9a49-343a-9052-b8294b94c665/documentType${getInactive ? '?getInactive=true' : ''}`;

    return AuthClass.doAuthenticatedCall<Array<IimDocumentType>>(HttpMethodsEnum.GET, urlPath).toPromise();
  }

  private loadDocumentTypes() {
    this.documentTypes.loading = true;

    DocumentTypeMethods.getDocumentTypes(this.showInactiveDocs).then(docTypesResponse => {
      this.documentTypes.loading = false;
      this.documentTypes.original = JSON.parse(JSON.stringify(docTypesResponse));
      this.documentTypes.initial = docTypesResponse;
      this.documentTypes.current = this.documentTypes.initial;
    }, docTypeError => {
      this.documentTypes.loading = false;

      if (docTypeError.status === 403) {
        this.alertService.showError('Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [GET DocumentTypes].`);
      } 
    });
  }

  addNewDocumentType(): void {
    this.selectedDocumentType = {
      id: null,
      name: null,
      description: null,
      archiveProvider: null,
      status: null,
      isDeletable: null,
      searchIndices: []
    };

    this.getArchiveProviders();
    this.getDataMappingGroups();

    this.modalEnabled = true;
  }

  editDocumentType(documentType: IimDocumentType) {
    this.selectedDocumentType = documentType;
    this.getArchiveProviders();
    this.getDataMappingGroups();

    this.modalEnabled = true;
  }

  getArchiveProviders() {
    ArchiveProviderMethods.getArchiveProviders().then(providersResponse => {
      this.archiveProviders = providersResponse;
    }, error => {
      if (error.status === 403) {
        this.alertService.showError('Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [GET ArchiveProviders].`);
      } else {
        this.alertService.showError('Error', 'Unable to get archive providers');
      }
    });
  }

  getDataMappingGroups() {
    const dataMappingSubscription = DataMappingMethods.getMappingGroup().subscribe(res => {
      this.dataMappingGroups = res;
    }, error => {
      if (error.status === 403) {
        this.alertService.showError('Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [GET DataMapping].`);
      } else {
        this.alertService.showError('Error', 'Unable to get data mapping groups');
      }
    });

    this.subscriptions.add(dataMappingSubscription);
  }

  deleteDocumentType(documentType: IimDocumentType): void {
    const [title, msg] = [`Delete Document Type`, `Are you sure you want to delete document type '${documentType.name}'?`];
    this.alertService.confirmDangerousAction(title, msg).then(confirmed => {
      if (confirmed) {
        DocumentTypeMethods.deleteDocumentType(documentType).then(success => {
          const toBeDeleted = this.documentTypes.initial.indexOf(documentType);
          this.documentTypes.original.splice(toBeDeleted, 1);
          this.documentTypes.initial.splice(toBeDeleted, 1);
          this.documentTypes.current = this.documentTypes.initial;
          this.alertService.showSuccessToast(`Document Type '${documentType.name}' Deleted`, undefined);
        }, error => {  // Error Deleting DocumentType
          let errorTitle = 'Error';
          let errorMsg = `Unable to delete Document Type '${documentType.name}'. Please try again.`;
          if (error.error && error.error.message && error.error.message === 'Document Type is associated with one or more Document Groups and must be removed first') {
            errorTitle = 'Associated with Collection(s)';
            errorMsg = `Document Type '${documentType.name}' is associated with one or more Collections and must be removed from them before it can be deleted.`;
          } else  if (error.status === 403) {
            this.alertService.showError('Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [DELETE DocumentType].`);
          }
          this.alertService.showError(errorTitle, errorMsg);
        });
      }
    }, error => {
    });
  }

  onDocumentTypeCreated(documentType: IimDocumentType) {
    this.documentTypes.original.push(documentType);
    this.documentTypes.initial.push(documentType);
    this.documentTypes.current = this.documentTypes.initial;
    this.selectedDocumentType = null;
  }

  prepUpdateDocumentTypeStatus(documentType: IimDocumentType, isActive: boolean) {
    let status: string = 'Active';

    if (!isActive) {
      status = 'Inactive';
    }
    documentType.status = status;

    DocumentTypeMethods.updateDocumentTypeStatus(documentType, status).then(docTypeId => {
      // tslint:disable-next-line:max-line-length
      this.alertService.showSuccessToast('Success', `${documentType.name} has been ${status === 'Inactive' ? 'inactivated' : 'activated'}.`);
    }, error => {
      if (error.status === 403) {
        const [title, msg] = ['Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [UPDATE DocumentTypeStatus].`];
        this.alertService.showError(title, msg);
      } else {
        const [title, msg] = ['Error', 'Unable to update status. Please try again.'];
        this.alertService.showError(title, msg);
      }
    });
  }

  closeSearchIndexModal() {
    this.modalEnabled = false;
    this.selectedDocumentType = null;
  }

  onDocumentTypeUpdated(documentType: IimDocumentType) {
    const toBeUpdated = this.documentTypes.initial.indexOf(this.selectedDocumentType);
    this.documentTypes.original[toBeUpdated] = documentType;
    this.documentTypes.initial[toBeUpdated] = documentType;
    this.documentTypes.current = this.documentTypes.initial;
    this.selectedDocumentType = null;
  }

  startRowDrag(e: Event) {
    this.sourceRow = e.target;
  }

  private getCurrentRow(target: EventTarget | Element) {
    const parent = target['parentNode'];
    return parent.classList.contains('datagrid-row') ? parent : this.getCurrentRow(parent);
  }

  stopRowDrag(e: Event) {
    e.preventDefault();
    this.updateData(this.sourceRow.innerText.split('\n')[0], this.getCurrentRow(e.target).innerText.split('\n')[0]);
    this.disableUpdateOrder = this.arrayCompare();
  }

  private arrayCompare() {
    return this.documentTypes.original.every((item, index) => {
      return item.id === this.documentTypes.initial[index].id;
    });
  }

  private updateData(sourceDocName: string, destinationDocName: string) {
    const sourceIndex: number = this.documentTypes.initial.findIndex(record => record.name === sourceDocName);
    const destinationIndex: number = this.documentTypes.initial.findIndex(record => record.name === destinationDocName);

    const source = this.documentTypes.initial[sourceIndex];
    this.documentTypes.initial.splice(sourceIndex, 1);
    this.documentTypes.initial.splice(destinationIndex, 0, source);

    this.documentTypes.current = this.documentTypes.initial;
  }

  updateDocumentTypeOrder() {
    const payload = {
      sequence: {}
    };
    this.documentTypes.initial.forEach((record, index) => {
      payload.sequence[record.id] = index;
    });

    this.documentTypes.loading = true;

    DocumentTypeMethods.updateDocumentTableLayout(payload).toPromise().then(() => {
      this.documentTypes.loading = false;
      this.disableUpdateOrder = true;
      this.documentTypes.original = JSON.parse(JSON.stringify(this.documentTypes.initial));
      this.alertService.showSuccessToast('Success', `Successfully Sequence Updated`);
    }, docTypeError => {
      this.documentTypes.loading = false;

      if (docTypeError.status === 403) {
        this.alertService.showError('Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [UPDATE DocumentTableLayout].`);
      } else {
        this.alertService.showError('Error', 'Unable to Update the Sequence of DocumentType. Please try again.');
      }
    });
  }

  toggleVisibilityInactiveDocs() {
    this.showInactiveDocs = !this.showInactiveDocs;
    this.loadDocumentTypes();
  }

  notify(event: any) {
    this.copyIdToClipboard = event;

    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      this.copyIdToClipboard = '';
    }, 3000);
  }
}
