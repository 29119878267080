<clr-modal id="files-modal"  [clrModalOpen]="true" (clrModalOpenChange)="closeModal()"
           [clrModalStaticBackdrop]="false" [clrModalSize]="'md'">
  <div class="modal-title">
    <div class="rectangle r1"></div>
    <div class="rectangle r2"></div>
    <h3 class="modal-title-text">
      <ng-container>Files</ng-container>
    </h3>
  </div>
  <div class="modal-body">
      <ul class="fileList">
        <li *ngFor="let fileName of documentData.fileNames">
          {{fileName}}
          <span class="actionBtnContainer">
            <div class="btn-group btn-sm btn-link btn-icon">
              <button [title]="'View Document'" class="btn btn-no-caps"
                      (click)="viewDocument(fileName)"
                      [clrLoading]="fileName == actionsInProgress.view">

              <clr-icon size="21" shape="eye"></clr-icon>
            </button>

            <button [title]="'Download Document'" class="btn btn-no-caps"
                    (click)="downloadDocument(fileName)"
                    [clrLoading]="fileName == actionsInProgress.download">

                    <clr-icon size="21" shape="download"></clr-icon>
            </button>
            </div>
          </span>
        </li>
      </ul>
  </div>
</clr-modal>
