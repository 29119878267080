<div class="card">
  <div class="card-block p-0">
    <div class="card-title br-dark">
      <span class="card-title__text">News & Quick Tips</span>
    </div>
  </div>
  <ul class="list-group list-group-flush">
    <li *ngIf="newsItems?.length === 0" class="list-group-item empty-message">
      <div>
        No News Items
      </div>
    </li>
    <li class="list-group-item news-item" [@fadeOutLeft] *ngFor="let news of newsItems">
      <button (click)="dismissNewsItem(news)" class="btn btn-icon btn-dismiss"><clr-icon size="14" shape="close"></clr-icon></button>
      <div class="item-preview">
        <div class="item-preview__date">{{ news.date | date:'MMM d, y, h:mm a'}}</div>
        <span class="item-preview__subject">{{news.subject}}</span>
        <span class="item-preview__description">{{news.message}}</span>
      </div>
    </li>
  </ul>
</div>
