import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Subscription} from 'rxjs';
import {IimUser} from 'ui-sdk/models/iim-user.model';
import {ClrDatagridSortOrder} from '@clr/angular';
import {fadeInLeftAnimation} from 'src/app/animations';
import {AlertService} from 'src/app/services/alert.service';
import {ClientService} from 'src/app/services/client.service';
import {Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {filter} from 'rxjs/operators';
import {ObjectKeysTextFilter} from 'src/app/components/table/filters/objectKeysText.filter';
import {ArchiveProviderMethods} from 'ui-sdk';
import {UserMethods} from 'ui-sdk';
import {ClientMethods} from 'ui-sdk';
import {IdmRoleMethods} from 'ui-sdk';
import {IdmPermission} from 'ui-sdk/models/idm-permission.model';

@Component({
  selector: 'app-client-admin-page',
  templateUrl: './client-admin-page.component.html',
  styleUrls: ['./client-admin-page.component.scss'],
  animations: [fadeInLeftAnimation]
})

export class ClientAdminPageComponent implements OnInit, OnDestroy {

  constructor(private alertService: AlertService,
    private clientService: ClientService,
    private router: Router) {

    const state = this.router.getCurrentNavigation()?.extras?.state;
    if (state) {
      if (state.clientId) {
        this.editClientRedirectId = state.clientId;
      }
      if (state.roleName) {
        this.editRoleRedirectName = state.roleName;
      }
    }
  }
  @Output() initialization: EventEmitter<any> = new EventEmitter();

  copyIdToClipboard: string;
  timer;

  clientOnboardModal_enabled: boolean = false;
  permissions: Map<string, IdmPermission>;
  permsLoading: boolean = false;
  editClientLoadingId: string;
  clientIdmRole: any;

  archiveProviders: Array<string>;
  tableSort: {'createDate': ClrDatagridSortOrder};
  currentClient: any = null;

  providerFilter = new ObjectKeysTextFilter();

  tableControls: { propertiesFilter: FormControl, pageSize: FormControl, textFilter: FormControl } = {
    propertiesFilter: new FormControl([]),
    pageSize: new FormControl(10),
    textFilter: new FormControl('')
  };

  subscriptions: Subscription = new Subscription();
  currentUser: IimUser;

  editClientRedirectId: string;
  editRoleRedirectName: string;

  clients: {initial: Array<any>, current: Array<any>, loading: boolean, status: string} = {
    initial: [],
    current: [],
    loading: false,
    status: 'Active'
  };


  ngOnInit() {
    const userSubscription = UserMethods.currentUser$.subscribe((user: IimUser) => {
      this.onUserLoaded(user);
    });
    this.subscriptions.add(userSubscription);
    this.getPermissions();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onUserLoaded(user: IimUser) {
    this.currentUser = user;
    if (this.currentUser) {
      if (this.currentUser.selectedClient.uiperms.client.read) {
        this.loadClients();
      }
    }
  }

  loadClients() {
    this.clients.loading = true;

    const clientObservable$ = ClientMethods.getClients();
    clientObservable$.pipe(
      filter(clients => true)
    )
    .subscribe(clients => {
      this.clients.loading = false;
      this.clients.initial = clients;

      this.clients.initial.map(client => {  // Force Status to Active
        client.status = 'Active';
      });

      if (this.currentUser.selectedClient.uiperms.client.update) {
        this.clients.current = this.clients.initial;
      } else {
        this.clients.current = this.clients.initial.filter(activeClients => {
          return activeClients.status === 'Active';
        });
      }
      this.initialization.emit();
      this.initialization.complete();

      if (this.editClientRedirectId) {
        const clientEdit = this.clients.current.filter(u => u.id === this.editClientRedirectId);
        if (clientEdit.length > 0) {
          this.editClient(clientEdit[0]);
        }
      }
    }, error => {
      this.clients.loading = false;
      if (error.status === 403) { 
          const [title, msg] = ['Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [GET Clients].`];
          this.alertService.showError(title, msg);
      } 
      this.initialization.complete();
    });
  }

  removeClient(client) {
    const [title, msg] = [`Delete Client`, `Are you sure you want to delete Client '${client.name}'?`];
    this.alertService.confirmDangerousAction(title, msg).then(confirmed => {
      if (confirmed) {
        ClientMethods.deleteClient(client.id).subscribe({
          next: () => this.deleteHandler(client),
          error: (res) => this.handleError(res)
        });
      }
    });
  }

  deleteHandler(client) {
    this.alertService.showSuccessToast(`Client '${client.name}' Deleted`, undefined);
    this.onClientsChanged();
  }

  handleError(error: HttpErrorResponse) {
    this.clients.loading = false;
    if (error.status === 403) {
        const [title, msg] = ['Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [DELETE Client].`];
        this.alertService.showError(title, msg);
    } 
  }

  addNewClient() {
    this.currentClient = null;
    this.clientIdmRole = null;
    this.getArchiveProviders();
    this.clientOnboardModal_enabled = true;
  }

  getArchiveProviders() {
    ArchiveProviderMethods.getArchiveProviders().then(providersResponse => {
      this.archiveProviders = providersResponse;
    }, error => {
      if (error.status === 403) {
        this.alertService.showError('Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [GET ArchiveProviders].`);
      } else {
        this.alertService.showError('Error', 'Unable to get archive providers');
      }
    });
  }

  editClient(client) {
    this.editClientLoadingId = client.id;
    this.currentClient = client;
    this.clientOnboardModal_enabled = true;

    IdmRoleMethods.getIdmRoleByClientId(client.id).then(roleResponse => {
      this.clientIdmRole = roleResponse;
      this.editClientLoadingId = '';
    }, error => {
      this.editClientLoadingId = '';

      if (error.status === 403) {
        const [title, msg] = ['Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [GET IdmRoleByClientId].`];
        this.alertService.showError(title, msg);
      } else {
        this.alertService.showError('Error', `Unable to get IdM Role for client [ ${client.name} ]`);
      }
    });
  }

  getPermissions() {
    this.permsLoading = true;
    IdmRoleMethods.getPermissions().then(permsResponse => {
      this.permissions = permsResponse;
      this.permsLoading = false;
    }, error => {
      this.permsLoading = false;
      if (error.status === 403) {
          this.alertService.showError('Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [GET Permissions].`);
      } 
    });
  }

  onClientsChanged() {
    UserMethods.getUserProfile(false).then();
  }

  archiveProviderList(providers: Array<string>): string {
    return Object.keys(providers).sort().join();
  }

  closeModal() {
    this.clientOnboardModal_enabled = false;
    this.editClientRedirectId = '';
    this.currentClient = null;
    this.clientIdmRole = null;
  }

  notify(event: any) {
    this.copyIdToClipboard = event;

    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      this.copyIdToClipboard = '';
    }, 3000);
  }
}

