<clr-modal id="create-legal-hold-modal"  [clrModalOpen]="true" (clrModalOpenChange)="closeModal()"
           [clrModalStaticBackdrop]="false" [clrModalSize]="'md'">
  <div class="modal-title">
    <h3 class="modal-title-text">
      <ng-container>Place Holds</ng-container>
    </h3>
  </div>
  <div class="modal-body">
    <form class="create-legal-hold-form"
          id="create-legal-hold-form"
          clrForm
          [formGroup]="createLegalHoldsForm"
          clrLayout="vertical"
          (ngSubmit)="onSubmitLegalHolds()">
      <clr-input-container class="form-control">
        <input class="clr-col-xs-12 clr-col-6" type="text" clrInput placeholder="Name of Hold" name="legal-hold-modal__name"
               id="legal-hold-modal__name" formControlName="holdName"/>
        <clr-control-error>Please provide a valid name.</clr-control-error>
      </clr-input-container>
      <clr-input-container class="form-control">
        <input class="clr-col-12" type="text" clrInput placeholder="Reason for Hold" name="legal-hold-modal__reason"
               id="legal-hold-modal__reason" formControlName="reasonForHold"/>
        <clr-control-error>Please provide a valid reason.</clr-control-error>
      </clr-input-container>
      <div class="clr-checkbox-wrapper margin-top">
        <input type="checkbox" id="legal-hold-modal__notify" name="legal-hold-modal__notify" class="clr-checkbox" formControlName="notify">
        <label class="clr-control-label text-dark clr-col-md-auto" for="legal-hold-modal__notify">Notify me when all holds are processed</label>
      </div>
      <div class="clr-checkbox-wrapper">
        <input type="checkbox" id="legal-hold-modal__impliedHold" name="legal-hold-modal__impliedHold" class="clr-checkbox" formControlName="impliedHold">
        <label class="clr-control-label text-dark clr-col-md-auto" for="legal-hold-modal__impliedHold">Apply Implied Holds to new records</label>
      </div>
      <div class="clr-checkbox-wrapper">
        <input type="checkbox" id="legal-hold-modal__consent" name="legal-hold-modal__consent" class="clr-checkbox" formControlName="consent">
        <label class="clr-control-label text-dark clr-col-md-auto" for="legal-hold-modal__consent">I confirm that I wish to place a legal hold on {{documentData.selected.length}} records.</label>
      </div>
      <input type="submit" hidden name="create-legal-hold-submit" id="create-legal-hold-submit">
    </form>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="closeModal()">Cancel</button>
    <button id="client-onboard-submit-trigger" type="submit" [disabled]="!createLegalHoldsForm.valid" (click)="clickHiddenSubmitButton()" [clrLoading]="submitInProgress" class="btn btn-primary">Submit</button>
  </div>

</clr-modal>
