import {Component, Input, OnInit} from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';

@Component({
  selector: 'app-barchart',
  templateUrl: './barchart.component.html',
  styleUrls: ['./barchart.component.scss']
})
export class BarchartComponent implements OnInit {
  @Input() labels: any;
  @Input() data: any;
  public chartColorsDocTypeIndividual: Array<any> = [
    {
      backgroundColor: ['#00567a', '#5EB715', '#5c32a8', '#29c0e6'],
      borderWidth: 2,
    }
  ];
  public chartType: ChartType = 'bar';
  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    // We use these empty structures as placeholders for dynamic theming.
    // scales: { xAxes: [{}], yAxes: [{}] },
    legend: {
      display: false
    },
    title: {
        display: true,
        text: 'Document Breakdown'
      }
  };
  constructor() { }

  ngOnInit(): void {
  }

}
