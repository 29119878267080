<app-loader *ngIf="loadingUser()"></app-loader>
<clr-main-container [@fadeInDown]>
  <app-cookie-alert></app-cookie-alert>
  <app-navigation [currentUser]="currentUser"></app-navigation>
  <app-breadcrumbs></app-breadcrumbs>
  <div class="content-container">
    <router-outlet #outlet="outlet"></router-outlet>
    <app-footer *ngIf="outlet.isActivated"></app-footer>
  </div>
</clr-main-container>
