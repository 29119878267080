import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminPageComponent } from './pages/admin-page/admin-page.component';
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';
import { SearchPageComponent } from './pages/search-page/search-page.component';
import { DocumentTypeAdminPageComponent } from './pages/document-type-admin-page/document-type-admin-page.component';
import { NotFoundPageComponent } from './pages/notfound-page/notfound-page.component';
import { Guard } from './services/guard.service';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { LegalHoldPageComponent } from './pages/legal-hold-page/legal-hold-page.component';
import { ClientAdminPageComponent } from './pages/client-admin-page/client-admin-page.component';
import {SiteMappingPageComponent} from './pages/site-mapping-page/site-mapping-page.component';
import { DataMappingPageComponent } from './pages/data-mapping-page/data-mapping-page.component';
import {UsersAdminPageComponent} from './pages/users-admin-page/users-admin-page.component';
import {ReportPageComponent} from './pages/report-page/report-page.component';
import {BatchTransactionPageComponent} from './pages/batch-transaction-page/batch-transaction-page.component';

const routes: Routes = [
  {path: '', pathMatch: 'full', component: DashboardPageComponent, data: {breadcrumb: 'My Home'}, canActivate: [Guard]},
  {path: 'search', component: SearchPageComponent, data: {breadcrumb: 'Search'}, canActivate: [Guard]},
  {path: 'report', component: ReportPageComponent, data: {breadcrumb: 'Report'}, canActivate: [Guard]},
  {path: 'batch-transactions', component: BatchTransactionPageComponent, data: {breadcrumb: 'Batch Transactions'}, canActivate: [Guard]},
  {path: 'login', component: LoginPageComponent, data: {breadcrumb: 'Login Page'}},
  {path: 'legal-hold', component: LegalHoldPageComponent, data: {breadcrumb: 'Legal Hold'}, canActivate: [Guard]},
  {path: 'admin', component: AdminPageComponent, data: {breadcrumb: 'Administration'}, canActivate: [Guard],
    children: [
      {path: 'document-type', component: DocumentTypeAdminPageComponent, data: {breadcrumb: 'Document Types'}, canActivate: [Guard]},
      {path: 'users', component: UsersAdminPageComponent, data: {breadcrumb: 'Users'}, canActivate: [Guard]},
      {path: 'client-admin', component: ClientAdminPageComponent, data: {breadcrumb: 'Clients'}, canActivate: [Guard]},
      {path: 'site-mapping', component: SiteMappingPageComponent, data: {breadcrumb: 'Site Mapping'}, canActivate: [Guard]},
      {path: 'data-mapping', component: DataMappingPageComponent, data: {breadcrumb: 'Data Mapping'}, canActivate: [Guard]}
    ]},
  {path: 'notfound', pathMatch: 'full', component: NotFoundPageComponent, data: {breadcrumb: 'Page Not Found'}},
  {path: '**', redirectTo: 'notfound'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
