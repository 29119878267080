import { ClrDatagridComparatorInterface } from '@clr/angular';
import { IimDocumentType } from "ui-sdk/models/iim-document-type.model";

export class ActiveStatusComparator implements ClrDatagridComparatorInterface<IimDocumentType> {
  compare(a: IimDocumentType, b: IimDocumentType) {
    const aValue = (a.status.toLowerCase() === 'active' ? 1 : 0) ;
    const bValue = (b.status.toLowerCase() === 'active' ? 1 : 0);
    return (aValue - bValue);
  }
}
