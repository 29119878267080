export class DefaultDateRanges {
  static defaultDateRanges = [
    {
      rangeType: 'Custom',
      rangeLabel: 'Last 7 Days',
      rangeStart: 7,
      rangeEnd: 0
    },
    {
      rangeType: 'Custom',
      rangeLabel: 'Last 30 Days',
      rangeStart: 30,
      rangeEnd: 0
    },
    {
      rangeType: 'Custom',
      rangeLabel: 'Last 12 Months',
      rangeStart: 365,
      rangeEnd: 0
    },
    {
      rangeType: 'Year to Date',
      rangeLabel: 'Year to Date',
      rangeStart: null,
      rangeEnd: null
    },
    {
      rangeType: 'Previous Year',
      rangeLabel: 'Previous Year',
      rangeStart: null,
      rangeEnd: null
    }
  ];
}
