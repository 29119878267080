<div class = "doctype-chart" style="display: block;height:100%;">
    <canvas baseChart
      width="100%"
      height="100%"
      [data]="data"
      [labels]="labels"
      [colors]="chartColorsDocTypeIndividual"
      [options]="barChartOptions"
      [chartType]="chartType">
    </canvas>
  </div>
