import { Component, OnInit } from '@angular/core';
import { fadeOutLeftAnimation } from '../../animations';
import { NewsItem } from 'ui-sdk/models/news-item.model';

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss'],
  animations: [fadeOutLeftAnimation]
})
export class NewsListComponent implements OnInit {
  newsItems: Array<NewsItem> = [];

  constructor() { }

  ngOnInit() {
    this.newsItems.push({
      subject: 'Welcome to Broadridge Intelligent Information Management (IIM)',
      message: 'Intelligent Information Management (IIM) lets you analyze and manipulate your reports.',
      date: new Date()
    });
  }

  dismissNewsItem(newsItem: NewsItem) {
    const toDelete = this.newsItems.indexOf(newsItem);
    if (toDelete !== -1) {
      this.newsItems.splice(toDelete, 1);
    } else {
      console.error('Could not find associated news item to dismiss.');
    }
  }
}
