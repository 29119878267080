import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  breadcrumbs: Array<Breadcrumb>;
  subscriptions: Subscription = new Subscription();

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit() {
    const routerEventsSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      distinctUntilChanged()
    ).subscribe((event) => {
      // Build breadcrumb starting with the root route of current activated route
      this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
    });
    this.subscriptions.add(routerEventsSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: Array<Breadcrumb> = []): Array<Breadcrumb> {
    const label = route.routeConfig ? route.routeConfig.data['breadcrumb'] : 'My Home';
    const path = route.routeConfig ? route.routeConfig.path : '';
    const nextUrl = `${url}${path}/`;

    if (path === 'login') {
      breadcrumbs = [];
    }

    const breadcrumb = {
      label: label,
      url: nextUrl
    };
    const newBreadcrumbs = [...breadcrumbs, breadcrumb];
    const isHomeRoute = (url === '/' && path === '');

    if (isHomeRoute) {
      return breadcrumbs;
    } else if (route.firstChild) {
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    } else {
      return newBreadcrumbs;
    }
  }
}

export interface Breadcrumb {
  label: string;
  url: string;
}
