<div [@fadeInLeft]>
  <div class="clr-row data-mapping-container">
    <div class="clr-col-xs-12 clr-col-sm-12 data-mapping-item">
      <button *ngIf="currentUser?.selectedClient?.uiperms?.datamapping.create" (click)="addNewMappingGroup()" class="btn btn-link btn-no-caps"><clr-icon shape="plus-circle"></clr-icon>&nbsp;New Data Mapping Group</button>
    </div>
    <div class="clr-col-xs-12 clr-col-md data-mapping-item-end">
      Show
      <div class="clr-select-wrapper">
        <select class="clr-select" name="pageAmount" id="pageAmount" [formControl]="tableControls.pageSize">
          <option [ngValue]="10">10</option>
          <option [ngValue]="25">25</option>
          <option [ngValue]="50">50</option>
        </select>
      </div>
      items per page
    </div>
  </div>

  <div class="clr-row">
    <div class="clr-col-xs-12 clr-col-sm-12">
      <clr-datagrid [clrDgLoading]="groups.loading">
        <clr-dg-column [clrDgField]="'name'">
          Group Name
          <clr-dg-filter [clrDgFilter]="name">
            <text-filter #name propertyName="name"></text-filter>
          </clr-dg-filter>
        </clr-dg-column>
        <clr-dg-column class="table-actions">
          Actions
        </clr-dg-column>

        <clr-dg-row *clrDgItems="let group of groups.initial" [clrDgItem]="group">

          <clr-dg-cell class="datagrid-align-cells">
            <button class="btn btn-icon btn-link copy-to-clipboard-btn" title="Copy Company Id" [appCopyClipboard]="group?.name" (copied)="notify($event)">
              <clr-icon class="copy-to-clipboard-icon" shape="copy-to-clipboard"></clr-icon>
            </button>
            {{group.name}}

            <span *ngIf="group?.name == copyIdToClipboard" class="clipboard-copy-success">
                <clr-icon shape="check" size="14"></clr-icon>
                Copied
              </span>
          </clr-dg-cell>
          <clr-dg-cell class="table-actions-cell" >
            <div class="btn-group btn-link btn-icon">
              <button [title]="'View Data Mapping Group'" *ngIf="!currentUser?.selectedClient?.uiperms?.datamapping?.read" class="btn btn-no-caps" (click)="editGroup(group)">
                <clr-icon shape="eye"></clr-icon>
              </button>
              <button [title]="'Edit Data Mapping Group'" *ngIf="currentUser?.selectedClient?.uiperms?.datamapping?.update" class="btn btn-no-caps" (click)="editGroup(group)">
                <clr-icon shape="pencil"></clr-icon>
              </button>
              <button [title]="'Delete Data Mapping Group'" *ngIf="currentUser?.selectedClient?.uiperms?.datamapping?.delete" class="btn btn-no-caps" (click)="removeGroup(group)">
                <clr-icon shape="trash"></clr-icon>
              </button>
            </div>
          </clr-dg-cell>
        </clr-dg-row>

        <clr-dg-footer>
          <clr-dg-pagination #pagination [clrDgPageSize]="tableControls.pageSize.value">
            {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
            of {{pagination.totalItems}} groups
          </clr-dg-pagination>
        </clr-dg-footer>
      </clr-datagrid>
    </div>
  </div>
</div>

<div *ngIf="dataMappingModal_enabled">
  <app-data-mapping-modal [modalEnabled]="dataMappingModal_enabled" (close)="dataMappingModal_enabled = false" (updated)="onGroupsChanged()" (created)="onGroupsCreated()" [selectedMappingGroup]="currentGroup" [functionInfo]="groups.functionInfo"></app-data-mapping-modal>
</div>
