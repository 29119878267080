<input #fileInput class="fileInput" type="button" id="upload-modal__file" name="upload-modal__file" (click)="openModal()"/>
<clr-modal class="upload-modal" id="upload-modal" [clrModalOpen]="isOpen" (clrModalOpenChange)="closeModal()" [clrModalStaticBackdrop]="false" [clrModalSize]="'lg'">
  <div class="modal-title">
    <h3 class="modal-title-text">Upload Document(s)</h3>
  </div>
  <div class="modal-body">

    <form id="upload-form" class="upload-form" [formGroup]="uploadForm" clrForm clrLayout="horizontal" (ngSubmit)="onSubmitUpload()">
      <div class="dropzone" appDnd (click)="fileDropRef.click()" (onFileDropped)="fileBrowserHandler($event)">
        <input hidden
                type="file"
                #fileDropRef
                (change)="onFilelistChange($event.target.files)"
                class="fileInput"
                id="document-upload-modal__file"
                name="document-upload-modal__files"
                multiple/>
        <clr-icon class="upload-icon" shape="upload" size="68"></clr-icon>
        <h4 class="upload-box-text">Drag and drop a file here or click to upload.</h4>
      </div>

      <div>
        <div class="clr-form-control files-list" *ngFor="let droppedFile of fileContentsMap | keyvalue">
          <div class="file-info">
            <clr-icon class="file-icon" shape="file" size="36"></clr-icon>
            <div class="file-name" title="{{droppedFile.key}}">
              {{ droppedFile.key }}

              <div class="file-size">
                {{ bytesToSize(droppedFile.value.size) }}
              </div>
            </div>
          </div>

          <div class="progress-bar">
            <clr-progress-bar clrValue="{{fileUploadProgress.get(droppedFile.key)}}" clrSuccess clrLabeled></clr-progress-bar>
          </div>

          <button *ngIf="fileUploadProgress.get(droppedFile.key) < 100" class="delete-file" (click)="deleteSelectedFile(droppedFile.key)">
            <clr-icon shape="trash" size="20"></clr-icon>
          </button>

          <clr-icon *ngIf="fileUploadProgress.get(droppedFile.key) == 100" class="upload-success-icon" size="25" shape="check-circle" title="File upload completed"></clr-icon>

        </div>
      </div>

      <div class="document-type-main">
        <div class="clr-control-container document-type-select">
          <div class="clr-select-wrapper">
            <select class="clr-select" id="upload-modal__docType" name="upload-modal__docType" title="Document Type" formControlName="docType">
              <option disabled [ngValue]="null">Select a Document Type</option>
              <option *ngFor="let docType of docTypes" [ngValue]="docType.id">{{docType.description}}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="clr-row clr-col-12 search-indexes-section-upload">
        <div class="search-indexes-inputs-upload clr-col-6 clr-col-xl-6" *ngFor="let searchIndex of searchIndices">
          <input *ngIf="!searchIndex.dataType.includes('Date')"
            clrInput
            placeholder="{{searchIndex.label}}"
            title="{{searchIndex.label}}"
            class="search-index-input clr-select"
            [type]="searchIndex.dataType"
            (focusout)="searchIndexFocusOut(searchIndex.searchIndex, searchIndexElement.value, false)"
            #searchIndexElement>

          <input *ngIf="searchIndex.dataType.includes('Date')"
            placeholder="{{searchIndex.label}}"
            title="{{searchIndex.label}}"
            value="{{this.metadataUploadPayload.metadata[0][searchIndex.searchIndex] | date: 'mediumDate'}}"
            readonly
            clrDate
            type="date"
            class="search-index-input search-index-date"
            (clrDateChange)="searchIndexFocusOut(searchIndex.searchIndex, $event, true)"
            autocomplete="off"
            #dateInput>

          <button *ngIf="searchIndex.dataType.includes('Date')" type="button" class="btn-clear-date btn-no-caps" (click)="clearDate(searchIndex.searchIndex)">
            <clr-icon class="clear-date-icon" title="Clear date input(s)" type=button shape="trash"></clr-icon>
          </button>
        </div>
    </div>

    <button id="upload-submit" hidden type="submit">Submit</button>
    </form>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="closeModal()">Cancel</button>
    <button id="upload-submit-trigger" type="submit" (click)="onSubmitUpload()" [disabled]="fileNames.length < 1 || checkMetadataEmpty() || uploadCompleted" [clrLoading]="uploadInProgress" class="btn btn-primary">Upload</button>
  </div>
</clr-modal>

<!-- Unsupported File Type Warning -->
<clr-modal [clrModalOpen]="showUnsupportedFileWarning" [clrModalStaticBackdrop]="false">
  <div class="modal-title">
    <div class="rectangle r1"></div>
    <div class="rectangle r2"></div>
    <h3 class="modal-title-text">
      <ng-container>Unsupported File Type</ng-container>
    </h3>
  </div>
  <div class="modal-body">
    The selected file is not in a format supported by IIM. File types accepted are: .doc, .docx, .xls, .xlsx, .pdf, .txt, .csv, .jpg.<br><br>
    Please select a different file.
  </div>
  <div class="modal-footer">
    <button type="submit" (click)="showUnsupportedFileWarning = false" class="btn btn-primary">Ok</button>
  </div>
</clr-modal>
