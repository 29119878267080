export class OperatorConfig {
  static dropdownSettings = {
    idField: 'item_id',
    textField: 'item_text',
    enableCheckAll: true,
    itemsShowLimit: '4'
  };

  static operatorList = [
    {item_id : 'eq', item_text : 'Equal'},
    {item_id : 'neq', item_text : 'Not Equal'},
    {item_id : 'lt', item_text : 'Less Than'},
    {item_id : 'lte', item_text : 'Less Than or Equal'},
    {item_id : 'gt', item_text : 'Greater Than'},
    {item_id : 'gte', item_text : 'Greater Than or Equal'},
    {item_id : 'between', item_text : 'Between'},
    {item_id : 'notbetween', item_text : 'Not Between' }
  ];

  static operatorStringList = [
    {item_id : 'eq', item_text : 'Equal'},
    {item_id : 'neq', item_text : 'Not Equal'},
    {item_id : 'like', item_text : 'Contains'}
  ];

  static getOperators(operators: string[]) {
    const operatorInputList = [];
    operators.forEach(o => {
      if (o === 'like') {
        operatorInputList.push({item_id : 'like', item_text : 'Contains'});
      }

      OperatorConfig.operatorList.forEach(ol => {
        if (ol.item_id === o) {
          operatorInputList.push(ol);
        }
      });
    });

    return operatorInputList;
  }
}
