import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Subscription} from 'rxjs';
import { IimUser } from 'ui-sdk/models/iim-user.model';
import {ClrDatagridSortOrder} from '@clr/angular';
import {fadeInLeftAnimation} from 'src/app/animations';
import {AlertService} from 'src/app/services/alert.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ObjectKeysTextFilter} from 'src/app/components/table/filters/objectKeysText.filter';
import {UserMethods} from 'ui-sdk';
import {FunctionInfo, DataMapping, DataMappingMethods} from 'ui-sdk';

@Component({
  selector: 'app-data-mapping-page',
  templateUrl: './data-mapping-page.component.html',
  styleUrls: ['./data-mapping-page.component.scss'],
  animations: [fadeInLeftAnimation]
})
export class DataMappingPageComponent implements OnInit, OnDestroy {
  @Output() initialization: EventEmitter<any> = new EventEmitter();

  copyIdToClipboard: string;
  timer;

  dataMappingModal_enabled: boolean = false;

  archiveProviders: Array<string>;
  tableSort: {'createDate': ClrDatagridSortOrder};
  currentGroup: any = null;

  providerFilter = new ObjectKeysTextFilter();

  tableControls: { propertiesFilter: FormControl, pageSize: FormControl, textFilter: FormControl } = {
    propertiesFilter: new FormControl([]),
    pageSize: new FormControl(10),
    textFilter: new FormControl('')
  };

  subscriptions: Subscription = new Subscription();
  currentUser: IimUser;

  groups: {initial: Array<DataMapping>, current: Array<DataMapping>, loading: boolean, status: string, functionInfo: FunctionInfo} = {
    initial: [],
    current: [],
    loading: false,
    status: 'Active',
    functionInfo: new FunctionInfo()
  };

  constructor(private alertService: AlertService) {
  }

  ngOnInit() {
    const userSubscription = UserMethods.currentUser$.subscribe((user: IimUser) => this.onUserLoaded(user));
    this.subscriptions.add(userSubscription);
    this.dataMappingModal_enabled = false;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onUserLoaded(user: IimUser) {
    this.currentUser = user;
    if (this.currentUser) {
      if (this.currentUser.selectedClient.uiperms.datamapping.read) {
        this.loadMappingGroups();
      }
    }
  }

  loadMappingGroups() {
    this.groups.loading = true;

    DataMappingMethods.getMappingGroup().subscribe(groups => {
      DataMappingMethods.functionMappingGroup().subscribe(functionInfo => {
        this.groups.functionInfo = functionInfo;
        this.groups.loading = false;
        this.groups.initial = groups;
      }, error => {
        this.groups.loading = false;
        if (error.status === 403) {
            const [title, msg] = ['Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [GET DataMapping].`];
            this.alertService.showError(title, msg);
        } 
      });
    }, error => {
      this.groups.loading = false;
      if (error.status === 403) {
          const [title, msg] = ['Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [GET DataMapping].`];
          this.alertService.showError(title, msg);
      } 
    });
  }

  removeGroup(group) {
    const [title, msg] = [`Delete Mapping Group`, `Are you sure you want to delete Mapping Group '${group.name}'?`];
    this.alertService.confirmDangerousAction(title, msg).then(confirmed => {
      if (confirmed) {
        DataMappingMethods.deleteMappingGroup(group.name).subscribe({
          next: () => this.deleteHandler(group),
          error: (res) => this.handleError(res)
        });
      }
    });
  }

  deleteHandler(group) {
    this.alertService.showSuccessToast(`Data Mapping '${group.name}' Deleted`, undefined);
    this.onGroupsChanged();
  }

  handleError(error: HttpErrorResponse) {
    this.groups.loading = false;

    if (error.status === 403) {
        const [title, msg] = ['Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [DELETE DataMapping].`];
        this.alertService.showError(title, msg);
    } 
  }

  addNewMappingGroup() {
    this.currentGroup = null;
    this.dataMappingModal_enabled = true;
  }

  editGroup(group) {
    this.currentGroup = group;
    this.dataMappingModal_enabled = true;
  }

  onGroupsChanged() {
    UserMethods.getUserProfile().then();
  }

  onGroupsCreated() {
    this.loadMappingGroups();
  }

  notify(event: any) {
    this.copyIdToClipboard = event;

    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      this.copyIdToClipboard = '';
    }, 3000);
  }
}
