import { AbstractControl, ValidatorFn } from '@angular/forms';
import moment from 'moment';

export const DATE_REGEX: RegExp = new RegExp(/^(\d{2}|\d)(\/|-)(\d{2}|\d)(\/|-)(\d{4}|\d{2})$/);
export const DATE_REGEX_YEAR_FIRST: RegExp = new RegExp(/^\d{4}(\/|-)(\d{2}|\d)(\/|-)(\d{2})$/);

export function documentDateValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const dateString = control.value;
    let dateFormat: string = 'MM/DD/YYYY';
    let isValidFormat: boolean = false;

    if (dateString) {
      if (DATE_REGEX.test(dateString)) {
        isValidFormat = true;
      } else if (DATE_REGEX_YEAR_FIRST.test(dateString)) {
        isValidFormat = true;
        dateFormat = 'YYYY/MM/DD';
      }
      if (isValidFormat) {
        const isValidDate = moment(dateString, dateFormat).isValid();
        if (isValidDate) {
          const isInFuture = new Date(dateString) > new Date();
          return isInFuture ? {'futureDate': {value: dateString}} : null;
        }
      }
      return {'invalidDate': {value: dateString}};
    } else {
      return null;
    }
  };
}
