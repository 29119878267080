<div class="row contain">
  <div class="col-sm-3 small">
  <nav class="sidenav" [clr-nav-level]="2">
    <section class="sidenav-content">
      <section class="nav-group">
        <!-- <input id="admin-sidenav-checkbox" type="checkbox"> -->
        <label for="admin-sidenav-checkbox" class="lab">Administration</label>
        <ul class="nav-list" style="list-style: none;">
          <li>
            <a (click)="select('Document Types')" [class.selected]="selectedItem === 'Document Types'" 
            *ngIf="currentUser?.selectedClient?.uiperms?.doctype.create ||
                        currentUser?.selectedClient?.uiperms?.doctype.read ||
                        currentUser?.selectedClient?.uiperms?.doctype.update ||
                        currentUser?.selectedClient?.uiperms?.doctype.delete"
                 routerLink="document-type" routerLinkActive="active" class="nav-link sidenav-label doc">Document Types</a></li>
  
          <li> 
            <a (click)="select('Users')" [class.selected]="selectedItem === 'Users'" id="user" 
            *ngIf="currentUser?.selectedClient?.uiperms?.users.create ||
                        currentUser?.selectedClient?.uiperms?.users.read ||
                        currentUser?.selectedClient?.uiperms?.users.update ||
                        currentUser?.selectedClient?.uiperms?.users.delete"
                 routerLink="users" routerLinkActive="active" class="nav-link sidenav-label user">Users</a></li>
  
          <li>
            <a (click)="select('Clients')" [class.selected]="selectedItem === 'Clients'" id="client" 
            *ngIf="currentUser?.selectedClient?.uiperms?.client.create ||
                        currentUser?.selectedClient?.uiperms?.client.read ||
                        currentUser?.selectedClient?.uiperms?.client.update ||
                        currentUser?.selectedClient?.uiperms?.client.delete"
                 routerLink="client-admin" routerLinkActive="active" class="nav-link sidenav-label client">Clients</a></li>
  
          <li>
            <a (click)="select('Site Mapping')" [class.selected]="selectedItem === 'Site Mapping'" id="site" 
            *ngIf="currentUser?.selectedClient?.uiperms?.sitemappings.create ||
                        currentUser?.selectedClient?.uiperms?.sitemappings.read ||
                        currentUser?.selectedClient?.uiperms?.sitemappings.update ||
                        currentUser?.selectedClient?.uiperms?.sitemappings.delete"
                 routerLink="site-mapping" routerLinkActive="active" class="nav-link sidenav-label site-map">Site Mapping</a></li>
  
          <li>
            <a (click)="select('Data Mapping')" [class.selected]="selectedItem === 'Data Mapping'" id="data"
            *ngIf="currentUser?.selectedClient?.uiperms?.datamapping.create ||
                        currentUser?.selectedClient?.uiperms?.datamapping.read ||
                        currentUser?.selectedClient?.uiperms?.datamapping.update ||
                        currentUser?.selectedClient?.uiperms?.datamapping.delete"
                 routerLink="data-mapping" routerLinkActive="active" class="nav-link sidenav-label data-map">Data Mapping</a></li>
        </ul>
      </section>
    </section>
  </nav>
</div>
<div [@fadeIn] class="content-area col-sm-9 big line">
  <router-outlet #outlet="outlet">
    <div [@fadeIn] class="clr-row mainbig" *ngIf="!outlet.isActivated">
      <button *ngIf="currentUser?.selectedClient?.uiperms?.doctype.create ||
                      currentUser?.selectedClient?.uiperms?.doctype.read ||
                      currentUser?.selectedClient?.uiperms?.doctype.update ||
                      currentUser?.selectedClient?.uiperms?.doctype.delete"
              routerLink="document-type" routerLinkActive="active"
              class="btn btn-primary-outline btn-no-caps btn-tile">
        <clr-icon shape="document"></clr-icon>
        <br>
        Document Types
      </button>
      <button *ngIf="currentUser?.selectedClient?.uiperms?.users.create ||
                      currentUser?.selectedClient?.uiperms?.users.read ||
                      currentUser?.selectedClient?.uiperms?.users.update ||
                      currentUser?.selectedClient?.uiperms?.users.delete"
              routerLink="users" routerLinkActive="active"
              class="btn btn-primary-outline btn-no-caps btn-tile">
        <clr-icon shape="users"></clr-icon>
        <br>
        Users
      </button>
      <button *ngIf="currentUser?.selectedClient?.uiperms?.client.create ||
                      currentUser?.selectedClient?.uiperms?.client.read ||
                      currentUser?.selectedClient?.uiperms?.client.update ||
                      currentUser?.selectedClient?.uiperms?.client.delete"
              routerLink="client-admin" routerLinkActive="active"
              class="btn btn-primary-outline btn-no-caps btn-tile">
        <clr-icon shape="world"></clr-icon>
        <br>
        Clients
      </button>
      <button *ngIf="currentUser?.selectedClient?.uiperms?.sitemappings.create ||
                      currentUser?.selectedClient?.uiperms?.sitemappings.read ||
                      currentUser?.selectedClient?.uiperms?.sitemappings.update ||
                      currentUser?.selectedClient?.uiperms?.sitemappings.delete"
              routerLink="site-mapping" routerLinkActive="active"
              class="btn btn-primary-outline btn-no-caps btn-tile">
        <clr-icon shape="network-settings"></clr-icon>
        <br>
        Site Mapping
      </button>
      <button *ngIf="currentUser?.selectedClient?.uiperms?.datamapping.create ||
                      currentUser?.selectedClient?.uiperms?.datamapping.read ||
                      currentUser?.selectedClient?.uiperms?.datamapping.update ||
                      currentUser?.selectedClient?.uiperms?.datamapping.delete"
              routerLink="data-mapping" routerLinkActive="active"
              class="btn btn-primary-outline btn-no-caps btn-tile">
        <clr-icon shape="map"></clr-icon>
        <br>
        Data Mapping
      </button>

    </div>
  </router-outlet>
</div>
</div>
<!-- <nav class="sidenav" [clr-nav-level]="2">
  <section class="sidenav-content">
    <section class="nav-group">
      <input id="admin-sidenav-checkbox" type="checkbox">
      <label for="admin-sidenav-checkbox" class="lab">Administration</label>
      <ul class="nav-list">
        <li><a *ngIf="currentUser?.selectedClient?.uiperms?.doctype.create ||
                      currentUser?.selectedClient?.uiperms?.doctype.read ||
                      currentUser?.selectedClient?.uiperms?.doctype.update ||
                      currentUser?.selectedClient?.uiperms?.doctype.delete"
               routerLink="document-type" routerLinkActive="active" class="nav-link">Document Types</a></li>

        <li><a *ngIf="currentUser?.selectedClient?.uiperms?.users.create ||
                      currentUser?.selectedClient?.uiperms?.users.read ||
                      currentUser?.selectedClient?.uiperms?.users.update ||
                      currentUser?.selectedClient?.uiperms?.users.delete"
               routerLink="users" routerLinkActive="active" class="nav-link">Users</a></li>

        <li><a *ngIf="currentUser?.selectedClient?.uiperms?.client.create ||
                      currentUser?.selectedClient?.uiperms?.client.read ||
                      currentUser?.selectedClient?.uiperms?.client.update ||
                      currentUser?.selectedClient?.uiperms?.client.delete"
               routerLink="client-admin" routerLinkActive="active" class="nav-link">Clients</a></li>

        <li><a *ngIf="currentUser?.selectedClient?.uiperms?.sitemappings.create ||
                      currentUser?.selectedClient?.uiperms?.sitemappings.read ||
                      currentUser?.selectedClient?.uiperms?.sitemappings.update ||
                      currentUser?.selectedClient?.uiperms?.sitemappings.delete"
               routerLink="site-mapping" routerLinkActive="active" class="nav-link">Site Mapping</a></li>

        <li><a *ngIf="currentUser?.selectedClient?.uiperms?.datamapping.create ||
                      currentUser?.selectedClient?.uiperms?.datamapping.read ||
                      currentUser?.selectedClient?.uiperms?.datamapping.update ||
                      currentUser?.selectedClient?.uiperms?.datamapping.delete"
               routerLink="data-mapping" routerLinkActive="active" class="nav-link">Data Mapping</a></li>
      </ul>
    </section>
  </section>
</nav> -->
