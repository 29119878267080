import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { IimUser } from 'ui-sdk/models/iim-user.model';

@Component({
  selector: 'app-multiple-file-download',
  templateUrl: './multiple-file-download.component.html',
  styleUrls: ['./multiple-file-download.component.scss']
})
export class MultipleFileDownloadComponent implements OnInit {

  currentLoginUser: IimUser;

  @Output() viewFile: EventEmitter<any> = new EventEmitter();
  @Output() downloadFile: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Input() documentData: any;
  @Input() actionsInProgress: any;

  constructor() {}

  ngOnInit() {}

  viewDocument(fileName) {
    this.viewFile.emit({ document: this.documentData, fileName});
  }

  downloadDocument(fileName) {
    this.downloadFile.emit({ document: this.documentData, fileName});
  }

  closeModal() {
    this.close.emit();
  }
}
