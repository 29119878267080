<div class="content-area">
  <h4>Transactions for Batch ID [<b>{{batchId}}</b>]</h4>
  <div class="clr-row batch-header-container">
    <div>
      <button (click)="exportReportController()" class="btn btn-link btn-no-caps" title="Export Transactions" [clrLoading]="exportingReport"><clr-icon shape="export" size="25"></clr-icon></button>
    </div>
    <div class="clr-col-xs-12 clr-col-md batch-header-item batch-header-item-end">
      Show
      <div class="clr-select-wrapper">
        <select class="clr-select" name="pageAmount" id="pageAmount" [formControl]="tableControls.pageSize">
          <option [ngValue]="10">10</option>
          <option [ngValue]="25">25</option>
          <option [ngValue]="50">50</option>
          <option [ngValue]="100">100</option>
        </select>
      </div>
      transactions per page
    </div>
  </div>

  <div class="clr-row">
    <div class="clr-col-xs-12 clr-col-sm-12">
      <clr-datagrid [clrDgLoading]="loading">
        <clr-dg-column class="table-actions-column">
          <ng-container *clrDgHideableColumn="{hidden: false}">
            Actions
          </ng-container>
        </clr-dg-column>
        <clr-dg-column *ngFor="let searchIndex of ((transactionCount > transactionCountThreshold || transactionCount === 0) ? columnIndexesMapPLACEHOLDER : columnIndexesMap) | keyvalue: originalOrder; index as i"
                       class="table-index-column"
                       [clrDgField]="searchIndex.value.key"
                       [clrDgSortBy]="getComparator(searchIndex.value.key)">
          <ng-container *clrDgHideableColumn="{hidden: false}">
            {{searchIndex.value.label}}
          </ng-container>
          <clr-dg-filter class="table-index-filter" *ngIf="i+1" [clrDgFilter]="i">
            <search-index-metadata-filter #i [propertyName]="searchIndex.value.key" [propertyType]="searchIndex.value.dataType"></search-index-metadata-filter>
          </clr-dg-filter>
        </clr-dg-column>

        <clr-dg-placeholder *ngIf="transactionCount > transactionCountThreshold">Max allowed viewing threshold ({{transactionCountThreshold}}) exceeded!</clr-dg-placeholder>

        <clr-dg-row *clrDgItems="let document of allBatchTransactions" [clrDgItem]="document">
          <clr-dg-cell class="table-actions-cell" *ngIf="transactionCount > 0 && transactionCount <= transactionCountThreshold">
            <div class="btn-group btn-sm btn-link btn-icon">
              <button title="View Document"
                      class="btn btn-no-caps"
                      [disabled]="!currentUser?.selectedClient?.uiperms?.documentcontent.read || document.status != 'Active' || document.fileNames?.length < 1"
                      (click)="viewDocument(document)"
                      [clrLoading]="document.UI_UUID == actionsInProgress.view">
                <clr-icon size="21" shape="eye"></clr-icon>
              </button>

              <button title="Download Document"
                      class="btn btn-no-caps"
                      [disabled]="!currentUser?.selectedClient?.uiperms?.documentcontent.read || document.status != 'Active' || document.fileNames?.length < 1"
                      (click)="downloadDocument(document)"
                      [clrLoading]="document.UI_UUID == actionsInProgress.download">
                <clr-icon size="21" shape="download"></clr-icon>
              </button>
            </div>
          </clr-dg-cell>

          <clr-dg-cell class="table-row doccolumn-width"
                       *ngFor="let searchIndex of document.metadata | keyvalue: originalOrder"
                       title={{document.documentTypeName}}>
            <b *ngIf="searchIndex.value == '~~No_Value~~'"><clr-icon shape="minus"></clr-icon></b>

            <a *ngIf="searchIndex.value !== '~~No_Value~~' && isUrl(searchIndex.value)" href="{{searchIndex.value}}" target="_blank" rel="noopener noreferrer">{{searchIndex.value}}</a>

            <b *ngIf="searchIndex.value !== '~~No_Value~~' && !isUrl(searchIndex.value)">
              {{searchIndex.value}}
            </b>
          </clr-dg-cell>

          <clr-dg-row-detail clrIfExpanded [ngClass]="clrIfExpanded ? 'row-details' : ''" (clrIfExpandedChange)="getAdditionalData($event, document)">
            <p>
              <span *ngIf="document.fileNames?.length > 0"><clr-icon shape="file"></clr-icon>&nbsp;<strong>Document Name(s): </strong> [ {{document.fileNames.join(' || ')}} ]</span>
              <span *ngIf="document.documentTypeName">&nbsp;&nbsp;  <clr-icon shape="file-settings"></clr-icon>&nbsp;<strong>Document Type:  </strong> {{document.documentTypeName}}</span>
              <span *ngIf="document.storageType">&nbsp;&nbsp;  <clr-icon shape="storage"></clr-icon>&nbsp;<strong>Storage Type:  </strong>  {{document.storageType}}</span>
              <span *ngIf="document.uploadDate">&nbsp;&nbsp;  <clr-icon shape="upload"></clr-icon>&nbsp;<strong>Upload Date:  </strong>  {{document.uploadDate | date:'fullDate'}}</span>
            </p>

            <p *ngIf="document.id == additionalDataLoadingId && !document.additionalData">
              Loading Additional Data <span class="spinner spinner-inline"></span>
            </p>

            <clr-datagrid class="additional-data-grid" *ngIf="document.additionalData && !additionalDataLoading" [clrDgLoading]="document.id == additionalDataLoadingId">
              <clr-dg-column>Additional Data</clr-dg-column>
              <clr-dg-column>Values</clr-dg-column>

              <clr-dg-row *ngFor="let additionalDataObject of document.additionalData | keyvalue">
                <clr-dg-cell>{{ additionalDataObject.key }}</clr-dg-cell>
                <clr-dg-cell>{{ additionalDataObject.value }}</clr-dg-cell>
              </clr-dg-row>
            </clr-datagrid>
          </clr-dg-row-detail>
        </clr-dg-row>

        <clr-dg-footer>
          <clr-dg-pagination #pagination [clrDgPageSize]="tableControls.pageSize.value">
            {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
            of {{pagination.totalItems}} transactions
          </clr-dg-pagination>
        </clr-dg-footer>

      </clr-datagrid>
    </div>
  </div>
</div>


<div *ngIf="multipleFileDownloadModal_enabled">
  <app-multiple-file-download (close)="multipleFileDownloadModal_enabled = false" [documentData]="documentDataModal" (viewFile)="viewSingleFile($event)" (downloadFile)="downloadSingleFile($event)" [actionsInProgress]="actionsInProgress" ></app-multiple-file-download>
</div>
