<clr-modal id="user-modal" [clrModalOpen]="true" (clrModalOpenChange)="closeModal()"
           [clrModalStaticBackdrop]="false" [clrModalSize]="'lg'" class="user-modal">
  <div class="modal-title">
    <div class="rectangle r1"></div>
    <div class="rectangle r2"></div>
    <h3 class="modal-title-text text1">
      <ng-container *ngIf="isNewUser()">Add User</ng-container>
      <ng-container *ngIf="!isNewUser()">
        Edit User [{{selectedUser?.userName}}]
        <button class="btn btn-icon btn-link copy-to-clipboard-btn" title="Copy User Name" [appCopyClipboard]="selectedUser.accountId" (copied)="notify($event)">
          <clr-icon shape="copy-to-clipboard"></clr-icon>
        </button>

        <span *ngIf="selectedUser.accountId == copyIdToClipboard" class="clipboard-copy-success clipboard-copy-success-modal">
              <clr-icon shape="check" size="14"></clr-icon>
              Copied
            </span>
      </ng-container>
    </h3>
  </div>
  <div class="modal-body">
    <form class="user-form" clrForm [formGroup]="userForm">
      <clr-tabs>
        <clr-tab>
          <button (click)="activeTab = 'addUser'" clrTabLink>
            <ng-container>General Info</ng-container>
          </button>
          <clr-tab-content id="doc-type-general-tab" *clrIfActive="true">

            <clr-input-container class="user-modal-inputs">
              <label class="clr-control-label">User Name:</label>
              <input class="user-input-element" type="text" clrInput placeholder="Enter User Name" name="user-modal__user-name"
                     id="user-modal__user-name" formControlName="name"/>
                     <clr-control-error *clrIfError="'required'">Please provide a name.</clr-control-error>
                     <clr-control-error *clrIfError="'pattern'">(username must be: 6-50 characters long, must have at least 1 alpha character, can only contain alphanumeric or dash characters)</clr-control-error>
            </clr-input-container>

            <clr-input-container class="user-modal-inputs">
              <label class="clr-control-label">eMail Address:</label>
              <input class="user-input-element" type="text" clrInput placeholder="Enter eMail Address" name="user-modal__email"
                     id="user-modal__email" formControlName="email"/>
                  <clr-control-error>Please provide a valid eMail Address.</clr-control-error>
            </clr-input-container>

            <clr-input-container class="user-modal-inputs">
              <label class="clr-control-label">First Name:</label>
              <input class="user-input-element" type="text" clrInput placeholder="Enter first name" name="user-modal__first-name"
                     id="user-modal__first-name" formControlName="firstName"/>
                  <clr-control-error>Please provide first name.</clr-control-error>
            </clr-input-container>

            <clr-input-container class="user-modal-inputs">
              <label class="clr-control-label">Last Name:</label>
              <input class="user-input-element" type="text" clrInput placeholder="Enter last name" name="user-modal__last-name"
                     id="user-modal__last-name" formControlName="lastName"/>
                  <clr-control-error>Please provide last name.</clr-control-error>
            </clr-input-container>

            <clr-input-container class="user-modal-inputs">
              <label class="clr-control-label">Jira Ticket Number:</label>
              <input class="user-input-element" type="text" clrInput placeholder="Enter Jira ID" name="user-modal__jira-id"
                     id="user-modal__jira-id" formControlName="jiraTrackingId"/>
                  <clr-control-error>Please provide jira ticket number.</clr-control-error>
            </clr-input-container>

          </clr-tab-content>
        </clr-tab>
        <clr-tab>
          <button (click)="activeTab = 'clientMgmt'" clrTabLink>
            Clients / Roles
          </button>

          <clr-tab-content id="doc-type-indexes-tab" *clrIfActive="false">
            <div class="add-client" *ngIf="!loadingClients">
              <clr-dropdown id="dom-test">
                <button clrDropdownTrigger class="add-client-btn">
                  Add Client To User
                  <clr-icon shape="caret down" size="17"></clr-icon>
                </button>

                <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
                  <div *ngFor="let client of allClients">
                    <a (click)="onAddClient(client)" *ngIf="!isSelectedClient(client.id)" href="javascript://" clrDropdownItem>
                     <span>{{client.name}}</span>
                  </a>
                </div>
              </clr-dropdown-menu>
              </clr-dropdown>
            </div>

            <p class="clients-load-message" *ngIf="loadingClients">Loading Clients <span class="spinner spinner-inline"></span></p>

            <p class="clients-load-message" *ngIf="!loadingClients && allClients && allClients.length == 0 && !isNewUser()">
              No added Clients<clr-icon shape="exclamation-circle"></clr-icon>
            </p>

            <p *ngIf="!loadingClients && allClients?.length && selectedClientMap?.size > 0" class="federated-role-note">
              [F] - Federated Role (only available for SSO auth), use non-federated roles for IIM Native auth
            </p>

            <div class="client-roles" *ngFor="let client of selectedClientMap | keyvalue: unsorted">
              <div class="roles-group-main">

                <button type="button" class="btn btn-link client-name-btn" title="Go to client edit modal" (click)="navigateToEditClientRole(client.value.id)">{{client.value.name}}</button>

                <div class="roles-group" *ngIf="objectKeys(client.value.roles).length > 0 && !loadingClients">
                  <div class="role-name-info" *ngFor="let role of client.value.roles | keyvalue: unsorted">
                    <div class="clr-checkbox-wrapper role">
                      <input type="checkbox" id="role-{{client.key}}-{{role.key}}" [checked]="userRolesMap?.get(client.key)?.includes(role.key)" (click)="addRole($event.target.checked, role.key, client.value.id)" name="role-{{client.key}}-{{role.key}}" class="clr-checkbox">
                      <label class="clr-control-label text-dark clr-col-md-auto" title="{{role.key}}" for="role-{{client.key}}-{{role.key}}">
                        {{role.key}}
                      </label>

                      <span>
                        <clr-icon class="client-role-info" title="View permissions" shape="info-circle" size="24" (click)="displayRolePerms(role, client?.value?.id)"></clr-icon>
                      </span>

                      <span *ngIf="federatedRoles.indexOf(client?.value?.id + '_' + role.key) > -1" title="Federated role" class="federated-role">
                           [F]
                      </span>
                    </div>
                  </div>
                </div>

                <p *ngIf="objectKeys(client.value.roles).length < 1 && !loadingClients" class="no-roles-message">
                  Client has no roles. Click the client name above to open client edit modal
                </p>

              </div>

              <div class="btn-group btn-link btn-icon">
                <button  class="btn btn-no-caps" (click)="onRemoveClient(client)">
                  <clr-icon class="remove-client-icon" size="20" shape="trash"></clr-icon>
                </button>
              </div>

            </div>
          </clr-tab-content>
        </clr-tab>
      </clr-tabs>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="closeModal()">Cancel</button>
    <button id="new-user-submit-trigger"
            type="submit"
            (click)="onSubmit()"
            [disabled]="!userForm.dirty && !rolesChanged"
            class="btn btn-primary"
            [clrLoading]="processingModalRequest">

      <ng-container *ngIf="isNewUser()">Add User</ng-container>
      <ng-container *ngIf="!isNewUser()">Save User</ng-container>
    </button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="roleToDisplayKey" (clrModalOpenChange)="roleToDisplayKey = null"
           [clrModalStaticBackdrop]="false" [clrModalSize]="'md'">
  <h3 class="modal-title">Role: {{roleToDisplayKey}}</h3>
  <div class="modal-body perms-modal">
    <clr-datagrid class="perms-grid">
      <clr-dg-column>
        Permission
      </clr-dg-column>
      <clr-dg-row *ngFor="let perms of roleToDisplayValue | keyvalue: unsorted">
        <clr-dg-cell class="perms-cell">
          <clr-icon shape="administrator" size="18" *ngIf="perms.key.endsWith('SuperUser')"></clr-icon>
          <clr-icon shape="cluster" size="18" *ngIf="perms.key.endsWith('ArchiveApiProvider') || perms.key.endsWith('ArchiveApiProviders')"></clr-icon>
          <clr-icon shape="tasks" size="18" *ngIf="perms.key.endsWith('EventStatus')"></clr-icon>
          <clr-icon shape="bank" size="18" *ngIf="perms.key.endsWith('Client') || perms.key.endsWith('Clients') || perms.key.endsWith('ClientById')"></clr-icon>
          <clr-icon shape="paint-roller" size="18" *ngIf="perms.key.endsWith('ClientCustomLogo')"></clr-icon>
          <clr-icon shape="upload-cloud" size="18" *ngIf="perms.key.endsWith('UploadDocument') || perms.key.endsWith('UploadDocumentLegacy')"></clr-icon>
          <clr-icon shape="download-cloud" size="18" *ngIf="perms.key.endsWith('DocumentDownload') || perms.key.endsWith('DocumentContent') || perms.key.endsWith('DocumentContentLegacy')"></clr-icon>
          <clr-icon shape="hard-drive" size="18" *ngIf="perms.key.endsWith('DocumentMetadata')"></clr-icon>
          <clr-icon shape="process-on-vm" size="18" *ngIf="perms.key.endsWith('RunningEvents')"></clr-icon>
          <clr-icon shape="search" size="18" *ngIf="perms.key.endsWith('SearchDocument')"></clr-icon>
          <clr-icon shape="pod" size="18" *ngIf="perms.key.endsWith('ClientDocType') || perms.key.endsWith('ClientDocTypeById') || perms.key.endsWith('ClientDocTypes')"></clr-icon>
          <clr-icon shape="shield-x" size="18" *ngIf="perms.key.endsWith('LegalHold') || perms.key.endsWith('LegalHolds')"></clr-icon>
          <clr-icon shape="tags" size="18" *ngIf="perms.key.endsWith('DocTypeSearchIndexes')"></clr-icon>
          <clr-icon shape="ruler-pencil" size="18" *ngIf="perms.key.endsWith('ClientPreference') || perms.key.endsWith('ClientPreferences')"></clr-icon>
          <clr-icon shape="floppy" size="18" *ngIf="perms.key.endsWith('SavedSearch') || perms.key.endsWith('SavedSearchById') || perms.key.endsWith('SavedSearches')"></clr-icon>
          <clr-icon shape="key" size="18" *ngIf="perms.key.endsWith('Permissions')"></clr-icon>
          <clr-icon shape="network-settings" size="18" *ngIf="perms.key.endsWith('SiteMappings') || perms.key.endsWith('SiteMappingById')"></clr-icon>
          <clr-icon shape="map" size="18" *ngIf="perms.key.endsWith('DataMappings') || perms.key.endsWith('DataMapping') || perms.key.endsWith('DataMappingById')"></clr-icon>
          <clr-icon shape="briefcase" size="18" *ngIf="perms.key.endsWith('Reports') || perms.key.endsWith('Report') || perms.key.endsWith('ReportById')"></clr-icon>

          {{ perms.key }}

        </clr-dg-cell>
        <clr-dg-row-detail *clrIfExpanded>
          Description: {{ perms.value.description }}<br><br>
          {{ perms.value.httpMethod }} {{ perms.value.path }}<br><br>
          Permision Code: [ {{ perms.value.permissionCode }} ]
        </clr-dg-row-detail>
      </clr-dg-row>
    </clr-datagrid>
    <div class="perms-modal-buttons">
      <button type="button" class="btn btn-outline" (click)="roleToDisplayKey = null">Cancel</button>
      <button class="btn btn-primary" (click)="editRole(roleToDisplayKey)">Edit Role</button>
    </div>
  </div>
</clr-modal>
