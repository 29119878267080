import {Component, OnInit} from '@angular/core';
import {UserService} from 'src/app/services/user.service';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from 'src/app/services/alert.service';
import {UserMethods} from 'ui-sdk';
import {IimUser} from 'ui-sdk/models/iim-user.model';
import { min } from 'moment';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  loginForm: FormGroup;
  passwordUpdateForm: FormGroup;
  forgotPasswordResetForm: FormGroup;
  forgotPasswordUpdateForm: FormGroup;
  forgotPasswordUpdateUsername: string = '';
  userNamePasswordReset: string='';
  loading: boolean = false;
  submitted: boolean = false;

  passwordReset: boolean = false;
  forgotPasswordResetBool: boolean = false;
  forgotPasswordUpdateBool: boolean = false;
  notContainstheUserName: boolean = true;
  clientModalEnabled: boolean = false;

  userNameNotFoundInLocalStorage = false;

  rememberMe: FormControl = new FormControl(false);

  constructor(private userService: UserService,
              private alertService: AlertService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {

    activatedRoute.queryParams.subscribe(
      params => {
        if (params['passReset']) {
          this.forgotPasswordUpdateBool = true;
          this.forgotPasswordResetBool = false;
        }
      });
  }

  ngOnInit() {
    UserMethods.deleteTokensFromStorage();

    this.loginForm = new FormGroup({
      userName: new FormControl({ value: null, disabled: false }, [Validators.required]),
      password: new FormControl({ value: null, disabled: false }, [Validators.required]),
    });

    this.forgotPasswordResetForm = new FormGroup({
      userNameForgotPassword: new FormControl({ value: null, disabled: false }, [Validators.required]),
    });

    this.forgotPasswordUpdateForm = new FormGroup({
      userName: new FormControl({ value: null, disabled: false }),
      newPasswordForgot: new FormControl({ value: null, disabled: false }, [Validators.required, this.newPasswordPolicyValidation]),
      confirmPasswordForgot: new FormControl({ value: null, disabled: false }, [Validators.required, this.confirmForgotPasswordCheck]),
      verificationToken: new FormControl({ value: null, disabled: false }, [Validators.required, Validators.pattern('\\d*'), Validators.minLength(6), Validators.maxLength(6)])
    });

    this.passwordUpdateForm = new FormGroup({
      currentPassword_update: new FormControl({ value: null, disabled: false }, [Validators.required]),
      newPassword_update: new FormControl({ value: null, disabled: false }, [Validators.required, this.newPasswordPolicyValidation]),
      confirmPassword_update: new FormControl({ value: null, disabled: false }, [Validators.required, this.confirmPasswordCheck]),
    });

    this.userNameNotFoundInLocalStorage = !localStorage.getItem('currentUser');
  }

  userLogin() {
    UserMethods.deleteTokensFromStorage();
    this.loading = true;

    localStorage.setItem('currentUser', this.loginForm.get('userName').value);

    UserMethods.authenticate(this.loginForm.value).then(authenticationResponse => {
      if (authenticationResponse['status'] === 'NEW_PASSWORD_REQUIRED') {
        const [warningTitle, warningMsg] = ['Update your password', 'Looks like you haven\'t changed your temporary password. Please fill out the change password form.'];
        this.alertService.showWarning(warningTitle, warningMsg);

        this.loading = false;
        this.passwordReset = true;
        this.loginForm.reset();

      } else {
        this.loading = false;
        this.passwordReset = false;

        localStorage.setItem('IIM-auth-token', authenticationResponse['access_token']);
        localStorage.setItem('IIM-refresh-token', authenticationResponse['refresh_token']);

        UserMethods.getUserProfile().then((userProfile: IimUser) => {
          if (userProfile) {
            this.loginForm.reset();

            if (userProfile['permissions'] != null && userProfile['permissions'].length > 1) {
              this.clientModalEnabled = true;
            } else {
              this.router.navigate(['/']);
            }
          } else {
            this.loading = false;

            const [errorTitle, errorMsg] = ['Could Not Load User Profile', 'The application was unable to retrieve user profile information. Please refresh the page and try again.'];
            this.alertService.showError(errorTitle, errorMsg);
          }
        }, error => {
          this.loading = false;

          if (error.status === 403 && error.error.message.includes('User account is locked')) {
             const [title, msg] = ['Account Locked', 'Please try again in 30 minutes.'];
             this.alertService.showError(title, msg);

          }else if(error.status === 403 ){
            const [title, msg] = ['Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [GET UseProfile].`];
            this.alertService.showError(title, msg);

          } else {
            const [errorTitle, errorMsg] = ['Could Not Load User Profile', 'The application was unable to retrieve user profile information. Please refresh the page and try again.'];
            this.alertService.showError(errorTitle, errorMsg);
          }
        });
      }
    }, error => {
      this.loading = false;

      if(error.status === 403 && error.error.message.includes('User account is locked')){
        const [title, msg] = ['Account Locked', 'Please try again in 30 minutes.'];
            this.alertService.showError(title, msg);
      }else if(error.status === 403 && error.error.message.includes('Password has expired. Please reset.')){
        const [title, msg] = ['Login Failed', 'Password has expired. Please reset.'];
            this.alertService.showError(title, msg);
      }
      else{
      const [errorTitle, errorMsg] = ['Login Failed', 'Invalid username and/or password. Please try again.'];
      this.alertService.showError(errorTitle, errorMsg);
      }
    });
  }

  passwordResetFunction() {
    this.loading = true;
    let userName;

    if (localStorage.getItem('currentUser')) {
      userName = this.passwordUpdateForm.value.userName_update = localStorage.getItem('currentUser');
    } else {
      userName = this.passwordUpdateForm.get('userName_update').value;
    }

    const passwordUpdatePayload = {
      userName: userName,
      tempPassword: this.passwordUpdateForm.get('currentPassword_update').value,
      password: this.passwordUpdateForm.get('newPassword_update').value
    };

    UserMethods.updateUserPassword(passwordUpdatePayload).then(response => {
      this.loading = false;
      this.passwordReset = false;

      const [title, msg] = ['Success', 'Your password has been updated successfully. Please log in using your new credentials.'];
      this.alertService.showSuccess(title, msg);

      this.passwordUpdateForm.reset();

    }, error => {
      this.loading = false;
      if (error.error.message.includes('User account is locked')) {
        const [title, msg] = ['Account Locked', 'Please try again in 30 minutes.'];
        this.alertService.showError(title, msg);

     }else{
      const [errorTitle, errorMsg] = ['Error', 'Failed to update password. Please try again.'];
      this.alertService.showError(errorTitle, errorMsg);
    }
    });
  }

  forgotPasswordReset() {
    this.loading = true;

    const usernameForgotPasswordReset = this.forgotPasswordResetForm.get('userNameForgotPassword').value;
    this.userNamePasswordReset = usernameForgotPasswordReset;
    this.forgotPasswordUpdateUsername = usernameForgotPasswordReset;

    UserMethods.forgotPassword(usernameForgotPasswordReset, {}).then(response => {
      this.loading = false;
      this.forgotPasswordResetBool = false;
      this.forgotPasswordUpdateBool = true;

      const [title, msg] = ['Password Reset Sent', `We've sent an email containing a verification token to your inbox. Please proceed with changing your password`];
      this.alertService.showSuccess(title, msg);

      this.forgotPasswordResetForm.reset();
    }, error => {
      this.loading = false;

      if (error.error.message.includes('User account is locked')) {
        const [title, msg] = ['Account Locked', 'Please try again in 30 minutes.'];
        this.alertService.showError(title, msg);

     }else{
      const [errorTitle, errorMsg] = ['Error', 'Failed to initiate password reset. Please try again.'];
      this.alertService.showError(errorTitle, errorMsg);
     }
    });
  }

  forgotPasswordUpdate() {
    this.loading = true;

    const forgotPasswordUpdatePayload = {
      verificationToken: this.forgotPasswordUpdateForm.get('verificationToken').value,
      password: this.forgotPasswordUpdateForm.get('newPasswordForgot').value
    };

    // tslint:disable-next-line:max-line-length
    const userName = this.userNameNotFoundInLocalStorage ? this.forgotPasswordUpdateForm.get('userName').value : localStorage.getItem('currentUser');

    UserMethods.forgotPassword(userName, forgotPasswordUpdatePayload).then(response => {
      this.loading = false;
      this.forgotPasswordUpdateBool = false;

      this.forgotPasswordUpdateForm.reset();

      // automatically log users in, if password update was successful
      this.loginForm.get('userName').setValue(userName);
      this.loginForm.get('password').setValue(forgotPasswordUpdatePayload.password);
      this.userLogin();
    }, error => {
      this.loading = false;
      if (error.error.message.includes('User account is locked')) {
        const [title, msg] = ['Account Locked', 'Please try again in 30 minutes.'];
        this.alertService.showError(title, msg);

     }else{
      const [errorTitle, errorMsg] = ['Error', 'Failed to create new password. Please try again.'];
      this.alertService.showError(errorTitle, errorMsg);
     }
    });
  }

  resetBackButton() {
    this.forgotPasswordResetBool = false;
    this.forgotPasswordUpdateBool = false;
    this.forgotPasswordResetForm.reset();
    this.forgotPasswordUpdateForm.reset();
  }

  confirmPasswordCheck(control: AbstractControl): { [key: string]: any } | null {
    const confirmPass: any = control.value;
    if (confirmPass && control.parent && confirmPass !== control.parent.get('newPassword_update').value) {
      return { 'confirmPasswordCheck': true };
    } else {
      return null;
    }
  }

  confirmForgotPasswordCheck(control: AbstractControl): { [key: string]: any } | null {
    const confirmPass: any = control.value;
    if (confirmPass && control.parent && confirmPass !== control.parent.get('newPasswordForgot').value) {
      return { 'confirmPasswordCheck': true };
    } else {
      if (control?.parent?.get('newPasswordForgot')?.errors) {
        delete control.parent.get('newPasswordForgot').errors['confirmPasswordCheck'];

        if (control.parent.get('newPasswordForgot').value) {
          control.parent.get('newPasswordForgot').updateValueAndValidity();
        }
      }

      return null;
    }
  }

  newPasswordPolicyValidation(control: AbstractControl): { [key: string]: any } | null {
    const passwordPolicy: any = control.value;
    const reg = new RegExp('^(?![0-9])(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*+=])');
    if (!passwordPolicy) {
      return null;
    }

    const confirmPassValue = control?.parent?.get('confirmPasswordForgot')?.value;
    if (passwordPolicy && confirmPassValue && passwordPolicy !== confirmPassValue) {
      return { 'confirmPasswordCheck': true };
    }

    if (reg.test(passwordPolicy) && passwordPolicy.length >= 8 && passwordPolicy.length <= 14) {
      return null;
    } else {
      return { 'passwordPolicyValidation': true };
    }
  }

  checkPassLength(control) {
    if (control) {
      const newPass: any = control.value ? control.value.trim() : '';

      return newPass.length >= 8 && newPass.length <= 14;
    }
  }

  checkPassLowercase(control) {
    if (control) {
      const newPass: any = control.value ? control.value.trim() : '';
      const reg = new RegExp('^(?=.*[a-z])');

      return reg.test(newPass);
    }
  }

  checkPassUppercase(control) {
    if (control) {
      const newPass: any = control.value ? control.value.trim() : '';
      const reg = new RegExp('^(?=.*[A-Z])');

      return reg.test(newPass);
    }
  }

  checkPassNumber(control) {
    if (control) {
      const newPass: any = control.value ? control.value.trim() : '';
      const reg = new RegExp('^(?=.*\\d)');

      return reg.test(newPass);
    }
  }

  checkPassSpecialChars(control) {
    if (control) {
      const newPass: any = control.value ? control.value.trim() : '';
      const reg = new RegExp('^(?=.*[!@#$%^&*+=])');

      return reg.test(newPass);
    }
  }

  checkNotBeginsWithNumber(control) {
    if (control) {
      const newPass: any = control.value ? control.value.trim() : '';
      const reg = new RegExp('^\\d');

      return newPass && !reg.test(newPass);
    }
  }

  checkNotContainsUserName(control) {
    if (control) {
      const newPass: any = control.value ? control.value.trim() : '';
      if(control.value == null){
        return false;
      }
      this.notContainstheUserName = newPass && !newPass.toLowerCase().includes(this.forgotPasswordUpdateUsername.toLowerCase());
      return this.notContainstheUserName;
    }
  }

  
  onKeyDown(e: KeyboardEvent) {
    if (e.code === 'Space') {
      e.preventDefault();
    }
  }
}
