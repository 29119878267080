import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-legal-hold-info-modal',
  templateUrl: './legal-hold-info-modal.component.html',
  styleUrls: ['./legal-hold-info-modal.component.scss']
})
export class LegalHoldInfoModalComponent implements OnInit {

  constructor() { }

  @Input() legalHoldsInfo: Array<any> = [];
  @Output() close: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
  }

  closeModal() {
    this.close.emit();
  }
}
