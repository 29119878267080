<div [@fadeInOut] *ngIf="searchResults && !isSearchFormCollapsed" (click)="isSearchFormCollapsed = true"></div>

<div class="content-area">
  <div class="collapser" *ngIf="searchResults">
    <button class="btn btn-sm collapse-btn btn-icon btn-link" [disabled]="!searchResults" (click)="isSearchFormCollapsed = !isSearchFormCollapsed">
      <clr-icon shape="angle-double" [dir]="isSearchFormCollapsed ? 'down' : 'up'"></clr-icon>
      {{isSearchFormCollapsed ? 'Show' : 'Hide'}} Search
    </button>
  </div>

  <div [@fadeIn] [hidden]="isSearchFormCollapsed" class="clr-row search-form-area overlap">
    <div class="clr-col-xs-12 clr-col-sm-12 clr-col-md-12 clr-col-lg-12 clr-col-xl-12">
      <h3 class="dashboard__title">Search</h3>

      <form class="search-form" clrForm clrLayout="horizontal" [formGroup]="searchForm" (ngSubmit)="onSubmitSearch()" (keydown.enter)="$event.preventDefault()">

        <clr-select-container class="clr-form-control asset-type-select">
          <label class="clr-control-label clr-control-label_main clr-col-xs-12">Asset Type</label>
          <select class="clr-col-xs-12" clrSelect id="search-form__asset-type" name="search-form__asset-type" formControlName="assetType">

            <option selected value="null">Select Asset Type</option>
            <option value="Documents">Documents</option>
            <option disabled value="Emails">Emails</option>
            <option disabled value="Audio">Audio</option>
            <option disabled value="Video">Video</option>
            <option disabled value="Websites">Websites</option>
            <option disabled value="SocialMedia">Social Media</option>
          </select>
        </clr-select-container>

        <div class=saved-search *ngIf="currentUser?.selectedClient?.uiperms?.savedsearch?.read">
          <app-saved-search-select #savedSearchSelect [user]="currentUser" (selected)="onSavedSearchSelected($event)" (deleted)="onSavedSearchDeleted($event)" [savedSearches]="savedSearches.initial">
          </app-saved-search-select>
        </div>

        <section class="form-block search-form__section-one doctype-btn-group">
          <div class="clr-form-control doctypes-header-row">
            <label for="docTypeNames" class="clr-control-label clr-control-label_main clr-col-xs-12 doctype-label">
              Document Types
              <span *ngIf="documentTypes.length > 0 && !loadingDocTypes" class="badge badge-info">
                {{documentTypes.length}}
              </span>

              <span *ngIf="documentTypes.length == 0 && !loadingDocTypes" class="badge badge-danger">
                {{documentTypes.length}}
              </span>

              <span *ngIf="loadingDocTypes" class="spinner spinner-inline"></span>

            </label>
            <ng-multiselect-dropdown [placeholder]="'Select Document Types'" [data]="documentTypes" formControlName="docTypeNames"
                                     [settings]="dropdownSettings" (onSelect)="onDocumentTypeSelect($event)"
                                     (onSelectAll)="onDocumentTypeSelectAll($event)" singleSelection = "false"
                                     (onDeSelect)="onDocumentTypeDeSelect($event)"
                                     (onDeSelectAll)="onDocumentTypeDeSelectAll($event) " ngDefaultControl>
            </ng-multiselect-dropdown>
          </div>

          <div class="clr-col-xs-12 clr-col-lg-6">
            <div *ngIf="currentUser?.selectedClient?.uiperms?.doctype.update" class="clr-checkbox-wrapper d-inline-block inactive-label  clr-col-md-auto clr-col-lg-12 clr-col-xl-6">
              <input type="checkbox" id="show-inactive-doc-type" name="show-inactive-doc-type" class="clr-checkbox" [formControl]="showInactiveDocumentTypes">
              <label class="clr-control-label clr-col-lg-12" for="show-inactive-doc-type">Include Inactive Document Types</label>
            </div>
          </div>

        </section>

        <div [@fadeIn] class="clr-form-control clr-row search-indexes-area">
          <div [@fadeIn] class="clr-col-xs-12 clr-col-md-9" *ngIf="commonSearchIndexes.length < 1 && selectedDocumentTypeNames.length == 1">
            <div class="clr-row">
              <div class="clr-control-container clr-col-xs-12 clr-col-sm-6">
                <p>Selected Document Type has no Search Indexes.</p>
              </div>
            </div>
          </div>

          <div [@fadeIn] class="clr-col-xs-12 clr-col-md-9" *ngIf="commonSearchIndexes.length < 1 && selectedDocumentTypeNames.length > 1">
            <div class="clr-row">
              <div class="clr-control-container clr-col-xs-12 clr-col-sm-6">
                <p>No Common Search Indexes.</p>
              </div>
            </div>
          </div>

          <div [@fadeIn] class="clr-col-xs-12 clr-col-md-9" *ngIf="selectedDocumentTypeNames.length < 1 && documentTypes.length > 0">
            <div class="clr-row">
              <div class="clr-control-container clr-col-xs-12 clr-col-sm-6">
                <p>Please select a Document Type/s above.</p>
              </div>
            </div>
          </div>

            <div class="clr-row clr-col-12 search-indexes-section">
              <ng-container *ngFor="let searchIndex of commonSearchIndexes; let i = index">
                <h5 class="document-type-heading clr-col-12" *ngIf="checkDocumentType(searchIndex.documentTypeName) || i===0">{{documentType}}</h5>
                <div class="search-indexes-inputs clr-col-12 clr-col-xl-6">
                  <label [@fadeIn] class="index-label" title="{{searchIndex.label + ' - ' +searchIndex.documentTypeName}}" #searchIndexLabel>
                   {{searchIndex.label}}
<!--                    <span title="Advanced search index" *ngIf="searchIndex.isAdvancedSearch"><clr-icon shape="bullseye"></clr-icon></span>-->
                  </label>

                  <select class="operators" [name]="searchIndex['key']" [value]="searchIndex.selectedOperator" (change)="operatorSelect(operatorList.value, searchIndex)" #operatorList>
                     <option *ngFor="let operator of searchIndex.indexOperators" [title]="operator.value" [value]="operator.value" [selected]="searchIndex.selectedOperator === operator.value">{{ operator.label ? operator.label:'NO VALUE'}}</option>
                  </select>
                  <span [class.multipe-operator]="isMultiOperator(searchIndex)" [class.multiple-date-time-operator]="isMultiOperator(searchIndex)">
                  <input *ngIf="!searchIndex.dataType.includes('Date')"
                          [ngClass]="searchIndex.errors[0] ? 'validation-error' : ''"
                          title="{{searchIndex.errors[0]}}"
                          value="{{searchIndex.value}}"
                          clrInput
                          placeholder="Enter Value"
                          class="search-index-input clr-select"
                          [type]="searchIndex.dataType.includes('Integer') || searchIndex.dataType.includes('Decimal') ? 'number' : searchIndex.dataType"
                          (keyup.enter)="searchOnEnter(searchIndex, firstValue.value, true)"
                          (input)="searchIndexFocusOut(searchIndex, firstValue.value, true)"
                          #firstValue>

                  <input *ngIf="!searchIndex.dataType.includes('Date') && isMultiOperator(searchIndex)"
                         [ngClass]="searchIndex.errors[1] ? 'validation-error' : ''"
                         title="{{searchIndex.errors[1]}}"
                         value="{{searchIndex.secondValue}}"
                         clrInput
                         placeholder="Enter Value"
                         class="search-index-input clr-select"
                         [type]="searchIndex.dataType.includes('Integer') || searchIndex.dataType.includes('Decimal') ? 'number' : searchIndex.dataType"
                         (keyup.enter)="searchOnEnter(searchIndex, secondValue.value, false)"
                         (input)="searchIndexFocusOut(searchIndex, secondValue.value, false)"
                         #secondValue>
                  </span>
                  <span [class.multipe-operator]="isMultiOperator(searchIndex)">
                  <input *ngIf="searchIndex.dataType.includes('Date') && !searchIndex.dataType.includes('DateTime')"
                         [ngClass]="searchIndex.errors[0] ? 'validation-error' : ''"
                         title="{{searchIndex.errors[0]}}"
                         value="{{searchIndex.value | date: 'mediumDate'}}"
                         [name]="searchIndex.searchIndex"
                         placeholder="Add Date"
                         readonly
                         clrDate
                         type="date"
                         class="search-index-input search-index-date"
                         (clrDateChange)="dateChange(searchIndex, $event, true)"
                         autocomplete="off">

                  <input *ngIf="searchIndex.dataType.includes('Date') && isMultiOperator(searchIndex) && !searchIndex.dataType.includes('DateTime')"
                         [ngClass]="searchIndex.errors[1] ? 'validation-error' : ''"
                         title="{{searchIndex.errors[1]}}"
                         value="{{searchIndex.secondValue | date: 'mediumDate'}}"
                         [name]="searchIndex.searchIndex"
                         placeholder="Add Date"
                         readonly
                         clrDate
                         type="date"
                         class="search-index-input search-index-date"
                         (clrDateChange)="dateChange(searchIndex, $event, false)"
                         autocomplete="off">

                  <input *ngIf="searchIndex.dataType.includes('DateTime')"
                         [ngClass]="searchIndex.errors[0] ? 'validation-error' : ''"
                         title="{{searchIndex.errors[0]}}"
                         value="{{searchIndex.value | date: 'mediumDate'}}"
                         [name]="searchIndex.searchIndex"
                         placeholder="Add Date"
                         readonly
                         clrDate
                         type="date"
                         class="search-index-input search-index-date"
                         (clrDateChange)="dateChange(searchIndex, $event, true)"
                         autocomplete="off">

                  <input *ngIf="isMultiOperator(searchIndex) && searchIndex.dataType.includes('DateTime')"
                         [ngClass]="searchIndex.errors[1] ? 'validation-error' : ''"
                         title="{{searchIndex.errors[1]}}"
                         value="{{searchIndex.secondValue | date: 'mediumDate'}}"
                         [name]="searchIndex.searchIndex"
                         placeholder="Add Date"
                         readonly
                         clrDate
                         type="date"
                         class="search-index-input search-index-date"
                         (clrDateChange)="dateChange(searchIndex, $event, false)"
                         autocomplete="off">
                  </span>
                  <button *ngIf="searchIndex.dataType.includes('Date') && (searchIndex.value || searchIndex.secondValue)"
                          type="button"
                          class="btn-clear-date btn-no-caps"
                          [class.date-range-trash]="isMultiOperator(searchIndex) && (searchIndex.dataType.includes('DateTime') || searchIndex.dataType.includes('Date'))"
                          (click)="clearDate(searchIndex)">
                    <clr-icon class="clear-date-icon" title="Clear date input(s)" type=button shape="trash"></clr-icon>
                  </button>

                </div>
              </ng-container>
            </div>

<!--          <div *ngIf="commonSearchIndexes.length > 0 && selectedDocumentTypeNames.length > 0">-->
<!--            <p><clr-icon shape="bullseye" size="17"></clr-icon> - Advanced search index</p>-->
<!--          </div>-->

        </div>

          <div class="clr-row search-form__actions">
            <div class="clr-offset-md-3"></div>
            <div class="clr-col-xs-12 clr-col-md-9">
              <button type="submit" id="search-form-submit" [disabled]="!validSearch"
                      class="btn btn-no-caps btn-br btn-primary" [clrLoading]="loadingSearchResults"
                      >
                Search
              </button>
              <button *ngIf="currentUser?.selectedClient?.uiperms?.savedsearch?.create && !savedSearchSelect?.control?.value" type="button" (click)="saveSearch()" class="btn btn-no-caps btn-br"
                      [disabled]="!validSearch">
                Save Search
              </button>
              <button *ngIf="currentUser?.selectedClient?.uiperms?.savedsearch?.update && savedSearchSelect?.control?.value" type="button" (click)="updateSavedSearch()" class="btn btn-no-caps btn-br"
                      [disabled]="!validSearch" [clrLoading]="updatingSavedSearch">
                Update Saved Search
              </button>
              <button type="button" (click)="resetSearchIndex()"
                      class="btn btn-no-caps btn-br btn-secondary">
                Clear
              </button>
            </div>
          </div>
      </form>
    </div>
  </div>

  <div [@fadeIn] *ngIf="searchResults" [hidden]="!isSearchFormCollapsed" class="clr-row search-results-area">
    <div class="clr-col-xs-12 clr-col-sm-12">
      <app-table [initialItems]="searchResults" [serverPaginationRes]="serverPaginationRes" [sortKeyForLocalStorage]="SORT_KEY_LOCAL_STORAGE" [clientDocumentTypes]="documentTypeIdMap" [searchIndexesTable]="allSearchIndicesTable" [currentUser]="currentUser" [searchIndexesPayload]="searchIndexesPayload" [searchResultsConfig]="searchResultsConfig"></app-table>
    </div>
  </div>
</div>
