import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ClrDatagridSortOrder } from '@clr/angular';
import { faSortAmountDown } from '@fortawesome/free-solid-svg-icons';
import { CSVExportService } from '../../services/export-csv.service';
import { AlertService } from '../../services/alert.service';
import { IimUser } from 'ui-sdk/models/iim-user.model';
import { Subscription } from 'rxjs';
import { KeyValue } from '@angular/common';
import { CustomDocumentComparator } from './comparators/customdocument.comparator';
import {DocumentMethods} from 'ui-sdk';

@Component({
  selector: 'app-legal-hold-table',
  templateUrl: './legal-hold-table.component.html',
  styleUrls: ['./legal-hold-table.component.scss']
})
export class LegalHoldTableComponent implements OnInit, OnDestroy {
  @Input()
  set initialItems(value: Array<any>) {
    this._initialItems = value;
    this.filterTableItems();
    this.extractAllSearchIndexes();
  }

  get initialItems(): Array<any> {
    return this._initialItems;
  }

  constructor(private alertService: AlertService,
              private csvExportService: CSVExportService) { }
  private _initialItems: Array<any> = [];

  legalHoldsInfo: Object;
  openLegalHoldInfoModal: boolean = false;
  multipleFileDownloadModal_enabled: boolean = false;
  createLegalHoldModal_enabled: boolean = false;
  documentDataModal: any = {};

  JWT: string = localStorage.getItem('IIM-auth-token');

  height = (screen.height / 2) + 150;
  width = (screen.width / 2) - 100;
  top = (screen.height / 2) - (this.height / 3);
  left = (screen.width / 2) - (this.width / 1.5);

  @Input() loading: boolean;
  @Input() sortKeyForLocalStorage: string;
  @Input() clientDocumentTypes = {};
  @Input() searchIndexesTable: Array<any> = [];
  @Input() currentUser: IimUser;
  subscriptions: Subscription = new Subscription();


  tableSort: {};
  customTableSort = new Map();
  tableControls: { propertiesFilter: FormControl, pageSize: FormControl, textFilter: FormControl } = {
    propertiesFilter: new FormControl([]),
    pageSize: new FormControl(25),
    textFilter: new FormControl('')
  };

  documents: { selected: Array<any>; current: Array<any> } = {
    selected: [],
    current: []
  };

  additionalDataLoadingId: string = '';

  tableFilters: { properties: Array<{ key: string, value: any }>, text: string } = {
    properties: [],
    text: ''
  };
  hasSavedSort: boolean = false; // If sort configuration is saved in LocalStorage or not.
  saveSortIcon = faSortAmountDown;
  documentPreviewContent: any;
  actionsInProgress: {view: string, download: string, legalHoldInfo: object} = {
    view: '',
    download: '',
    legalHoldInfo: {}
  };

  columnIndexes = [];

  OrderByIndexKey = (a: KeyValue<number, Object>, b: KeyValue<number, Object>): number => {
    return a.key > b.key ? 1 : (b.key > a.key ? -1 : 0);
  }

  ngOnInit() {
    this.clearSort();
    this.getSavedSort();
    this.onChanges();
    this.filterTableItems();
    this.setComparator();
  }

  getComparator(columnName: string) {
    return this.customTableSort.get(columnName);
  }

  setComparator() {
    this.columnIndexes.forEach(element => {
      const documentCustomComparator = new CustomDocumentComparator();
      documentCustomComparator.setPropertyName(element.key);
      this.customTableSort.set(element.key, documentCustomComparator);
    });
  }

  openCreateLegalHoldModal() {
    this.createLegalHoldModal_enabled = true;
  }

  normalizeSearchIndexLabel (searchIndex): string {
    return searchIndex;
    // return searchIndex.toLowerCase().trim().replace(/\W/g, '');
  }

  normalizeSearchIndex (searchIndex): string {
    return 'indexes.' + this.normalizeSearchIndexLabel(searchIndex);
  }

  extractAllSearchIndexes() {
    // Column Indexes is a  clone of the searachIndexes Table
    // This is necessary to isolate the manipulation between the columns between the table.component, and search-page.component
    // When sorting in table component, it changes the order of the indexes displayed in the search page
    this.columnIndexes = [];
    // clone Search Index Table to its own display
    this.columnIndexes = JSON.parse(JSON.stringify(this.searchIndexesTable));

    this.columnIndexes.sort((a, b) => (a.key > b.key) ? 1 : (b.key > a.key) ? -1 : 0);
    this.clearSort();
    const indexTableKeys = [];
    this.columnIndexes.forEach(indexTable => {
      indexTableKeys.push(indexTable['key']);
    });

    if (this.initialItems) {
      this.initialItems.forEach(documentResult => {
          // Column Indexes has all of the required indexes for the result set (display table and CSV export)
            indexTableKeys.forEach(indexTable => {
            if (!documentResult.hasOwnProperty('metadata') || Object.entries(documentResult['metadata']).length === 0) {
              // No Indexes Property (or empty)? Add the first one.
              documentResult['metadata'] = {};
            } else {
              // If the document result metadata array does not contain a required index,
              // Add it with a value of ~~No_Value~~
              if (!documentResult['metadata'].hasOwnProperty('indexes.' + indexTable)) {
                documentResult['metadata']['indexes.' + indexTable] = '~~No_Value~~';
              }

              Object.keys(documentResult['metadata']).forEach(resultIndex => {
                const resultIndexKey = resultIndex.substring(resultIndex.indexOf('indexes.') + 8);
                //
                //  Add the Assigned Index Label to the metadata.
                //
                if (!indexTableKeys.includes(resultIndexKey)) {
                  delete documentResult['metadata'][resultIndex];
                }
              });
            }
        });
      });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onChanges() {
    const tableFilterChanges$ = this.tableControls.textFilter.valueChanges.subscribe(q => {
      this.filterTableItemsBySearchQuery(q);
    });
    this.subscriptions.add(tableFilterChanges$);

    this.extractAllSearchIndexes();
  }

  getSavedSort(): void {
    const savedTableSort = localStorage.getItem(this.sortKeyForLocalStorage);
    if (savedTableSort) {
      this.tableSort = JSON.parse(savedTableSort);
      this.hasSavedSort = true;
    }
  }

  saveSort(): void {
    const title = 'Feature Coming Soon';
    const msg = `Please be patient, we'll get there in no time!`;
    this.alertService.showSuccess(title, msg);
  }

  clearSort(): void {

    // Initialze a sort key for each of the keys in the current documents
    this.tableSort = {uploadDate: ClrDatagridSortOrder.DESC};
    if (this.documents.current.length > 0) {
      Object.keys(this.documents.current[0]).forEach(element => {
        if (element !== 'metadata') {
          this.tableSort[element] = ClrDatagridSortOrder.UNSORTED;
        }
      });
      // Add the Search Indexes to the tableSort
      this.columnIndexes.forEach(element => {

      this.tableSort[element.key] = ClrDatagridSortOrder.UNSORTED;
      });
      localStorage.removeItem(this.sortKeyForLocalStorage);
      this.hasSavedSort = false;
    }
  }

  filterTableItemsBySearchQuery(q: string) {
    this.tableFilters.text = q.toLowerCase();
    this.filterTableItems();
  }

  filterTableItemsByProperties(props: Array<{ key: string, value: any }>) {
    this.tableFilters.properties = props;
    this.filterTableItems();
  }

  filterTableItems(): void {
    const docs = this.initialItems;

    if (this.currentUser && this.currentUser.selectedClient.uiperms.document.update) {
      this.documents.current = docs;
    } else {
      this.documents.current = docs.filter(document => {
        return document.status === 'Active';
      });
    }
  }

  clearDocumentFilter(): void {
    this.tableControls.propertiesFilter.reset([]);
    this.tableFilters.properties = [];
    this.filterTableItems();
  }

  exportSearchResults(): void {
      const exportOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        showLabels: true,
        showTitle: false,
        title: 'Export Search Results',
        useBom: true,
        noDownload: false,
        // Set up static metadata columns in the header
        headers: ['Document Name',
                  'Document Id',
                  'Document Type Name',
                  'Document Status',
                  'Storage Type',
                  'ClientId',
                  'Legal Hold',
                  'Media Type',
                  'Errasure'
        ]
      };

      // sort the items based on sort order
      let sortKeys = [];
      if (!!this.tableSort) {
        sortKeys = Object.keys(this.tableSort).filter(pro => {
          return this.tableSort[pro] !== 0;
       });
      }

      let sortedDocuments = (this.documents.selected &&
                             this.documents.selected.length > 0) ?
                              this.documents.selected :
                              this.documents.current;


      if (sortKeys.length > 0) {
        const sortKeyName = sortKeys[0];
        if (sortKeyName === 'uploadDate' || sortKeyName === 'expireDate') {
          if (this.tableSort[sortKeyName] > 0) {  // Is this an Ascending Sort
            sortedDocuments = sortedDocuments.sort((x, y) => {
              if (Date.parse(x[sortKeyName]) < Date.parse(y[sortKeyName])) { return -1; }
              if (Date.parse(x[sortKeyName]) > Date.parse(y[sortKeyName])) { return 1; }
              return 0;
            });
          } else {  // Else this a descending Sort
            sortedDocuments = sortedDocuments.sort((x, y) => {
              if (Date.parse(x[sortKeyName]) > Date.parse(y[sortKeyName])) { return -1; }
              if (Date.parse(x[sortKeyName]) < Date.parse(y[sortKeyName])) { return 1; }
              return 0;
            });
          }
        } else {
          if (this.tableSort[sortKeyName] > 0) {  // Is this an Ascending Sort
            sortedDocuments = sortedDocuments.sort((x, y) => {
            if (!!!x[sortKeyName]) {
              x = x.indexes;
              y = y.indexes;
            }
            if (typeof(x[sortKeyName]) === 'string' || typeof(x[sortKeyName] === 'number')) {
                  if (x[sortKeyName] < y[sortKeyName]) { return -1; }
                if (x[sortKeyName] > y[sortKeyName]) { return 1; }
                } else {
                  if (x[sortKeyName].toString() < y[sortKeyName].toString()) { return -1; }
                  if (x[sortKeyName].toString() > y[sortKeyName].toString()) { return 1; }
                }
              return 0;
            });
          } else {  // Else this a descending Sort
            sortedDocuments = sortedDocuments.sort((x, y) => {
              if (typeof(x[sortKeyName]) === 'string' || typeof(x[sortKeyName] === 'number')) {
                if (x[sortKeyName] > y[sortKeyName]) { return -1; }
                if (x[sortKeyName] < y[sortKeyName]) { return 1; }
              } else {
                if (x[sortKeyName].toString() > y[sortKeyName].toString()) { return -1; }
                if (x[sortKeyName].toString() < y[sortKeyName].toString()) { return 1; }
              }
              return 0;
            });
          }
        }
      }

      function zeroPad(num, places) {
        return String(num).padStart(places, '0');
      }

      const exportDocuments = [];

      sortedDocuments.forEach(element => {
        const document = new Object();
        document['Name'] = element.fileName ? element.fileName : '-';
        document['DocumentId'] = element.id ? element.id : '-';
        document['DocumentTypeName'] = element.documentTypeName ? element.documentTypeName : '-';
        document['DocumentStatus'] = element.status ? element.status : '-';
        document['StorageType'] = element.storageType ? element.storageType : '-';

        // tslint:disable-next-line:max-line-length
        document['ClientId'] = element.clientId ? element.clientId : '-';
        document['LegalHold'] = !!element.legalHold ? element.legalHold : false;
        document['MediaType'] = element.mediaType ? element.mediaType : '-';
        document['Errasure'] = !!element.errasure ? element.errasure : false;
        let searchKeyIndex = 0;
        if (!!element.metadata) {
          Object.keys(element.metadata).sort().forEach(key => {
            document[`INDEX${zeroPad(searchKeyIndex, 4)}_${key}`] =
              (element.metadata[key] ? element.metadata[key] : '-');

            searchKeyIndex++;
          });
        }
        exportDocuments.push(document);
      });

      //  Add Search Index Fields to the Header
      this.columnIndexes.forEach(index => {
        exportOptions.headers.push(index['label']);
      });

      this.csvExportService.generateCsv(exportDocuments, 'export', exportOptions);
  }

  documentStatusToggle(document: any, isActive: boolean) {
    let status: string = 'Active';

    if (!isActive) {
      status = 'Inactive';
    }
    document.status = status;

    DocumentMethods.updateDocumentStatus(document).then(updateStatusResponse => {
      if (status === 'Inactive') {
        this.alertService.showSuccessToast('Success', `${document.fileName} has been inactivated.`);
      } else {
        this.alertService.showSuccessToast('Success', `${document.fileName} has been activated.`);
      }
    }, error => {
      if (error.status === 403) {
        const [title, msg] = ['Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [UPDATE DocumentStatus].`];
        this.alertService.showError(title, msg);
      } else {
        const [title, msg] = ['Error', 'Unable to update status. Please try again.'];
        this.alertService.showError(title, msg);
      }
    });
  }

  downloadContent(documentInfo: any) {
    if (documentInfo.fileNames.length > 1) {
      this.multipleFileDownloadModal_enabled = true;
      this.documentDataModal = documentInfo;
    } else {
      this.downloadSingleFile({document: documentInfo, fileName: documentInfo.fileName, singleFile: true});
    }
  }

  downloadSingleFile({document, fileName,  singleFile}) {
      this.actionsInProgress.download = singleFile ? document.id : fileName;
      const type = document['mediaType'];
      let url = '';

      // tslint:disable-next-line:max-line-length
      DocumentMethods.getPresignedUrl(document.id, document.documentTypeId, fileName, document.mediaType).then(presignedUrlResponse => {
        url = presignedUrlResponse['downloadUrl'];

      DocumentMethods.getDocumentContent(url).then(contentResponse => {
        const a = window.document.createElement('a');
        window.document.body.appendChild(a);
        a.style.display = 'none';
        a.href = window.URL.createObjectURL(
          new Blob([contentResponse], { type: type })
        );
        fileName = fileName.replaceAll('[', '').replaceAll(']', '').replaceAll('_', '').replaceAll('"', '');
        a.setAttribute('download', fileName);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        this.actionsInProgress.download = '';
      }, error => {
        this.actionsInProgress.download = '';
      });
    }, error => {
      this.actionsInProgress.download = '';
    });
  }


  viewSingleFile({document, fileName, singleFile}) {
    this.actionsInProgress.view = singleFile ? document.id : fileName;
    let url = '';

    // tslint:disable-next-line:max-line-length
    DocumentMethods.getPresignedUrl(document.id, document.documentTypeId, fileName, document.mediaType).then(presignedUrlResponse => {
      url = presignedUrlResponse['downloadUrl'];
      window.open(url, '_blank');
      this.actionsInProgress.view = '';
    }, () => {
      this.actionsInProgress.view = '';
    });
  }

  isUrl(searchIndexValue): boolean {
    // tslint:disable-next-line:max-line-length
    const regexp = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

    return regexp.test(searchIndexValue);
  }

  getAdditionalData(isRowExpanded: boolean, document: Object) {
    if (isRowExpanded) {
      this.additionalDataLoadingId = document['id'];

      DocumentMethods.getAdditionalData(document['documentTypeId'], document['id']).then(additionalData => {
        this.documents.current.forEach(docObject => {
          if (docObject['id'] === document['id']) {
            docObject['additionalData'] = additionalData;
          }
        });

        this.additionalDataLoadingId = '';
      }, error => {
        if (error.status === 403) {
          const [title, msg] = ['Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [GET AdditionalData].`];
          this.alertService.showError(title, msg);
        } else if (error.status !== 400 && !error.message.includes('Additional data not found')) {
          const [title, msg] = ['Error', 'Unable to load additional data. Please try again.'];
          this.alertService.showError(title, msg);
        }

        this.additionalDataLoadingId = '';
      });
    }
  }
}
