<nav class="subnav hidden-lg-down" aria-label="breadcrumb">
  <ol class="nav">
    <li *ngFor="let breadcrumb of breadcrumbs; last as isLast"
         class="breadcrumb-item" style="margin-right: -33px;">
      <a [routerLink]="breadcrumb.url"
         href="javascript://"
         class="btn btn-link"
         [ngClass]="isLast ? 'disabled' : ''">
        {{ breadcrumb.label }}
      </a>
    </li>
  </ol>
</nav>
