import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AlertService} from 'src/app/services/alert.service';
import { IimUser } from 'ui-sdk/models/iim-user.model';
import {Subscription} from 'rxjs';
import {ClrForm} from '@clr/angular';
import {UserMethods} from 'ui-sdk';
import {ClientMethods} from 'ui-sdk';
import {IdmRoleMethods} from 'ui-sdk';
import {Router} from '@angular/router';
import {IdmPermission} from 'ui-sdk/models/idm-permission.model';
import {IdmRole} from 'ui-sdk/models/idm-role.model';

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.scss']
})
export class UserModalComponent implements OnInit, OnDestroy {
  objectKeys = Object.keys;
  copyIdToClipboard: string;
  timer;

  @Input() modalEnabled: boolean;

  currentLoginUser: IimUser;
  subscriptions: Subscription = new Subscription();

  activeTab = '';
  loadingClients: boolean = true;
  selectedClientMap: Map<string, any> = new Map<string, any>();
  userClients: Map<string, string> = new Map<string, string>();
  allClients: Array<any> = [];
  allRoles: Array<IdmRole>;
  permissions: Map<string, IdmPermission>;
  federatedRoles: Array<string> = [];
  userRolesMap: Map<string, Array<string>> = new Map<string, Array<string>>();
  companyIds: Set<string> = new Set();

  rolesChanged: boolean = false;

  roleToDisplayKey: string;
  roleToDisplayValue: any;
  selectedClientId: string;
  usernamePattern = '^(?=.*[a-zA-Z])([a-zA-Z0-9\-\_\.]){6,50}$';

  @Output() changed: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  @Input() selectedUser: IimUser;

  client: String;
  userForm: FormGroup;
  processingModalRequest: boolean = false;

  @ViewChild(ClrForm) clrForm;

  constructor(private alertService: AlertService,
              private router: Router) {
  }

  ngOnInit() {
    const userSubscription = UserMethods.currentUser$.subscribe(
      (loginUser: IimUser) => this.currentLoginUser = loginUser
    );

    this.loadingClients = true;
    this.loadClientRoles();

    if (this.isNewUser()) {
      this.userForm = this.createUserFormGroup(null);
    } else {
      this.userForm = this.createUserFormGroup(this.selectedUser);
    }

    this.subscriptions.add(userSubscription);
    this.activeTab = 'addUser';
   }

  addRole(isChecked, role, clientId) {
    const currentRoles = this.userRolesMap.get(clientId) != null ? this.userRolesMap.get(clientId) : [];

    if (isChecked) {
      if (!currentRoles.includes(role)) {
        currentRoles.push(role);
      }
    } else {
      currentRoles.splice(currentRoles.indexOf(role), 1);
    }

    if (currentRoles.length < 1) {
      this.userRolesMap.delete(clientId);
    } else {
      this.userRolesMap.set(clientId, currentRoles);
    }

    this.rolesChanged = true;
  }

 loadClientRoles(): void {
    IdmRoleMethods.getIdmRoles().then(allRoles => {
      this.allRoles = allRoles.filter(r => {
        return r.clientId && r.roles;
      });
      this.loadPermissions();

    }, error => {
      if (error.status === 403) {
        this.alertService.showError('Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [GET IdMRoles].`);
      } else {
        this.alertService.showError('Error', 'Unable to get Site Mappings');
      }
    });
  }

  loadPermissions(): void {
    IdmRoleMethods.getPermissions().then(permsResponse => {
      this.permissions = permsResponse;
      this.loadAllClients();
    });
  }

  loadAllClients() {
    ClientMethods.getClients().subscribe({
      next: response  => this.loadClientHandler(response),
      error: this.loadClientErrorHandler
    });
  }

  loadClientHandler(response: any) {
    this.federatedRoles = [];
    this.allClients = Array<any> ();

    response.forEach(client => {
      if (this.allRoles != null && this.allRoles.length > 0) {

        this.allRoles.forEach(role => {
          if (role['clientId'] === client['id']) {
            const rolesNames = Object.keys(role['roles']);
            const existingClientIndex = this.allClients.indexOf(client);

            if (rolesNames !== null && rolesNames.length > 0) {
              rolesNames.forEach(roleName => {
                if (role['clientId'] !== role['companyId'] && this.federatedRoles.indexOf(roleName) < 0) {
                  this.federatedRoles.push(client['id'] + '_' + roleName);
                }
                const permCodes = {};

                role['roles'][roleName].forEach(permCode => {
                  Object.keys(this.permissions).forEach(r => {
                    if (permCode === this.permissions[r]['permissionCode']) {
                      permCodes[r] = this.permissions[r];
                    }
                  });

                  role['roles'][roleName] = JSON.parse(JSON.stringify(permCodes));
                });
              });

              if (existingClientIndex > -1) {
                Object.keys(role['roles']).forEach(roleName => {
                  this.allClients[existingClientIndex]['roles'][roleName] = role['roles'][roleName];
                });
              } else {
                client['roles'] = role['roles'];
                client['companyId'] = role['companyId'];
                this.allClients.push(client);
              }
            } else if (this.selectedUser?.clients && Object.keys(this.selectedUser.clients).indexOf(client['id']) > -1) {
              if (existingClientIndex < 0) {
                client['roles'] = {};
                this.allClients.push(client);
              }
            }
          }
        });
      }
    });

    this.loadingClients = false;

    // Add the Current Users Clients to the selected Client list
    if (!this.isNewUser()) {
      const selectedUserClients = this.selectedUser.clients ? Object.keys(this.selectedUser.clients) : null;
      if (selectedUserClients !== null && selectedUserClients.length > 0) {
        selectedUserClients.forEach(clientId => {
          this.allClients.forEach(client => {
            if (clientId === client.id) {
              this.selectedClientMap.set(client.id, client);
              this.userClients.set(client.id, client.name);
              this.companyIds.add(client['companyId']);
            }
          });
        });
      }

      const selectedUserRoles = this.selectedUser.roles ? Object.keys(this.selectedUser.roles) : null;
      if (selectedUserRoles !== null && selectedUserRoles.length > 0) {
        selectedUserRoles.forEach(clientId => {
          this.selectedUser.roles[clientId].forEach(roleName => {
            this.addRole(true, roleName, clientId);
          });
        });

        // reset changed status
        this.rolesChanged = false;
      }
    }
  }

  filterOutDeletedClients() {
    if (this.selectedUser) {
    Object.keys(this.selectedUser.clients).forEach(clientId => {
      if (this.allClients.filter(c => c.id === clientId)?.length < 1) {
        this.userRolesMap.delete(clientId);
        this.userClients.delete(clientId);
      }
    });
  }
}

  loadClientErrorHandler() {
    this.loadingClients = false;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
   }

  closeModal() {
    this.close.emit();
  }

  isNewUser() {
    return (!!!this.selectedUser);
  }

  private createUserFormGroup(user: any) {
    return new FormGroup({
      // tslint:disable-next-line:max-line-length
      name: new FormControl({value: !!user && !!user.userName ? user.userName : null, disabled: !!!this.isNewUser()}, [Validators.required, Validators.pattern(this.usernamePattern)]),
      email: new FormControl({value: !!user && !!user.email ? user.email : null, disabled: false}, [Validators.email]),
      firstName: new FormControl({value: !!user && !!user.firstName ? user.firstName : null, disabled: false}),
      lastName: new FormControl({value: !!user && !!user.lastName ? user.lastName : null, disabled: false}),
      jiraTrackingId: new FormControl({value: !!user && !!user.jiraTrackingId ? user.jiraTrackingId : null,disabled: false})
    });
  }

  onAddClient(client: any) {
    if (!this.isSelectedClient(client.id)) {
      this.selectedClientMap.set(client.id, client);
      this.userClients.set(client.id, client.name);
      this.companyIds.add(client['companyId']);
    }

    this.userForm.markAsDirty();
    this.rolesChanged = true;
  }

  onRemoveClient(client: any) {
    if (this.isSelectedClient(client.key)) {
      this.selectedClientMap.delete(client.key);
      this.userRolesMap.delete(client.key);
      this.userClients.delete(client.key);

      this.companyIds.delete(client['companyId']);
    }

    this.userForm.markAsDirty();
    this.rolesChanged = true;
  }

  isSelectedClient(key: string) {
    return(this.selectedClientMap.has(key));
  }

  onSubmit() {
    if (!this.isNewUser()) {
      this.updateUser();
    } else {
      if (this.userForm.valid) {
        this.saveUser();
      } else {
        this.clrForm.markAsDirty();
      }
    }
  }

  mapToObj(clientMap: any): Object {
    const jsonObject = {};
    clientMap.forEach((value, key) => {
      jsonObject[key] = value;
    });
    return jsonObject;
  }

  objToMap(jsonObject: Object): Map<string, string> {
    const clientMap: Map<string, string> = new Map<string, string>();
    Object.keys(jsonObject).forEach (k => {
       clientMap.set(k, jsonObject[k]);
    });
    return clientMap;
  }

  mapToString(clientMap: Map<string, string>): String {
    return JSON.stringify(
      Array.from(clientMap.entries()).reduce((o, [key, value]) => {
          o[key] = value;
          return o;
        },
        {})
    );
  }

  saveUser() {
    const user = this.getUserPayload();
    this.processingModalRequest = true;

    UserMethods.createUser(user).subscribe(response  => {
        this.processingModalRequest = false;
        this.changed.emit(user);
        this.closeModal();
        this.alertService.showSuccessToast('Success', `user ${user.userName} successfully created`);
    },
    error => { // Error for create user
      this.processingModalRequest = false;
      if (error.status === 403) {
        this.alertService.showError('Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [CREATE User].`);
      } else {
        this.alertService.showError('Error', `Unable to create user '${user.userName}'\n${error.error.message}`);
      }
    });
  }

  updateUser() {
    const user = this.getUserPayload();
    this.processingModalRequest = true;

    UserMethods.updateUser(user).subscribe(response  => {
        UserMethods.getUserProfile(false).then(() => {
          this.processingModalRequest = false;
          this.closeModal();
          this.alertService.showSuccessToast('Success', `user ${user.userName} successfully updated`);
        }, error => {
          this.processingModalRequest = false;
          this.closeModal();
        });
    },
    error => { // Error for create user
      this.processingModalRequest = false;
      if (error.status === 403) {
        this.alertService.showError('Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [UPDATE User].`);
      } else {
        this.alertService.showError('Error', `Unable to update user '${user.userName}'\n${error.error.message}`);
      }
    });
  }

  getUserPayload() {
    this.filterOutDeletedClients();

    return {
      accountId: this.userForm.get('name').value.trim(),
      userName: this.userForm.get('name').value.trim(),
      email: this.userForm.get('email').value,
      firstName: this.userForm.get('firstName').value,
      lastName: this.userForm.get('lastName').value,
      jiraTrackingId: this.userForm.get('jiraTrackingId').value,
      clients: this.mapToObj(this.userClients),
      roles: this.mapToObj(this.userRolesMap),
      companyId: this.companyIds != null && this.companyIds.size > 0 ? this.companyIds.values().next().value : ''
    };
  }

  navigateToEditClientRole(clientId: string, roleName?: string) {
    const routerState = {
      state: {
        clientId: clientId,
        roleName: roleName
      }
    };

    if (this.rolesChanged) {
      // tslint:disable-next-line:max-line-length
      this.alertService.confirmAction('Are you sure you want to leave this page?', 'All unsaved changes will be lost. Click ok to continue?').then(confirmed => {
        if (confirmed) {

          this.goToClientModal(routerState);
        }
      });
    } else {
      this.goToClientModal(routerState);
    }
  }

  goToClientModal(routerState) {
    this.router.navigateByUrl('/admin/client-admin', routerState);
  }

  editRole(roleToDisplayKey: string) {
    this.navigateToEditClientRole(this.selectedClientId, roleToDisplayKey);
  }

  displayRolePerms(role: any, clientId: string) {
    this.roleToDisplayKey = role.key;
    this.roleToDisplayValue = role.value;
    this.selectedClientId = clientId;
  }

  notify(event: any) {
    this.copyIdToClipboard = event;

    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      this.copyIdToClipboard = '';
    }, 3000);
  }

  unsorted() {}
}
