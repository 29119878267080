import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import moment from 'moment';

@Component({
  selector: 'date-filter',
  template: `
    <input type="date" clrDate name="date-filter" [formControl]="control"/>
    <button class="btn btn-sm btn-block btn-clear-filter" (click)="clearFilter()">Clear</button>
  `,
  styles: [`
    .btn-clear-filter {
      margin-top: 0.5rem;
    }
  `],
})
export class DateFilter implements OnInit, OnDestroy {
  @Input() propertyName: string = null;
  control: FormControl = new FormControl(null);

  private _changes = new Subject<any>();
  subscriptions: Subscription = new Subscription();

  // We do not want to expose the Subject itself, but the Observable which is read-only
  public get changes(): Observable<any> {
    return this._changes.asObservable();
  }

  ngOnInit(): void {
    this.subscriptions.add(this.control.valueChanges.subscribe(value => {
      this._changes.next(true);
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  clearFilter() {
    this.control.setValue(null);
    this._changes.next(true);
  }

  accepts(item: any) {
    const inputDateString = this.control.value ? this.control.value.toLowerCase().trim() : this.control.value;
    if (!inputDateString) {
      return true;
    }
    if (!item[this.propertyName]) {
      return false;
    }
    const inputMoment = moment(this.control.value);
    const itemMoment = moment(item[this.propertyName]);
    const matchMoment = itemMoment.isSame(inputMoment, 'day');

    return matchMoment
      || itemMoment.format().toLowerCase().startsWith(inputDateString)
      || itemMoment.format('L').toLowerCase().startsWith(inputDateString)
      || itemMoment.format('l').toLowerCase().startsWith(inputDateString)
      || itemMoment.format('LL').toLowerCase().startsWith(inputDateString)
      || itemMoment.format('ll').toLowerCase().startsWith(inputDateString)
      || itemMoment.format('LLLL').toLowerCase().startsWith(inputDateString)
      || itemMoment.format('M/DD/Y').toLowerCase().startsWith(inputDateString)
      || itemMoment.format('M/DD/YY').toLowerCase().startsWith(inputDateString)
      || itemMoment.format('M/D/YY').toLowerCase().startsWith(inputDateString);
  }

  isActive(): boolean {
    return this.control.value && this.control.value.trim();
  }
}
