import {Component, OnDestroy, OnInit} from '@angular/core';
import {fadeInLeftAnimation} from '../../animations';
import {IimUser} from 'ui-sdk/models/iim-user.model';
import {Subscription} from 'rxjs';
import {AuthenticationService, UserMethods} from 'ui-sdk';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss'],
  animations: [fadeInLeftAnimation]
})
export class DashboardPageComponent implements OnInit, OnDestroy {
  currentUser: IimUser;
  lastLoginTimestamp: Date = new Date();
  subscriptions: Subscription = new Subscription();

  constructor(private authService: AuthenticationService) { }

  ngOnInit() {
    UserMethods.currentUser$.subscribe((user: any) => {
      this.currentUser = Object.assign({}, user);
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}