import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {ClrDatagridSortOrder} from '@clr/angular';
import { faSortAmountDown } from '@fortawesome/free-solid-svg-icons';
import { CSVExportService } from '../../services/export-csv.service';
import { AlertService } from '../../services/alert.service';
import { IimUser } from 'ui-sdk/models/iim-user.model';
import { Subscription } from 'rxjs';
import { ClientService } from 'src/app/services/client.service';
import { CustomDocumentComparator } from './comparators/customdocument.comparator';
import {AuthClass, DocumentMethods, environment, HttpMethodsEnum} from 'ui-sdk';
import {SearchMethods} from 'ui-sdk';
import { v4 as uuidv4 } from 'uuid';
import {Router} from '@angular/router';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnDestroy {
  @Input()
  set initialItems(value: Array<any>) {
    this._initialItems = value;
    this.filterTableItems();
    this.extractAllSearchIndexes();
  }

  get initialItems(): Array<any> {
    return this._initialItems;
  }

  constructor(private alertService: AlertService,
              private csvExportService: CSVExportService,
              private clientService: ClientService,
              private router: Router) { }

  private _initialItems: Array<any> = [];

  legalHoldsInfo: Object;
  openLegalHoldInfoModal: boolean = false;
  multipleFileDownloadModal_enabled: boolean = false;
  createLegalHoldModal_enabled: boolean = false;
  documentDataModal: any = {};

  JWT: string = localStorage.getItem('IIM-auth-token');

  height = (screen.height / 2) + 150;
  width = (screen.width / 2) - 100;
  top = (screen.height / 2) - (this.height / 3);
  left = (screen.width / 2) - (this.width / 1.5);

  @Input() loading: boolean;
  @Input() sortKeyForLocalStorage: string;
  @Input() clientDocumentTypes = {};
  @Input() searchIndexesTable: Array<any> = [];
  @Input() currentUser: IimUser;
  @Input() searchIndexesPayload;
  @Input() serverPaginationRes;
  @Input() searchResultsConfig;
  subscriptions: Subscription = new Subscription();


  tableSort: {};
  customTableSort = new Map();
  tableControls: { propertiesFilter: FormControl, pageSize: FormControl, textFilter: FormControl } = {
    propertiesFilter: new FormControl([]),
    pageSize: new FormControl(25),
    textFilter: new FormControl('')
  };

  currentPage: number;
  currentPageOffset: number = 0;
  pageOffsetStep: number = 0;
  nextLoading: boolean = false;
  previousLoading: boolean = false;
  oldItemsPerPage: number = 25;
  totalItemsRetrieved: number = 0;
  isMaxItemsReached: boolean = false;

  documents: { selected: Array<any>; current: Array<any> } = {
    selected: [],
    current: []
  };

  additionalDataLoadingId: string = '';

  tableFilters: { properties: Array<{ key: string, value: any }>, text: string } = {
    properties: [],
    text: ''
  };
  hasSavedSort: boolean = false; // If sort configuration is saved in LocalStorage or not.
  saveSortIcon = faSortAmountDown;
  documentPreviewContent: any;
  actionsInProgress: {view: string, download: string, legalHoldInfo: object} = {
    view: '',
    download: '',
    legalHoldInfo: {}
  };

  columnIndexesOrig = [];
  columnIndexesDisplay = [];

  onPrevious(pagination) {
    if (pagination.currentPage > 1) {
      this.currentPage = pagination.currentPage - 1;
    } else {
      this.getNextSearchResults('previous', pagination);
    }
  }

  onNext(pagination) {
    if (pagination.currentPage < pagination.lastPage) {
      this.currentPage = pagination.currentPage + 1;
    } else {
      this.getNextSearchResults('next', pagination);
    }
  }

  isNextEnable(pagination) {
    if ((pagination.currentPage < pagination.lastPage) || (this.serverPaginationRes && this.serverPaginationRes.nextToken)) {
      return false;
    }
    return true;
  }

  setTotalItemsRetrieved() {
    this.totalItemsRetrieved = (this.currentPageOffset * this.tableControls.pageSize.value) + this.initialItems.length;
  }

  getNextSearchResults(action, pagination) {
    const paginationOldLastPage = JSON.parse(JSON.stringify(pagination.lastPage));


    const payload: any = { query: this.searchIndexesPayload };
    if (action === 'next') {
      this.nextLoading = true;
      payload.pagination = {};
      payload.pagination.nextToken = this.serverPaginationRes.nextToken;
      payload.pagination.hash = this.serverPaginationRes.hash;
    } else if (action === 'previous') {
      this.previousLoading = true;
      payload.pagination = {};
      payload.pagination.previousToken = this.serverPaginationRes.previousToken;
      payload.pagination.hash = this.serverPaginationRes.hash;
    }
    SearchMethods.documentSearch(payload).then((searchRes: any) => {
      if (searchRes.results.length < 1) {
        const [title, msg] = ['Warning', 'No Documents match the specified search criteria. Please try again.'];
        this.alertService.showWarning(title, msg);
        this.nextLoading = false;
        this.previousLoading = false;
      } else {
        this.initialItems = searchRes.results;
        this.serverPaginationRes = searchRes['pagination'] ? searchRes['pagination'] : {};
        if (action === 'previous') {
          this.currentPageOffset = this.currentPageOffset - this.pageOffsetStep;
           this.currentPage = Math.ceil(this.initialItems.length / this.tableControls.pageSize.value);
          this.serverPaginationRes.currentPage = this.currentPage;
        } else if (action === 'refresh') {
           this.currentPage = 1;
        } else if (action === 'next') {
          this.currentPageOffset = this.currentPageOffset + paginationOldLastPage;
          this.pageOffsetStep = paginationOldLastPage;
           this.currentPage = 1;
        }
        pagination.currentPage = this.currentPage;

        setTimeout(() => {
          if (action === 'next') {
            this.setTotalItemsRetrieved();
          }
          this.nextLoading = false;
          this.previousLoading = false;
          if (!this.serverPaginationRes?.nextToken) {
            this.isMaxItemsReached = true;
          }
        }, 0);
      }
    }, error => {
      if (error.status === 403) {
        const [title, msg] = ['Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [CREATE DocumentSearch].`];
        this.alertService.showError(title, msg);
      } else {
        const [title, msg] = ['Error', 'Unable to retrieve documents. Please refresh the page and try again.'];
        this.alertService.showError(title, msg);
        this.nextLoading = false;
        this.previousLoading = false;
      }
    });
  }

  OrderByDefaultOrder = (): number => 0;

  ngOnInit() {
    this.clearSort();
    this.getSavedSort();
    this.onChanges();
    this.filterTableItems();
    this.setComparator();
    this.tableControls.pageSize.valueChanges.subscribe(value => {
      if (this.currentPageOffset !== 0) {
        const d = value / this.oldItemsPerPage;
        this.currentPageOffset = this.currentPageOffset / d;
        this.pageOffsetStep = this.pageOffsetStep / d;
      }
      this.oldItemsPerPage = value;
    });
    this.setTotalItemsRetrieved();
    if (!this.serverPaginationRes?.nextToken) {
      this.isMaxItemsReached = true;
    }
  }

  getComparator(columnName: string) {
    return this.customTableSort.get(columnName);
  }

  setComparator() {
    this.columnIndexesOrig.forEach(element => {
      const documentCustomComparator = new CustomDocumentComparator();
      documentCustomComparator.setPropertyName(element.key);
      this.customTableSort.set(element.key, documentCustomComparator);
    });
  }

  openCreateLegalHoldModal() {
    this.createLegalHoldModal_enabled = true;
  }

  extractAllSearchIndexes() {
    this.columnIndexesOrig = [];
    this.columnIndexesOrig = JSON.parse(JSON.stringify(this.searchIndexesTable));
    this.clearSort();

    if (this.initialItems) {
      this.initialItems.forEach(documentResult => {
          this.columnIndexesOrig.forEach(indexObj => {
            const indexTable = indexObj['key'];

            if (!documentResult.hasOwnProperty('metadata') || Object.entries(documentResult['metadata']).length === 0) {
              // No Indexes Property (or empty)? Add the first one.
              documentResult['metadata'] = {};
            } else {
              // If the document result metadata array does not contain a required index,
              // Add it with a value of ~~No_Value~~
              const index = indexTable.split(documentResult.documentTypeName);
              let key = index[1] ? `indexes.${index[1]}` : `indexes.${index[0]}`;
              key = key .split(' ').join('');
              if (!documentResult.metadata.hasOwnProperty(key)) {
                if (this.searchResultsConfig['actionButtons'] === 'viewBatchAndGenerateReport' && indexObj.isAdvancedSearch === true) {
                  delete documentResult.metadata[key];
                } else {
                  documentResult.metadata[`indexes.${indexTable}`] = '~~No_Value~~';
                }
              } else {
                documentResult.metadata[`indexes.${indexTable}`] = documentResult.metadata[key];
                delete documentResult.metadata[key];
              }
            }
        });
            documentResult['UI_UUID'] = uuidv4();
      });

      this.columnIndexesDisplay = [];
      if (this.searchResultsConfig && this.searchResultsConfig['actionButtons'] === 'viewBatchAndGenerateReport') {
        this.columnIndexesOrig.forEach(index => {
          if (!index.isAdvancedSearch) {
            this.columnIndexesDisplay.push(index);
          }
        });
      } else {
        this.columnIndexesDisplay = [...this.columnIndexesOrig];
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onChanges() {
    const tableFilterChanges$ = this.tableControls.textFilter.valueChanges.subscribe(q => {
      this.filterTableItemsBySearchQuery(q);
    });
    this.subscriptions.add(tableFilterChanges$);

    this.extractAllSearchIndexes();
  }

  getSavedSort(): void {
    const savedTableSort = localStorage.getItem(this.sortKeyForLocalStorage);
    if (savedTableSort) {
      this.tableSort = JSON.parse(savedTableSort);
      this.hasSavedSort = true;
    }
  }

  saveSort(): void {
    const title = 'Feature Coming Soon';
    const msg = `Please be patient, we'll get there in no time!`;
    this.alertService.showSuccess(title, msg);
  }

  clearSort(): void {
    // Initialze a sort key for each of the keys in the current documents
    this.tableSort = { uploadDate: ClrDatagridSortOrder.DESC };
    if (this.documents.current.length > 0) {
      Object.keys(this.documents.current[0]).forEach(element => {
        if (element !== 'metadata') {
          this.tableSort[element] = ClrDatagridSortOrder.UNSORTED;
        }
      });
      // Add the Search Indexes to the tableSort
      this.columnIndexesOrig.forEach(element => {

        this.tableSort[element.key] = ClrDatagridSortOrder.UNSORTED;
      });
      localStorage.removeItem(this.sortKeyForLocalStorage);
      this.hasSavedSort = false;
    }
  }

  filterTableItemsBySearchQuery(q: string) {
    this.tableFilters.text = q.toLowerCase();
    this.filterTableItems();
  }

  filterTableItems(): void {
    if (this.currentUser && this.currentUser.selectedClient.uiperms.documentmetadata.update) {
      this.documents.current = this.initialItems;
    } else {
      this.documents.current = this.initialItems.filter(document => document.status === 'Active');
    }
  }

  clearDocumentFilter(): void {
    this.tableControls.propertiesFilter.reset([]);
    this.tableFilters.properties = [];
    this.filterTableItems();
  }

  exportSearchResults(): void {
      const exportOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        showLabels: true,
        showTitle: false,
        title: 'Export Search Results',
        useBom: true,
        noDownload: false,
        // Set up static metadata columns in the header
        headers: ['Document Name',
                  'Document Id',
                  'Document Type Name',
                  'Document Status',
                  'Storage Type',
                  'ClientId',
                  'Legal Hold',
                  'Media Type',
                  'Errasure'
        ]
      };

      // sort the items based on sort order
      let sortKeys = [];
      if (!!this.tableSort) {
        sortKeys = Object.keys(this.tableSort).filter(pro => {
          return this.tableSort[pro] !== 0;
       });
      }

      let sortedDocuments = (this.documents.selected &&
                             this.documents.selected.length > 0) ?
                              this.documents.selected :
                              this.documents.current;

      if (sortKeys.length > 0) {
        const sortKeyName = sortKeys[0];
        if (sortKeyName === 'uploadDate' || sortKeyName === 'expireDate') {
          if (this.tableSort[sortKeyName] > 0) {  // Is this an Ascending Sort
            sortedDocuments = sortedDocuments.sort((x, y) => {
              if (Date.parse(x[sortKeyName]) < Date.parse(y[sortKeyName])) { return -1; }
              if (Date.parse(x[sortKeyName]) > Date.parse(y[sortKeyName])) { return 1; }
              return 0;
            });
          } else {  // Else this a descending Sort
            sortedDocuments = sortedDocuments.sort((x, y) => {
              if (Date.parse(x[sortKeyName]) > Date.parse(y[sortKeyName])) { return -1; }
              if (Date.parse(x[sortKeyName]) < Date.parse(y[sortKeyName])) { return 1; }
              return 0;
            });
          }
        } else {
          if (this.tableSort[sortKeyName] > 0) {  // Is this an Ascending Sort
            sortedDocuments = sortedDocuments.sort((x, y) => {
            if (!!!x[sortKeyName]) {
              x = x.indexes;
              y = y.indexes;
            }
            if (typeof(x[sortKeyName]) === 'string' || typeof(x[sortKeyName] === 'number')) {
                  if (x[sortKeyName] < y[sortKeyName]) { return -1; }
                if (x[sortKeyName] > y[sortKeyName]) { return 1; }
                } else {
                  if (x[sortKeyName].toString() < y[sortKeyName].toString()) { return -1; }
                  if (x[sortKeyName].toString() > y[sortKeyName].toString()) { return 1; }
                }
              return 0;
            });
          } else {  // Else this a descending Sort
            sortedDocuments = sortedDocuments.sort((x, y) => {
              if (typeof(x[sortKeyName]) === 'string' || typeof(x[sortKeyName] === 'number')) {
                if (x[sortKeyName] > y[sortKeyName]) { return -1; }
                if (x[sortKeyName] < y[sortKeyName]) { return 1; }
              } else {
                if (x[sortKeyName].toString() > y[sortKeyName].toString()) { return -1; }
                if (x[sortKeyName].toString() < y[sortKeyName].toString()) { return 1; }
              }
              return 0;
            });
          }
        }
      }

      function zeroPad(num, places) {
        return String(num).padStart(places, '0');
      }

      const exportDocuments = [];

      sortedDocuments.forEach(element => {
        const document = {};
        document['Name'] = element.fileNames ? element.fileNames.join(' , ') : '-';
        document['DocumentId'] = element.id ? element.id : '-';
        document['DocumentTypeName'] = element.documentTypeName ? element.documentTypeName : '-';
        document['DocumentStatus'] = element.status ? element.status : '-';
        document['StorageType'] = element.storageType ? element.storageType : '-';

        // tslint:disable-next-line:max-line-length
        document['ClientId'] = element.clientId ? element.clientId : '-';
        document['LegalHold'] = !!element.legalHold ? element.legalHold : false;
        document['MediaType'] = element.mediaType ? element.mediaType : '-';
        document['Errasure'] = !!element.errasure ? element.errasure : false;
        let searchKeyIndex = 0;
        if (!!element.metadata) {
          Object.keys(element.metadata).sort().forEach(key => {
            document[`INDEX${zeroPad(searchKeyIndex, 4)}_${key}`] =
              (element.metadata[key] && element.metadata[key] !== '~~No_Value~~' ? element.metadata[key] : '');

            searchKeyIndex++;
          });
        }
        exportDocuments.push(document);
      });

      //  Add Search Index Fields to the Header
      this.columnIndexesOrig.forEach(index => {
        exportOptions.headers.push(index['label']);
      });

      this.csvExportService.generateCsv(exportDocuments, 'export', exportOptions);
  }

  documentStatusToggle(document: any, isActive: boolean) {
    let status: string = 'Active';

    if (!isActive) {
      status = 'Inactive';
    }
    document.status = status;

    DocumentMethods.updateDocumentStatus(document).then(updateStatusResponse => {
      if (status === 'Inactive') {
        this.alertService.showSuccessToast('Success', `${document.fileName} has been inactivated.`);
      } else {
        this.alertService.showSuccessToast('Success', `${document.fileName} has been activated.`);
      }
    }, error => {
      if (error.status === 403) {
        const [title, msg] = ['Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [UPDATE DocumentStatus].`];
        this.alertService.showError(title, msg);
      } else {
        const [title, msg] = ['Error', 'Unable to update status. Please try again.'];
        this.alertService.showError(title, msg);
      }
    });
  }

  downloadContent(documentInfo: any) {
    if (documentInfo.fileNames.length > 1) {
      this.multipleFileDownloadModal_enabled = true;
      this.documentDataModal = documentInfo;
    } else {
      this.downloadSingleFile({document: documentInfo, fileName: documentInfo.fileName, singleFile: true});
    }
  }

  downloadSingleFile({document, fileName,  singleFile}) {
      this.actionsInProgress.download = singleFile ? document.UI_UUID : fileName;
      const type = document['mediaType'];
      let url = '';

      // tslint:disable-next-line:max-line-length
      DocumentMethods.getPresignedUrl(document.id, document.documentTypeId, fileName, document.mediaType).then(presignedUrlResponse => {
        url = presignedUrlResponse['downloadUrl'];

      DocumentMethods.getDocumentContent(url).then(contentResponse => {
        const a = window.document.createElement('a');
        window.document.body.appendChild(a);
        a.style.display = 'none';
        a.href = window.URL.createObjectURL(
          new Blob([contentResponse], { type: type })
        );
        fileName = fileName.replaceAll('[', '').replaceAll(']', '').replaceAll('_', '').replaceAll('"', '');
        a.setAttribute('download', fileName);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        this.actionsInProgress.download = '';
      }, error => {
        this.actionsInProgress.download = '';
      });
    }, error => {
      this.actionsInProgress.download = '';
    });
  }

  viewSingleFile({document, fileName, singleFile}) {
    this.actionsInProgress.view = singleFile ? document.UI_UUID : fileName;
    let url = '';

    // tslint:disable-next-line:max-line-length
    DocumentMethods.getPresignedUrl(document.id, document.documentTypeId, fileName, document.mediaType).then(presignedUrlResponse => {
      url = presignedUrlResponse['downloadUrl'];
      window.open(url, '_blank');
      this.actionsInProgress.view = '';
    }, error => {
      this.actionsInProgress.view = '';
    });
  }

  actionButton1(rowObject) {
    if (this.searchResultsConfig && this.searchResultsConfig['actionButtons'] === 'viewBatchAndGenerateReport') {
      this.viewBatchTransactions(rowObject);

    } else {
      this.viewDocument(rowObject);
    }
  }

  actionButton2(rowObject) {
    if (this.searchResultsConfig && this.searchResultsConfig['actionButtons'] === 'viewBatchAndGenerateReport') {
      this.generateBatchReport(rowObject);

    } else {
      this.downloadContent(rowObject);
    }
  }

  viewBatchTransactions(batch) {
    this.actionsInProgress.view = batch.UI_UUID;

    const documentTypeName = batch.documentTypeName;
    const documentTypeId = batch.documentTypeId;
    let batchId;

    const metadataKeys = Object.keys(batch.metadata);
    for (let i = 0; i < metadataKeys?.length; i++) {
      const key = metadataKeys[i];

      if (key.toLowerCase().includes('batchid')) {
        batchId = batch.metadata[key];
        break;
      }
    }

    this.getBatchReportByBatchId(documentTypeId, batchId).then(res => {
      // batch report exists in S3
      if (res?.batchReportId && res?.transactionCount > 0) {
        this.navigateToBatchTransactionsPage(res?.batchReportId, res?.transactionCount, batchId, documentTypeId, documentTypeName);

      } else {
        this.navigateToBatchTransactionsPage(res?.batchReportId, 0, batchId, documentTypeId, documentTypeName);
      }

      this.actionsInProgress.view = '';
    });
  }

  generateBatchReport(batch: any) {
    this.actionsInProgress.download = batch.UI_UUID;

    const metadataSearchIndexKeys = Object.keys(batch?.metadata);
    let batchId = null;

    if (metadataSearchIndexKeys?.length > 0) {
      for (let i = 0; i < metadataSearchIndexKeys?.length; i++) {
        if (metadataSearchIndexKeys[i].toLowerCase().includes('batchid')) {
          batchId = batch?.metadata[metadataSearchIndexKeys[i]];
          break;
        }
      }
    }

    if (batchId !== null) {
      const requestBody = {
        batchId: batchId
      };

      this.createBatchReport(batch, requestBody).then(res => {
        this.actionsInProgress.download = '';

        const [title, msg] = ['Success', `Report for Batch '${batchId}' has been submitted for creation. Please click 'OK' if you wish to view your report on the Report page. Otherwise you can click 'Cancel' to stay on the same page. \n\n Note: Multiple reports can be submitted for creation at one time, and  all can be viewed on the Report page.`];
        this.alertService.confirmAction(title, msg).then(confirmed => {
          if (confirmed) {
            this.navigateToReportPage();
          }
        });

      }, error => {
        if (error.status === 403) {
          const [title, msg] = ['Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [CREATE BatchReport].`];
          this.alertService.showError(title, msg);
        } else {
          this.alertService.showError('Error', 'Error occurred while attempting to create a batch report');
        }

        this.actionsInProgress.download = '';
      });

    } else {
      this.alertService.showError('Error', 'Batch ID not found in the metadata.');
      this.actionsInProgress.download = '';
    }
  }

  createBatchReport(batch, requestBody): Promise<any> {
      const urlPath = `${environment.baseUrl}/client/${batch.clientId}/documentType/${batch.documentTypeId}/batchReport`;

      return AuthClass.doAuthenticatedCall(HttpMethodsEnum.POST, urlPath, requestBody).toPromise();
  }

  viewDocument(documentInfo: any) {
    if (documentInfo.fileNames.length > 1) {
      this.multipleFileDownloadModal_enabled = true;
      this.documentDataModal = documentInfo;
    } else {
      this.viewSingleFile({ document: documentInfo, fileName: documentInfo.fileName, singleFile: true });
    }
  }

  getLegalHoldInfoModal(documentId) {
    this.actionsInProgress.legalHoldInfo[documentId] = true;
    const getLegalHoldPayload = {
      documentIds: [documentId]
    };

    this.clientService.getLegalHoldInfo(getLegalHoldPayload).subscribe(legalHoldsResponse  => {
      this.legalHoldsInfo = legalHoldsResponse;
      this.openLegalHoldInfoModal = true;
      delete this.actionsInProgress.legalHoldInfo[documentId];
    }, error => {
      if (error.status === 403) {
        this.alertService.showError('Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [GET LegalHoldInfo].`);
      } else {
        this.alertService.showError('Error', `Unable to fetch Legal Hold Information for selected document.`);
      }
    });
  }

  isUrl(searchIndexValue): boolean {
    // tslint:disable-next-line:max-line-length
    const regexp = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

    return regexp.test(searchIndexValue);
  }

  getAdditionalData(isRowExpanded: boolean, document: Object) {
    if (isRowExpanded) {
      this.additionalDataLoadingId = document['id'];

      DocumentMethods.getAdditionalData(document['documentTypeId'], document['id']).then(additionalData => {
        this.documents.current.forEach(docObject => {
          if (docObject['id'] === document['id']) {
            docObject['additionalData'] = additionalData;
          }
        });

        this.additionalDataLoadingId = '';
      }, error => {
        if (error.status === 403) {
          const [title, msg] = ['Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [GET AdditionalData].`];
          this.alertService.showError(title, msg);
        } else if (error.status !== 400 && !error.message.includes('Additional data not found')) {
          const [title, msg] = ['Error', 'Unable to load additional data. Please try again.'];
          this.alertService.showError(title, msg);
        }

        this.additionalDataLoadingId = '';
      });
    }
  }

  navigateToReportPage() {
    this.router.navigateByUrl('/report');
  }

  navigateToBatchTransactionsPage(batchReportId: string, transactionCount: number, batchId: string, documentTypeId: string, documentTypeName: string) {
    const params = {};

    if (batchReportId !== null) {
      params['batchReportId'] = batchReportId;
    }
    if (transactionCount > 0) {
      params['transactionCount'] = transactionCount;
    }
    if (batchId !== null) {
      params['batchId'] = batchId;
    }
    if (documentTypeId !== null) {
      params['documentTypeId'] = documentTypeId;
    }
    if (documentTypeName !== null) {
      params['documentTypeName'] = documentTypeName;
    }

    const searchIndexesRedirect = [];
    this.columnIndexesOrig.forEach(index => {
      searchIndexesRedirect.push({
        key: index.key.substring(index.key.indexOf(documentTypeName) + documentTypeName.length),
        name: index.name.substring(index.name.indexOf(documentTypeName) + documentTypeName.length),
        label: index.label,
        dataType: index.dataType
      });
    });

    localStorage.setItem(`${batchId}_indexes`, JSON.stringify(searchIndexesRedirect));

    const batchUrl = this.router.serializeUrl(
      this.router.createUrlTree(['/batch-transactions'], {queryParams: params})
    );

    window.open(batchUrl, '_blank');
  }

  getBatchReportByBatchId(documentTypeId, batchId): Promise<any> {
    const urlPath = `${environment.baseUrl}/client/${this.currentUser.selectedClient.clientId}/documentType/${documentTypeId}/batchReport?batchId=${batchId}`;

    return AuthClass.doAuthenticatedCall(HttpMethodsEnum.GET, urlPath).toPromise();
  }

}
