import { Directive, Output, EventEmitter, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[appDnd]'
})

export class DndDirective {

  constructor() { }

  @Output() onFileDropped = new EventEmitter<any>();

  @HostBinding('style.background-color') public background = '#ffffff';
  @HostBinding('style.opacity') public opacity = '1';

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    this.background = '#9ecbec';
    this.opacity = '0.8';
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#ffffff';
    this.opacity = '1';
  }

  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#f5fcff';
    this.opacity = '1';
    const files: Array<File> = evt.dataTransfer.files;

    for (let i = 0; i < files.length; i++) {
      this.onFileDropped.emit(files[i]);
    }
  }
}
