<div [@fadeInLeft]>
  <div class="clr-row users-header-container">
    <div class="clr-col-xs-12 clr-col-sm-12 users-header-item">
      <button *ngIf="currentLoginUser?.selectedClient?.uiperms?.users.create" (click)="addNewUser()" class="btn btn-link btn-no-caps"><clr-icon shape="plus-circle"></clr-icon>&nbsp;New User</button>
    </div>
    <div class="clr-col-xs-12 clr-col-md users-header-item users-header-item-end">
      Show
      <div class="clr-select-wrapper">
        <select class="clr-select" name="pageAmount" id="pageAmount" [formControl]="tableControls.pageSize">
          <option [ngValue]="10">10</option>
          <option [ngValue]="25">25</option>
          <option [ngValue]="50">50</option>
        </select>
      </div>
      items per page
    </div>
  </div>

  <div class="clr-row">
    <div class="clr-col-xs-12 clr-col-sm-12">
      <clr-datagrid [clrDgLoading]="users.loading" class="main-dg">
        <clr-dg-column class="users-item-align-cells " [clrDgField]="'userName'">
          User Name
          <clr-dg-filter [clrDgFilter]="userName">
            <text-filter #userName propertyName="userName"></text-filter>
          </clr-dg-filter>
        </clr-dg-column>
        <clr-dg-column class="users-item-align-cells " [clrDgField]="'email'">
          Email Address
          <clr-dg-filter [clrDgFilter]="email">
            <text-filter #email propertyName="email"></text-filter>
          </clr-dg-filter>
        </clr-dg-column>
        <clr-dg-column class="table-actions" *ngIf="currentLoginUser?.selectedClient?.uiperms?.users.delete || currentLoginUser?.selectedClient?.uiperms?.users.update">
          Actions
        </clr-dg-column>

        <clr-dg-row *clrDgItems="let user of users.current" [clrDgItem]="user">
          <clr-dg-cell class="users-item-align-cells">
            <button class="btn btn-icon btn-link copy-to-clipboard-btn" title="Copy User Name" [appCopyClipboard]="user.userName" (copied)="notify($event)">
              <clr-icon class="copy-to-clipboard-icon" shape="copy-to-clipboard"></clr-icon>
            </button>

            <span class="cell-text">
              {{user.userName}}
            </span>

            <span *ngIf="user.userName == copyIdToClipboard" class="clipboard-copy-success">
              <clr-icon shape="check" size="14"></clr-icon>
              Copied
            </span>
          </clr-dg-cell>
          <clr-dg-cell class="users-item-align-cells">{{user.email}}</clr-dg-cell>

          <clr-dg-cell class="table-actions" *ngIf="currentLoginUser?.selectedClient?.uiperms?.users.delete || currentLoginUser?.permissions?.users.update" >
            <div class="btn-group btn-link btn-icon">
              <button [title]="'Edit User Profile'" *ngIf="currentLoginUser?.selectedClient?.uiperms?.users.update" [disabled]="user.status !== 'Active'" class="btn btn-no-caps" (click)="editUserProfile(user)">
                <clr-icon shape="pencil"></clr-icon>
              </button>
              <button [title]="'Delete User'" *ngIf="currentLoginUser?.selectedClient?.uiperms?.users.delete"  [disabled]="user.status !== 'Active'" class="btn btn-no-caps" (click)="removeUser(user)">
                <clr-icon shape="trash"></clr-icon>
              </button>
            </div>
          </clr-dg-cell>
        </clr-dg-row>

        <clr-dg-footer>
          <clr-dg-pagination #pagination [clrDgPageSize]="tableControls.pageSize.value">
            {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
            of {{pagination.totalItems}} users
          </clr-dg-pagination>
        </clr-dg-footer>
      </clr-datagrid>
    </div>
  </div>
</div>
<div *ngIf="usersModal_enabled">
  <app-user-modal (close)="closeModal()" (changed)="onUsersChanged()" [selectedUser]="currentWorkingUser"></app-user-modal>
</div>
