import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'boolean-filter',
  template: `
    <clr-radio-container clrInline>
      <clr-radio-wrapper>
        <input type="radio" clrRadio name="options" [value]="true" [formControl]="control"/>
        <label>Yes</label>
      </clr-radio-wrapper>
      <clr-radio-wrapper>
        <input type="radio" clrRadio name="options" [value]="false" [formControl]="control"/>
        <label>No</label>
      </clr-radio-wrapper>
    </clr-radio-container>
    <button class="btn btn-sm btn-block btn-clear-filter" (click)="clearFilter()">Clear</button>
  `,
  styles: [`
    .clr-form-control {
      margin-top: 0;
    }

    .btn-clear-filter {
      margin-top: 0.5rem;
    }
  `],
})
export class BooleanFilter implements OnInit {
  @Input() propertyName: string = null;
  @Input() propertyValue: string = null;
  control: FormControl = new FormControl(null);

  private _changes = new Subject<any>();

  // We do not want to expose the Subject itself, but the Observable which is read-only
  public get changes(): Observable<any> {
    return this._changes.asObservable();
  }

  ngOnInit(): void {
    this.control.valueChanges.subscribe(value => {
      this._changes.next(true);
    });
  }

  clearFilter() {
    this.control.setValue(null);
    this._changes.next(true);
  }

  accepts(item: any) {
    const currentValue = this.control.value;
    if (currentValue === null) {
      return true;
    } else {
      if (typeof item[this.propertyName] === 'boolean') {
        return item[this.propertyName] === currentValue;
      } else {
        if (this.propertyName && this.propertyValue) {
          return (((item[this.propertyName] === this.propertyValue) && currentValue) ||
                  ((item[this.propertyName] !== this.propertyValue) && !currentValue) ? true : false);
        }
        return true;
      }
    }
  }

  isActive(): boolean {
    return this.control.value !== null;
  }
}
