<clr-alert id="cookie-alert" [clrAlertType]="'warning'" [clrAlertAppLevel]="true" (clrAlertClosedChange)="onClose()" [(clrAlertClosed)]="isCookieAlertClosed">
  <clr-alert-item>
        <span class="alert-text">
            Broadridge uses cookies to give you the best experience of the website. By continuing to browse, you agree to the use of Broadridge's cookies.
        </span>
    <div class="alert-actions">
      <button (click)="isCookieAlertClosed = true; onClose();" class="btn alert-action">OK</button>
    </div>
  </clr-alert-item>
</clr-alert>
