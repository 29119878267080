import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { IimUser } from 'ui-sdk/models/iim-user.model';
import { ClrDatagridSortOrder } from '@clr/angular';
import { fadeInLeftAnimation } from 'src/app/animations';
import { AlertService } from 'src/app/services/alert.service';
import { ClientService } from 'src/app/services/client.service';
import { filter } from 'rxjs/operators';
import { UserMethods } from 'ui-sdk';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-users-admin-page',
  templateUrl: './users-admin-page.component.html',
  styleUrls: ['./users-admin-page.component.scss'],
  animations: [fadeInLeftAnimation]
})

export class UsersAdminPageComponent implements OnInit, OnDestroy {
  @Output() initialization: EventEmitter<any> = new EventEmitter();

  copyIdToClipboard: string;
  timer;

  usersModal_enabled: boolean = false;

  tableSort: {'createDate': ClrDatagridSortOrder};
  currentWorkingUser: any = null;
  myProfileUserName: string;

  tableControls: { propertiesFilter: FormControl, pageSize: FormControl, textFilter: FormControl } = {
    propertiesFilter: new FormControl([]),
    pageSize: new FormControl(10),
    textFilter: new FormControl('')
  };

  subscriptions: Subscription = new Subscription();
  currentLoginUser: IimUser;

  users: {initial: Array<any>, current: Array<any>, loading: boolean, status: string} = {
    initial: [],
    current: [],
    loading: false,
    status: 'Active'
  };

  constructor(private alertService: AlertService,
              private clientService: ClientService,
              private activatedRoute: ActivatedRoute) {

    this.subscriptions.add(
      activatedRoute.queryParams.subscribe(
        params => {
          if (params['userName']) {
            this.myProfileUserName = params['userName'];
          }
        }
      )
    );
  }

  ngOnInit() {
    const userSubscription = UserMethods.currentUser$.subscribe((user: IimUser) => {
      this.onUserLoaded(user);
    });
    this.subscriptions.add(userSubscription);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.currentWorkingUser = null;
  }

  onUserLoaded(user: IimUser) {
    this.currentLoginUser = user;
    if (this.currentLoginUser) {
      if (this.currentLoginUser.selectedClient.uiperms.users.read) {
        this.loadUsers();
      }
    }
  }

  loadUsers() {
    this.users.loading = true;

    const userObservable$ = UserMethods.getUsers();
    userObservable$.pipe(
      filter(users => true)
    )
    .subscribe(users => {
      this.users.loading = false;
      this.users.initial = users;

      this.users.initial.map(user => {  // Force Status to Active
        user.status = 'Active';
      });

      if (this.currentLoginUser.selectedClient.uiperms.users.update) {
        this.users.current = this.users.initial;
      } else {
        this.users.current = this.users.initial.filter(activeUsers => {
          return activeUsers.status === 'Active';
        });
      }
      this.initialization.emit();
      this.initialization.complete();

      if (this.myProfileUserName?.length > 0) {
        const myProfileUserEdit = this.users.current.filter(u => u.accountId === this.myProfileUserName);
        if (myProfileUserEdit.length > 0) {
          this.editUserProfile(myProfileUserEdit[0]);
        }
      }
    }, error => {
      this.users.loading = false;
      if (error.status === 403) {    
          const [title, msg] = ['Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [GET Users].`];
          this.alertService.showError(title, msg);      
      } 
      this.initialization.complete();
    });
  }

  removeUser(user) {
    let [title, msg] = [`Delete User`, `Are you sure you want to delete User '${user.userName}'?`];
    this.alertService.confirmDangerousAction(title, msg).then(confirmed => {
      if (confirmed) {
        UserMethods.deleteUser(user).subscribe(next => {
          const toBeDeleted = this.users.initial.indexOf(user);
          this.users.initial.splice(toBeDeleted, 1);
          this.users.current = this.users.initial;
          this.alertService.showSuccessToast(`User '${user.userName}' Deleted`, undefined);

        }, error => {
          this.users.loading = false;
          if (error.status === 403) {          
              [title, msg] = ['Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [DELETE User].`];
              this.alertService.showError(title, msg);
          } else{
            [title, msg] = ['Error', 'Could not retrieve Users. Please try again.'];
            this.alertService.showError(title, msg);
          }
        });
      }
    });
  }

  addNewUser() {
    this.currentWorkingUser = null;
    this.usersModal_enabled = true;
  }

  editUserProfile(user) {
    this.currentWorkingUser = user;
    this.usersModal_enabled = true;
  }

  onUsersChanged() {
    this.loadUsers();
  }

  closeModal() {
    this.usersModal_enabled = false;
    this.myProfileUserName = '';
  }

  notify(event: any) {
    this.copyIdToClipboard = event;

    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      this.copyIdToClipboard = '';
    }, 3000);
  }
}
