import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';
import { NotFoundPageComponent  } from './pages/notfound-page/notfound-page.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { LoaderComponent } from './components/loader/loader.component';
import { SearchPageComponent } from './pages/search-page/search-page.component';
import { TableComponent } from './components/table/table.component';
import { TableBulkActionsComponent } from './components/table-bulk-actions/table-bulk-actions.component';
import { UploadModalComponent } from './components/modals/upload-modal/upload-modal.component';
import { ClarityModule } from '@clr/angular';
import { ToastrModule } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';
import { AdminPageComponent } from './pages/admin-page/admin-page.component';
import { DocumentTypeAdminPageComponent } from './pages/document-type-admin-page/document-type-admin-page.component';
import { DocumentTypeModalComponent } from './components/modals/document-type-modal/document-type-modal.component';
import { ClientDropdownComponent } from './components/client-dropdown/client-dropdown.component';
import { CookieAlertComponent } from './components/cookie-alert/cookie-alert.component';
import { FileSizePipe } from './pipes/file-size.pipe';
import { TokenInterceptor } from './services/token.interceptor';
import { BooleanFilter } from './components/table/filters/boolean.filter';
import { ObjectKeysTextFilter } from './components/table/filters/objectKeysText.filter';
import { DateFilter } from './components/table/filters/date.filter';
import { TextFilter } from './components/table/filters/text.filter';
import { SavedSearchSelectComponent } from './components/saved-search-select/saved-search-select.component';
import { NewsListComponent } from './components/news-list/news-list.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { LegalHoldModalComponent } from './components/modals/legal-hold-modal/legal-hold-modal.component';
import { LegalHoldInfoModalComponent } from './components/modals/legal-hold-info-modal/legal-hold-info-modal.component';
import { UsersAdminPageComponent } from './pages/users-admin-page/users-admin-page.component';
import { UserModalComponent } from './components/modals/user-modal/user-modal.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ClientAdminPageComponent } from './pages/client-admin-page/client-admin-page.component';
import { ClientOnboardModalComponent } from './components/modals/client-onboard-modal/client-onboard-modal.component';
import { DndDirective } from './directives/dnd/dnd.directive';
import { SearchIndexMetadataFilter } from './components/table/filters/searchIndexMetadata.filter';
import { MultipleFileDownloadComponent } from './components/modals/multiple-file-download/multiple-file-download.component';
import { LegalHoldPageComponent } from './pages/legal-hold-page/legal-hold-page.component';
import { CreateLegalHoldModalComponent } from './components/modals/create-legal-hold-modal/create-legal-hold-modal.component';
import { PieChartComponent } from './components/piechart/piechart.component';
import { ChartsModule } from 'ng2-charts';
import { LegalHoldTableComponent } from './components/legal-hold-table/legal-hold-table.component';
import { SiteMappingPageComponent } from './pages/site-mapping-page/site-mapping-page.component';
import { ClientSelectionModalComponent } from './components/modals/client-selection-modal/client-selection-modal.component';
import { BarchartComponent } from './components/barchart/barchart.component';
import { CopyClipboardDirective } from './directives/copy-clipboard.directive';
import { DataMappingPageComponent } from './pages/data-mapping-page/data-mapping-page.component';
import { DataMappingModalComponent } from './components/modals/data-mapping-modal/data-mapping-modal.component';
import { SiteMappingModalComponent } from './components/modals/site-mapping-modal/site-mapping-modal.component';
import { DataTransformModalComponent } from './components/modals/data-transform-modal/data-transform-modal.component';
import { IconPickerModule } from 'ngx-icon-picker';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReportPageComponent } from './pages/report-page/report-page.component';
import { BatchTransactionPageComponent } from './pages/batch-transaction-page/batch-transaction-page.component';
@NgModule({
  declarations: [
    AppComponent,
    AdminPageComponent,
    BooleanFilter,
    BreadcrumbsComponent,
    DashboardPageComponent,
    DateFilter,
    FileSizePipe,
    FooterComponent,
    LoaderComponent,
    NavigationComponent,
    ObjectKeysTextFilter,
    SearchPageComponent,
    TableComponent,
    TableBulkActionsComponent,
    TextFilter,
    UploadModalComponent,
    NotFoundPageComponent,
    DocumentTypeAdminPageComponent,
    DocumentTypeModalComponent,
    ClientDropdownComponent,
    CookieAlertComponent,
    SavedSearchSelectComponent,
    NewsListComponent,
    LoginPageComponent,
    LegalHoldModalComponent,
    LegalHoldInfoModalComponent,
    UsersAdminPageComponent,
    UserModalComponent,
    ClientAdminPageComponent,
    ClientOnboardModalComponent,
    DndDirective,
    SearchIndexMetadataFilter,
    MultipleFileDownloadComponent,
    LegalHoldPageComponent,
    CreateLegalHoldModalComponent,
    PieChartComponent,
    LegalHoldTableComponent,
    ClientSelectionModalComponent,
    BarchartComponent,
    SiteMappingPageComponent,
    ClientSelectionModalComponent,
    CopyClipboardDirective,
    DataMappingPageComponent,
    DataMappingModalComponent,
    SiteMappingModalComponent,
    DataTransformModalComponent,
    ReportPageComponent,
    BatchTransactionPageComponent

  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    ClarityModule,
    FormsModule,
    HttpClientModule,
    NgSelectModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    AutocompleteLibModule,
    NgMultiSelectDropDownModule,
    ChartsModule,
    IconPickerModule,
    FontAwesomeModule
    ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule {
}
