import { Injectable } from "@angular/core";
import {
  ActivatedRoute,
  Router,
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { AlertService } from "./alert.service";
import { IimUser } from "ui-sdk/models/iim-user.model";
import { AuthenticationService } from "ui-sdk";
import { UserMethods } from "ui-sdk";

@Injectable({
  providedIn: "root",
})
export class Guard implements CanActivate {
  constructor(
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private authService: AuthenticationService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.authService.tryGetTokenFromCookies();
    return new Promise((resolve) => {
      if (route.fragment) {
        this.authService
          .tryGetTokenFromUrl(route.fragment, true)
          .then((res) => {
            UserMethods.getUserProfile()
              .then((userProfile: any) => {
                  this.router.navigate(["/"]);
                  resolve(true);
              })
              .catch((error) => {});
          })
          .catch((error) => { resolve(false);});
      } else if (
        localStorage.getItem("IIM-auth-token") != null &&
        localStorage.getItem("IIM-refresh-token")
      ) {
        UserMethods.getUserProfile()
          .then((userProfile: any) => {
              resolve(true);
          })
          .catch((error) => { resolve(false);});
      } else {
        this.router.navigate(["/login"]);
        resolve(false);
      }
    });
  }
}
