import { IimUser } from 'ui-sdk/models/iim-user.model';
import {UserMethods} from 'ui-sdk';
import { Subscription } from 'rxjs/internal/Subscription';
import { Component, OnInit, } from '@angular/core';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  currentUser: IimUser;
  subscriptions: Subscription = new Subscription();

  constructor() { }

  ngOnInit() {
    this.subscriptions.add(UserMethods.currentUser$.subscribe({
      next:  user => {
        this.currentUser = Object.assign({}, user);
      }
    }));
  }
}
