export class FileSizeCalc {
  public static bytesToSize(bytes): string {
    const neg = bytes < 0;
    const units = ['Bytes', 'KB', 'MB', 'GB'];
    if (bytes < 0) {
      bytes = -bytes;
    }
    if (bytes < 1) {
      return (neg ? '-' : '') + bytes + ' Bytes';
    }
    const exponent = Math.min(Math.floor(Math.log(bytes) / Math.log(1000)), units.length - 1);
    bytes = Number((bytes / Math.pow(1000, exponent)).toFixed(2));
    const unit = units[exponent];
    return (neg ? '-' : '') + bytes + ' ' + unit;
  }
}
