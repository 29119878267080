import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookie-alert',
  templateUrl: './cookie-alert.component.html',
  styleUrls: ['./cookie-alert.component.scss']
})
export class CookieAlertComponent implements OnInit {
  isCookieAlertClosed: boolean;

  constructor() {
    this.isCookieAlertClosed = true;
  }

  ngOnInit(): void {
    try {
      if (localStorage.getItem('cookiePopup') !== 'disabled') {
        this.isCookieAlertClosed = false;
      }
    } catch (e) {
      console.log('Cookies are disabled by your web browser. Please enable cookies for full website functionality.');
    }
  }

  onClose() {
    localStorage.setItem('cookiePopup', 'disabled');
  }
}
