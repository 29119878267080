<div class="clr-row">
    <div class="clr-col-xs-12 clr-col-md-12">
      <div *ngIf="!loading" class="clr-row additional-actions">
        <div class="clr-col-xs-12 clr-col-sm-12">
          <div class="btn-group btn-link" style="align-items: center;">
          </div>
        </div>
      </div>
      <div *ngIf="!loading" class="clr-row table-actions">
        <div class="table-actions__bulk-actions clr-col-xs-12 clr-col-md">
        </div>
        <div class="table-actions__sort clr-col-xs-12 clr-col-md">
          Show
          <div class="clr-select-wrapper">
            <select class="clr-select" name="pageAmount" id="pageAmount" [formControl]="tableControls.pageSize">
              <option [ngValue]="25">25</option>
              <option [ngValue]="50">50</option>
              <option [ngValue]="100">100</option>
              <option [ngValue]="200">200</option>
              <option [ngValue]="500">500</option>
              <option [ngValue]="1000">1000</option>
            </select>
          </div>
          items per page
        </div>
        <button [disabled]="documents.current?.length === 0" (click)="exportSearchResults()" class="btn btn-icon btn-link"
               title="Export Results"><clr-icon size="20" shape="export"></clr-icon></button>

      </div>
      <clr-datagrid [clrDgLoading]="loading">
        <clr-dg-column class="table-actions-column" *ngIf="currentUser?.selectedClient?.uiperms?.legalhold.create">
          <ng-container *clrDgHideableColumn="{hidden: false}">
            Hold Date
          </ng-container>
        </clr-dg-column>
        <clr-dg-column class="table-actions-column">
          <ng-container *clrDgHideableColumn="{hidden: false}">
            Removal Date
          </ng-container>
        </clr-dg-column>
        <clr-dg-column class="table-actions-column">
          <ng-container *clrDgHideableColumn="{hidden: false}">
              Hold Name
          </ng-container>
        </clr-dg-column>
        <clr-dg-column *ngFor="let searchIndex of columnIndexes; index as i"
                       class="table-index-column"
                      [clrDgField]="searchIndex.key"
                       [clrDgSortBy]="getComparator(searchIndex.key)">
            {{searchIndex.label}}
            <clr-dg-filter *ngIf="i+1" [clrDgFilter]="i">
              <search-index-metadata-filter #i [propertyName]="searchIndex.key" [propertyType]="searchIndex.dataType"></search-index-metadata-filter>
            </clr-dg-filter>
        </clr-dg-column>

        <clr-dg-row [ngClass]='document.status == "Active" ? "" : "text-faded"' *clrDgItems="let document of documents.current" [clrDgItem]="document">
          <clr-dg-cell class="document-type-align-cells" *ngIf="currentUser?.selectedClient?.uiperms?.legalhold.update">
          </clr-dg-cell>
          <clr-dg-cell class="document-type-align-cells">
          </clr-dg-cell>
          <clr-dg-cell class="table-actions-cell">
          </clr-dg-cell>
          <clr-dg-cell class="table-row"
                       *ngFor="let searchIndex of document.metadata | keyvalue:OrderByIndexKey"
                       title={{searchIndex.key}}>
            <b *ngIf="searchIndex.value == '~~No_Value~~'"><clr-icon shape="minus"></clr-icon></b>

            <a *ngIf="searchIndex.value !== '~~No_Value~~' && isUrl(searchIndex.value)" href="{{searchIndex.value}}" target="_blank" rel="noopener noreferrer">{{searchIndex.value}}</a>

            <b *ngIf="searchIndex.value !== '~~No_Value~~' && !isUrl(searchIndex.value)">
              {{searchIndex.value}}
            </b>
          </clr-dg-cell>

          <clr-dg-row-detail clrIfExpanded [ngClass]="clrIfExpanded ? 'row-details' : ''" (clrIfExpandedChange)="getAdditionalData($event, document)">
            <p>
              <span *ngIf="document.fileNames?.length > 0"><clr-icon shape="file"></clr-icon>&nbsp;<strong>Document Name(s): </strong> [ {{document.fileNames.join(' || ')}} ]</span>
              <span *ngIf="document.documentTypeName">&nbsp;&nbsp;  <clr-icon shape="file-settings"></clr-icon>&nbsp;<strong>Document Type:  </strong> {{document.documentTypeName}}</span>
              <span *ngIf="document.storageType">&nbsp;&nbsp;  <clr-icon shape="storage"></clr-icon>&nbsp;<strong>Storage Type:  </strong>  {{document.storageType}}</span>
              <span *ngIf="document.uploadDate">&nbsp;&nbsp;  <clr-icon shape="upload"></clr-icon>&nbsp;<strong>Upload Date:  </strong>  {{document.uploadDate | date:'fullDate'}}</span>
            </p>

            <p *ngIf="document.id == additionalDataLoadingId && !document.additionalData">
              Loading Additional Data <span class="spinner spinner-inline"></span>
            </p>

            <clr-datagrid class="additional-data-grid" *ngIf="document.additionalData && !additionalDataLoading" [clrDgLoading]="document.id == additionalDataLoadingId">
              <clr-dg-column>Additional Data</clr-dg-column>
              <clr-dg-column>Values</clr-dg-column>

              <clr-dg-row *ngFor="let additionalDataObject of document.additionalData | keyvalue">
                <clr-dg-cell>{{ additionalDataObject.key }}</clr-dg-cell>
                <clr-dg-cell>{{ additionalDataObject.value }}</clr-dg-cell>
              </clr-dg-row>
            </clr-datagrid>

          </clr-dg-row-detail>
        </clr-dg-row>

        <clr-dg-footer>
          <clr-dg-column-toggle title="Show/Hide Columns">
              <clr-dg-column-toggle-title>Show/Hide Columns</clr-dg-column-toggle-title>
              <clr-dg-column-toggle-button>Select All</clr-dg-column-toggle-button>
          </clr-dg-column-toggle>

          <div *ngIf="documents.selected.length" class="selected-rows-count">
            {{documents.selected.length}} selected
          </div>
          <clr-dg-pagination #pagination [clrDgPageSize]="tableControls.pageSize.value">
            {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
            of {{pagination.totalItems}} documents
          </clr-dg-pagination>
        </clr-dg-footer>

      </clr-datagrid>
    </div>
  </div>

  <div *ngIf="openLegalHoldInfoModal">
    <app-legal-hold-info-modal (close)="openLegalHoldInfoModal = false" [legalHoldsInfo]="legalHoldsInfo"></app-legal-hold-info-modal>
  </div>

  <div *ngIf="multipleFileDownloadModal_enabled">
    <app-multiple-file-download (close)="multipleFileDownloadModal_enabled = false" [documentData]="documentDataModal" (viewFile)="viewSingleFile($event)" (downloadFile)="downloadSingleFile($event)" [actionsInProgress]="actionsInProgress" ></app-multiple-file-download>
  </div>

  <div *ngIf="createLegalHoldModal_enabled">
    <app-create-legal-hold-modal (close)="createLegalHoldModal_enabled = false" [documentData]="documents" ></app-create-legal-hold-modal>
  </div>
