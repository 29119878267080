import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';

declare var swal: any;

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private childClickedEvent = new BehaviorSubject<string>('');

  constructor(private toastr: ToastrService) { }

  showError(title, msg): Promise<any> {
    return swal({
      title,
      text: msg,
      icon: 'error',
      buttons: {
        cancel: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'btn btn-no-caps btn-danger-outline btn-br',
          closeModal: true
        }
      }
    });
  }

  confirmAction(title, msg): Promise<boolean> {
    return swal({
      title: title,
      text: msg,
      buttons: {
        cancel: {
          text: 'Cancel',
          value: null,
          visible: true,
          className: 'btn',
          closeModal: true,
        },
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'btn btn-primary',
          closeModal: true
        }
      },
      dangerMode: false
    });
  }

  confirmWarning(title, msg): Promise<boolean> {
    return swal({
      title: title,
      text: msg,
      buttons: {
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'btn btn-primary',
          closeModal: true
        }
      },
      dangerMode: true
    });
  }

  confirmDangerousAction(title, msg, customClass?, notCloseModalOnConfirm?): Promise<boolean> {
    return swal({
      title: title,
      text: msg,
      buttons: {
        cancel: {
          text: 'Cancel',
          value: null,
          visible: true,
          className: 'btn btn-br btn-link btn-no-caps text-dark',
          closeModal: true,
        },
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'btn btn-danger-outline btn-no-caps btn-br',
          closeModal: !notCloseModalOnConfirm
        }
      },
      dangerMode: true,
      className: customClass
    });
  }

  confirmActionWithInput(title: string, msg: string, inputPlaceholder: string, initialValue?: string, customClass?: string, id?: string ): Promise<string> {
    const settings: any = {
      title: title,
      text: msg,
      content: {
        element: 'input',
        attributes: {
          placeholder: inputPlaceholder
        }
      },
      buttons: {
        cancel: {
          text: 'Cancel',
          value: null,
          visible: true,
          className: 'btn btn-no-caps btn-br btn-link',
          closeModal: true,
        },
        confirm: {
          text: 'Save',
          value: true,
          visible: true,
          className: 'btn btn-no-caps btn-br btn-primary',
          closeModal: false
        }
      },
      dangerMode: false,
      className: customClass,
      showCloseButton: true
    };
    if (initialValue) {
      settings.content.attributes.value = initialValue;
    }
    if (id) {
      settings.content.attributes.id = id;
    }
    return swal(settings);
  }

  showWarning(title, msg): Promise<any> {
    return swal({
      title,
      text: msg,
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'btn btn-danger-outline btn-br',
          closeModal: true
        }
      }
    });
  }

  showSuccess(title, msg): void {
    const span = document.createElement('span');
    span.innerHTML = msg;
    swal({
      title,
      html: true,
      content: span,
      icon: 'success',
      buttons: {
        cancel: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'btn btn-success-outline',
          closeModal: true
        }
      }
    });
  }

  showSuccessToast(title, msg): void {
    this.toastr.success(msg, title, {closeButton: true, tapToDismiss: true});
  }

  emitChildEvent(msg: string) {
     this.childClickedEvent.next(msg);
  }

  childEventListner() {
     return this.childClickedEvent.asObservable();
   }
}
