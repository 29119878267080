<app-upload-modal [clientId]="currentUser?.selectedClient?.id"></app-upload-modal>

<clr-header class="main-header header-5">
    <div class="branding">
      <a routerLink="/" href="javascript://" class="nav-link">
        <div class="clr-icon broadridge-logo" *ngIf="!(!logoError && this.currentUser?.selectedClient?.s3LogoUri)"></div>
        <div class="" *ngIf="!logoError && this.currentUser?.selectedClient?.s3LogoUri" style="height: 100%">
            <img height="100%" [src]="this.currentUser.selectedClient.s3LogoUri" (error)="onLogoError()">
        </div>
        <div class="iim-title-main-nav" *ngIf="!(!logoError && this.currentUser?.selectedClient?.s3LogoUri)">
          <a class="title"><span class="long-name">Intelligent Information Management</span><span class="short-name">IIM</span></a>
        </div>
        </a>
    </div>

    <div *ngIf="getToken()" class="header-actions" [clr-nav-level]="1">

      <a *ngIf="currentUser !== null" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" href="javascript://"
          class="nav-link nav-text header-action-button nav-space">
        <clr-icon class="is-solid nav-button-icon" shape="home"></clr-icon>
        <p class="text">Home</p>
      </a>

      <clr-dropdown class="advanced-dropdown" *ngIf="currentUser?.selectedClient?.uiperms?.legalhold?.create">
        <button class="nav-icon header-action-button nav-dropdown-button" clrDropdownTrigger title="Advanced">
          <clr-icon class="is-solid nav-button-icon" shape="book" size="23"></clr-icon>
          <p class="align">Advanced</p>
          <clr-icon shape="caret down" size="15" style="margin-top: 1px;"></clr-icon>
        </button>
        <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
          <div>
            <a class="client-option" href="javascript://" clrDropdownItem routerLink="/legal-hold">
              <span>Legal Hold</span>
            </a>
          </div>

        </clr-dropdown-menu>
      </clr-dropdown>

      <a *ngIf="currentUser?.selectedClient?.uiperms?.search?.create && currentUser?.clients?.length > 0" routerLink="/search" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" href="javascript://"
          class="nav-link nav-text header-action-button">
        <clr-icon class="is-solid nav-button-icon" shape="search" size="22"></clr-icon>
        <p class="text">Search</p>
      </a>

      <!-- Disable Upload until they have a filetype Scanner -->
      <btn *ngIf="false && currentUser?.selectedClient?.uiperms?.upload?.create && currentUser?.clients?.length > 0" (click)="openUploadModal()" class="nav-link nav-text header-action-button">
        <clr-icon class="is-solid nav-button-icon" shape="upload-cloud" size="22"></clr-icon>
        Upload
      </btn>

      <btn *ngIf="currentUser?.selectedClient?.uiperms?.report?.read ||
      currentUser?.selectedClient?.uiperms?.report?.create ||
      currentUser?.selectedClient?.uiperms?.report?.update ||
      currentUser?.selectedClient?.uiperms?.report?.delete"
           routerLink="/report" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" href="javascript://"
           class="nav-link nav-text header-action-button">
        <clr-icon class="is-solid nav-button-icon" shape="briefcase" size="22"></clr-icon>
        <p class="text">Report</p>
      </btn>

      <a
        *ngIf="currentUser?.selectedClient?.uiperms?.doctype.read ||
        currentUser?.selectedClient?.uiperms?.doctype.update ||
        currentUser?.selectedClient?.uiperms?.doctype.create ||
        currentUser?.selectedClient?.uiperms?.doctype.delete ||
        currentUser?.selectedClient?.uiperms?.client.read ||
        currentUser?.selectedClient?.uiperms?.client.update ||
        currentUser?.selectedClient?.uiperms?.client.create ||
        currentUser?.selectedClient?.uiperms?.client.delete ||
        currentUser?.selectedClient?.uiperms?.datamapping.read ||
        currentUser?.selectedClient?.uiperms?.datamapping.update ||
        currentUser?.selectedClient?.uiperms?.datamapping.create ||
        currentUser?.selectedClient?.uiperms?.datamapping.delete ||
        currentUser?.selectedClient?.uiperms?.sitemappings.read ||
        currentUser?.selectedClient?.uiperms?.sitemappings.update ||
        currentUser?.selectedClient?.uiperms?.sitemappings.create ||
        currentUser?.selectedClient?.uiperms?.sitemappings.delete"
         id="admin-btn" routerLink="/admin" href="javascript://" routerLinkActive="active"
         class="nav-link nav-text header-action-button">
        <clr-icon class="is-solid nav-button-icon" shape="cog" size="22"></clr-icon>
        <p class="text">Administration</p>
      </a>

      <clr-dropdown *ngIf="currentUser?.clients?.length > 1">  <!-- Client Selection -->
        <button class="nav-icon header-action-button nav-dropdown-button nav-client" clrDropdownTrigger title="Client Selection">
          <clr-icon class="is-solid nav-button-icon" shape="building" size="24"></clr-icon>
            <p class="align">Clients</p>
            <clr-icon shape="caret down" size="15" style="margin-top: 2px;"></clr-icon>
        </button>
        <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
          <div *ngFor="let client of clients">
            <a (click)="switchClient(client)" class="client-option" title="{{client.clientId}}"  [ngClass]="isSelectedClient(client.clientId) ? 'client-option--selected' : 'client-option--unselected'" href="javascript://" clrDropdownItem>
                <clr-icon *ngIf="isSelectedClient(client.clientId)" shape="check"></clr-icon>
              <span>{{client.clientName}}</span>
            </a>
          </div>
        </clr-dropdown-menu>
      </clr-dropdown>

      <clr-dropdown class="nav-user-dropdown">
        <button class="nav-icon header-action-button nav-dropdown-button" style="margin-top:-1px;" clrDropdownTrigger [ngClass]="!currentUser?.selectedClient?.uiperms?.users?.update ? 'sso-user-no-privileges-avatar': ''">
          <clr-icon class="is-solid nav-button-icon" shape="user" size="22"></clr-icon>
            <span *ngIf="currentUser?.firstName" class="username-nav" [ngClass]="currentUser.firstName.length > 6 ? 'username-nav-caret-margin' : ''">
              {{currentUser.firstName}}
            </span>
            <clr-icon shape="caret down" size="14" *ngIf="!isSSOUser() && currentUser?.selectedClient?.uiperms?.users?.update"></clr-icon>
        </button>
        <clr-dropdown-menu clrPosition="bottom-right" *ngIf="!isSSOUser() && currentUser?.selectedClient?.uiperms?.users?.update">
            <a *ngIf="currentUser?.selectedClient?.uiperms?.users?.update" (click)="myProfileClick()" title="My Profile" clrDropdownItem><clr-icon shape="wrench" class="nav-user-dropdown-icons"></clr-icon> My Profile</a>
            <a *ngIf="!isSSOUser()" (click)="logoutClick()" title="Logout" clrDropdownItem><clr-icon shape="logout" class="nav-user-dropdown-icons"></clr-icon> Logout</a>
        </clr-dropdown-menu>
      </clr-dropdown>
    </div>
  </clr-header>
