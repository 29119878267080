<clr-modal id="document-type-modal" [clrModalOpen]="modalEnabled===true" [clrModalStaticBackdrop]="true" [clrModalSize]="'md'" [clrModalClosable]="false">
  <div class="modal-title">
    <div class="rectangle r1 view1"></div>
    <div class="rectangle r2 view2"></div>
    <h3 class="modal-title-text text1">
      <ng-container>Select Client</ng-container>
    </h3>
  </div>
  <div class="modal-body modal-lg">
    <form class="document-type-form" clrForm>
      <clr-select-container class="form-control clr-select-wrapper_height view">
        <label class="clr-col-xs-12 text" for="document-type-modal__archiveProvider">Client:</label>
        <select class="search-index-input archive-provider-dropdown clr-col-xs-12 view" clrSelect
          id="client-select-modal__client" name="client-select-modal__client" [(ngModel)]="selectedClient">
          <option selected disabled value="null" class="view">Select a Client</option>
          <option *ngFor="let client of clients" value="{{client.clientId}}" class="view">{{client.clientName}}</option>
        </select>
        <clr-control-error>Please select a client</clr-control-error>
      </clr-select-container>
    </form>
  </div>
  <div class="modal-footer">
    <button id="new-document-type-submit-trigger" type="submit" (click)="onSubmit()" [disabled]="!selectedClient" [clrLoading]="loading" class="btn btn-primary">
      <ng-container>Ok</ng-container>
    </button>
  </div>
</clr-modal>
