import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ChangeDetectorRef, OnDestroy} from '@angular/core';
import {FormArray, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {AlertService} from '../../../services/alert.service';
import {ClrForm} from '@clr/angular';
import { IimUser } from 'ui-sdk/models/iim-user.model';
import {Subscription} from 'rxjs';
import moment from 'moment';
import {DateFormatter} from '../../../utility/date-formatter';
import {UserMethods} from 'ui-sdk';
import {IimDocumentType, SearchIndex, DocumentTypeMethods} from 'ui-sdk';
import getUuidByString from 'uuid-by-string';
import {OperatorConfig} from './operator-config';
import {DefaultDateRanges} from './default-date-ranges';
import {HelperMethods} from './helper-methods';

@Component({
  selector: 'app-document-type-modal',
  templateUrl: './document-type-modal.component.html',
  styleUrls: ['./document-type-modal.component.scss']
})
export class DocumentTypeModalComponent implements OnInit, OnDestroy {
  private selectedDocumentTypeOriginal: IimDocumentType;
  public _selectedDocumentType: IimDocumentType = null;
  private _archiveProviders: Array<string>;
  private _dataMappingGroups: Array<string>;

  currentUser: IimUser;
  subscriptions: Subscription = new Subscription();

  @Input('archiveProviders')
  set setArchiveProviders(value: Array<string>) {
    this._archiveProviders = value;
    setTimeout(() => {
      this.resetDocumentTypeForm(this._selectedDocumentType);
    }, 10);
  }

  get archiveProviders(): Array<string> {
    return this._archiveProviders;
  }

  @Input('dataMappingGroups')
  set setDataMappingGroups(value: Array<string>) {
    this._dataMappingGroups = value;
    setTimeout(() => {
      this.resetDocumentTypeForm(this._selectedDocumentType);
    }, 10);
  }

  get dataMappingGroups(): Array<string> {
    return this._dataMappingGroups;
  }

  @Input('selectedDocumentType')
  set setSelectedDocumentType(value: IimDocumentType) {
    this._selectedDocumentType = value;
    this.selectedDocumentTypeOriginal = JSON.parse(JSON.stringify(value));
    this.resetDocumentTypeForm(value);
  }

  get selectedDocumentType(): IimDocumentType {
    return this._selectedDocumentType;
  }

  @Input() modalEnabled: boolean;
  @Output() created: EventEmitter<IimDocumentType> = new EventEmitter();
  @Output() updated: EventEmitter<IimDocumentType> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  documentTypeForm: FormGroup;
  searchIndexFormArray: FormArray;
  additionalAttrFormArray: FormArray;
  sortingFormArray: FormArray;

  defaultDateRanges = DefaultDateRanges.defaultDateRanges;

  usedSearchIndexKeys: Set<string> = new Set<string>();
  usedSearchIndexId: string = '';

  processingModalRequest: boolean = false;
  showDocumentRetention: boolean = false;
  operatorList: Array<any> = [];
  operatorStringList: Array<any> = [];
  dropdownSettings: any = {};
  searchIndexArray: Array<any> = [];

  copyIdToClipboard: string;
  timer;
  searchIndexMultiOptions: any;
  dragSourceIndex;

  @ViewChild(ClrForm) clrForm;

  constructor (private alertService: AlertService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    const userSubscription = UserMethods.currentUser$.subscribe(
      (user: IimUser) => this.currentUser = user
    );
    this.subscriptions.add(userSubscription);

    this.createDocTypeForm();
    this.onChanges();

    this.dropdownSettings = OperatorConfig.dropdownSettings;
    this.operatorList = OperatorConfig.operatorList;
    this.operatorStringList = OperatorConfig.operatorStringList;
  }

  private createDocTypeForm() {
    this.documentTypeForm = this.createDocumentTypeFormGroup();
  }

  startRowDrag(e: any) {
    this.dragSourceIndex = e.target.index;
  }

  private getCurrentRow(target: EventTarget | Element) {
    const parent = target['parentNode'];
    return parent.classList.contains('search-index-list') ? parent : this.getCurrentRow(parent);
  }

  stopRowDrag(e: Event) {
    e.preventDefault();
    const toIndex = this.getCurrentRow(e.target).index;
    const fromGroup = this.searchIndexFormArray.at(this.dragSourceIndex);
    this.searchIndexFormArray.removeAt(this.dragSourceIndex);
    this.searchIndexFormArray.insert(toIndex, fromGroup);
    this.documentTypeForm.markAsDirty();
  }

  onChanges(): void {
    this.subscriptions.add(this.documentTypeForm.get('archiveProvider').valueChanges.subscribe(archiveProviderVal => {
      this.showDocumentRetention = archiveProviderVal === 'IIM_Native';
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  isNewDocumentType() {
    return !!!this._selectedDocumentType || (!!this._selectedDocumentType && !!!this._selectedDocumentType.id);
  }

  private createDocumentTypeFormGroup() {
    const dtFormGroup =  new FormGroup({
      documentTypeName: new FormControl({
        value: this._selectedDocumentType ? this._selectedDocumentType.name : null,
        disabled: false},
        [Validators.required]),
      documentTypeDescription: new FormControl({
        value: this._selectedDocumentType ? this._selectedDocumentType.description : null,
        disabled: false},
        [Validators.required]),
      archiveProvider: new FormControl({
        value: this._selectedDocumentType ? this._selectedDocumentType.archiveProvider : null,
        disabled: false},
        [Validators.required]),
      documentRetention: new FormControl({
        value: this._selectedDocumentType ? this._selectedDocumentType.retentionPolicyDays : null,
        disabled: false},
        [ Validators.pattern('^[1-9][0-9]*$')]),
      maxDocumentsToDownload: new FormControl({
        // tslint:disable-next-line:max-line-length
        value: this._selectedDocumentType?.additionalAttributes ? this._selectedDocumentType.additionalAttributes.maxDocumentsToDownload : null,
        disabled: false}, [ Validators.pattern('^[1-9][0-9]*$')]),
      openOnLoad: new FormControl({
        value: this._selectedDocumentType ? this._selectedDocumentType.openOnLoad : true,
        disabled: false}, []),
      searchIndexFormArray: new FormArray([]),
      sortingFormArray: new FormArray([]),
      groupByIndex: new FormControl({
          value: this._selectedDocumentType ? this._selectedDocumentType.groupBy : null,
          disabled: false}),
      additionalAttrFormArray: new FormArray([]),
      accountIdSearchIndexKey: new FormControl({
        value: this._selectedDocumentType?.entitlements ? this._selectedDocumentType.entitlements.accountIdSearchIndexKey : null,
        disabled: false
      })
    });

    this.searchIndexFormArray = <FormArray> dtFormGroup.get('searchIndexFormArray');
    this.sortingFormArray = <FormArray> dtFormGroup.get('sortingFormArray');
    this.additionalAttrFormArray = <FormArray> dtFormGroup.get('additionalAttrFormArray');

    this.filterSearchIndexChange();

    return dtFormGroup;
  }

  onAddFilter(e) {
    e.preventDefault();
    const siFormGroup = new FormGroup({
      elementType: new FormControl({value: 'dateDropdown', disabled: false}, []),
      placeholderLabel: new FormControl({value: '', disabled: false}, []),
      delimiter: new FormControl({value: '', disabled: false}, []),
      searchIndex: new FormControl({value: '', disabled: false}, [Validators.required]),
      searchIndexes: new FormControl({value: '', disabled: false}, []),
      defaultSelected: new FormControl({value: 'Last 12 Months', disabled: false}, [Validators.required]),
      dateRangesFormArray: new FormArray([])
    });

    siFormGroup.setControl('dateRangesFormArray', this.getDefaultDateRangesFormArray());

    this.additionalAttrFormArray.insert(0, siFormGroup);
  }

  private getDefaultDateRangesFormArray(): FormArray {
    const dateRangesFormArray: FormArray = new FormArray([]);

    this.defaultDateRanges.forEach(range => {
      const ctrl = new FormGroup({
        rangeType: new FormControl({value: range?.rangeType, disabled: false}, [Validators.required]),
        rangeLabel: new FormControl({value: range?.rangeLabel, disabled: false}, [Validators.required, Validators.pattern('^[a-zA-Z0-9 ]*$'), this.whitespaceValidator]),
        rangeStart: new FormControl({value: range?.rangeStart, disabled: false}, range?.rangeType === 'Custom' ? [
          Validators.required,
          Validators.pattern('^[0-9]*$')
        ] : [Validators.pattern('^[0-9]*$')]),
        rangeEnd: new FormControl({value: range?.rangeEnd, disabled: false}, [Validators.pattern('^[0-9]*$')])
      });

      ctrl.controls['rangeType'].updateValueAndValidity();
      ctrl.controls['rangeLabel'].updateValueAndValidity();
      ctrl.controls['rangeStart'].updateValueAndValidity();
      ctrl.controls['rangeEnd'].updateValueAndValidity();

      dateRangesFormArray.push(ctrl);
    });

    return dateRangesFormArray;
  }

  removeFilter(index) {
    this.additionalAttrFormArray.removeAt(index);

    this.documentTypeForm.markAsDirty();
  }

  onAddSorting(e) {
    e.preventDefault();
    const sortFormGroup = new FormGroup({
      searchIndexKey: new FormControl({value: '', disabled: false}, [Validators.required]),
      sortOrder: new FormControl({value: 'NONE', disabled: false}, [Validators.required]),
      sortId: new FormControl({value: '', disabled: false})
    });

    this.sortingFormArray.push(sortFormGroup);
  }

  removeSorting(index) {
    this.sortingFormArray.removeAt(index);
    this.documentTypeForm.markAsDirty();

    this.updatedUsedSearchIndexKeys();
  }

  checkNumOfSortingsAgainstNumOfSearchIndexes(): boolean {
    return this.sortingFormArray?.controls?.length >= this.searchIndexFormArray?.getRawValue()?.length;
  }

  onAddIndex(e) {
    e.preventDefault();
    const siFormGroup = new FormGroup({
      label: new FormControl({value: '', disabled: false}, [Validators.required]),
      searchIndex: new FormControl({value: '', disabled: false}, [Validators.required]),
      dataType: new FormControl({value: 'String', disabled: false}, [Validators.required]),
      dataMappingName: new FormControl({value: '', disabled: false}, []),
      searchable: new FormControl({value: true, disabled: false}, []),
      isAdvancedSearch: new FormControl({value: false, disabled: false}, []),
      showInResult: new FormControl({value: true, disabled: false}, []),
      operators: new FormControl({value: [], disabled: false}),
      min: new FormControl({value: '', disabled: false}),
      max: new FormControl({value: '', disabled: false}),
      isUsed: new FormControl({value: false, disabled: false}),
      indexId: new FormControl({value: '', disabled: false})
    });

    this.searchIndexFormArray.insert(0, siFormGroup);
    this.changeSearchIndex();
  }

  removeSearchIndex(index) {
    this.sortingFormArray.controls.forEach(ctrl => {
      if (ctrl['controls']['sortId'].value === this.searchIndexFormArray.controls[index]['controls']['indexId']['value']) {
        this.sortingFormArray.removeAt(this.sortingFormArray.controls.indexOf(ctrl));
      }
    });

    this.searchIndexFormArray.removeAt(index);
    this.documentTypeForm.markAsDirty();
    this.changeSearchIndex();
  }

  dataTypeChange(formGroup) {
    if (formGroup) {
       formGroup.get('operators')?.setValue(null);
       formGroup.get('min')?.setValue(null);
       formGroup.get('max')?.setValue(null);
    }
  }

  elementTypeChange(formGroup) {
    if (formGroup) {
      const elementTypeValue = formGroup.get('elementType')?.value;

      if (elementTypeValue === 'multiSelectDropdown') {
        formGroup.get('placeholderLabel')?.setValidators([Validators.required]);
        formGroup.get('searchIndex')?.setValidators([]);
        formGroup.get('delimiter')?.setValidators([Validators.required]);
        formGroup.get('searchIndexes')?.setValidators([Validators.required]);
        formGroup.get('defaultSelected')?.setValidators([]);

      } else {
        formGroup.get('placeholderLabel')?.setValidators([]);
        formGroup.get('searchIndex')?.setValidators([Validators.required]);
        formGroup.get('delimiter')?.setValidators([]);
        formGroup.get('searchIndexes')?.setValidators([]);
        formGroup.get('defaultSelected')?.setValidators([Validators.required]);
        formGroup.get('dateRangesFormArray')?.setValidators([]);

        if (elementTypeValue === 'inputBox') {
          formGroup.get('placeholderLabel')?.setValidators([Validators.required]);
          formGroup.get('defaultSelected')?.setValidators([]);
        }
      }

      formGroup.get('searchIndex')?.setValue('');
      formGroup.get('searchIndex')?.updateValueAndValidity();
      formGroup.updateValueAndValidity();

      this.cdr.detectChanges();
    }
  }

  filterSearchIndexChange() {
    this.searchIndexFormArray.controls.map((formGroup, i) => {
        formGroup.get('searchIndex').enable();
    });

    this.additionalAttrFormArray.controls.map((filterGroup) => {
      if (filterGroup.get('searchIndex').value) {
        this.searchIndexFormArray.controls.map((formGroup, i) => {
          if (formGroup.get('searchIndex').value === filterGroup.get('searchIndex').value) {
            formGroup.get('searchIndex').disable();
          }
        });
      }
      if (filterGroup.get('searchIndexes')?.value?.length > 0) {
        const arr = filterGroup.get('searchIndexes').value.map(v => v.item_id);
        this.searchIndexFormArray.controls.map((formGroup, i) => {
          if (arr.includes(formGroup.get('searchIndex').value)) {
            formGroup.get('searchIndex').disable();
          }
        });
      }
    });
  }

  resetDocumentTypeForm(docType: IimDocumentType) {
    if (docType) {
      const investorAdditionalAttr =  docType.additionalAttributes?.investor;

      this.documentTypeForm.get('documentTypeName').setValue(docType ? docType.name : null);
      this.documentTypeForm.get('documentTypeDescription').setValue(docType ? docType.description : null);
      this.documentTypeForm.get('archiveProvider').setValue(docType ? docType.archiveProvider : null);
      this.documentTypeForm.get('documentRetention').setValue(docType ? docType.retentionPolicyDays : null);
      // tslint:disable-next-line:max-line-length
      this.documentTypeForm.get('maxDocumentsToDownload').setValue(docType.additionalAttributes ? docType.additionalAttributes.maxDocumentsToDownload : null);
      this.documentTypeForm.get('openOnLoad').setValue(investorAdditionalAttr?.openPageLoad ? investorAdditionalAttr.openPageLoad : true);

      this.documentTypeForm.get('groupByIndex').setValue(docType ? docType.groupBy : null);

      this.searchIndexFormArray.clear();
      this.sortingFormArray.clear();
      this.additionalAttrFormArray.clear();
      // tslint:disable-next-line:max-line-length
      this.documentTypeForm.get('accountIdSearchIndexKey').setValue(docType?.entitlements ? docType.entitlements.accountIdSearchIndexKey : null);

      docType.searchIndices.forEach(si => {
        if (!si.hasOwnProperty('validation')) {
          si.validation = {};
        }
        if (si.validation['min'] && si.dataType === 'DateTime') {
          si.validation['min'] = moment(si.validation['min']).format('YYYY-MM-DDTHH:mm:ss');
        }
        if (si.validation['max'] && si.dataType === 'DateTime') {
          si.validation['max'] = moment(si.validation['max']).format('YYYY-MM-DDTHH:mm:ss');
        }

        const siFormGroup = new FormGroup({
          label: new FormControl({value: si.label, disabled: false}, [Validators.required]),
          searchIndex: new FormControl({value: si.searchIndex, disabled: false}, [Validators.required]),
          dataType: new FormControl({value: si.dataType ? si.dataType : 'String', disabled: si.isUsed}, [Validators.required]),
          dataMappingName: new FormControl({value: si.dataMappingName ? si.dataMappingName : '', disabled: si.isUsed}, []),
          searchable: new FormControl({value: si.searchable !== false, disabled: si.isUsed}, []),
          isAdvancedSearch: new FormControl({value: si.isAdvancedSearch === true, disabled: si.isUsed}, []),
          showInResult: new FormControl({value: si.showInResult !== false, disabled: false}, []),
          operators: new FormControl({value: si.operators ? OperatorConfig.getOperators(si.operators) : [], disabled: false}),
          min: new FormControl({value: si.validation['min'], disabled: si.isUsed}),
          max: new FormControl({value: si.validation['max'], disabled: si.isUsed}),

          isUsed: new FormControl({value: si.isUsed, disabled: si.isUsed}),
          indexId: new FormControl({value: getUuidByString(si.searchIndex), disabled: false})
        });

        this.searchIndexFormArray.push(siFormGroup);
      });

      if (docType.sorting) {
        docType.sorting.forEach(so => {
          const sortFormGroup = new FormGroup({
            searchIndexKey: new FormControl({value: so.searchIndexKey, disabled: false}),
            sortOrder: new FormControl({value: so.sortOrder ? so.sortOrder : 'NONE', disabled: false}),
            sortId: new FormControl({value: getUuidByString(so.searchIndexKey), disabled: false}),
          });

          this.sortingFormArray.push(sortFormGroup);
        });

        this.updatedUsedSearchIndexKeys();
      }

      this.populateInvestorAdditionalAttributesFormArray(investorAdditionalAttr);
      this.filterSearchIndexChange();
      this.changeSearchIndex();
    }
  }

  private populateInvestorAdditionalAttributesFormArray(investorAdditionalAttr) {
    investorAdditionalAttr?.filterElements?.forEach(si => {
      const siFormGroupAddAttr = new FormGroup({
        elementType: new FormControl({ value: si.formElementType ? si.formElementType : 'dateDropdown', disabled: false }),
        // tslint:disable-next-line:max-line-length
        searchIndex: new FormControl({ value: si.searchIndex ? si.searchIndex : '', disabled: false }, si.formElementType === 'dateDropdown' ? [Validators.required] : []),
        placeholderLabel: new FormControl({ value: si.placeHolderLabel ? si.placeHolderLabel : '', disabled: false }, si.formElementType !== 'dateDropdown' && si.formElementType !== 'customDateWithRanges' ? [Validators.required] : []),
        delimiter: new FormControl({ value: si.delimiter ? si.delimiter : '', disabled: false }, si.formElementType === 'multiSelectDropdown' ? [Validators.required] : []),
        // tslint:disable-next-line:max-line-length
        searchIndexes: new FormControl({ value: si.searchIndexes ? this.getFilterSearchIndexes(si.searchIndexes) : '', disabled: false }, si.formElementType === 'multiSelectDropdown' ? [Validators.required] : []),
        // tslint:disable-next-line:max-line-length
        defaultSelected: new FormControl({ value: si.defaultSelected ? si.defaultSelected : 'Last 12 Months', disabled: false }, [Validators.required]),
        dateRangesFormArray: new FormArray([])
      });

      const dateRangesFormArray: FormArray = new FormArray([]);
      si.dateRanges?.forEach(range => {
        dateRangesFormArray.push(new FormGroup({
          rangeType: new FormControl({value: range?.rangeType, disabled: false}, [Validators.required]),
          rangeLabel: new FormControl({value: range?.rangeLabel, disabled: false}, [Validators.required, Validators.pattern('^[a-zA-Z0-9 ]*$'), this.whitespaceValidator]),
          rangeStart: new FormControl({value: range?.rangeStart, disabled: false}, range?.rangeType === 'Custom' ? [
            Validators.required,
            Validators.pattern('^[0-9]*$')
          ] : [Validators.pattern('^[0-9]*$')]),
          rangeEnd: new FormControl({value: range?.rangeEnd, disabled: false}, [Validators.pattern('^[0-9]*$')])
        }));
      });

      siFormGroupAddAttr.setControl('dateRangesFormArray', dateRangesFormArray);

      this.additionalAttrFormArray.push(siFormGroupAddAttr);
    });
  }

  getFilterSearchIndexes(searchIndexes) {
    const searchIndexesInputList = [];

    searchIndexes.forEach(s => {
      searchIndexesInputList.push({item_id : s, item_text : s });
    });

    return searchIndexesInputList;
  }

  onSubmit() {
    if (this.documentTypeForm.valid) {
      this.saveDocumentType();

    } else {
      this.clrForm.markAsDirty();
    }
  }

  saveButtonEnabled() {
    return this.documentTypeForm.dirty && this.documentTypeForm.valid && this.searchIndexFormArray.value.length > 0;
  }

  onMinChange(e, formControl) {
    if (e) {
      formControl.controls.min.setValidators([(control) => {
        if (control.value || control.value === 0) {
          const formGroup = control.parent;
          const n = control.value;
          if (formGroup.value.dataType === 'String') {
            if (n <= 0) {
              return { min: true };
            }
          }
          if ((formGroup.value.dataType === 'String' || formGroup.value.dataType === 'Integer') && !Number.isInteger(n)) {
            return { customInt: true };
          }
        }
      }]);
      formControl.controls.max.setValidators([(control) => {
        if (control.value || control.value === 0) {
          const formGroup = control.parent;
          const n = control.value;
          if (formGroup.value.dataType === 'Date') {
            if (formGroup.value.min && new Date(formGroup.value.min) > new Date(control.value)) {
              return { custom: true };
            }
          } else {
            if (formGroup.value.dataType === 'String' && n <= 0) {
              return { min: true };
            }
            if ((formGroup.value.dataType === 'String' || formGroup.value.dataType === 'Integer') &&
              !Number.isInteger(n)) {
              return { customInt: true };
            }

            if ((formGroup.value.min || formGroup.value.min === 0) && formGroup.value.min > control.value) {
              return { custom: true };
            }
          }
        }

        return null;
      }]);

    } else {
      formControl.controls.max.setValidators(null);
    }

    formControl.controls.max.updateValueAndValidity();
  }

  getSearchIndexOptions() {
    const searchIndexArray = [];

    for (let i = 0; i < this.searchIndexFormArray.getRawValue().length; i++) {
      searchIndexArray.push(this.searchIndexFormArray.getRawValue()[i].searchIndex);
    }

    return searchIndexArray;
  }

  onSortSearchIndexKeyChanged(index, searchIndexKey) {
    this.sortingFormArray.controls[index]['controls']['sortId'].value = getUuidByString(searchIndexKey);
    this.updatedUsedSearchIndexKeys();
  }

  updatedUsedSearchIndexKeys() {
    this.usedSearchIndexKeys.clear();

    this.sortingFormArray.controls.map((formGroup, i) => {
      this.usedSearchIndexKeys.add(formGroup.get('searchIndexKey').value);
    });
  }

  onSearchIndexKeyFocus(searchIndex) {
    this.sortingFormArray.controls.map((formGroup, i) => {
      if (formGroup.get('sortId').value === searchIndex.indexId) {
        this.usedSearchIndexId = searchIndex.indexId;
      }
    });
  }

  changeSearchIndex(index?) {
    this.searchIndexMultiOptions = [];
    for (let i = 0; i < this.searchIndexFormArray.getRawValue().length; i++) {
      // tslint:disable-next-line:max-line-length
      this.searchIndexMultiOptions.push({item_id : this.searchIndexFormArray.getRawValue()[i].searchIndex, item_text : this.searchIndexFormArray.getRawValue()[i].searchIndex });
    }

    if (index) {
      const searchIndexKey = this.searchIndexFormArray.controls[index]['controls']['searchIndex']['value'];
      const currentSearchIndexId = getUuidByString(searchIndexKey);

      this.searchIndexFormArray.controls[index]['controls']['indexId']['value'] = currentSearchIndexId;

      if (this.usedSearchIndexId?.length > 0 && this.usedSearchIndexId !== currentSearchIndexId) {
        this.sortingFormArray.controls.forEach(ctrl => {
          if (ctrl['controls']['sortId'].value === this.usedSearchIndexId) {
            ctrl['controls']['sortId'].value = currentSearchIndexId;
            ctrl['controls']['searchIndexKey'].value = searchIndexKey;
          }
        });

        this.updatedUsedSearchIndexKeys();
      }

      this.usedSearchIndexId = '';
    }
  }

  getSearchIndexesFromForm(): Array<SearchIndex> {
    const searchIndexArray: Array<SearchIndex> = [];
    const formValue = this.searchIndexFormArray.getRawValue();

    for (let i = 0; i < formValue.length; i++) {
      const searchIndex: SearchIndex = new SearchIndex;
      searchIndex.searchIndex = formValue[i].searchIndex;
      searchIndex.label = formValue[i].label;
      searchIndex.dataType = formValue[i].dataType;
      searchIndex.searchable = formValue[i].searchable;
      searchIndex.isAdvancedSearch = formValue[i].isAdvancedSearch;
      searchIndex.showInResult = formValue[i].showInResult;
      const dataMappingName = formValue[i].dataMappingName;
      if (dataMappingName) {
        searchIndex.dataMappingName = formValue[i].dataMappingName;
      }

      const selectedOperators = formValue[i].operators;
      if (selectedOperators && selectedOperators.length > 0) {
        searchIndex.operators = [];
        selectedOperators.forEach(element => {
          searchIndex.operators.push(element.item_id);
        });
      }
      const newValidation = {};
      if (formValue[i].min) {
        newValidation['min'] = formValue[i].min;
      }
      if (formValue[i].max) {
        newValidation['max'] = formValue[i].max;
      }
      searchIndex.validation = newValidation


      searchIndex.isUsed = formValue[i].isUsed;

      if (searchIndex.dataType === 'Date') {
        if (searchIndex.validation['min']) {
          searchIndex.validation['min'] = DateFormatter.formatDate(searchIndex.validation['min']);
        }
        if (searchIndex.validation['max']) {
          searchIndex.validation['max'] = DateFormatter.formatDate(searchIndex.validation['max']);
        }
      } else if (searchIndex.dataType === 'DateTime') {
        if (searchIndex.validation['min']) {
          const date = searchIndex.validation['min'];
          searchIndex.validation['min'] = moment(date).format('YYYY-MM-DD HH:mm:ss');
        }
        if (searchIndex.validation['max']) {
          const date = searchIndex.validation['max'];
          searchIndex.validation['max'] = moment(date).format('YYYY-MM-DD HH:mm:ss');
        }
      }

      searchIndexArray.push(searchIndex);
    }

    return searchIndexArray;
  }

  getFilterElementsFromForm(): Array<any> {
    const filterArray: Array<any> = [];

    for (let i = 0; i < this.additionalAttrFormArray.length; i++) {
      const filterElement: any = {};
      filterElement.formElementType = this.additionalAttrFormArray.at(i).value.elementType;

      if (filterElement.formElementType === 'customDateWithRanges') {
        filterElement.searchIndex = this.additionalAttrFormArray.at(i).value.searchIndex;
        filterElement.dateRanges = this.additionalAttrFormArray.at(i).value.dateRangesFormArray;

      } else if (filterElement.formElementType === 'dateDropdown') {
        filterElement.searchIndex = this.additionalAttrFormArray.at(i).value.searchIndex;
        filterElement.defaultSelected = this.additionalAttrFormArray.at(i).value.defaultSelected;

      } else if (filterElement.formElementType === 'multiSelectDropdown') {
        filterElement.placeHolderLabel = this.additionalAttrFormArray.at(i).value.placeholderLabel;
        filterElement.delimiter = this.additionalAttrFormArray.at(i).value.delimiter;
        const selectedSearchIndexes = this.additionalAttrFormArray.at(i).value.searchIndexes;

        if (selectedSearchIndexes?.length > 0) {
          filterElement.searchIndexes = [];
          selectedSearchIndexes.forEach(element => {
            filterElement.searchIndexes.push(element.item_id);
          });
        }
      } else if (filterElement.formElementType === 'inputBox') {
        filterElement.searchIndex = this.additionalAttrFormArray.at(i).value.searchIndex;
        filterElement.placeHolderLabel = this.additionalAttrFormArray.at(i).value.placeholderLabel;
      }

      filterArray.push(filterElement);
    }

    return filterArray;
  }

  isSearchIndexEditable(searchIndex) {
    if (!this.isNewDocumentType()) {
       const oldsi = this._selectedDocumentType.searchIndices.map((s) => s.searchIndex);

       if (oldsi.includes(searchIndex.value.searchIndex)) {
         searchIndex.get('searchIndex').disable();
         searchIndex.get('label').disable();

         return true;
       }
    }
    return false;
  }

  saveDocumentType() {
    const form = Object.assign({}, this.documentTypeForm.value); // If nested form groups, do a deep copy here
    if (this.isNewDocumentType()) {
      this._selectedDocumentType = new IimDocumentType();
    }

    this._selectedDocumentType.name = form.documentTypeName;
    this._selectedDocumentType.description = form.documentTypeDescription;
    this._selectedDocumentType.archiveProvider = form.archiveProvider;
    this._selectedDocumentType.retentionPolicyDays = form.documentRetention;
    this._selectedDocumentType.openOnLoad = form.openOnLoad;

    if (form.groupByIndex !== 'null') {
      this._selectedDocumentType.groupBy = form.groupByIndex;

    } else {
      delete this._selectedDocumentType.groupBy;
    }

    this._selectedDocumentType.status = 'Active';

    const beforeSearchIndexes = this._selectedDocumentType.searchIndices;
    const afterSearchIndexes = this.getSearchIndexesFromForm();
    const identicalIndexes = HelperMethods.deepEqual(beforeSearchIndexes, afterSearchIndexes);

    this._selectedDocumentType.searchIndices = this.getSearchIndexesFromForm();

    if (!this._selectedDocumentType.additionalAttributes) {
      this._selectedDocumentType.additionalAttributes = {};
    }

    if (!this._selectedDocumentType.additionalAttributes.investor) {
      this._selectedDocumentType.additionalAttributes.investor = {};
    }

    this._selectedDocumentType.additionalAttributes.maxDocumentsToDownload = form.maxDocumentsToDownload;
    this._selectedDocumentType.additionalAttributes.investor.openPageLoad = this._selectedDocumentType.openOnLoad;
    this._selectedDocumentType.additionalAttributes.investor.filterElements = this.getFilterElementsFromForm();


    const sortingFormArrayValue = this.sortingFormArray.getRawValue();
    if (sortingFormArrayValue) {
      this._selectedDocumentType.sorting = sortingFormArrayValue;
    }

    this._selectedDocumentType.entitlements = {
      accountIdSearchIndexKey: form.accountIdSearchIndexKey
    };

    if (this.isNewDocumentType()) {
      this.processingModalRequest = true;

      DocumentTypeMethods.createDocumentType(this._selectedDocumentType).subscribe(docType => {
        this._selectedDocumentType.id = docType['id'];
        this._selectedDocumentType.isDeletable = docType['isDeletable'];
        this._selectedDocumentType.dateCreated = docType['dateCreated'];

        // Always Create search Indices, even if its empty.
        DocumentTypeMethods.createSearchIndexes(docType['id'], this._selectedDocumentType).subscribe(response => {
          this.processingModalRequest = false;
          this.alertService.showSuccessToast(`Document Type '${this._selectedDocumentType.name}' created`, undefined);
          this.closeModal('created');
        },
        error => {
          this.processingModalRequest = false;
          if (error.status === 403) {
            const [title, msg] = ['Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [CREATE DocumentTypeSearchIndexes].`];
            this.alertService.showError(title, msg);
          } else {
            this.alertService.showError('Error', `Unable to create Search Indexes for ${docType['name']}. ${error.error.message}`);
          }
        });
        this.processingModalRequest = false;
      }, error => {
        this.processingModalRequest = false;
        if (error.status === 403) {
          this.alertService.showError('Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [CREATE DocumentTypeSearchIndexes].`);
        } else {
          this.alertService.showError('Error', `Unable to create Document Type. Please try again.  ${error.error.message}`);
        }
      });
    } else {
      this.processingModalRequest = true;
      DocumentTypeMethods.updateDocumentType(this._selectedDocumentType).subscribe(docType => {
        if (!identicalIndexes) {
          DocumentTypeMethods.createSearchIndexes(docType['id'], this._selectedDocumentType).subscribe(response  => {
            this.processingModalRequest = false;
            this.alertService.showSuccessToast(`Document Type '${this._selectedDocumentType.name}' updated`, undefined);
            this.closeModal('updated');
          },
          error => {
            this.processingModalRequest = false;
            if (error.status === 403) {
              this.alertService.showError('Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [CREATE DocumentTypeSearchIndexes].`);
            } else {
              this.alertService.showError('Error', `Unable to update Search Indexes for Document Type ${this._selectedDocumentType.name}.  ${error.error.message} `);
            }

            this.resetDocTypeToOriginalValue();
          });
        } else {
            this.processingModalRequest = false;
            this.alertService.showSuccessToast(`Document Type '${this._selectedDocumentType.name}' updated`, undefined);
            this.closeModal('updated');
        }
      }, error => {
        this.processingModalRequest = false;
        if (error.status === 403) {
          this.alertService.showError('Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [UPDATE DocumentType].`);
        } else {
          this.alertService.showError('Error', `Unable to update Document Type  ${this._selectedDocumentType.name}.  ${error.error.message} `);
        }

        this.resetDocTypeToOriginalValue();
      });
    }
  }

  private resetDocTypeToOriginalValue() {
    this._selectedDocumentType = JSON.parse(JSON.stringify(this.selectedDocumentTypeOriginal));
    this.createDocTypeForm();
  }

  closeModal(eventType) {
    if (eventType === 'created') {
      this.created.emit(this._selectedDocumentType);
    } else if (eventType === 'updated') {
      this.updated.emit(this._selectedDocumentType);
    }

    this._selectedDocumentType = null;
    this.documentTypeForm.reset();
    this.close.emit();
  }

  track(item: any, index: number) {
    return index;
  }

  notify(event: any) {
    this.copyIdToClipboard = event;

    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      this.copyIdToClipboard = '';
    }, 3000);
  }

  onAddDateRange(e, filterIndex) {
    e.preventDefault();
    const dateRangesFormArray = this.additionalAttrFormArray.at(filterIndex).get('dateRangesFormArray') as FormArray;

    dateRangesFormArray.insert(0, new FormGroup({
      rangeType: new FormControl({value: '', disabled: false}, [Validators.required]),
      rangeLabel: new FormControl({value: '', disabled: false}, [Validators.required, Validators.pattern('^[a-zA-Z0-9 ]*$'), this.whitespaceValidator]),
      rangeStart: new FormControl({value: '', disabled: false}, [Validators.required, Validators.pattern('^[0-9]*$')]),
      rangeEnd: new FormControl({value: '', disabled: false}, [Validators.pattern('^[0-9]*$')])
    }));

    this.documentTypeForm.markAsDirty();
  }

  whitespaceValidator(form: FormControl): ValidationErrors {
    if (form.value?.startsWith(' ') || form.value?.trim()?.length < 1 || form.value?.endsWith(' ')) {
      return {whitespace: true};
    }

    return null;
  }

  removeDateRange(filterIndex, rangeIndex) {
    (this.additionalAttrFormArray.at(filterIndex).get('dateRangesFormArray') as FormArray).removeAt(rangeIndex);

    this.documentTypeForm.markAsDirty();
  }

  getRangesFormControls(i) {
    return (this.additionalAttrFormArray.at(i).get('dateRangesFormArray') as FormArray).controls;
  }

  rangeTypeSelectChange(filterIndex, rangeIndex, rangeType) {
    const rangeControls = (this.additionalAttrFormArray.at(filterIndex).get('dateRangesFormArray') as FormArray).at(rangeIndex);

    if (rangeType !== 'Custom') {
      this.clearControlValidators(rangeControls.get('rangeStart'));
    }

    // clear range label on range type update
    rangeControls.get('rangeLabel').reset();

    this.documentTypeForm.markAsDirty();
  }

  clearControlValidators(formControlField) {
    formControlField.clearValidators();
    formControlField.updateValueAndValidity();
  }

  onKeyDownNumber(event: KeyboardEvent) {
    if (['.', 'e', '+', '-'].includes(event.key)) {
      event.preventDefault();
    }
  }
}
