<form class="saved-search-select-form" clrLayout="horizontal" clrForm>
  <section class="form-block">
    <div class="clr-form-control clr-row">
      <label for="selected-search" class="clr-control-label clr-col-xs-12">
        Saved Searches
        <span *ngIf="savedSearches.length > 0" class="badge badge-info">
          {{savedSearches.length}}
        </span>
        <span *ngIf="savedSearches.length == 0" class="badge badge-danger">
          {{savedSearches.length}}
        </span>
      </label>
      <div class="clr-control-container clr-col-xs-12 clr-col-md-9 saved-search-select">
        <div [@fadeInOut] *ngIf="savedSearches" class="clr-select-wrapper">
          <select class="clr-select" id="selected-search" name="selected-search"
                  [formControl]="control">
            <option [ngValue]="null">Select Saved Search</option>
            <option *ngFor="let search of savedSearches" [ngValue]="search">{{search.itemName}}</option>
          </select>
          <clr-icon class="clr-validate-icon" shape="exclamation-circle"></clr-icon>
        </div>
        <button [disabled]="!control.value" *ngIf="currentUser?.selectedClient?.uiperms?.savedsearch?.delete" (click)="deleteSavedSearch(control.value)" class="btn btn-icon btn-link btn-sm btn-no-caps">
          <clr-icon shape="trash" class= "clear-save-icon"></clr-icon>
        </button>
      </div>
    </div>
  </section>
</form>
