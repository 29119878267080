<div [@fadeInLeft] class="content-area">
  <div class="clr-row report-header-container">

    <div class="refresh-actions">
      <button (click)="getClientReports()" class="btn btn-link btn-no-caps refresh-btn" title="Refresh Reports" [clrLoading]="loadingReports"><clr-icon shape="refresh" size="20"></clr-icon></button>

      <div class="clr-control-container">
        <div class="clr-toggle-wrapper" title="Auto refresh">
          <input type="checkbox" clrToggle (click)="toggleAutoRefreshReports()" [checked]='isAutoRefreshEnabled' name="toggle">
        </div>
        <div class="auto-refresh-toggle-label">Auto Refresh (<span>{{AUTO_REFRESH_INTERVAL / 1000}}</span> sec)</div>
      </div>
    </div>

    <div class="clr-col-xs-12 clr-col-md report-header-item report-header-item-end">
      Show
      <div class="clr-select-wrapper">
        <select class="clr-select" name="pageAmount" id="pageAmount" [formControl]="tableControls.pageSize">
          <option [ngValue]="10">10</option>
          <option [ngValue]="25">25</option>
          <option [ngValue]="50">50</option>
        </select>
      </div>
      reports per page
    </div>
  </div>

  <div class="clr-row">
    <div class="clr-col-xs-12 clr-col-sm-12">
      <clr-datagrid [clrDgLoading]="loadingReports" class="main-dg">
        <clr-dg-column [clrDgField]="'batchId'" class="batchid-column">
          Batch ID
          <clr-dg-filter [clrDgFilter]="batchId">
            <text-filter #batchId propertyName="batchId"></text-filter>
          </clr-dg-filter>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'status'">
          Status
          <clr-dg-filter [clrDgFilter]="status">
            <text-filter #status propertyName="status"></text-filter>
          </clr-dg-filter>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'statusReason'">
          Status Reason
          <clr-dg-filter [clrDgFilter]="statusReason">
            <text-filter #statusReason propertyName="statusReason"></text-filter>
          </clr-dg-filter>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'statusReason'">
          Transaction Count
          <clr-dg-filter [clrDgFilter]="transactionCount">
            <text-filter #transactionCount propertyName="transactionCount"></text-filter>
          </clr-dg-filter>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'submittedDate'">
          Submitted Date
          <clr-dg-filter [clrDgFilter]="submittedDate">
            <text-filter #submittedDate propertyName="submittedDate"></text-filter>
          </clr-dg-filter>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'submittedBy'">
          Submitted By
          <clr-dg-filter [clrDgFilter]="submittedBy">
            <text-filter #submittedBy propertyName="submittedBy"></text-filter>
          </clr-dg-filter>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'lastUpdatedDate'">
          Last Updated Date
          <clr-dg-filter [clrDgFilter]="lastUpdatedDate">
            <text-filter #lastUpdatedDate propertyName="lastUpdatedDate"></text-filter>
          </clr-dg-filter>
        </clr-dg-column>
        <clr-dg-column class="">
          Actions
        </clr-dg-column>

        <clr-dg-row *clrDgItems="let report of allClientReports" [clrDgItem]="report">

          <clr-dg-cell class="datagrid-align-cells batchid-column" title="companyId">
            <button class="btn btn-icon btn-link copy-to-clipboard-btn" title="Copy Report Id" [appCopyClipboard]="report?.id" (copied)="notify($event)">
              <clr-icon class="copy-to-clipboard-icon" shape="copy-to-clipboard"></clr-icon>
            </button>
            {{report?.batchId}}

            <span *ngIf="report?.id == copyIdToClipboard" class="clipboard-copy-success">
                <clr-icon shape="check" size="14"></clr-icon>
                Copied
              </span>

          </clr-dg-cell>
          <clr-dg-cell class="dg-cell datagrid-align-cells" title="status">
            <span *ngIf="report?.status === 'SUBMITTED'"><clr-icon shape="pinboard" size="17" class="report-status-icon"></clr-icon></span>
            <span *ngIf="report?.status === 'PROCESSING'"><clr-icon shape="sync" size="17" class="report-status-icon"></clr-icon></span>
            <span *ngIf="report?.status === 'COMPLETED'"><clr-icon shape="success-standard" size="17" class="report-status-icon completed"></clr-icon></span>
            <span *ngIf="report?.status === 'FAILED'"><clr-icon shape="error-standard" size="17" class="report-status-icon failed"></clr-icon></span>
            &nbsp;&nbsp;
            <span [ngClass]="report?.status === 'COMPLETED' ? 'completed' : report?.status === 'FAILED' ? 'failed' : ''">{{report?.status}}</span>
          </clr-dg-cell>
          <clr-dg-cell class="dg-cells datagrid-align-cells" title="status">{{report?.statusReason}}</clr-dg-cell>
          <clr-dg-cell class="dg-cells datagrid-align-cells" title="status">{{report?.transactionCount}}</clr-dg-cell>
          <clr-dg-cell class="dg-cells datagrid-align-cells" title="status">{{report?.submittedDate | date: 'mediumDate'}}</clr-dg-cell>
          <clr-dg-cell class="dg-cells datagrid-align-cells" title="status">{{report?.submittedBy}}</clr-dg-cell>
          <clr-dg-cell class="dg-cells datagrid-align-cells" title="status">{{report?.lastUpdatedDate | date: 'mediumDate'}}</clr-dg-cell>
          <clr-dg-cell class="dg-cells table-actions-cell" >
            <div class="btn-group btn-link btn-icon">
              <button [title]="'View Batch Transactions'" [disabled]="report?.status !== 'COMPLETED'" class="btn btn-no-caps" (click)="viewBatchTransactions(report)">
                <clr-icon shape="eye"></clr-icon>
              </button>
              <button [title]="'Export Report'" [clrLoading]="reportIdLoadingExport == report?.id" [disabled]="report?.status !== 'COMPLETED'" class="btn btn-no-caps" (click)="exportReport(report)">
                <clr-icon shape="download"></clr-icon>
              </button>
              <button [title]="'Delete Report'" [clrLoading]="reportIdLoadingDelete == report?.id" [disabled]="report?.status === 'SUBMITTED' || report?.status === 'PROCESSING'" class="btn btn-no-caps" (click)="removeReport(report)">
                <clr-icon shape="trash" class="delete-icon"></clr-icon>
              </button>
            </div>
          </clr-dg-cell>
        </clr-dg-row>

        <clr-dg-footer>
          <clr-dg-pagination #pagination [clrDgPageSize]="tableControls.pageSize.value">
            {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
            of {{pagination.totalItems}} reports
          </clr-dg-pagination>
        </clr-dg-footer>
      </clr-datagrid>
    </div>
  </div>
</div>
