import {Component, OnDestroy, OnInit} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IimUser } from 'ui-sdk/models/iim-user.model';
import { AlertService } from '../../services/alert.service';
import { fadeInAnimation, fadeInDownAnimation, fadeInOutAnimation, fadeInLeftAnimation } from '../../animations';
import { documentDateValidator } from '../../validators/document-date.validator';
import { DateRangeValidator } from '../../validators/date-range.validator';
import { Subscription } from 'rxjs';
import { SavedSearch } from 'ui-sdk/models/saved-search.model';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { OperatorMap } from './search-index-operators';
import { DateFormatter } from '../../utility/date-formatter';
import {UserMethods} from 'ui-sdk';
import {SearchMethods} from 'ui-sdk';
import {DocumentTypeMethods} from 'ui-sdk';
import {DocumentMethods} from 'ui-sdk';

@Component({
  selector: 'app-legal-hold-page',
  templateUrl: './legal-hold-page.component.html',
  styleUrls: ['./legal-hold-page.component.scss'],
  animations: [
    fadeInDownAnimation,
    fadeInOutAnimation,
    fadeInAnimation,
    fadeInLeftAnimation
  ]
})
export class LegalHoldPageComponent implements OnInit, OnDestroy {

  SORT_KEY_LOCAL_STORAGE: string = 'IIM_table-sort_document-search-results';

  operatorMap = OperatorMap;

  searchForm: FormGroup;
  formErrors = {};
  validSearch = false;

  searchResults: Array<any> = null;
  loadingSearchResults = false;
  isSearchFormCollapsed = false;
  displayDateSearch = false;
  updatingSavedSearch = false;

  loadingDocTypes = false;

  legalHoldModalEvent = false;

  selectedDocumentTypeNames: Array<string> = [];
  selectedDocumentTypeIds: Array<string> = [];
  commonSearchIndexes: Array<any> = [];
  currentSearchIndexes: Array<any> = [];
  allsearchIndices: Array<any> = [];
  allsearchIndicesTable: Array<any> = [];

  initialDocTypes: Array<any> = [];
  documentTypes: Array<any> = [];
  documentTypeIdMap: {};
  documentTypeNameMap: {};
  searchIndices: Array<Object> = [];

  currentSearchIndexesValues: Object = {};
  searchIndexesPayload: object = {};

  currentUser: IimUser;

  showAdvancedSearch = false;
  showInactiveDocumentTypes: FormControl = new FormControl(true);
  showUniqueIndexes: FormControl = new FormControl(true);
  currentClientIndexes: Array<any> = [];


  subscriptions: Subscription = new Subscription();
  dropdownSettings: IDropdownSettings = {};

  // @ViewChild('savedSearchSelect') savedSearchSelect: SavedSearchSelectComponent;
  savedSearches: { initial: Array<SavedSearch>, loading: boolean } = {
    initial: [],
    loading: false
  };

  pieChartData: Array<number> = [];
  barChartLabel: Array<string> = [];
  barChartData: Array<number> = [];

  prevSavedSearch: SavedSearch;

  constructor(private alertService: AlertService) {}

  ngOnInit() {
    this.getChartData();
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'name',
      textField: 'description',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 7,
      allowSearchFilter: false
    };

    this.searchForm = this.createSearchFormGroup();
    this.subscriptions.add(UserMethods.currentUser$.subscribe(
      (user: IimUser) => {
        this.currentUser = user;
        this.resetSearchForm();
        if (this.currentUser) {
          this.loadDocumentTypes();
        }
      }
    ));

    this.showUniqueIndexes.setValue(false);
    this.onChanges();

    this.subscriptions.add(this.alertService.childEventListner().subscribe(event => {
      if (event === 'Refetch Documents') {
        this.legalHoldModalEvent = true;
        this.onSubmitSearch();
      }
    }));
  }

  getChartData() {
    DocumentMethods.getLegalHoldCount().then((res) => {
      let totalHolds = 0;
      let activeHolds = 0;
      this.barChartLabel = [];
      this.barChartData = [];
      res.map((holdData) => {
        totalHolds = totalHolds + holdData.totalLegalHolds;
        activeHolds = activeHolds + holdData.activeLegalHolds;
        this.barChartLabel.push(holdData.documentType);
        this.barChartData.push(holdData.activeLegalHolds);
      });
      this.pieChartData = [totalHolds, activeHolds];

    }, (err) => {
      const [title, msg] = [err.statusText, err.error?.messagee];
      this.alertService.showError(title, msg);
    });
  }

  onDocumentTypeSelect(item: any) {
    this.selectDocumentType(item.name);
    this.refreshSearchIndexes(this.showUniqueIndexes.value);
  }

  onDocumentTypeSelectAll(items: any) {
    this.selectedDocumentTypeNames = this.documentTypes.map(element => element.name);
    this.refreshSearchIndexes(this.showUniqueIndexes.value);
  }

  onDocumentTypeDeSelect(item: any) {
    const index = this.selectedDocumentTypeNames.indexOf(item.name);
    if (index >= 0) {
      this.selectedDocumentTypeNames.splice(index, 1);
    }
    this.refreshSearchIndexes(this.showUniqueIndexes.value);
  }

  onDocumentTypeDeSelectAll(items: any) {
    this.selectedDocumentTypeNames = [];
    this.refreshSearchIndexes(this.showUniqueIndexes.value);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  createSearchFormGroup(): FormGroup {
    return new FormGroup({
      assetType: new FormControl({ value: null, disabled: false }),
      docTypeNames: new FormControl({ value: null, disabled: true }, [Validators.required]),
      docDateStart: new FormControl({ value: null, disabled: true }, [documentDateValidator()]),
      docDateEnd: new FormControl({ value: null, disabled: true }, [documentDateValidator()]),
      mostRecent: new FormControl({ value: false, disabled: true }),
      annotationCriteria: new FormControl({ value: 'All Documents', disabled: true }),
    }, DateRangeValidator);
  }

  searchOnEnter(searchIndex, searchIndexValue, isFirstValue) {
    this.searchIndexFocusOut(searchIndex, searchIndexValue, isFirstValue);
    this.onSubmitSearch();
  }

  operatorSelect(operatorValue, searchIndex) {
    searchIndex['selectedOperator'] = operatorValue;

    if (!this.isMultiOperator(searchIndex)) {
      searchIndex['secondValue'] = '';
    }

    searchIndex['errors'] = [];
    this.updateCurrentSearchIndexes(searchIndex);
  }

  dateChange(searchIndex, dateValue: Date, isFirstValue) {
    this.searchIndexFocusOut(searchIndex, DateFormatter.formatDate(dateValue.toLocaleDateString()), isFirstValue);
  }

  clearDate(searchIndex) {
    searchIndex['value'] = '';
    searchIndex['secondValue'] = '';
    searchIndex['errors'] = [];

    delete this.currentSearchIndexesValues[searchIndex['searchIndex']];

    this.searchIndexFocusOut(searchIndex, searchIndex['value'], true);
  }

  searchIndexFocusOut(searchIndex, searchIndexValue, isFirstValue) {
    if (isFirstValue) {
      searchIndex['changed'] = true;
      searchIndex['value'] = searchIndexValue.trim();
    } else {
      searchIndex['secondChanged'] = true;
      searchIndex['secondValue'] = searchIndexValue.trim();
    }
    this.updateCurrentSearchIndexes(searchIndex);
  }

  updateCurrentSearchIndexes(searchIndex) {
    this.validateIndex(searchIndex);

    if (searchIndex['errors'].length < 1 && searchIndex['value'].length > 0) {
      this.currentSearchIndexesValues[searchIndex['searchIndex']] = JSON.parse(this.formatIndexValue(searchIndex));
    } else {
      delete this.currentSearchIndexesValues[searchIndex['searchIndex']];
    }
    this.validSearch = Object.keys(this.formErrors).length < 1 && Object.keys(this.currentSearchIndexesValues).length > 0;
  }

  validateIndex(searchIndex) {
    let errors = [];
    if (searchIndex['dataType'].includes('Integer')) {
      const firstValueIn = searchIndex['value'];
      if (firstValueIn.indexOf('.') !== -1) {
        errors[0] = ('First value should be Integer');
      }
    }
    if (errors.length === 0 && this.isMultiOperator(searchIndex)) {
      if (!searchIndex['dataType'].includes('Date')) {
        // tslint:disable-next-line:radix
        const firstValue = parseInt(searchIndex['value']);
        // tslint:disable-next-line:radix
        const secondValue = parseInt(searchIndex['secondValue']);

        if (isNaN(firstValue) && searchIndex['changed']) {
          errors[0] = ('First value cannot be empty');
        }

        if (isNaN(secondValue)) {
          errors[1] = ('Second value cannot be empty');
        }

        if ((typeof firstValue === 'number' && typeof secondValue === 'number') && firstValue >= secondValue) {
          errors[1] = ('Second value must be greater');
        }

        if (searchIndex['dataType'].includes('Integer')) {
          const secondValueIn = searchIndex['secondValue'];
          if (secondValueIn.indexOf('.') !== -1) {
            errors[1] = ('Second value should be Integer');
          }
        }
      } else {
        const firstDate = new Date(searchIndex['value']).getTime();
        const secondDate = new Date(searchIndex['secondValue']).getTime();

        if (isNaN(firstDate)) {
          errors[0] = ('First date cannot be empty');
        }

        if (isNaN(secondDate)) {
          errors[1] = ('Second date cannot be empty');
        }

        if ((typeof firstDate === 'number' && typeof secondDate === 'number') && firstDate >= secondDate) {
          errors[1] = ('Second date must be greater');
        }
      }
    }

    if (!searchIndex['value'] && !searchIndex['secondValue']) {
      errors = [];
    }

    searchIndex['errors'] = errors;

    if (errors.length > 0) {
      this.formErrors[searchIndex['searchIndex']] = true;
    } else {
      delete this.formErrors[searchIndex['searchIndex']];
    }
  }

  formatIndexValue(searchIndex) {
    let prop = '';
    if (searchIndex['selectedOperator'].includes('like')) {
      // Uses Wildcard Operators in the data value (Only String Datatype)
      switch (searchIndex['selectedOperator']) {
        case '*like':  // Ends With
          prop = `{"like": "*${searchIndex['value']}"}`;
          break;

        case 'like*':  // Starts With
          prop = `{"like": "${searchIndex['value']}*"}`;
          break;

        case '*like*': // Contains
          prop = `{"like": "*${searchIndex['value']}*"}`;
          break;
      }

    } else if (this.isMultiOperator(searchIndex)) {
      // Needs two values for this operator  (No String Datatype)
      switch (searchIndex.dataType) {
        case 'Date':
        case 'Time':
        case 'DateTime':
          prop = `{"${searchIndex['selectedOperator']}":
                  ["${searchIndex['value']}",
                   "${searchIndex['secondValue']}"]}`;
          break;
        case 'Number':
          prop = `{"${searchIndex['selectedOperator']}":
                  [${searchIndex['value']},
                   ${searchIndex['secondValue']}]}`;
          break;
        case 'Integer':
          prop = `{"${searchIndex['selectedOperator']}":
                  [${searchIndex['value']},
                   ${searchIndex['secondValue']}]}`;
          break;
        case 'Decimal':
          prop = `{"${searchIndex['selectedOperator']}":
                  [${searchIndex['value']},
                   ${searchIndex['secondValue']}]}`;
          break;
      }
    } else if (searchIndex['selectedOperator'] === 'relative') {
      // Needs Single relative value from today
      try {
        const now = new Date(Date.now() - (searchIndex['value'] * 24 * 60 * 60 * 1000)).toISOString().substring(0, 10);

        switch (searchIndex.dataType) {
          case 'Date':
          case 'Time':
          case 'DateTime':
            prop = `{"gte": "${now}"}`;
            break;
        }
      } catch (err) {
      }
    } else { // Not Relative Operator
      switch (searchIndex.dataType) {
        case 'String':
        case 'Date':
        case 'Time':
        case 'DateTime':
          prop = `{"${searchIndex['selectedOperator']}": "${searchIndex['value']}"}`;
          break;

        case 'Number':
          prop = `{"${searchIndex['selectedOperator']}": ${searchIndex['value']}}`;
          break;

        case 'Integer':
          prop = `{"${searchIndex['selectedOperator']}": ${searchIndex['value']}}`;
          break;

        case 'Decimal':
          prop = `{"${searchIndex['selectedOperator']}": ${searchIndex['value']}}`;
          break;
        default:
      }
    }
    return prop;
  }

  searchIndexesPrependPrep() {
    for (const key in this.currentSearchIndexesValues) {
      if (this.currentSearchIndexesValues[key].toString().trim()) {
        const newKey = `indexes.${key}`;
        this.searchIndexesPayload[newKey] = this.currentSearchIndexesValues[key];
        delete this.searchIndexesPayload[key];
      }
    }
  }

  resultSorter(x, y) {
    if (x.uploadDate > y.uploadDate) { return -1; }
    if (x.uploadDate < y.uploadDate) { return 1; }
    return 0;
  }

  onSubmitSearch(): void {
    this.searchIndexesPayload = {};
    this.loadingSearchResults = true;
    this.searchIndexesPrependPrep();

    this.searchResults = null;  // reset the Search Results

    if (Object.getOwnPropertyNames(this.searchIndexesPayload).length !== 0) {

      this.searchIndexesPayload['documentTypeId'] = this.selectedDocumentTypeNames;
      SearchMethods.documentSearch(this.searchIndexesPayload).then(results => {
        if (results.length < 1) {
          const [title, msg] = ['Warning', 'No Documents match the specified search criteria. Please try again.'];
          this.alertService.showWarning(title, msg);
          this.loadingSearchResults = false;
          this.searchIndexesPayload = [];
        } else {
          results.sort(this.resultSorter);
          this.isSearchFormCollapsed = true;
          this.searchResults = results;
          this.loadingSearchResults = false;
        }
      }, error => {
        if (error.status === 403) {
          const [title, msg] = ['Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [CREATE DocumentSearch].`];
          this.alertService.showError(title, msg);
        } else {
          const [title, msg] = ['Error', 'Unable to retrieve documents. Please refresh the page and try again.'];
          this.alertService.showError(title, msg);
          this.loadingSearchResults = false;

        }
      });
    } else {
      this.loadingSearchResults = false;
      if (!this.legalHoldModalEvent) {
        const [title, msg] = ['Error', 'Unable to retrieve documents. Please provide at least one search criteria.'];
        this.alertService.showError(title, msg);
      }
    }
  }

  resetSearchForm(preserveSavedSearch?: boolean): void {
    for (let a = 0; a < this.documentTypes.length; a++) {
      const element = document.getElementById(`buttonDoctype${a + 1}`);
    }

    this.selectedDocumentTypeNames = [];
    this.allsearchIndices = [];
    this.commonSearchIndexes = [];
    this.currentSearchIndexesValues = [];
    this.searchIndexesPayload = [];

    this.searchForm.get('docTypeNames').reset({ value: null, disabled: false }, [Validators.required]);
    this.searchForm.get('docDateStart').reset({ value: null, disabled: true }, [documentDateValidator()]);
    this.searchForm.get('docDateEnd').reset({ value: null, disabled: true }, [documentDateValidator()]);
    this.searchForm.get('mostRecent').reset({ value: false, disabled: true });
    this.searchForm.get('annotationCriteria').reset({ value: 'All Documents', disabled: true });
    this.showInactiveDocumentTypes.reset();
    this.showUniqueIndexes.reset();
    this.searchResults = null;
    this.isSearchFormCollapsed = false;
    this.validSearch = false;
  }

  onChanges() {
    this.subscriptions.add(this.showInactiveDocumentTypes.valueChanges.subscribe((value: boolean) => {
      this.refreshDocTypes();
    }));

    this.subscriptions.add(this.showUniqueIndexes.valueChanges.subscribe((value: boolean) => {
      this.refreshSearchIndexes(value);
    }));
  }

  loadDocumentTypes(): void {
    this.loadingDocTypes = true;

    DocumentTypeMethods.getDocumentTypes().then(docTypes => {
      this.initialDocTypes = docTypes;
      this.loadingDocTypes = false;
      this.refreshDocTypes();

    }, error => {
      this.loadingDocTypes = false;

      if (error.status === 403) {
        const [title, msg] = ['Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [GET DocumentTypes].`];
        this.alertService.showError(title, msg);
      } else {
        const [title, msg] = ['Error', 'Could not retrieve Document Types. Please try again.'];
        this.alertService.showError(title, msg);
      }
    });
  }

  clearClientIndexes(): void {
    this.currentClientIndexes.forEach(ci => {
    });
    this.currentClientIndexes = [];
  }

  // this method refreshed the displayed Document list in the dropdown.
  // initialDocTypes always contains the complete list
  // documentTypes contains the filtered list in the dropdown
  // showInactive is directly bound to the checkbox indicating that they should/shouldnot display Inactive Document Types.
  refreshDocTypes() {
    const showInactive: boolean = this.showInactiveDocumentTypes.value;
    this.documentTypes = showInactive ? this.initialDocTypes : this.initialDocTypes.filter(initialDocTypes => initialDocTypes.status === 'Active'); // Only Active Document Types

    this.searchIndices = [];
    this.currentSearchIndexesValues = {};
    this.searchIndexesPayload = {};
    this.commonSearchIndexes = [];

    // Rebuild Document Id Map
    const documentTypeIdMap = {};
    const documentTypeNameMap = {};

    this.documentTypes.map(element => {
      documentTypeIdMap[element['id']] = element;
    });

    this.documentTypes.map(element => {
      documentTypeNameMap[element['name']] = element;
    });

    this.documentTypeIdMap = documentTypeIdMap;
    this.documentTypeNameMap = documentTypeNameMap;
    if (this.documentTypes.length >= 1) {
        this.selectedDocumentTypeNames = [];
        this.onDocumentTypeSelect(this.documentTypes[0]);
      // tslint:disable-next-line:max-line-length
        this.searchForm.controls.docTypeNames.setValue([{ name: this.documentTypes[0].name, description: this.documentTypes[0].description }]);

    } else {
      this.selectedDocumentTypeNames = [];
      this.searchForm.controls.docTypeNames.setValue([]);
    }
    this.refreshSearchIndexes(this.showUniqueIndexes.value);
  }

  selectDocumentType(docTypeName) {
    this.searchIndices = [];
    this.currentSearchIndexesValues = {};
    this.searchIndexesPayload = {};
    this.displayDateSearch = true;
    this.commonSearchIndexes = [];

    this.selectedDocumentTypeNames = [docTypeName]; // this should be removed once multiselect functionlity is used.

  }

  refreshSearchIndexes(showUnique: boolean) {
    this.commonSearchIndexes = [];
    this.currentSearchIndexesValues = {};
    this.allsearchIndices = [];
    this.validSearch = false;
    this.extractAllSearchIndexes();
  }

  deepEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = this.isObject(val1) && this.isObject(val2);
      if (areObjects && !this.deepEqual(val1, val2) || !areObjects && val1 !== val2) {
        return false;
      }
    }

    return true;
  }

  isObject(obj) {
    return obj != null && typeof obj === 'object';
  }

  extractAllSearchIndexes() {
    const allIndexKeys = [];
    const allUniqueSearchIndices = new Map<String, String>();
    const commonSearchIndices = new Map<String, String>();

    if (this.documentTypes) {
      this.selectedDocumentTypeNames.forEach(selectedDoctype => {
        this.documentTypes.forEach(documentType => {
          if (documentType) {
            if (documentType['name'] === selectedDoctype) {
              documentType['searchIndices'].forEach(indexObject => {

                // validation values
                indexObject['indexOperators'] = this.getOperators(indexObject['dataType']);
                indexObject['selectedOperator'] = 'eq';
                indexObject['value'] = '';
                indexObject['changed'] = false;
                indexObject['errors'] = [];

                if (indexObject['dataType'] !== 'String') {
                  indexObject['secondValue'] = '';
                  indexObject['secondChanged'] = false;
                }

                if (!allUniqueSearchIndices.get(indexObject.searchIndex)) {

                  allUniqueSearchIndices.set(indexObject.searchIndex, indexObject);
                } else {
                  if (this.deepEqual(allUniqueSearchIndices.get(indexObject.searchIndex), indexObject)) {
                    commonSearchIndices.set(indexObject.searchIndex, indexObject);
                  }
                }

                allIndexKeys.push(indexObject.searchIndex);
              });
            }
          }
        });
      });
    }

    // tslint:disable-next-line:max-line-length
    this.allsearchIndicesTable = Array.from(allUniqueSearchIndices, ([name, indexObject]) => ({ 'key': name, 'name': name, 'label': indexObject['label'] , 'dataType': indexObject['dataType']  }));
    this.allsearchIndices = Array.from(allUniqueSearchIndices, ([name, indexObject]) => (indexObject));
    this.commonSearchIndexes = Array.from(commonSearchIndices, ([name, indexObject]) => (indexObject));

    if (this.selectedDocumentTypeNames.length === 1 || this.showUniqueIndexes.value) {
      this.commonSearchIndexes = this.allsearchIndices;
    }
  }

  isMultiOperator(searchIndex): boolean {
    let indexOperator;

    if (searchIndex && searchIndex['indexOperators']) {
      indexOperator = searchIndex['indexOperators'].find(io => {
        return io.value === searchIndex['selectedOperator'];
      });
    }

    return (indexOperator && indexOperator.multi);
  }
  getOperators(datatype: any) {
    return this.operatorMap[datatype];
  }
}
