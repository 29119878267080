import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'ui-sdk';
import { Observable } from 'rxjs';
import { httpOptions } from '../../../auth-headers';
import { UserService } from './user.service';      // SWF STUBOUT

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  httpOptions = httpOptions;
  baseUrl: string = `${environment.baseUrl}/client`;

  constructor(private http: HttpClient, private userService: UserService) { }

  getSelectedClient(): string {
    return this.userService.currentClientId;
  }

  // ************************** LEGAL HOLD METHODS **************************

  createLegalHold(legalHold: any) {
    const url = `${this.baseUrl}/${this.getSelectedClient()}/legalHold`;
    const legalHoldPayload = JSON.stringify(legalHold);
    return this.http.post(url, legalHoldPayload, this.httpOptions);
  }

  updateLegalHold(legalHold: any) {
    const url = `${this.baseUrl}/${this.getSelectedClient()}/legalHold/${legalHold.legalHoldId}`;
    const legalHoldPayload = JSON.stringify(legalHold);
    return this.http.put(url, legalHoldPayload, this.httpOptions);
  }

  getLegalHolds(): Observable<any> {
    const url = `${this.baseUrl}/${this.getSelectedClient()}/legalHold`;
    return this.http.get(url, this.httpOptions);
  }

  deleteLegalHold(legalHold: any): Observable<any> {
    const url = `${this.baseUrl}/${this.getSelectedClient()}/legalHold/${legalHold.legalHoldId}`;
    return this.http.delete(url, this.httpOptions);
  }

  // Info, Remove
  getLegalHoldInfo(getlegalHoldPayload: Object) {
    const url = `${this.baseUrl}/${this.getSelectedClient()}/legalHoldSearch`;
    return this.http.post(url, getlegalHoldPayload, this.httpOptions);
  }

  removeDocumentsFromLegalHold(legalHoldPayload: Object, legalHoldGroupId: string) {
    const url = `${this.baseUrl}/${this.getSelectedClient()}/legalHold/${legalHoldGroupId}`;
    this.httpOptions['body'] = legalHoldPayload;

    return this.http.delete(url, this.httpOptions);
  }
}
