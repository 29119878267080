import { FormGroup, ValidatorFn } from '@angular/forms';

export const DateRangeValidator: ValidatorFn = (fg: FormGroup) => {
  const start = fg.get('docDateStart').value;
  const end = fg.get('docDateEnd').value;
  if (!start || !end) {
    return null;
  } else {
    try {
      return new Date(start) < new Date(end) ? null : {'range': true};
    } catch {
      return {'range': true};
    }
  }
};
