import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { httpOptions } from '../../../auth-headers';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {IimUserRole, IimUser, environment} from 'ui-sdk';

@Injectable({
  providedIn: 'root'
})

export class UserService {
  httpOptions = httpOptions;

  currentUser$ = new BehaviorSubject<IimUser>(null);
  private _currentUser: IimUser;

  get currentClientId(): string {
    if (this._currentUser?.selectedClient?.clientId) {
      return this._currentUser.selectedClient.clientId;
    } else {
      this.getUserProfile();
    }
  }

  loading: boolean = false;

  constructor(private http: HttpClient) {
  }

  getUserProfile(): Observable<IimUser> {
    this.loading = true;
    const url = `${environment.baseUrl}/user/current`;
    return this.http.get<IimUser>(url, this.httpOptions)
      .pipe(
        tap(
          (user: IimUser) => {
            user.permissions.forEach(client => {
              const role = new IimUserRole();
              if (client.role === 'SuperUser') {
                role.roleName = 'SuperAdmin';
                role.permissions = [
                  'READ',
                  'CREATE',
                  'UPDATE',
                  'DELETE'
                ];
              } else {
                role.roleName = 'ReadUser';
                role.permissions = [
                  'READ',
                ];
              }
              client.roles = [ role ];
            });

            this.loading = false;
            this._currentUser = new IimUser(user);
            this.currentUser$.next(this._currentUser);
            return this._currentUser;
          }, error => {
            this.loading = false;
          }
        )
      );
    }

  hasAdminAccess(): boolean {
    if (this._currentUser) {
      return this._currentUser.role.includes('admin')
        || this._currentUser.permissions.clientCollection.read
        || this._currentUser.permissions.documentType.read
        || this._currentUser.permissions.documentMetadata.read
        || this._currentUser.permissions.retentionPolicy.read
        || this._currentUser.permissions.client.read
        || this._currentUser.permissions.users.read;
    } else {
      return false;
    }
  }
}
