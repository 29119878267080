import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AlertService } from '../../../services/alert.service';
import { fadeInAnimation } from '../../../animations';
import { IimUser } from 'ui-sdk/models/iim-user.model';
import { IimDocumentMetadata } from 'ui-sdk/models/iim-document-metadata.model';
import { DateFormatter } from '../../../utility/date-formatter';
import {UserMethods} from 'ui-sdk';
import {DocumentTypeMethods} from 'ui-sdk';
import {DocumentMethods} from 'ui-sdk';
import {FileSizeCalc} from '../../../utility/fie-size-calc';

@Component({
  selector: 'app-upload-modal',
  templateUrl: './upload-modal.component.html',
  styleUrls: ['./upload-modal.component.scss'],
  animations: [fadeInAnimation]
})

export class UploadModalComponent implements OnInit, OnDestroy {
  @Input() clientId: string;
  @Output() close: EventEmitter<any> = new EventEmitter();

  docTypes: Array<any> = [];
  subscriptions: Subscription = new Subscription();
  currentUser: IimUser;
  searchIndices: Array<Object> = [];
  fileContentPartsForUpload: Array<any> = [];

  uploadTried: boolean = false;
  uploadCompleted: boolean = false;

  @ViewChild('fileInput') fileInput: ElementRef;
  uploadForm: FormGroup;

  fileContentsMap: Map<string, File> = new Map<string, File>();
  fileUploadProgress: Map<string, number> = new Map<string, number>();
  fileNames: Array<string> = [];

  metadataUploadPayload: IimDocumentMetadata = {
    fileNames: [],
    documentTypeId: '',
    metadata: [],
    additionalData: {}
  };

  isOpen: boolean = false;
  allowedMimeTypes: Array<string> = [
    'application/zip',
    'application/x-zip-compressed',
    'image/png',
    'image/tiff',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/pdf',
    'text/plain',
    'text/csv',
    'image/jpeg'
  ];
  showUnsupportedFileWarning: boolean = false;
  documentTypeChange$: Subscription;
  uploadInProgress: boolean;
  uploadProgressTotal: number = 0;
  uploadProgressCurrent: number = 0;

  @ViewChild('fileDropRef', {read: ElementRef}) fileDropRef: ElementRef;

  constructor(private alertService: AlertService) {}

  ngOnInit() {
    this.uploadForm = new FormGroup({
      docType: new FormControl({value: null, disabled: false}, [Validators.required])
    });

    const userSubscription = UserMethods.currentUser$.subscribe((user: IimUser) => this.onUserLoaded(user));
    this.subscriptions.add(userSubscription);

    this.documentTypeChange$ = this.uploadForm.get('docType').valueChanges.subscribe((documentTypeId) => {
      this.searchIndices = [];
      this.metadataUploadPayload.documentTypeId = documentTypeId;
      this.metadataUploadPayload.metadata = [];
      this.metadataUploadPayload.additionalData = {};

      this.extractSearchIndexes(documentTypeId);
    });

    this.subscriptions.add(this.alertService.childEventListner().subscribe(event => {
      if (event === 'Upload Modal') {
        document.getElementById('upload-modal__file').click();
      }
    }));
  }

  extractSearchIndexes(documentTypeId) {
    if (this.docTypes) {
      const numOfObjects = this.docTypes.length;

      for (let i = 0; i < numOfObjects; i++) {
        if (this.docTypes[i]) {
          if (this.docTypes[i]['id'] === documentTypeId) {
            this.searchIndices = (this.docTypes[i]['searchIndices']);

            const metadataObject = {};

            for (let a = 0; a < this.searchIndices.length; a++) {
              metadataObject[this.searchIndices[a]['searchIndex']] = '';
            }

            this.metadataUploadPayload.metadata.push(metadataObject);
          }
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.documentTypeChange$.unsubscribe();
  }

  onUserLoaded(user: IimUser) {
    this.currentUser = user;
  }

  private loadDocumentTypes(): void {
    DocumentTypeMethods.getDocumentTypes().then(docTypes => {
      this.docTypes = docTypes.filter(docType => docType.status === 'Active');
    }, error => {
      if (error.status === 403) {
        const [title, msg] = ['Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [GET DocumentTypes].`];
        this.alertService.showError(title, msg);
      } else {
        const [title, msg] = ['Error', 'Could not retrieve Document Types. Please try again.'];
        this.alertService.showError(title, msg);
      }
    });
  }

  fileBrowserHandler(file: File) {
    this.fileNames = Array.from(this.fileContentsMap.keys());

    const fileName = file.name.split(' ').join('_');

    if ((this.fileNames && this.fileNames.indexOf(fileName) < 0) || !this.fileNames) {
      this.uploadTried = false;

      this.fileNames.push(fileName);

      this.fileContentsMap.set(fileName, file);
      this.fileUploadProgress.set(fileName, 0);
    }
  }

  onFilelistChange(files: Array<File>) {
    for (let i = 0; i < files.length; i++) {
      this.fileBrowserHandler(files[i]);
    }
  }

  deleteSelectedFile(fileName) {
    this.fileContentsMap.delete(fileName);
    this.fileUploadProgress.delete(fileName);

    this.fileNames.splice(this.fileNames.indexOf(fileName), 1);
    this.fileDropRef.nativeElement.value = null;
  }

  bytesToSize(bytes): string {
    return FileSizeCalc.bytesToSize(bytes);
  }

  openModal() {
    this.loadDocumentTypes();
    this.isOpen = true;
  }

  searchIndexFocusOut(searchIndexKey: string, searchIndexValue: string, isDate: boolean) {
    if (isDate) {
      const dateValue = new Date(searchIndexValue);

      this.metadataUploadPayload.metadata[0][searchIndexKey] = DateFormatter.formatDate(dateValue.toLocaleDateString());
    } else if (searchIndexValue.replace(/\s/g, '').length) {
      this.metadataUploadPayload.metadata[0][searchIndexKey] = searchIndexValue;
    } else {
      delete this.metadataUploadPayload.metadata[0][searchIndexKey];
    }
  }

  removeEmptyIndexes() {
    if (this.metadataUploadPayload.metadata[0]) {
      Object.keys(this.metadataUploadPayload.metadata[0]).forEach(searchIndexKey => {
        if (!this.metadataUploadPayload.metadata[0][searchIndexKey]) {
          delete this.metadataUploadPayload.metadata[0][searchIndexKey];
        }
      });
    }
  }

  checkMetadataEmpty(): boolean {
    if (this.uploadForm.get('docType').value && this.metadataUploadPayload.metadata[0]) {
      this.removeEmptyIndexes();
      return Object.keys(this.metadataUploadPayload.metadata[0]).length < 1;
    } else {
      return true;
    }
  }

  onSubmitUpload()  {
    this.removeEmptyIndexes();

    this.uploadInProgress = true;
    this.metadataUploadPayload.fileNames = Array.from(this.fileContentsMap.keys());

    DocumentMethods.uploadMetaData(this.metadataUploadPayload).then(uploadMetadataResponse => {
      const fileNames = Object.keys(uploadMetadataResponse['uploadUrls']);
      const uploadUrls = Object.entries(uploadMetadataResponse['uploadUrls']);

      let successfullUploadsCounter = 0;

      for (const [fileName, uploadUrl] of uploadUrls) {
        const blob = new Blob([this.fileContentsMap.get(fileName)], {type: 'application/octet-stream'});

        DocumentMethods.uploadFile(uploadUrl, blob).then(() => {
          this.fileUploadProgress.set(fileName, 100);
          successfullUploadsCounter++;

          if (successfullUploadsCounter === fileNames.length) {
            const [title, msg] = ['Uploaded', `File(s) uploaded successfully.`];
            this.alertService.showSuccess(title, msg);
            this.uploadInProgress = false;
            this.uploadCompleted = true;

            this.closeModal();
          }
        }, error => {
          this.uploadInProgress = false;

          const [title, msg] = ['Error', 'An error occurred during file upload. Please try again.'];
          this.alertService.showError(title, msg);
        });
      }
    }, error => {
      this.uploadInProgress = false;
      if (error.status === 403) {
        const [title, msg] = ['Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [CREATE DocumentMetadata].`];
        this.alertService.showError(title, msg);
      } else {
        const [title, msg] = ['Error', 'An error occurred during file upload. Please try again.'];
        this.alertService.showError(title, msg);
      }
    });
  }

  isMultiOperator(searchIndex): boolean {
    const indexOperator = searchIndex['indexOperators'].find(io => {
      return io.value === searchIndex['selectedOperator'];
    });

    return (indexOperator && indexOperator.multi);
  }

  clearDate(searchIndexKey) {
    delete this.metadataUploadPayload.metadata[0][searchIndexKey];
  }

  isValidFile(file: File) {
    return this.allowedMimeTypes.some(type => file.type === type);
  }

  clickHiddenSubmitButton() {
    document.getElementById('upload-submit').click();
  }

  closeModal() {
    this.docTypes = [];
    this.isOpen = false;
    this.uploadForm.reset();
    this.searchIndices = [];

    this.fileContentsMap.clear();
    this.fileUploadProgress.clear();
    this.fileNames = [];
    this.uploadCompleted = false;

    this.close.emit();
  }

  uploadPercentComplete(): number {
    const percent = Math.round(100 * this.uploadProgressCurrent / this.uploadProgressTotal);
    return isNaN(percent) ? 0 : percent;
  }
}
