import {Component, OnDestroy, OnInit} from '@angular/core';
import {IimClient} from 'ui-sdk/models/iim-client.model';
import {interval, Subscription} from 'rxjs';
import {IimUser} from 'ui-sdk/models/iim-user.model';
import {FormControl} from '@angular/forms';
import {AlertService} from '../../services/alert.service';
import {AuthClass, DocumentTypeMethods, environment, HttpMethodsEnum, UserMethods} from 'ui-sdk';
import {HttpHeaders} from '@angular/common/http';
import {CSVExportService} from '../../services/export-csv.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-report-page',
  templateUrl: './report-page.component.html',
  styleUrls: ['./report-page.component.scss']
})
export class ReportPageComponent implements OnInit, OnDestroy {
  public static TRANSACTION_COUNT_THRESHOLD = 1000;

  allClientReports: Array<any>;
  loadingReports: boolean = false;
  reportIdLoadingExport: string = '';
  reportIdLoadingDelete: string = '';

  copyIdToClipboard: string;
  timer;

  clients: Array<IimClient> = [];

  subscriptions: Subscription = new Subscription();
  currentUser: IimUser;

  isDevelopmentInProgress = true;

  isAutoRefreshEnabled = false;
  AUTO_REFRESH_INTERVAL = 10000; // 10 seconds
  autoRefreshSubscription: Subscription = new Subscription();

  tableControls: { propertiesFilter: FormControl, pageSize: FormControl, textFilter: FormControl } = {
    propertiesFilter: new FormControl([]),
    pageSize: new FormControl(10),
    textFilter: new FormControl('')
  };

  columnIndexKeys: Array<string>;
  columnIndexes: any;
  columnIndexesViewTransactions = [];
  metadataColumnIndexes: any;

  documentTypeSearchIndexesMap: Map<string, any> = new Map();
  flattenedReportJsonArr: any;

  exportFileName: string;

  constructor(private alertService: AlertService, private csvExportService: CSVExportService, private router: Router) { }

  ngOnInit(): void {
    const userSubscription = UserMethods.currentUser$.subscribe((user: IimUser) => this.onUserLoaded(user));
    this.subscriptions.add(userSubscription);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.autoRefreshSubscription.unsubscribe();
  }

  onUserLoaded(user: IimUser) {
    this.currentUser = user;
    if (this.currentUser) {
      if (this.currentUser.selectedClient.uiperms.report.read) {
        this.getClientReports();
      }
    }
  }

  getSearchIndexes(documentTypeId: string) {
    DocumentTypeMethods.getSearchIndexes(documentTypeId).then(docType => {
      this.documentTypeSearchIndexesMap.set(documentTypeId, docType.searchIndices);
    });
  }

  async getClientReports() {
    this.loadingReports = true;
    this.allClientReports = [];

    DocumentTypeMethods.getDocumentTypes(false).then(docTypes => {
      docTypes.forEach(async docType => {

        await this.getSearchIndexes(docType.id);

        this.getBatchReports(docType.id).then(reports => {
          reports.forEach(report => {
            report['documentTypeName'] = docType.name;
            this.allClientReports.push(report);
          });

          this.loadingReports = false;

        }, error => {
          this.loadingReports = false;

          if (error.status === 403) {
            const [title, msg] = ['Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [GET Batch Reports].`];
            this.alertService.showError(title, msg);
          } else {
            this.alertService.showError('Error', 'Unable to get Batch Reports');
          }
        });
      });
    }, docTypeError => {
      if (docTypeError.status === 403) {
        this.alertService.showError('Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [GET DocumentTypes].`);
      } else {
        this.alertService.showError('Error', 'Unable to get Document Types');
      }

      this.loadingReports = false;
    });
  }

  exportReport(report: any) {
    this.reportIdLoadingExport = report.id;

    function zeroPad(num, places) {
      return String(num).padStart(places, '0');
    }

    this.extractAllSearchIndexes(report.documentTypeId);
    let url = '';

    this.getPresignedUrl(report).then(presignedUrlResponse => {
      url = presignedUrlResponse['downloadUrl'];

      this.getBatchReportContent(url).then(async contentResponse => {
        const responseReportJson = JSON.parse(await  contentResponse.text());
        this.flattenedReportJsonArr = [];

        this.exportFileName = '';

        responseReportJson.forEach(transaction => {
          const document = {};
          document['Name'] = transaction['fileNames'] ? transaction['fileNames'].join(' , ') : '-';
          document['DocumentId'] = transaction['id'] ? transaction['id'] : '-';
          document['DocumentTypeName'] = transaction['documentTypeName'] ? transaction['documentTypeName'] : '-';
          document['DocumentStatus'] = transaction['status'] ? transaction['status'] : '-';
          document['StorageType'] = transaction['storageType'] ? transaction['storageType'] : '-';
          document['ClientId'] = transaction['clientId'] ? transaction['clientId'] : '-';
          document['LegalHold'] = !!transaction['legalHold'] ? transaction['legalHold'] : false;
          document['MediaType'] = transaction['mediaType'] ? transaction['mediaType'] : '-';
          document['Errasure'] = !!transaction['errasure'] ? transaction['errasure'] : false;

          if (!transaction.hasOwnProperty('metadata') || Object.entries(transaction['metadata']).length === 0) {
            transaction['metadata'] = {};
          }

          let searchKeyIndex = 0;
          this.columnIndexKeys.forEach(indexKey => {
            let normalizedIndex = indexKey;
            if (normalizedIndex.startsWith('indexes.')) {
              normalizedIndex = normalizedIndex.substring(normalizedIndex.indexOf('indexes.') + 8);
            }

            let index = indexKey;
            if (!index.startsWith('indexes.')) {
              index = `indexes.${indexKey}`;
            }

            if (!transaction.metadata.hasOwnProperty(index)) {
              document[`INDEX${zeroPad(searchKeyIndex, 4)}_${normalizedIndex}`] = '';

            } else {
              if (this.exportFileName === '' && index.toLowerCase().includes('batchid')) {
                this.exportFileName = transaction['metadata'][index];
              }

              // tslint:disable-next-line:max-line-length
              document[`INDEX${zeroPad(searchKeyIndex, 4)}_${normalizedIndex}`] = (transaction['metadata'][index] ? transaction['metadata'][index] : '');
            }

            searchKeyIndex++;
          });

          this.flattenedReportJsonArr.push(document);
        });

        this.generateReportCsv();

        this.reportIdLoadingExport = '';

      }, error => {
        this.reportIdLoadingExport = '';
      });
    }, error => {
      this.reportIdLoadingExport = '';
      this.alertService.showError('Error', 'Error occurred while attempting download batch report.');
    });
  }

  generateReportCsv() {
    const exportOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'Export Batch Report',
      useBom: true,
      noDownload: false,
      headers: ['Document Name',
        'Document Id',
        'Document Type Name',
        'Document Status',
        'Storage Type',
        'ClientId',
        'Legal Hold',
        'Media Type',
        'Errasure'
      ]
    };

    this.columnIndexKeys.forEach(key => {
      exportOptions.headers.push(this.columnIndexes.get(key)['label']);
    });

    this.csvExportService.generateCsv(this.flattenedReportJsonArr, this.exportFileName, exportOptions);
  }

  removeReport(report: any): void {
    let  [title, msg] = [`Delete Report`,
      `Are you sure you want to delete the Report for Batch '${report.batchId}'?`];
    this.alertService.confirmDangerousAction(title, msg).then(confirmed => {
      if (confirmed) {
        this.reportIdLoadingDelete = report.id;

        this.deleteBatchReport(report).then(() => {
          this.reportIdLoadingDelete = '';

          [title, msg] = ['Success', `Report for Batch '${report.batchId}' Deleted`];
          this.alertService.showSuccess(title, msg);

          this.getClientReports();

        }, deleteError => {
          this.reportIdLoadingDelete = '';
          if (deleteError.status === 403) {
            [title, msg] = ['Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [DELETE Batch Report].`];
            this.alertService.showError(title, msg);
          } else {
            [title, msg] = ['Error', 'Error occurred while attempting to delete the Batch Report. Please try again.'];
            this.alertService.showError(title, msg);
          }
        });
      }
    });
  }

  notify(event: any) {
    this.copyIdToClipboard = event;

    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      this.copyIdToClipboard = '';
    }, 3000);
  }

  toggleAutoRefreshReports() {
    this.isAutoRefreshEnabled = !this.isAutoRefreshEnabled;
    this.autoRefreshReports();
  }

  autoRefreshReports() {
    if (this.isAutoRefreshEnabled) {
      this.autoRefreshSubscription = interval(this.AUTO_REFRESH_INTERVAL).subscribe(() => {
        this.getClientReports();
      });
    } else {
      this.autoRefreshSubscription.unsubscribe();
    }
  }

  extractAllSearchIndexes(documentTypeId: string) {
    const searchIndexes = JSON.parse(JSON.stringify(this.documentTypeSearchIndexesMap.get(documentTypeId).filter(index => index.showInResult)));
    this.columnIndexKeys = searchIndexes.map(index => index.searchIndex);
    this.columnIndexes = new Map();
    searchIndexes.forEach(searchIndex => {
      this.columnIndexes.set(searchIndex.searchIndex, searchIndex);

      this.columnIndexesViewTransactions.push(
        {
          key: searchIndex.searchIndex,
          name: searchIndex.searchIndex,
          label: searchIndex.label,
          dataType: searchIndex.dataType
        }
      );
    });
  }

  deleteBatchReport(report): Promise<any> {
    const urlPath = `${environment.baseUrl}/client/${this.currentUser.selectedClient.clientId}/documentType/${report.documentTypeId}/batchReport/${report.id}`;

    return AuthClass.doAuthenticatedCall(HttpMethodsEnum.DELETE, urlPath).toPromise();
  }

  getBatchReports(documentTypeId): Promise<any> {
    const urlPath = `${environment.baseUrl}/client/${this.currentUser.selectedClient.clientId}/documentType/${documentTypeId}/batchReport`;

    return AuthClass.doAuthenticatedCall(HttpMethodsEnum.GET, urlPath).toPromise();
  }

  getPresignedUrl(report): Promise<any> {
    const urlPath = `${environment.baseUrl}/client/${this.currentUser.selectedClient.clientId}/documentType/${report.documentTypeId}/batchReport/${report.id}/content`;

    return AuthClass.doAuthenticatedCall(HttpMethodsEnum.GET, urlPath).toPromise();
  }

  getBatchReportContent(url: string): Promise<any> {
    const customHeaders = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Access-Control-Allow-Origin':  '*'
      }),
      'responseType': 'blob' as 'json'
    };

    return AuthClass.doAuthenticatedCall(HttpMethodsEnum.GET, url, null, true, customHeaders).toPromise();
  }

  viewBatchTransactions(report: any) {
    this.extractAllSearchIndexes(report.documentTypeId);

    const params = {};

    if (report.id !== null) {
      params['batchReportId'] = report.id;
    }
    if (report.transactionCount > 0) {
      params['transactionCount'] = report.transactionCount;
    }
    if (report.batchId !== null) {
      params['batchId'] = report.batchId;
    }
    if (report.documentTypeId !== null) {
      params['documentTypeId'] = report.documentTypeId;
    }
    if (report.documentTypeName !== null) {
      params['documentTypeName'] = report.documentTypeName;
    }

    localStorage.setItem(`${report.batchId}_indexes`, JSON.stringify(this.columnIndexesViewTransactions));

    const batchUrl = this.router.serializeUrl(
      this.router.createUrlTree(['/batch-transactions'], {queryParams: params})
    );

    window.open(batchUrl, '_blank');
  }
}
