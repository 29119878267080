<div class="clr-row">
  <div class="clr-col-xs-12 clr-col-md-12">
    <div *ngIf="!loading" class="clr-row additional-actions">
      <div class="clr-col-xs-12 clr-col-sm-12">
        <div class="btn-group btn-link" style="align-items: center;">
        </div>
      </div>
    </div>
    <div *ngIf="!loading" class="clr-row">
      <div class="table-actions__bulk-actions clr-col-xs-12 clr-col-md" *ngIf="currentUser?.selectedClient?.uiperms?.task?.bulkActions">
        <button *ngIf="currentUser?.selectedClient?.uiperms?.legalhold?.create && (!searchResultsConfig || searchResultsConfig['actionButtons'] !== 'viewBatchAndGenerateReport')" [disabled]="documents.selected?.length === 0" (click)="openCreateLegalHoldModal()" class="btn btn-primary"
                title="Hold Selected">Hold Selected</button>
      </div>
      <div class="table-actions__sort clr-col-xs-12 clr-col-md">
        Show
        <div class="clr-select-wrapper">
          <select class="clr-select" name="pageAmount" id="pageAmount" [formControl]="tableControls.pageSize">
            <option [ngValue]="25">25</option>
            <option [ngValue]="50">50</option>
          </select>
        </div>
        items per page
      </div>
      <button *ngIf="currentUser?.selectedClient?.uiperms?.documentcontent.read && searchResultsConfig['actionButtons'] !== 'viewBatchAndGenerateReport'" [disabled]="documents.current?.length === 0" (click)="exportSearchResults()" class="btn btn-icon btn-link"
             title="Export Results"><clr-icon size="20" shape="export"></clr-icon></button>

    </div>
    <clr-datagrid [clrDgLoading]="loading" [(clrDgSelected)]="documents.selected">
      <clr-dg-column class="table-actions-column" *ngIf="currentUser?.selectedClient?.uiperms?.legalhold?.create && (!searchResultsConfig || searchResultsConfig['actionButtons'] !== 'viewBatchAndGenerateReport')">
        <ng-container *clrDgHideableColumn="{hidden: false}">
          Legal Hold
        </ng-container>
      </clr-dg-column>
      <clr-dg-column class="table-actions-column">
        <ng-container *clrDgHideableColumn="{hidden: false}">
            Actions
        </ng-container>
      </clr-dg-column>
      <clr-dg-column *ngFor="let searchIndex of columnIndexesDisplay; index as i"
                     class="table-index-column"
                    [clrDgField]="searchIndex.key"
                     [clrDgSortBy]="getComparator(searchIndex.key)">
        <ng-container *clrDgHideableColumn="{hidden: false}">
          {{searchIndex.label}}
        </ng-container>
<!--        class table-index-filter is used to hide filter on search result table  -->
          <clr-dg-filter class="table-index-filter" *ngIf="i+1" [clrDgFilter]="i">
            <search-index-metadata-filter #i [propertyName]="searchIndex.key" [propertyType]="searchIndex.dataType"></search-index-metadata-filter>
          </clr-dg-filter>
      </clr-dg-column>

      <clr-dg-row [ngClass]='document.status == "Active" ? "" : "text-faded"' *clrDgItems="let document of documents.current" [clrDgItem]="document">
        <clr-dg-cell class="document-type-align-cells" *ngIf="currentUser?.selectedClient?.uiperms?.legalhold?.create && (!searchResultsConfig || searchResultsConfig['actionButtons'] !== 'viewBatchAndGenerateReport')">
          <div class="btn-group btn-sm btn-link btn-icon">
            <button *ngIf="document.legalHold" [title]="'Under Legal Hold(s). Click for more details.'" class="btn btn-no-caps legal-hold-icon-button"
              (click)="getLegalHoldInfoModal(document.id)"
              [clrLoading]="actionsInProgress.legalHoldInfo[document.id]">
              <clr-icon size="21" shape="lock" class="legal-hold"></clr-icon>
            </button>
            <clr-icon title="No Legal Hold" *ngIf="!document.legalHold" size="21" shape="unlock" class="no-legal-hold"></clr-icon>
          </div>
        </clr-dg-cell>

        <clr-dg-cell class="table-actions-cell">
            <div class="btn-group btn-sm btn-link btn-icon">
              <button [title]="searchResultsConfig && searchResultsConfig['actionButtons'] === 'viewBatchAndGenerateReport' ? 'View Batch Transactions' : document.fileNames?.length < 1 ? 'No document name(s) found' : 'View Document'"
                      class="btn btn-no-caps"
                      [disabled]="searchResultsConfig && searchResultsConfig['actionButtons'] === 'viewBatchAndGenerateReport' ? !currentUser?.selectedClient?.uiperms?.report?.create : !currentUser?.selectedClient?.uiperms?.documentcontent.read || document.status != 'Active' || document.fileNames?.length < 1"
                      (click)="actionButton1(document)"
                      [clrLoading]="document.UI_UUID == actionsInProgress.view">
                      <clr-icon size="21" shape="eye"></clr-icon>
              </button>

              <button [title]="searchResultsConfig && searchResultsConfig['actionButtons'] === 'viewBatchAndGenerateReport' ? 'Generate Batch Report' : document.fileNames?.length < 1 ? 'No document name(s) found' : 'Download Document'"
                      class="btn btn-no-caps"
                      [disabled]="searchResultsConfig && searchResultsConfig['actionButtons'] === 'viewBatchAndGenerateReport' ? !currentUser?.selectedClient?.uiperms?.report?.create : !currentUser?.selectedClient?.uiperms?.documentcontent.read || document.status != 'Active' || document.fileNames?.length < 1"
                      (click)="actionButton2(document)"
                      [clrLoading]="document.UI_UUID == actionsInProgress.download">
                      <clr-icon size="21" shape="download"></clr-icon>
              </button>
            </div>
        </clr-dg-cell>

        <clr-dg-cell class="table-row doccolumn-width"
                     *ngFor="let searchIndex of document.metadata | keyvalue: OrderByDefaultOrder"
                     title={{document.documentTypeName}}>
          <b *ngIf="searchIndex.value == '~~No_Value~~'"><clr-icon shape="minus"></clr-icon></b>

          <a *ngIf="searchIndex.value !== '~~No_Value~~' && isUrl(searchIndex.value)" href="{{searchIndex.value}}" target="_blank" rel="noopener noreferrer">{{searchIndex.value}}</a>

          <b *ngIf="searchIndex.value !== '~~No_Value~~' && !isUrl(searchIndex.value)">
            {{searchIndex.value}}
          </b>
        </clr-dg-cell>

        <clr-dg-row-detail clrIfExpanded [ngClass]="clrIfExpanded ? 'row-details' : ''" (clrIfExpandedChange)="getAdditionalData($event, document)" *ngIf="searchResultsConfig && searchResultsConfig['actionButtons'] !== 'viewBatchAndGenerateReport'">
          <p>
            <span *ngIf="document.fileNames?.length > 0"><clr-icon shape="file"></clr-icon>&nbsp;<strong>Document Name(s): </strong> [ {{document.fileNames.join(' || ')}} ]</span>
            <span *ngIf="document.documentTypeName">&nbsp;&nbsp;  <clr-icon shape="file-settings"></clr-icon>&nbsp;<strong>Document Type:  </strong> {{document.documentTypeName}}</span>
            <span *ngIf="document.storageType">&nbsp;&nbsp;  <clr-icon shape="storage"></clr-icon>&nbsp;<strong>Storage Type:  </strong>  {{document.storageType}}</span>
            <span *ngIf="document.uploadDate">&nbsp;&nbsp;  <clr-icon shape="upload"></clr-icon>&nbsp;<strong>Upload Date:  </strong>  {{document.uploadDate | date:'fullDate'}}</span>
          </p>

          <p *ngIf="document.id == additionalDataLoadingId && !document.additionalData">
            Loading Additional Data <span class="spinner spinner-inline"></span>
          </p>

          <clr-datagrid class="additional-data-grid" *ngIf="document.additionalData && !additionalDataLoading" [clrDgLoading]="document.id == additionalDataLoadingId">
            <clr-dg-column>Additional Data</clr-dg-column>
            <clr-dg-column>Values</clr-dg-column>

            <clr-dg-row *ngFor="let additionalDataObject of document.additionalData | keyvalue">
              <clr-dg-cell>{{ additionalDataObject.key }}</clr-dg-cell>
              <clr-dg-cell>{{ additionalDataObject.value }}</clr-dg-cell>
            </clr-dg-row>
          </clr-datagrid>
        </clr-dg-row-detail>
      </clr-dg-row>

      <clr-dg-footer>
         <clr-dg-column-toggle  >
           <!-- <clr-dg-column-toggle-title>Show/Hide Columns</clr-dg-column-toggle-title>
            <clr-dg-column-toggle-button >Select All</clr-dg-column-toggle-button>  -->
        </clr-dg-column-toggle>
        <div *ngIf="documents.selected.length" class="selected-rows-count">
          {{documents.selected.length}} selected
        </div>
        <clr-dg-pagination #pagination [clrDgPageSize]="tableControls.pageSize.value" [(clrDgPage)]="currentPage" class="custom-pagination">
          <div *ngIf="!loading">
           {{ (currentPageOffset*tableControls.pageSize.value) + pagination.firstItem + 1}} - {{(currentPageOffset*tableControls.pageSize.value) + pagination.lastItem + 1}}  of {{totalItemsRetrieved}}<span *ngIf="serverPaginationRes?.nextToken && !isMaxItemsReached">+</span> documents
            <button class="btn btn-link" [clrLoading]="previousLoading" (click)="onPrevious(pagination)" [disabled]="pagination.currentPage+currentPageOffset <= 1"> < </button>
            {{currentPageOffset + pagination.currentPage}} / {{currentPageOffset + pagination.lastPage}}<span *ngIf="serverPaginationRes?.nextToken">+</span>
            <button class="btn btn-link" [clrLoading]="nextLoading" (click)="onNext(pagination)" [disabled]="isNextEnable(pagination)"> > </button>
          </div>
        </clr-dg-pagination>
      </clr-dg-footer>

    </clr-datagrid>
  </div>
</div>

<div *ngIf="openLegalHoldInfoModal">
  <app-legal-hold-info-modal (close)="openLegalHoldInfoModal = false" [legalHoldsInfo]="legalHoldsInfo"></app-legal-hold-info-modal>
</div>

<div *ngIf="multipleFileDownloadModal_enabled">
  <app-multiple-file-download (close)="multipleFileDownloadModal_enabled = false" [documentData]="documentDataModal" (viewFile)="viewSingleFile($event)" (downloadFile)="downloadSingleFile($event)" [actionsInProgress]="actionsInProgress" ></app-multiple-file-download>
</div>

<div *ngIf="createLegalHoldModal_enabled">
  <app-create-legal-hold-modal (close)="createLegalHoldModal_enabled = false" [documentData]="documents" ></app-create-legal-hold-modal>
</div>
