import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {AlertService} from '../../../services/alert.service';
import {IimUser} from 'ui-sdk/models/iim-user.model';
import {Subscription} from 'rxjs';
import {IimDocumentType, UserMethods} from 'ui-sdk';
import {DataMappingMethods} from 'ui-sdk';
import {FunctionInfo} from 'ui-sdk/models/data-mapping.model';

@Component({
  selector: 'app-data-mapping-modal',
  templateUrl: './data-mapping-modal.component.html',
  styleUrls: ['./data-mapping-modal.component.scss']
})
export class DataMappingModalComponent implements OnInit {
  copyIdToClipboard: string;
  timer;

  currentUser: IimUser;
  subscriptions: Subscription = new Subscription();

  @Input() selectedMappingGroup;
  @Input() modalEnabled: boolean;
  @Output() created: EventEmitter<IimDocumentType> = new EventEmitter();
  @Output() updated: EventEmitter<IimDocumentType> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Input() functionInfo: FunctionInfo;

  groupMappingForm: FormGroup;
  mappingFormArray: FormArray;

  processingModalRequest: boolean = false;

  isDataMappingFunctionDesignerEnabled = false;
  dataTransformFunctions: Map<string, any> = new Map<string, any>();
  functionData: any = null;

  constructor(private alertService: AlertService) {}

  ngOnInit() {
    const userSubscription = UserMethods.currentUser$.subscribe(
      (user: IimUser) => this.currentUser = user
    );
    this.subscriptions.add(userSubscription);

    if (this.selectedMappingGroup?.mappings) {
      this.dataTransformFunctions = new Map(Object.entries(this.selectedMappingGroup.mappings));
    }
    this.createMappingForm();
  }

  private createMappingForm() {
    this.groupMappingForm = this.createGroupMappingFormGroup();
  }

  isNewDataMapping() {
    return !this.selectedMappingGroup?.clientId;
  }

  private createGroupMappingFormGroup() {
    return new FormGroup({
      groupName: new FormControl({value: this.selectedMappingGroup ? this.selectedMappingGroup.name : null, disabled: this.selectedMappingGroup?.clientId}, [Validators.required]),
      mappings: new FormControl({value: this.selectedMappingGroup ? this.selectedMappingGroup.mappings : null}, [Validators.required]),
    });
  }

  onAddMapping(e) {
    e.preventDefault();
    this.functionData = null;
    this.isDataMappingFunctionDesignerEnabled = true;
  }

  saveButtonEnabled() {
    return !(this.groupMappingForm.controls.groupName.value && this.dataTransformFunctions.size);
  }

  saveMappingGroup() {
    const form = Object.assign({}, this.groupMappingForm.getRawValue()); // If nested form groups, do a deep copy here
    const payload: any = {
      name: form.groupName,
      mappings: this.dataTransformFunctions
    };

    this.processingModalRequest = true;

    if (this.isNewDataMapping()) {
      DataMappingMethods.createMappingGroup(payload).subscribe(docType => {
        this.processingModalRequest = false;
        this.alertService.showSuccessToast(`Data Mapping Group '${payload.name}' created`, undefined);
        this.selectedMappingGroup = {
          clientId: docType['id'],
          name: payload.name,
          mappings: payload.mappings
        };
        this.closeModal('created');
      }, error => {
        this.processingModalRequest = false;
        if (error.status === 403) {
          this.alertService.showError('Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [CREATE DataMapping].`);
        } else {
          this.alertService.showError('Error', `Unable to create Data Mapping. Please try again.  ${error.error.message}`);
        }
      });
    } else {
      DataMappingMethods.updateMappingGroup(payload).subscribe(docType => {
        this.processingModalRequest = false;
        this.alertService.showSuccessToast(`Data Mapping Group '${payload.name}' updated`, undefined);
        this.selectedMappingGroup.mappings = payload.mappings;
        this.closeModal('updated');
      }, error => {
        this.processingModalRequest = false;
        if (error.status === 403) {
          this.alertService.showError('Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [UPDATE DataMapping].`);
        } else {
          this.alertService.showError('Error', `Unable to Update data mapping group. Please try again.  ${error.error.message}`);
        }
      });
    }
  }

  closeModal(eventType) {
    if (eventType === 'created') {
      this.created.emit(this.selectedMappingGroup);
    } else if (eventType === 'updated') {
      this.updated.emit(this.selectedMappingGroup);
    }
    this.close.emit();
  }

  notify(event: any) {
    this.copyIdToClipboard = event;

    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      this.copyIdToClipboard = '';
    }, 3000);
  }

  functionsChangedEventHandler(value: any) {
    if (value) {
      this.dataTransformFunctions = value;
    }
  }

  editFunction(f: any) {
    this.functionData = {
      ...f.value,
      functionName: f.key
    };
    this.isDataMappingFunctionDesignerEnabled = true;
  }

  removeFunction(f: any)  {
    this.dataTransformFunctions.delete(f.key);
  }
}
