import { transition, trigger, useAnimation } from '@angular/animations';
import { fadeInDown, fadeIn, fadeOut, fadeInLeft, fadeOutLeft } from 'ng-animate';

export const fadeInDownAnimation =
  trigger('fadeInDown', [transition(':enter', useAnimation(fadeInDown, {
    params: {
      timing: 0.4,
      delay: 0,
      a: '-0.5rem'
    }
  }))]);

export const fadeInLeftAnimation =
  trigger('fadeInLeft', [transition(':enter', useAnimation(fadeInLeft, {
      params: {
        timing: 0.4,
        delay: 0,
        a: '-0.5rem'
      }
    }
  ))]);

export const fadeInOutAnimation =
  trigger('fadeInOut', [
    transition(':enter', useAnimation(fadeIn, {params: {timing: 0.4, delay: 0}})),
    transition(':leave', useAnimation(fadeOut, {params: {timing: 0.4, delay: 0}}))
  ]);

export const fadeInAnimation =
  trigger('fadeIn', [
    transition(':enter', useAnimation(fadeIn, {params: {timing: 0.4, delay: 0}}))
  ]);

export const fadeOutAnimation =
  trigger('fadeOut', [
    transition(':leave', useAnimation(fadeOut, {params: {timing: 0.4, delay: 0}}))
  ]);

export const fadeOutLeftAnimation =
  trigger('fadeOutLeft', [
    transition(':leave', useAnimation(fadeOutLeft, {params: {timing: 0.4, a: '0', b: '-0.5rem'}}))
  ]);
