import { Component, OnDestroy, OnInit } from '@angular/core';
import { IimUser } from 'ui-sdk/models/iim-user.model';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/services/alert.service';
import { Router } from '@angular/router';
import {ClientService} from 'ui-sdk';
import {UserMethods} from 'ui-sdk';
import {AuthClass} from 'ui-sdk';
import {IimClient} from 'ui-sdk/models/iim-client.model';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy {
  currentUser: IimUser;
  clients: Array<IimClient>;
  logoError: boolean = false;
  subscriptions: Subscription = new Subscription();
  usernameToggle: boolean = true;

  constructor(private clientService: ClientService,
              private alertService: AlertService,
              private router: Router) {
  }

  ngOnInit() {
    this.subscriptions.add(UserMethods.currentUser$.subscribe(user => {
      this.currentUser = Object.assign({}, user);
      this.clients = this.currentUser.clients?.filter(c => c.permissions?.length > 0);
      this.logoError = false;
    }));
  }

  myProfileClick() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate(['/admin/users'], {queryParams: { userName: this.currentUser.firstName}});
    });
  }

  onLogoError() {
    this.logoError = true;
  }

  isSelectedClient(clientId) {
    return this.currentUser.selectedClient.clientId === clientId;
  }

  switchClient(client: IimClient) {
    this.clientService.switchClient(client);
  }

  getToken() {
    return localStorage.getItem('IIM-auth-token');
  }

  showTab(route: string) {
    if (this.currentUser) {
      return UserMethods.hasRouteAccess(route);
    } else {
      return false;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  mobileClick() {
    const title = 'SMS Archiving';
    const msg = `Feature Coming Soon, we'll get there in no time!`;
    this.alertService.showSuccess(title, msg);
  }

  logoutClick() {
      this.alertService.confirmAction('Logout?', 'You are about to log out, press ok to confirm this action.').then(confirmed => {
      if (confirmed) {
        UserMethods._currentUser = null;
        UserMethods.currentUser$.next(null);
        this.ngOnInit();

        this.router.navigate(['/login']);

        this.alertService.showSuccess('Till next time.', 'You have been succesfully logged out. Thank you for using IIM.');
      }
    });
  }

  openUploadModal() {
    this.alertService.emitChildEvent('Upload Modal');
  }

  isSSOUser(): boolean {
    const authTokenToParse = localStorage.getItem(AuthClass.IIM_AUTH_TOKEN);
    return AuthClass.parseJwt(authTokenToParse)['cmpid'];
  }
}
