import { Component, OnInit } from '@angular/core';
import { fadeInDownAnimation } from '../../animations';

@Component({
  selector: 'notfound-page',
  templateUrl: './notfound-page.component.html',
  styleUrls: ['./notfound-page.component.scss'],
  animations: [fadeInDownAnimation]
})
export class NotFoundPageComponent implements OnInit {
  constructor() { }

  ngOnInit() {}
}
