import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IimUser } from 'ui-sdk/models/iim-user.model';
import moment from 'moment';
import { AlertService } from '../../services/alert.service';
import { fadeInAnimation, fadeInDownAnimation, fadeInOutAnimation, fadeInLeftAnimation } from '../../animations';
import { documentDateValidator } from '../../validators/document-date.validator';
import { DateRangeValidator } from '../../validators/date-range.validator';
import { Subscription } from 'rxjs';
import { SavedSearchSelectComponent } from '../../components/saved-search-select/saved-search-select.component';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { OperatorMap } from './search-index-operators';
import { OperatorValueMap } from './search-index-operators';
import { DateFormatter } from '../../utility/date-formatter';
import {ClientMethods, SavedSearch, UserMethods} from 'ui-sdk';
import {SearchMethods} from 'ui-sdk';
import {DocumentTypeMethods} from 'ui-sdk';

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.scss'],
  animations: [
    fadeInDownAnimation,
    fadeInOutAnimation,
    fadeInAnimation,
    fadeInLeftAnimation
  ]
})
export class SearchPageComponent implements OnInit, OnDestroy {
  SORT_KEY_LOCAL_STORAGE: string = 'IIM_table-sort_document-search-results';

  operatorMap = OperatorMap;
  operatorValueMap = OperatorValueMap;

  searchForm: FormGroup;
  formErrors = {};
  validSearch = false;

  searchResults: Array<any> = null;
  loadingSearchResults = false;
  isSearchFormCollapsed = false;
  displayDateSearch = false;
  updatingSavedSearch = false;

  loadingDocTypes = false;

  legalHoldModalEvent = false;

  selectedDocumentTypeNames: Array<any> = [];
  selectedDocumentTypeIds: Array<string> = [];
  commonSearchIndexes: Array<any> = [];
  tempCommonSearchIndexes: Array<any> = [];
  currentSearchIndexes: Array<any> = [];
  allsearchIndices: Array<any> = [];
  allSearchIndicesTable: Array<any> = [];

  initialDocTypes: Array<any> = [];
  documentTypes: Array<any> = [];
  documentTypeIdMap: {};
  documentTypeNameMap: {};
  searchIndices: Array<Object> = [];

  currentSearchIndexesValues: Object = {};
  searchIndexesPayload: Array<Object> = [];

  searchResultsConfig: any;

  currentUser: IimUser;

  showAdvancedSearch = false;
  showInactiveDocumentTypes: FormControl = new FormControl(true);
  showUniqueIndexes: FormControl = new FormControl(true);
  currentClientIndexes: Array<any> = [];


  subscriptions: Subscription = new Subscription();
  dropdownSettings: IDropdownSettings = {};
  documentType: String;
  serverPaginationRes = {};

  @ViewChild('savedSearchSelect') savedSearchSelect: SavedSearchSelectComponent;
  savedSearches: { initial: Array<SavedSearch>, loading: boolean } = {
    initial: [],
    loading: false
  };

  prevSavedSearch: SavedSearch;

  constructor(private alertService: AlertService) {
  }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'name',
      textField: 'description',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 7,
      allowSearchFilter: false
    };

    this.searchForm = this.createSearchFormGroup();
    this.subscriptions.add(UserMethods.currentUser$.subscribe(
      (user: IimUser) => {
        this.currentUser = user;
        this.resetSearchForm();
        if (this.savedSearchSelect) {
          this.savedSearchSelect.control.setValue(null);
        }
        if (this.currentUser) {
          if (this.currentUser.selectedClient.uiperms.doctype.read) {
            this.loadDocumentTypes();
          }
          if (this.currentUser.selectedClient.uiperms.savedsearch.read) {
            this.loadSavedSearches();
          }
        }
        ClientMethods.getClientById(this.currentUser.selectedClient.clientId).then(client => {
          this.searchResultsConfig = client.searchResultsConfig ? client.searchResultsConfig : null;
        });
      }
    ));

    this.showUniqueIndexes.setValue(false);
    this.onChanges();

    this.subscriptions.add(this.alertService.childEventListner().subscribe(event => {
      if (event === 'Refetch Documents') {
        this.legalHoldModalEvent = true;
        this.onSubmitSearch();
      }
    }));
  }

  onDocumentTypeSelect(item: any) {
    this.tempCommonSearchIndexes = JSON.parse(JSON.stringify(this.commonSearchIndexes));
    this.selectDocumentType(item.name);
    this.refreshSearchIndexes(this.showUniqueIndexes.value, true);

  }

  onDocumentTypeSelectAll(items: any) {
    this.tempCommonSearchIndexes = JSON.parse(JSON.stringify(this.commonSearchIndexes));
    this.selectedDocumentTypeNames = this.documentTypes.map(element => element.name);
    this.refreshSearchIndexes(this.showUniqueIndexes.value, true);
  }

  onDocumentTypeDeSelect(item: any) {
    this.tempCommonSearchIndexes = JSON.parse(JSON.stringify(this.commonSearchIndexes));
    const index = this.selectedDocumentTypeNames.indexOf(item.name);
    if (index >= 0) {
      this.selectedDocumentTypeNames.splice(index, 1);
    }
    this.refreshSearchIndexes(this.showUniqueIndexes.value, true);
  }

  onDocumentTypeDeSelectAll(items: any) {
    this.tempCommonSearchIndexes = JSON.parse(JSON.stringify(this.commonSearchIndexes));
    this.selectedDocumentTypeNames = [];
    this.refreshSearchIndexes(this.showUniqueIndexes.value, true);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  createSearchFormGroup(): FormGroup {
    return new FormGroup({
      assetType: new FormControl({ value: null, disabled: false }),
      docTypeNames: new FormControl({ value: null, disabled: true }, [Validators.required]),
      docDateStart: new FormControl({ value: null, disabled: true }, [documentDateValidator()]),
      docDateEnd: new FormControl({ value: null, disabled: true }, [documentDateValidator()]),
      mostRecent: new FormControl({ value: false, disabled: true }),
      annotationCriteria: new FormControl({ value: 'All Documents', disabled: true }),
    }, DateRangeValidator);
  }

  searchOnEnter(searchIndex, searchIndexValue, isFirstValue) {
    this.searchIndexFocusOut(searchIndex, searchIndexValue, isFirstValue);
    this.onSubmitSearch();
  }

  operatorSelect(operatorValue, searchIndex) {
    searchIndex['selectedOperator'] = operatorValue;

    if (!this.isMultiOperator(searchIndex)) {
      searchIndex['secondValue'] = '';
    }

    searchIndex['errors'] = [];
    this.updateCurrentSearchIndexes(searchIndex);
  }

  dateChange(searchIndex, dateValue: Date, isFirstValue) {
    this.searchIndexFocusOut(searchIndex, DateFormatter.formatDate(dateValue.toLocaleDateString()), isFirstValue);
  }

  dateTimeChange(searchIndex, e, isFirstValue) {
    this.searchIndexFocusOut(searchIndex, e.target.value, isFirstValue);
  }

  clearDate(searchIndex) {
    searchIndex['value'] = '';
    searchIndex['secondValue'] = '';
    searchIndex['errors'] = [];

    delete this.currentSearchIndexesValues[searchIndex['searchIndex']];

    this.searchIndexFocusOut(searchIndex, searchIndex['value'], true);
  }

  searchIndexFocusOut(searchIndex, searchIndexValue, isFirstValue) {
    if (isFirstValue) {
      searchIndex['changed'] = true;
      searchIndex['value'] = searchIndexValue.trim();
    } else {
      searchIndex['secondChanged'] = true;
      searchIndex['secondValue'] = searchIndexValue.trim();
    }
    this.updateCurrentSearchIndexes(searchIndex);
  }

  updateCurrentSearchIndexes(searchIndex, noValidityUpdate?: boolean) {
    this.validateIndex(searchIndex);

    if (searchIndex['errors'].length < 1 && searchIndex['value'].length > 0) {
      this.currentSearchIndexesValues[searchIndex['documentTypeName']] = {
        ...this.currentSearchIndexesValues[searchIndex['documentTypeName']],
        [searchIndex['searchIndex']]: JSON.parse(this.formatIndexValue(searchIndex))
      };
    } else {
      // tslint:disable-next-line:max-line-length
      if (this.currentSearchIndexesValues[searchIndex['documentTypeName']] && this.currentSearchIndexesValues[searchIndex['documentTypeName']][searchIndex['searchIndex']]) {
        delete this.currentSearchIndexesValues[searchIndex['documentTypeName']][searchIndex['searchIndex']];
      }
    }
    if (!noValidityUpdate) {
    // tslint:disable-next-line:max-line-length
      this.validSearch = Object.keys(this.formErrors || {}).length < 1 && Object.keys(this.currentSearchIndexesValues[searchIndex['documentTypeName']] || {}).length > 0;
    }
  }

  validateIndex(searchIndex) {
    let errors = [];

    if (searchIndex['dataType'].includes('Integer')) {
      const firstValueIn = searchIndex['value'];
      if (firstValueIn.indexOf('.') !== -1) {
        errors[0] = ('First value should be Integer');
      }
    }
    if (errors.length === 0 && this.isMultiOperator(searchIndex)) {
      if (!searchIndex['dataType'].includes('Date')) {
        // tslint:disable-next-line:radix
        const firstValue = parseInt(searchIndex['value']);
        // tslint:disable-next-line:radix
        const secondValue = parseInt(searchIndex['secondValue']);

        if (isNaN(firstValue) && searchIndex['changed']) {
          errors[0] = ('First value cannot be empty');
        }

        if (isNaN(secondValue)) {
          errors[1] = ('Second value cannot be empty');
        }

        if ((typeof firstValue === 'number' && typeof secondValue === 'number') && firstValue >= secondValue) {
          errors[1] = ('Second value must be greater');
        }

        if (searchIndex['dataType'].includes('Integer')) {
          const secondValueIn = searchIndex['secondValue'];
          if (secondValueIn.indexOf('.') !== -1) {
            errors[1] = ('Second value should be Integer');
          }
        }
      } else {
        const firstDate = new Date(searchIndex['value']).getTime();
        const secondDate = new Date(searchIndex['secondValue']).getTime();

        if (isNaN(firstDate)) {
          errors[0] = ('First date cannot be empty');
        }

        if (isNaN(secondDate)) {
          errors[1] = ('Second date cannot be empty');
        }

        if ((typeof firstDate === 'number' && typeof secondDate === 'number') && firstDate >= secondDate) {
          errors[1] = ('Second date must be greater');
        }
      }
    }

    if (!searchIndex['value'] && !searchIndex['secondValue']) {
      errors = [];
    }

    searchIndex['errors'] = errors;

    if (errors.length > 0) {
      this.formErrors[searchIndex['searchIndex']] = true;
    } else {
      delete this.formErrors[searchIndex['searchIndex']];
    }
  }

  formatIndexValue(searchIndex) {
    let prop = '';
    if (searchIndex['selectedOperator'].includes('like')) {
      // Uses Wildcard Operators in the data value (Only String Datatype)
      switch (searchIndex['selectedOperator']) {
        case '*like':  // Ends With
          prop = `{"like": "*${searchIndex['value']}"}`;
          break;
        case 'like*':  // Starts With
          prop = `{"like": "${searchIndex['value']}*"}`;
          break;
        case 'like': // Contains
          prop = `{"like": "*${searchIndex['value']}*"}`;
          break;
        default:
          prop = `{"like": "*${searchIndex['value']}*"}`;
          break;
      }
    } else if (this.isMultiOperator(searchIndex)) {
      // Needs two values for this operator  (No String Datatype)
      switch (searchIndex.dataType) {
        case 'Date':
        case 'Time':
        case 'DateTime':
          prop = `{"${searchIndex['selectedOperator']}":
                  ["${searchIndex['value']}",
                   "${searchIndex['secondValue']}"]}`;
          break;
        case 'Number':
          prop = `{"${searchIndex['selectedOperator']}":
                  [${searchIndex['value']},
                   ${searchIndex['secondValue']}]}`;
          break;
        case 'Integer':
          prop = `{"${searchIndex['selectedOperator']}":
                  [${searchIndex['value']},
                   ${searchIndex['secondValue']}]}`;
          break;
        case 'Decimal':
          prop = `{"${searchIndex['selectedOperator']}":
                  [${searchIndex['value']},
                   ${searchIndex['secondValue']}]}`;
          break;
      }
    } else if (searchIndex['selectedOperator'] === 'relative') {
      // Needs Single relative value from today
      try {
        const now = new Date(Date.now() - (searchIndex['value'] * 24 * 60 * 60 * 1000)).toISOString().substring(0, 10);

        switch (searchIndex.dataType) {
          case 'Date':
          case 'Time':
          case 'DateTime':
            prop = `{"gte": "${now}"}`;
            break;
        }
      } catch (err) {
      }
    } else { // Not Relative Operator
      switch (searchIndex.dataType) {
        case 'String':
        case 'Date':
        case 'Time':
        case 'DateTime':
          prop = `{"${searchIndex['selectedOperator']}": "${searchIndex['value']}"}`;
          break;
        case 'Number':
          prop = `{"${searchIndex['selectedOperator']}": ${searchIndex['value']}}`;
          break;
        case 'Integer':
          prop = `{"${searchIndex['selectedOperator']}": ${searchIndex['value']}}`;
          break;
        case 'Decimal':
          prop = `{"${searchIndex['selectedOperator']}": ${searchIndex['value']}}`;
          break;
        default:
      }
    }
    return prop;
  }

  searchIndexesPrependPrep(isSearch?) {
    this.searchIndexesPayload = [];
    for (const documentTypeName in this.currentSearchIndexesValues) {
      const filter = {};
      for (const indexes in this.currentSearchIndexesValues[documentTypeName]) {
        if (isSearch) {
          const datetimeSi = this.commonSearchIndexes.filter((si) => (si.searchIndex === indexes && si.dataType === "DateTime"));
          if (datetimeSi.length >= 1) {
            const operator = datetimeSi[0].selectedOperator;
            const selectedDateValue = this.currentSearchIndexesValues[documentTypeName][indexes][operator];
            const filterVal = {};
            switch (operator) {
              case 'eq':
                filterVal["between"] = [moment(selectedDateValue).startOf('day').format("YYYY-MM-DDTHH:mm:ss"), moment(selectedDateValue).endOf('day').format("YYYY-MM-DDTHH:mm:ss")];
                break;
              case 'neq':
                filterVal["notbetween"] = [moment(selectedDateValue).startOf('day').format("YYYY-MM-DDTHH:mm:ss"), moment(selectedDateValue).endOf('day').format("YYYY-MM-DDTHH:mm:ss")];
                break;
              case 'lt':
                filterVal["lt"] = moment(selectedDateValue).startOf('day').format("YYYY-MM-DDTHH:mm:ss");
                break;
              case 'lte':
                filterVal["lte"] = moment(selectedDateValue).endOf('day').format("YYYY-MM-DDTHH:mm:ss");
                break;
              case 'gt':
                filterVal["gt"] = moment(selectedDateValue).endOf('day').format("YYYY-MM-DDTHH:mm:ss");
                break;
              case 'gte':
                filterVal["gte"] = moment(selectedDateValue).startOf('day').format("YYYY-MM-DDTHH:mm:ss");
                break;
              case 'between':
                filterVal["between"] = [moment(selectedDateValue[0]).startOf('day').format("YYYY-MM-DDTHH:mm:ss"), moment(selectedDateValue[1]).endOf('day').format("YYYY-MM-DDTHH:mm:ss")];
                break;
              case 'notbetween':
                filterVal["notbetween"] = [moment(selectedDateValue[0]).startOf('day').format("YYYY-MM-DDTHH:mm:ss"), moment(selectedDateValue[1]).endOf('day').format("YYYY-MM-DDTHH:mm:ss")];
                break;
            }
            filter[`indexes.${indexes}`] = filterVal;
          } else {
            filter[`indexes.${indexes}`] = this.currentSearchIndexesValues[documentTypeName][indexes];
          }
        } else{
          filter[`indexes.${indexes}`] = this.currentSearchIndexesValues[documentTypeName][indexes];
        }
      }

      filter['documentTypeId'] = [documentTypeName];
      this.searchIndexesPayload.push(filter);
    }
  }

  resultSorter(x, y) {
    if (x.uploadDate > y.uploadDate) { return -1; }
    if (x.uploadDate < y.uploadDate) { return 1; }
    return 0;
  }

  onSubmitSearch(): void {
    this.searchIndexesPayload = [];
    this.loadingSearchResults = true;
    this.searchIndexesPrependPrep(true);

    this.searchResults = null;  // reset the Search Results

    if (this.searchIndexesPayload.length) {

      SearchMethods.documentSearch({query: this.searchIndexesPayload}).then(searchRes => {
        if (searchRes['results'].length < 1) {
          const [title, msg] = ['Warning', 'No Documents match the specified search criteria. Please try again.'];
          this.alertService.showWarning(title, msg);
          this.loadingSearchResults = false;
          this.searchIndexesPayload = [];
        } else {
          this.isSearchFormCollapsed = true;
          this.searchResults = searchRes['results'];
          this.serverPaginationRes = searchRes['pagination'];
          this.loadingSearchResults = false;
        }
      }, error => {
        if (error.status === 403) {
          const [title, msg] = ['Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [CREATE DocumentSearch].`];
          this.alertService.showError(title, msg);

        } else {
          const [title, msg] = ['Error', 'Unable to retrieve documents. Please refresh the page and try again.'];
          this.alertService.showError(title, msg);
          this.loadingSearchResults = false;
        }
      });
    } else {
      this.loadingSearchResults = false;
      if (!this.legalHoldModalEvent) {
        const [title, msg] = ['Error', 'Unable to retrieve documents. Please provide at least one search criteria.'];
        this.alertService.showError(title, msg);
      }
    }
  }

  loadSavedSearches(): void {
    SearchMethods.getSavedSearches().then(savedSearches => {
      this.savedSearches.initial = savedSearches;
    }, error => {
      if (error.status === 403) {
        this.alertService.showError('Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [GET SavedSearches].`);
      } else {
        this.alertService.showError('Error', 'Unable to load saved searches.');
      }
    });
  }

  saveSearch(previousSearchName?: string): void {
    this.alertService.confirmActionWithInput('Save Search', 'Give your saved search a name', 'Enter a name', previousSearchName, 'blue-header-modal').then((searchName: string) => {
      if (searchName && searchName.trim()) {
        searchName = searchName.trim();
        const isDuplicateSearchName: boolean = !!this.savedSearches.initial.find(s => s.itemName === searchName);
        if (isDuplicateSearchName) {
          this.alertService.showWarning('Duplicate Search Name',
            'The given name is already used by a saved search. Please enter a different name and try again.').then(() => {
              this.saveSearch(searchName);
            });
        } else {
          this.searchIndexesPrependPrep();
          const savedSearch: SavedSearch = new SavedSearch(searchName, this.searchIndexesPayload);
          savedSearch['criteria']['documentTypeId'] = this.selectedDocumentTypeNames;

          SearchMethods.createSavedSearch(savedSearch).then(createdSavedSearchResponse => {
            savedSearch.id = createdSavedSearchResponse['id'];

            this.savedSearches.initial.push(savedSearch);
            this.savedSearchSelect.control.setValue(savedSearch);
            this.alertService.showSuccess('Search Saved', `'${savedSearch.itemName}' added to searches.`);
          }, error => {
            if (error.status === 403) {
              this.alertService.showError('Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [CREATE SavedSearch].`);
            } else {
              this.alertService.showError('Error', 'Unable to save search. Please try again.');
            }
          });
        }
      } else if (searchName !== null) {
        this.alertService.showError('Enter a Search Name', 'Please enter a name for the search when you save it.').then(() => {
          this.saveSearch(searchName);
        });
      }
    });
  }

  updateSavedSearch(): void {
    const selectedSavedSearch: SavedSearch = this.savedSearchSelect.control.value;
    // tslint:disable-next-line:max-line-length
    this.alertService.confirmActionWithInput('Update Saved Search', 'Give your saved search a name', 'Enter a name', selectedSavedSearch.itemName, 'blue-header-modal', 'updateSaveSearchSwal').then((searchName: string) => {
      const defaultVal: any = document.querySelector('#updateSaveSearchSwal');
      if (searchName !== null && searchName.length === 0 && defaultVal ) {
         searchName = defaultVal.value;
      }
      if (searchName && searchName.trim()) {
        searchName = searchName.trim();
        const isDuplicateSearchName: boolean = !!this.savedSearches.initial.find(s => s.itemName === searchName);
        if (isDuplicateSearchName && selectedSavedSearch.itemName !== searchName) {
          this.alertService.showWarning('Duplicate Search Name',
            'The given name is already used by a saved search. Please enter a different name and try again.').then(() => {
              this.updateSavedSearch();
            });
        } else {
          this.updatingSavedSearch = true;
          this.searchIndexesPrependPrep();
          const savedSearch: SavedSearch = new SavedSearch(searchName, this.searchIndexesPayload);
          // tslint:disable-next-line:max-line-length
          SearchMethods.updateSavedSearch(selectedSavedSearch.id, savedSearch, this.selectedDocumentTypeNames).then((updatedSearch: SavedSearch) => {
            this.updatingSavedSearch = false;
            const index = this.savedSearches.initial.findIndex(s => s.itemName === selectedSavedSearch.itemName);
            this.savedSearches.initial[index] = updatedSearch;
            this.savedSearchSelect.control.setValue(updatedSearch);
            this.alertService.showSuccess('Search Updated', `'${updatedSearch.itemName}' has been updated.`);
          }, error => {
            this.updatingSavedSearch = false;
            if (error.status === 403) {
              this.alertService.showError('Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [UPDATE SavedSearch].`);
            } else {
              this.alertService.showError('Error', 'Unable to update saved search. Please try again.');
            }
          });
        }
      } else if (searchName !== null) {
        this.alertService.showError('Enter a Search Name', 'Please enter a name for the search when you save it.').then(() => {
          this.updateSavedSearch();
        });
      }
    });
  }

  onSavedSearchSelected(savedSearch: SavedSearch): void {
    this.resetSearchForm(true);
    if (savedSearch) {
      this.selectedDocumentTypeNames = [];
      const docTypeNames = [];
      savedSearch.criteria.forEach(crit => {
        for (const key in crit) {
          const criteria =  crit[key];
          if (criteria.toString().trim()) {
            if (key === 'documentTypeId') {
              this.selectedDocumentTypeNames.push(criteria);
              criteria.forEach(name => {
                const description = this.documentTypes.filter(doctype => (doctype.name === name))[0].description;
                docTypeNames.push({ name, description });
                this.selectedDocumentTypeNames.push(name);
              });
            }
          }
        }
      });

      this.searchForm.controls.docTypeNames.setValue(docTypeNames);
      this.extractAllSearchIndexes();
      this.refreshSearchIndexes(this.showUniqueIndexes.value);

      setTimeout(() => {
        savedSearch.criteria.forEach(crit => {
          for (const key in crit) {
            if (key !== 'documentTypeId' && crit[key].toString().trim()) {
              const newKey = key.split('.')[1];
              if (newKey) {
                this.setSavedSearchForIndex(crit[key], newKey, crit['documentTypeId'][0]);
              }
            }
          }
        });
      }, 0);
    }
  }

  setSavedSearchForIndex(criteria, key, documentTypeName) {
    // tslint:disable-next-line:max-line-length
    const searchIndex = this.commonSearchIndexes.filter(searchIndexData => searchIndexData.searchIndex.toUpperCase() === key.toUpperCase() && documentTypeName === searchIndexData.documentTypeName);

    if (searchIndex && searchIndex.length > 0) {
      const operator = Object.keys(criteria)[0];
      const value = criteria[operator];
      if (Array.isArray(value)) {
        searchIndex[0].selectedOperator = operator;
        searchIndex[0].value = value[0];
        setTimeout(() => {
          searchIndex[0].secondValue = value[1];
        }, 10);
      } else if (operator === 'like') {
        const splitValue = value.split('*');
        let newOperator = '';
        splitValue.forEach(v => {
          if (v === '') {
            newOperator = newOperator + '*';
          } else {
            newOperator = newOperator + operator;
            searchIndex[0].value = v;
          }
        });
        searchIndex[0].selectedOperator = newOperator;

      } else {
        searchIndex[0].selectedOperator = operator;
        searchIndex[0].value = value;
      }
      if (!this.currentSearchIndexesValues[documentTypeName]) {
        this.currentSearchIndexesValues[documentTypeName] = {};
      }
      this.currentSearchIndexesValues[documentTypeName][key] = criteria;
    }
    this.validSearch = Object.keys(this.formErrors).length < 1 && Object.keys(this.currentSearchIndexesValues).length > 0;
  }

  onSavedSearchDeleted(savedSearch: SavedSearch): void {
    SearchMethods.deleteSavedSearch(savedSearch).then(success => {

      // tslint:disable-next-line:max-line-length
      const toDelete: number = this.savedSearches.initial.indexOf(this.savedSearches.initial.find(s => s.itemName === savedSearch.itemName));
      this.savedSearches.initial.splice(toDelete, 1);
      this.alertService.showSuccess('Search Deleted', `'${savedSearch.itemName}' was removed from searches.`);
    }, error => {
      if (error.status === 403) {
        const [title, msg] = ['Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [DELETE SavedSearch].`];
        this.alertService.showError(title, msg);
      } else {
        const [title, msg] = ['Error', `Unable to delete Saved Search ${savedSearch.itemName}. Please refresh the page and try again.`];
        this.alertService.showError(title, msg);
      }
    });
  }

  resetSearchIndex(){
    this.refreshSearchIndexes(this.showUniqueIndexes.value, true);
  }

  resetSearchForm(preserveSavedSearch?: boolean): void {
    for (let a = 0; a < this.documentTypes.length; a++) {
      const element = document.getElementById(`buttonDoctype${a + 1}`);
    }

    this.selectedDocumentTypeNames = [];
    this.allsearchIndices = [];
    this.commonSearchIndexes = [];
    this.currentSearchIndexesValues = {};
    this.searchIndexesPayload = [];

    if (!preserveSavedSearch && this.savedSearchSelect) {
      this.savedSearchSelect.control.setValue(null);
    }

    this.searchForm.get('docTypeNames').reset({ value: null, disabled: false }, [Validators.required]);
    this.searchForm.get('docDateStart').reset({ value: null, disabled: true }, [documentDateValidator()]);
    this.searchForm.get('docDateEnd').reset({ value: null, disabled: true }, [documentDateValidator()]);
    this.searchForm.get('mostRecent').reset({ value: false, disabled: true });
    this.searchForm.get('annotationCriteria').reset({ value: 'All Documents', disabled: true });
    this.showInactiveDocumentTypes.reset();
    this.showUniqueIndexes.reset();
    this.searchResults = null;
    this.isSearchFormCollapsed = false;
    this.validSearch = false;
  }

  onChanges() {
    this.subscriptions.add(this.showInactiveDocumentTypes.valueChanges.subscribe((value: boolean) => {
      this.refreshDocTypes();
    }));

    this.subscriptions.add(this.showUniqueIndexes.valueChanges.subscribe((value: boolean) => {
      this.refreshSearchIndexes(value);
    }));
  }

  loadDocumentTypes(): void {
    this.loadingDocTypes = true;

    DocumentTypeMethods.getDocumentTypes(true).then(docTypes => {
      this.initialDocTypes = docTypes;
      this.loadingDocTypes = false;
      this.refreshDocTypes();

    }, error => {
      this.loadingDocTypes = false;

      if (error.status === 403) {
        const [title, msg] = ['Not Permitted', `Your session has either timed out, or you don't have sufficient permissions to access this resource [GET DocumentTypes].`];
        this.alertService.showError(title, msg);
      } else {
        const [title, msg] = ['Error', 'Could not retrieve Document Types. Please try again.'];
        this.alertService.showError(title, msg);
      }
    });
  }

  clearClientIndexes(): void {
    this.currentClientIndexes = [];
  }


  // this method refreshed the displayed Document list in the dropdown.
  // initialDocTypes always contains the complete list
  // documentTypes contains the filtered list in the dropdown
  // showInactive is directly bound to the checkbox indicating that they should/shouldnot display Inactive Document Types.
  refreshDocTypes() {
    const showInactive: boolean = this.showInactiveDocumentTypes.value;
    this.documentTypes = showInactive ? this.initialDocTypes : this.initialDocTypes.filter(initialDocTypes => initialDocTypes.status === 'Active'); // Only Active Document Types

    // this.searchForm.get('searchIndexes').reset({ value: null, disabled: false });
    this.searchIndices = [];
    this.currentSearchIndexesValues = {};
    this.searchIndexesPayload = [];
    this.commonSearchIndexes = [];

    // Rebuild Document Id Map
    const documentTypeIdMap = {};
    const documentTypeNameMap = {};

    this.documentTypes.map(element => {
      documentTypeIdMap[element['id']] = element;
    });

    this.documentTypes.map(element => {
      documentTypeNameMap[element['name']] = element;
    });

    this.documentTypeIdMap = documentTypeIdMap;
    this.documentTypeNameMap = documentTypeNameMap;
    if (this.documentTypes.length >= 1) {
      if (this.prevSavedSearch === this.savedSearchSelect.control.value) {
        const selectedDocTypesFormValue = [];
        this.searchForm.controls.docTypeNames.value.forEach(docTypeName => {
          if (documentTypeNameMap[docTypeName.name]) {
            selectedDocTypesFormValue.push(docTypeName);
          }
        });
        this.searchForm.controls.docTypeNames.setValue(selectedDocTypesFormValue);
      } else {
        this.selectedDocumentTypeNames = [];
        this.onDocumentTypeSelect(this.documentTypes[0]);

        // tslint:disable-next-line:max-line-length
        this.searchForm.controls.docTypeNames.setValue([{ name: this.documentTypes[0].name, description: this.documentTypes[0].description }]);
      }

    } else {
      this.selectedDocumentTypeNames = [];
      this.searchForm.controls.docTypeNames.setValue([]);
    }

    this.refreshSearchIndexes(this.showUniqueIndexes.value);
  }

  selectDocumentType(docTypeName) {
    this.searchIndices = [];
    this.currentSearchIndexesValues = {};
    this.searchIndexesPayload = [];
    this.displayDateSearch = true;
    this.commonSearchIndexes = [];


    this.selectedDocumentTypeNames = this.selectedDocumentTypeNames.filter(item => item !== docTypeName);
    this.selectedDocumentTypeNames.push(docTypeName);
    this.extractAllSearchIndexes();
  }

  refreshSearchIndexes(showUnique: boolean, useCacheValue?: boolean) {
    this.commonSearchIndexes = [];
    this.currentSearchIndexesValues = {};
    this.allsearchIndices = [];
    this.validSearch = false;
    this.extractAllSearchIndexes(useCacheValue);
  }

  deepEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = this.isObject(val1) && this.isObject(val2);
      if (areObjects && !this.deepEqual(val1, val2) || !areObjects && val1 !== val2) {
        return false;
      }
    }

    return true;
  }

  isObject(obj) {
    return obj != null && typeof obj === 'object';
  }

  extractAllSearchIndexes(useCacheValue?: boolean) {
    const allUniqueSearchIndices = new Map<String, String>();
    const commonSearchIndices = new Map<String, String>();

    if (this.documentTypes) {
      this.selectedDocumentTypeNames.forEach(selectedDoctype => {
        this.documentTypes.forEach(documentType => {
          if (documentType) {
            if (documentType['name'] === selectedDoctype) {
              documentType['searchIndices'].forEach(indexObject => {
                // validation values
                if (indexObject['operators'] && indexObject['operators'].length > 0 ) {
                  indexObject['indexOperators'] = [];
                  indexObject['operators'].forEach( element => {
                    indexObject['indexOperators'].push(this.getOperatorByValue(element));
                    if (element === 'like') {
                         indexObject['indexOperators'].push({value: 'like*', label: 'Begins With', multi: false, relative: false});
                         indexObject['indexOperators'].push({value: '*like', label: 'Ends With', multi: false, relative: false});
                    }
                  });
                } else {
                  indexObject['indexOperators'] = this.getOperators(indexObject['dataType']);
                }
                // default selected operator is the first operator from dropdown of operators
                indexObject['selectedOperator'] = indexObject['indexOperators'][0].value;
                indexObject['value'] = '';
                indexObject['changed'] = false;
                indexObject['errors'] = [];
                indexObject['documentTypeName'] = documentType['name'];

                if (indexObject['dataType'] !== 'String') {
                  indexObject['secondValue'] = '';
                  indexObject['secondChanged'] = false;
                }

                if (!allUniqueSearchIndices.get(documentType['name'] + indexObject.searchIndex)) {
                  allUniqueSearchIndices.set(documentType['name'] + indexObject.searchIndex, indexObject);
                } else {
                  if (this.deepEqual(allUniqueSearchIndices.get(documentType['name'] + indexObject.searchIndex), indexObject)) {
                    commonSearchIndices.set(documentType['name'] + indexObject.searchIndex, indexObject);
                  }
                }
              });
            }
          }
        });
      });
    }

    this.allSearchIndicesTable = [];
    allUniqueSearchIndices.forEach((indexObject, name) => {
      if (indexObject['showInResult']) {
        this.allSearchIndicesTable.push(
          {
            key: name, name,
            label: indexObject['label'],
            dataType: indexObject['dataType'],
            isAdvancedSearch: indexObject['isAdvancedSearch']
          }
        );
      }
    });

    this.allsearchIndices = Array.from(allUniqueSearchIndices, ([name, indexObject]) => (indexObject));
    this.commonSearchIndexes = this.allsearchIndices;
    this.commonSearchIndexes = this.commonSearchIndexes.filter((s) => s.searchable !== false);
    if (useCacheValue && this.tempCommonSearchIndexes.length) {
      this.commonSearchIndexes = this.commonSearchIndexes.map((searchIndex) => {
        let matchSI = this.tempCommonSearchIndexes.filter(s => (s.searchIndex === searchIndex.searchIndex && s.documentTypeName === searchIndex.documentTypeName));
        if (matchSI.length) {
          this.updateCurrentSearchIndexes(matchSI[0], true);
          return matchSI[0];
        }
        this.updateCurrentSearchIndexes(searchIndex, true);
        return searchIndex;
      });

      this.tempCommonSearchIndexes = [];
    }
  }

  isMultiOperator(searchIndex): boolean {
    let indexOperator;

    if (searchIndex && searchIndex['indexOperators']) {
      indexOperator = searchIndex['indexOperators'].find(io => {
        return io.value === searchIndex['selectedOperator'];
      });
    }

    return (indexOperator && indexOperator.multi);
  }

  getOperators(datatype: any) {
    return this.operatorMap[datatype];
  }

  getOperatorByValue(value: any) {
    return this.operatorValueMap[value];
  }

  checkDocumentType(documentTypeName: string) {
    if (documentTypeName === this.documentType) {
      return false;
    } else {
      this.documentType = documentTypeName;
      return true;
    }
  }
}
