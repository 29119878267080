import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';

//  This filter extracts the keys from an object, and sorts them and joins them 
// Into a text string which can then be filters using the template defination
@Component({
  selector: 'object-keys-text-filter',
  template: `
    <input type="text" class="clr-input" name="text-filter" [formControl]="control"/>
    <button class="btn btn-sm btn-block btn-clear-filter" (click)="clearFilter()">Clear</button>
  `,
  styles: [`
    .btn-clear-filter {
      margin-top: 0.5rem;
    }
  `],
})
export class ObjectKeysTextFilter implements OnInit, OnDestroy {
  @Input() propertyName: string = null;
  control: FormControl = new FormControl(null);
  
  private _changes = new Subject<any>();
  subscriptions: Subscription = new Subscription();

  // We do not want to expose the Subject itself, but the Observable which is read-only
  public get changes(): Observable<any> {
    return this._changes.asObservable();
  }

  ngOnInit(): void {
    this.subscriptions.add(this.control.valueChanges.subscribe(value => {
      this._changes.next(true);
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  clearFilter() {
    this.control.setValue(null);
    this._changes.next(true);
  }

  accepts(item: any): boolean {
    if (!this.control.value) {
      return true;
    }
    const textInput = this.control.value.trim().toLowerCase();
    const value = Object.keys(item[this.propertyName]).sort().join().toLowerCase();
    return value && value.toString().indexOf(textInput) > -1;
  }

  isActive(): boolean {
    return this.control.value !== null;
  }
}
