import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { FormControl } from '@angular/forms';
import { fadeInOutAnimation } from '../../animations';
import { IimUser } from 'ui-sdk/models/iim-user.model';
import { SavedSearch } from 'ui-sdk/models/saved-search.model';
import { AlertService } from '../../services/alert.service';
import {UserMethods} from 'ui-sdk';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-saved-search-select',
  templateUrl: './saved-search-select.component.html',
  styleUrls: ['./saved-search-select.component.scss'],
  animations: [fadeInOutAnimation]
})
export class SavedSearchSelectComponent implements OnInit, OnDestroy {
  control: FormControl = new FormControl(null);

  @Input() savedSearches: Array<SavedSearch> = [];
  @Input() user: IimUser = null;
  @Output() selected: EventEmitter<SavedSearch> = new EventEmitter<SavedSearch>();
  @Output() deleted: EventEmitter<SavedSearch> = new EventEmitter<SavedSearch>();

  currentUser: IimUser;
  subscriptions: Subscription = new Subscription();

  constructor(private alertService: AlertService) {}

  ngOnInit() {
    const userSubscription = UserMethods.currentUser$.subscribe((user: IimUser) => this.currentUser = user);
    this.subscriptions.add(userSubscription);
    this.onChanges();
  }

  onChanges() {
    this.control.valueChanges.subscribe((selectedSavedSearch: SavedSearch) => {
      this.selected.emit(selectedSavedSearch);
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  deleteSavedSearch(value: SavedSearch) {
    this.alertService.confirmDangerousAction('Delete Saved Search?', `Are you sure you want to delete search '${value.itemName}'?`, 'blue-header-modal', true).then(confirmed => {
      if (confirmed) {
        this.clearSavedSearch();
        this.deleted.emit(value);
      }
    });
  }

  clearSavedSearch() {
    this.control.setValue(null);
  }
}
