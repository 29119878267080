<div class="legal-hold-page">
    <div class="clr-row">
        <div class="clr-col-12">
            <h1><b>Active Holds</b></h1>
          </div>
    </div>
    <div class="clr-row clr-justify-content-center">
      <div class="clr-col-5">
        <div class="card chart-card">
          <div class="card-block">
            <div class="clr-row clr-align-items-center">
              <div class="clr-col-6 info-text">
                <h3>
                  <span class="highlited-text"><b>{{pieChartData[0]}}</b></span> Total Holds
                </h3>
                <h3>
                  <span class="highlited-text"><b>{{pieChartData[1]}}</b></span> Active Holds
                </h3>
                <div>
                </div>
              </div>
              <div class="clr-col-6 ">
                <div class="chart-section">
                  <app-piechart [total]="pieChartData[0]" [active]="pieChartData[1]"></app-piechart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="clr-col-5">
        <div class="card chart-card">
          <div class="card-block">
            <div class="chart-section">
              <app-barchart [labels]="barChartLabel" [data]="barChartData"></app-barchart>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
<div class="clr-row">
  <div class="clr-col-12">
<div class="card table-card">
<div [@fadeInOut] *ngIf="searchResults && !isSearchFormCollapsed" (click)="isSearchFormCollapsed = true"></div>

<div class="content-area">
<div class="clr-row">
    <div class="clr-col-12">
<h4>Reports</h4>
</div>
</div>
<div class="collapser" *ngIf="searchResults">
  <button class="btn btn-sm collapse-btn btn-icon btn-link" [disabled]="!searchResults" (click)="isSearchFormCollapsed = !isSearchFormCollapsed">
    <clr-icon shape="angle-double" [dir]="isSearchFormCollapsed ? 'down' : 'up'"></clr-icon>
    {{isSearchFormCollapsed ? 'Show' : 'Hide'}} Search
  </button>
</div>

<div [@fadeIn] [hidden]="isSearchFormCollapsed" class="clr-row search-form-area overlap">
  <div class="clr-col-xs-12 clr-col-sm-12 clr-col-md-12 clr-col-lg-12 clr-col-xl-12">

    <form class="search-form" clrForm clrLayout="horizontal" [formGroup]="searchForm" (ngSubmit)="onSubmitSearch()"
      (keydown.enter)="$event.preventDefault()">

      <section class="form-block search-form__section-one doctype-btn-group">
        <div class="clr-form-control doctypes-header-row">
          <label for="docTypeNames" class="clr-control-label clr-control-label_main clr-col-xs-12 doctype-label">
            Document Types
            <span *ngIf="documentTypes.length > 0 && !loadingDocTypes" class="badge badge-info">
              {{documentTypes.length}}
            </span>

            <span *ngIf="documentTypes.length == 0 && !loadingDocTypes" class="badge badge-danger">
              {{documentTypes.length}}
            </span>

            <span *ngIf="loadingDocTypes" class="spinner spinner-inline"></span>

          </label>
          <ng-multiselect-dropdown [placeholder]="'Select Document Types'" [data]="documentTypes"
            formControlName="docTypeNames" [settings]="dropdownSettings" (onSelect)="onDocumentTypeSelect($event)"
            (onSelectAll)="onDocumentTypeSelectAll($event)" (onDeSelect)="onDocumentTypeDeSelect($event)"
            (onDeSelectAll)="onDocumentTypeDeSelectAll($event) " ngDefaultControl>
          </ng-multiselect-dropdown>
        </div>

      </section>

      <div [@fadeIn] class="clr-form-control clr-row search-indexes-area">
        <div [@fadeIn] class="clr-col-xs-12 clr-col-md-9" *ngIf="commonSearchIndexes.length < 1 && selectedDocumentTypeNames.length == 1">
          <div class="clr-row">
            <div class="clr-control-container clr-col-xs-12 clr-col-sm-6">
              <p>Selected Document Type has no Search Indexes.</p>
            </div>
          </div>
        </div>

        <div [@fadeIn] class="clr-col-xs-12 clr-col-md-9" *ngIf="commonSearchIndexes.length < 1 && selectedDocumentTypeNames.length > 1">
          <div class="clr-row">
            <div class="clr-control-container clr-col-xs-12 clr-col-sm-6">
              <p>No Common Search Indexes.</p>
            </div>
          </div>
        </div>

        <div [@fadeIn] class="clr-col-xs-12 clr-col-md-9" *ngIf="selectedDocumentTypeNames.length < 1 && documentTypes.length > 0">
          <div class="clr-row">
            <div class="clr-control-container clr-col-xs-12 clr-col-sm-6">
              <p>Please select a Document Type/s above.</p>
            </div>
          </div>
        </div>

          <div class="clr-row clr-col-12 search-indexes-section">
              <div class="search-indexes-inputs clr-col-12 clr-col-xl-6" *ngFor="let searchIndex of commonSearchIndexes">
                <label [@fadeIn] class="index-label" title="{{searchIndex.label}}" #searchIndexLabel>{{searchIndex.label}}</label>

                <span [class.multipe-operator]="isMultiOperator(searchIndex)">
                <input *ngIf="!searchIndex.dataType.includes('Date')"
                        [ngClass]="searchIndex.errors[0] ? 'validation-error' : ''"
                        title="{{searchIndex.errors[0]}}"
                        value="{{searchIndex.value}}"
                        clrInput
                        placeholder="Enter Value"
                        class="search-index-input clr-select"
                        [type]="searchIndex.dataType.includes('Integer') || searchIndex.dataType.includes('Decimal') ? 'number' : searchIndex.dataType"
                        (keyup.enter)="searchOnEnter(searchIndex, firstValue.value, true)"
                        (input)="searchIndexFocusOut(searchIndex, firstValue.value, true)"
                        #firstValue>

                <input *ngIf="!searchIndex.dataType.includes('Date') && isMultiOperator(searchIndex)"
                       [ngClass]="searchIndex.errors[1] ? 'validation-error' : ''"
                       title="{{searchIndex.errors[1]}}"
                       value="{{searchIndex.secondValue}}"
                       clrInput
                       placeholder="Enter Value"
                       class="search-index-input clr-select"
                       [type]="searchIndex.dataType.includes('Integer') || searchIndex.dataType.includes('Decimal') ? 'number' : searchIndex.dataType"
                       (keyup.enter)="searchOnEnter(searchIndex, secondValue.value, false)"
                       (input)="searchIndexFocusOut(searchIndex, secondValue.value, false)"
                       #secondValue>
                </span>
                <span [class.multipe-operator]="isMultiOperator(searchIndex)">
                <input *ngIf="searchIndex.dataType.includes('Date')"
                       [ngClass]="searchIndex.errors[0] ? 'validation-error' : ''"
                       title="{{searchIndex.errors[0]}}"
                       value="{{searchIndex.value | date: 'mediumDate'}}"
                       [name]="searchIndex.searchIndex"
                       placeholder="Add Date"
                       readonly
                       clrDate
                       type="date"
                       class="search-index-input search-index-date"
                       (clrDateChange)="dateChange(searchIndex, $event, true)"
                       autocomplete="off">

                <input *ngIf="searchIndex.dataType.includes('Date') && isMultiOperator(searchIndex)"
                       [ngClass]="searchIndex.errors[1] ? 'validation-error' : ''"
                       title="{{searchIndex.errors[1]}}"
                       value="{{searchIndex.secondValue | date: 'mediumDate'}}"
                       [name]="searchIndex.searchIndex"
                       placeholder="Add Date"
                       readonly
                       clrDate
                       type="date"
                       class="search-index-input search-index-date"
                       (clrDateChange)="dateChange(searchIndex, $event, false)"
                       autocomplete="off">
                </span>
                <button *ngIf="searchIndex.dataType.includes('Date') && (searchIndex.value || searchIndex.secondValue)"
                        type="button"
                        class="btn-clear-date btn-no-caps"
                        (click)="clearDate(searchIndex)">
                  <clr-icon class="clear-date-icon" title="Clear date input(s)" type=button shape="trash"></clr-icon>
                </button>

              </div>
          </div>
        </div>

        <div class="clr-row search-form__actions">
          <div class="clr-offset-md-3"></div>
          <div class="clr-col-xs-12 clr-col-md-9">
            <button type="submit" id="search-form-submit" [disabled]="!validSearch"
                    class="btn btn-no-caps btn-br btn-primary" [clrLoading]="loadingSearchResults"
                    >
              Search
            </button>
            <button type="button" (click)="resetSearchForm()"
                    class="btn btn-no-caps btn-br btn-secondary">
              Clear
            </button>
          </div>
        </div>
    </form>
  </div>
</div>

<div [@fadeIn] *ngIf="searchResults" [hidden]="!isSearchFormCollapsed" class="clr-row search-results-area">
  <div class="clr-col-xs-12 clr-col-sm-12">
    <app-legal-hold-table [initialItems]="searchResults" [sortKeyForLocalStorage]="SORT_KEY_LOCAL_STORAGE" [clientDocumentTypes]="documentTypeIdMap" [searchIndexesTable]="allsearchIndicesTable" [currentUser]="currentUser"></app-legal-hold-table>
  </div>
</div>

</div>
</div>
</div>
</div>
