<clr-modal id="legal-hold-modal"  [clrModalOpen]="true"  (clrModalOpenChange)="closeModal()"
           [clrModalStaticBackdrop]="false" [clrModalSize]="'md'">
  <div class="modal-title">
    <div class="rectangle r1"></div>
    <div class="rectangle r2"></div>
    <h3 class="modal-title-text">
      <ng-container>Manage Legal Hold(s)</ng-container>
    </h3>
  </div>
  <div class="modal-body">
    <form class="legal-hold-form" clrForm [formGroup]="legalHoldForm">
      <clr-tabs>
        <clr-tab>
          <button (click)="activeTab = 'addLegalHold'" clrTabLink>
            <ng-container *ngIf="isNewLegalHold()">Add Legal Hold</ng-container>
            <ng-container *ngIf="!isNewLegalHold()">Edit Legal Hold</ng-container>
          </button>
          <clr-tab-content id="doc-type-general-tab" *clrIfActive="true">
            <clr-input-container class="space-around">
              <label class="clr-control-label">Account Number:</label>
              <input class="clr-col-lg-12" type="text" clrInput placeholder="Enter Account Number" name="legal-hold-modal__legal-hold-account-number"
                      id="legal-hold-modal__legal-hold-account-number" formControlName="legalHoldAccountNumber"/>
            </clr-input-container>
            <clr-input-container class="space-around">
              <label class="clr-control-label">Legal Hold Name:</label>
              <input class="clr-col-lg-12" type="text" clrInput placeholder="Enter Legal Hold Name" name="legal-hold-modal__legal-hold-name"
                      id="legal-hold-modal__legal-hold-name" formControlName="legalHoldName"/>
              <clr-control-error>Please provide a name.</clr-control-error>
            </clr-input-container>
            <clr-input-container class="space-around">
              <label class="clr-control-label">Description:</label>
              <input class="clr-col-lg-12" type="text" clrInput placeholder="Enter Legal Hold Description" name="legal-hold-modal__legal-hold-description"
                      id="legal-hold-modal__legal-hold-description" formControlName="legalHoldDescription"/>
              <clr-control-error>Please provide a description.</clr-control-error>
            </clr-input-container>
          </clr-tab-content>
        </clr-tab>
        <clr-tab *ngIf="isNewLegalHold()">
          <button (click)="activeTab = 'removeLegalHold'" clrTabLink>Remove Legal Hold</button>
          <clr-tab-content *clrIfActive="false">

            <div  *ngIf="activeLegalHoldsInfo.length > 0">
              <div class="legalHoldSelectGroup">
                <label class="clr-control-label">
                  Legal Hold:
                  <span *ngIf="activeLegalHoldsInfo.length > 0" class="badge badge-info">
                    {{activeLegalHoldsInfo.length}}
                  </span>
                </label>
                
                <div class="clr-select-wrapper">
                  <select id="select-basic" class="clr-select" name="legalHoldGroupId" formControlName="legalHoldGroupId">
                    <option selected disabled value="null">Select Legal Hold to remove</option>
                    <option *ngFor="let legalHold of activeLegalHoldsInfo; let i - index" value="{{legalHold.legalHoldId}}">{{ legalHold.name == null ? legalHold.legalHoldId : legalHold.name}}</option>
                  </select>
                </div>

              </div>

                <clr-datagrid *ngIf="selectedLegalHold !== ''">
                  <clr-dg-column>
                    Document Name
                  </clr-dg-column>
                  <clr-dg-column>
                    Document ID
                  </clr-dg-column>
                  <clr-dg-row *clrDgItems="let document of displayCurrentDocuments" [clrDgItem]="document" >
                    <clr-dg-cell>
                      {{document.fileName}}
                    </clr-dg-cell>
                    <clr-dg-cell>
                      {{document.id}}
                    </clr-dg-cell>
                  </clr-dg-row>
                  <clr-dg-footer>
                    <clr-dg-pagination #pagination [clrDgPageSize]="10">
                      {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
                      of {{pagination.totalItems}} documents
                    </clr-dg-pagination>
                  </clr-dg-footer>
                </clr-datagrid>
            </div>

            <p *ngIf="activeLegalHoldsInfo.length === 0">
              Selected Documents have no active Legal Holds. 
            </p>

          </clr-tab-content>
        </clr-tab>
      </clr-tabs>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="closeModal()">Cancel</button>
    <button id="new-legalHold-submit-trigger" type="submit" (click)="onSubmit()"
            [disabled]="!legalHoldForm.dirty && activeTab === 'addLegalHold' || selectedLegalHold === '' && activeTab === 'removeLegalHold'" class="btn btn-primary" [clrLoading]="processingModalRequest">
      <ng-container *ngIf="activeTab === 'addLegalHold' && isNewLegalHold()">Add Legal Hold</ng-container>
      <ng-container *ngIf="!isNewLegalHold()">Save Legal Hold</ng-container>
      <ng-container *ngIf="activeTab === 'removeLegalHold' && isNewLegalHold()">Remove Legal Hold</ng-container>
    </button>
  </div>
</clr-modal>
