import {Component, Input, OnInit, OnChanges} from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { MultiDataSet, Label } from 'ng2-charts';

@Component({
  selector: 'app-piechart',
  templateUrl: './piechart.component.html',
  styleUrls: ['./piechart.component.scss']
})
export class PieChartComponent implements OnInit, OnChanges {
  @Input() total: any;
  @Input() active: any;

  // Doughnut
  public doughnutChartLabels: Label[] = ['Active Holds', 'Non Active Holds'];
  public doughnutChartData: MultiDataSet = [];
  public chartColorsDocTypeIndividual: Array<any> = [
    {
      backgroundColor: ['#00567a', '#5EB715'],
      hoverBackgroundColor: ['#93ea93', '#FF5A5E'],
      borderWidth: 2,
    }
  ];
  public doughnutChartType: ChartType = 'doughnut';
  public chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    // We use these empty structures as placeholders for dynamic theming.
    legend: {
      display: false
    },

  };

  constructor() {
    this.doughnutChartData = [this.active, this.total - this.active];
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.doughnutChartData = [this.active, this.total - this.active];
  }

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
  }
}
