<clr-modal id="data-mapping-modal" [clrModalOpen]="modalEnabled===true" (clrModalOpenChange)="closeModal()"
           [clrModalStaticBackdrop]="false" [clrModalSize]="'md'">
  <div class="modal-title">
    <div class="rectangle r1"></div>
    <div class="rectangle r2"></div>
    <h3 class="modal-title-text text1">
      <ng-container *ngIf="!_selectedSiteMapping">New Site Mapping</ng-container>
      <ng-container *ngIf="_selectedSiteMapping">
        Edit Site Mapping [{{_selectedSiteMapping?.companyId}}]
        <button class="btn btn-icon btn-link copy-to-clipboard-btn" title="Copy Company Id" [appCopyClipboard]="_selectedSiteMapping?.companyId" (copied)="notify($event)">
          <clr-icon shape="copy-to-clipboard"></clr-icon>
        </button>

        <span *ngIf="_selectedSiteMapping?.companyId == copyIdToClipboard" class="clipboard-copy-success clipboard-copy-success-modal">
            <clr-icon shape="check" size="14"></clr-icon>
            Copied
        </span>
      </ng-container>
    </h3>
  </div>
  <div class="modal-body modal-lg">
    <form class="site-mapping-form" clrForm [formGroup]="siteMappingForm">
      <clr-input-container class="form-control site-mapping-input">
        <label class="clr-control-label clr-col-xs-12" for="company_id">Company Id:</label>
        <input class="clr-col-xs-12" type="text" clrInput placeholder="Enter Company Id" name="company_id"
               id="company_id" formControlName="companyId"/>
        <clr-control-error>Please provide a valid Company Id.</clr-control-error>
      </clr-input-container>

      <clr-select-container class="form-control clr-select-wrapper_height site-mapping-input">
        <label class="clr-control-label clr-col-xs-12" for="client_id">Client Id:</label>
        <select class="archive-provider-dropdown clr-col-xs-12" clrSelect id="client_id" name="client_id"
                formControlName="clientId">
          <option selected disabled value="null">Select a Client Id</option>
          <option *ngFor="let client of _clients" value="{{client?.id}}">{{client?.name}}</option>
        </select>
        <clr-control-error>Please select a Client Id</clr-control-error>
      </clr-select-container>

      <clr-input-container class="form-control site-mapping-input">
        <label class="clr-control-label clr-col-xs-12" for="external_client_id">External Client Id:</label>
        <input class="clr-col-xs-12" type="text" clrInput placeholder="Enter External Client Id" name="external_client_id"
               id="external_client_id" formControlName="externalClientId"/>
        <clr-control-error>Please provide a valid External Client Id.</clr-control-error>
      </clr-input-container>

    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="closeModal()">Cancel</button>
    <button id="new-document-type-submit-trigger" type="submit" (click)="onSubmit()"
            [disabled]="!saveButtonEnabled()" class="btn btn-primary" [clrLoading]="processingModalRequest">
      <ng-container *ngIf="isNewDataMapping()">Add</ng-container>
      <ng-container *ngIf="!isNewDataMapping()">Save</ng-container>
    </button>
  </div>
</clr-modal>
