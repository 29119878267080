import { Component, OnDestroy } from '@angular/core';
import { fadeInDownAnimation } from './animations';
import { IimUser } from 'ui-sdk/models/iim-user.model';
import { Subscription } from 'rxjs';
import {UserMethods} from 'ui-sdk';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    fadeInDownAnimation
  ]
})
export class AppComponent implements OnDestroy {
  currentUser: IimUser;
  userSubscription: Subscription;

  constructor() {
    this.userSubscription = UserMethods.currentUser$.subscribe((user: IimUser) => {
      this.currentUser = user;
      }
    );
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

  loadingUser(): boolean {
    return UserMethods.loading;
  }
}
